import { Text } from "@chakra-ui/react";

const IntroText = ({ children }: { children: React.ReactNode }) => (
	<>
		<Text textAlign="center" fontSize="xl" lineHeight="short" color="#4A5568">
			{children}
		</Text>
		{/* <CustomDivider /> */}
	</>
);

export default IntroText;
