import { Heading } from "@chakra-ui/react";

interface SectionTitleProps {
	children: React.ReactNode;
}

const SectionTitle = ({ children }: SectionTitleProps) => (
	<Heading as="h2" size="2xl" textAlign="center">
		{children}
	</Heading>
);

export default SectionTitle;
