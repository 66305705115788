import { useMutation } from "@apollo/client";
import { updateCatalog as UPDATE_CATALOG } from "./mutations";
import { CatalogInput } from "../../types";

interface UpdateCatalogData {
	updateCatalog: boolean;
}

const useUpdateCatalog = () => {
	const [updateCatalogMutation, { loading, error }] =
		useMutation<UpdateCatalogData>(UPDATE_CATALOG);
	const updateCatalog = async (
		catalogID: string,
		catalogInput: CatalogInput,
	) => {
		try {
			const { data } = await updateCatalogMutation({
				variables: {
					catalogID,
					catalogInput,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.updateCatalog };
		} catch (error: any) {
			return { error };
		}
	};
	return { updateCatalog, loading, error };
};
export default useUpdateCatalog;
