import React, { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { ECWID_CATEGORY_MAPPING, ECWID_SEARCH_MAPPING } from "consts";
import { StoreContext } from "contexts";
import { Flex } from "@chakra-ui/react";
import { EcwidStore } from "page-components";

const StoreCategory: React.FC = () => {
	const { category } = useParams();
	const { openPage } = useContext(StoreContext);

	useEffect(() => {
		if (!category) {
			return;
		}

		const keyword = ECWID_CATEGORY_MAPPING[category];
		if (!keyword) {
			const search = ECWID_SEARCH_MAPPING[category];
			if (search) {
				openPage("search", { keyword: search });
			} else {
				openPage("search", { keyword: category });
			}
		}
	}, [category, openPage]);

	return (
		<Flex zIndex={1}>
			<EcwidStore
				categoryId={ECWID_CATEGORY_MAPPING[category || ""]}
				search={category}
			/>
		</Flex>
	);
};

export default StoreCategory;
