import React, { useLayoutEffect } from "react";
import { Box, Flex, Progress } from "@chakra-ui/react";
import useBoundingRect from "./useBoundingRect"; // Assuming the custom hook
import { percentage } from "./utils"; // Assuming the utility function
import { colors } from "styles";

interface SliderProps {
	setTrackIsActive: (isActive: boolean) => void;
	initSliderWidth: (width: number) => void;
	setActiveItem: (updateFn: (prev: number) => number) => void;
	activeItem: number;
	constraint: number;
	itemWidth: number;
	positions: number[];
	children: React.ReactNode;
	gap: number;
	button: React.ReactNode;
}

const Slider: React.FC<SliderProps> = ({
	setTrackIsActive,
	initSliderWidth,
	setActiveItem,
	activeItem,
	constraint,
	itemWidth,
	positions,
	children,
	gap,
	button,
}) => {
	const [ref, { width }] = useBoundingRect();

	useLayoutEffect(
		() => initSliderWidth(Math.round(width)),
		[width, initSliderWidth],
	);

	const handleFocus = () => setTrackIsActive(true);

	// const handleDecrementClick = () => {
	// 	setTrackIsActive(true);
	// 	if (activeItem > 0) {
	// 		setActiveItem((prev) => prev - 1);
	// 	}
	// };

	// const handleIncrementClick = () => {
	// 	setTrackIsActive(true);
	// 	if (activeItem < positions.length - constraint) {
	// 		setActiveItem((prev) => prev + 1);
	// 	}
	// };

	return (
		<Flex
			direction="column"
			alignItems="center"
			justifyContent="space-between"
			w="full"
			onFocus={handleFocus}
			overflow="hidden"
			position="relative"
			//Start the slider translated to the right by half of the slider width
			//to center the first item in the viewport
		>
			<Box
				ref={ref}
				w={{ base: "100%", md: `calc(100% + ${gap}px)` }}
				ml={{ base: 0, md: `-${gap / 2}px` }}
				px={`${gap / 2}px`}
				position="relative"
				overflow="hidden"
				_before={{
					bgGradient: "linear(to-r, base.d400, transparent)",
					position: "absolute",
					w: `${gap / 2}px`,
					content: "''",
					zIndex: 1,
					h: "100%",
					left: 0,
					top: 0,
				}}
				_after={{
					bgGradient: "linear(to-l, base.d400, transparent)",
					position: "absolute",
					w: `${gap / 2}px`,
					content: "''",
					zIndex: 1,
					h: "100%",
					right: 0,
					top: 0,
				}}
				mb={24}
			>
				{children}
			</Box>

			<Flex
				w={{
					base: `${itemWidth}px`,
					md: `${itemWidth * 4}px`,
				}}
				mt={`${gap / 2}px`}
				mx="auto"
				borderRadius="12px"
				overflow="hidden"
				border="2px solid"
				borderColor={colors.graydient[200]}
				//mt={4}
			>
				<Progress
					hasStripe
					isAnimated
					value={percentage(activeItem, positions.length - constraint)}
					alignSelf="center"
					borderRadius="8px"
					//bg="base.d100"
					flex={1}
					size={"lg"}
					colorScheme="primary"
				/>
			</Flex>
			{button}
		</Flex>
	);
};

export default Slider;
