import React from "react";
import { Form } from "components";
import { ModalWrapper } from "wrappers";
import { useUpdateProductCollectionForm } from "hooks/form-hooks";
import { ProductCollection } from "services/types";

interface UpdateProductCollectionFormProps {
	isOpen: boolean;
	onClose: () => void;
	refetch: () => Promise<any>;
	existingProductCollection: ProductCollection;
}

const UpdateProductCollectionForm: React.FC<
	UpdateProductCollectionFormProps
> = ({ isOpen, onClose, refetch, existingProductCollection }) => {
	const { fields, handleSubmit, loading } = useUpdateProductCollectionForm({
		refetch,
		existingProductCollection,
	});

	const onSubmit = async () => {
		await handleSubmit();
		onClose();
	};

	return (
		<ModalWrapper
			isOpen={isOpen}
			onClose={onClose}
			showShadow={true}
			heading="Update Product Collection"
			hideCloseButton={false}
			size={"5xl"}
		>
			<Form
				fields={fields}
				onSubmit={onSubmit}
				isLoading={loading}
				maxHeight="85vh"
				overflowY={"auto"}
			/>
		</ModalWrapper>
	);
};

export default UpdateProductCollectionForm;
