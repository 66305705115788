/**
 * @module ModalButton.tsx
 */
import React from "react";
import { Button } from "@chakra-ui/react";

/**
 * @typedef ModalButtonProps
 * @property {string} buttonText The text of the button.
 * @property {() => void} onClick The function to call when the button is clicked.
 */
interface ModalButtonProps {
	buttonText: string;
	onClick: () => void;
}

/**
 * A button for a modal.
 * @param {ModalButtonProps} props The props of the component.
 * @returns {React.ReactElement} A button for a modal.
 */
const ModalButton: React.FC<ModalButtonProps> = ({ buttonText, onClick }) => {
	return (
		<Button variant="primary" size={{ base: "md", md: "lg" }} onClick={onClick}>
			{buttonText}
		</Button>
	);
};

export default ModalButton;
