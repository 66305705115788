import React, { useState } from "react";
import {
	ActionsRow,
	ContactDetailsPopover,
} from "components/features/tables/cells";
import { useRetrieval } from "hooks";
import { SearchTable } from "components/features/tables";
import {
	DealerApplication,
	useGetDealerApplications,
	FilterSettingsInput,
	useResolveDealerApplication,
} from "services";
import { createColumnHelper } from "@tanstack/react-table";
import { DeleteDealerApplicationModal } from "page-components/admin/dealer-applications";
import { AdminResponsiveLayout } from "components";
import { Badge, useToast } from "@chakra-ui/react";
import { FaCheck, FaTimes } from "react-icons/fa";

const AdminDealerApplications: React.FC = () => {
	const {
		paginationInput,
		setPaginationInput,
		filterSettings,
		handleFiltersChange,
		sortInput,
		handleSortChange,
	} = useRetrieval();

	const handleBuildFilterSettings = (
		query: string,
		conditional: "AND" | "OR" = "OR",
	) => {
		const filterSettings: FilterSettingsInput = {
			conditional,
			filters: [
				{
					field: "company",
					operator: "regex",
					value: query,
				},
				{
					field: "contactName",
					operator: "regex",
					value: query,
				},
			],
		};
		return filterSettings;
	};

	const {
		loading,
		error,
		data: dealerApplications,
		refetch,
	} = useGetDealerApplications({
		filterSettings: handleBuildFilterSettings(
			filterSettings.query,
			filterSettings.conditional,
		),
		pagination: paginationInput,
		sort: sortInput,
	});

	const [selectedDealerApplication, setSelectedDealerApplication] =
		useState<DealerApplication | null>(null);

	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

	const closeDeleteModal = () => {
		setIsDeleteModalOpen(false);
		setSelectedDealerApplication(null);
	};

	const onRowDelete = (pos: DealerApplication) => {
		setSelectedDealerApplication(pos);
		setIsDeleteModalOpen(true); // Open the modal
	};

	const toast = useToast();
	const { resolveDealerApplication } = useResolveDealerApplication();
	const handleResolveDealerApplication = async (
		dealerApplicationId: string,
		status: string,
	) => {
		const { data, error } = await resolveDealerApplication(
			dealerApplicationId,
			status,
		);
		if (error) {
			toast({
				title: "Error",
				description: error.message,
				status: "error",
				duration: 5000,
				isClosable: true,
			});
		} else if (data) {
			toast({
				title: "Success",
				description: "Dealer application resolved successfully.",
				status: "success",
				duration: 5000,
				isClosable: true,
			});
			await refetch();
		} else {
			toast({
				title: "Error",
				description: "An unexpected error occurred. Please try again later.",
				status: "error",
				duration: 5000,
				isClosable: true,
			});
		}
	};

	const columnHelper = createColumnHelper<DealerApplication>();

	const columns = [
		columnHelper.accessor("applicationDetails.name", {
			header: () => "Name",
			cell: (info) => info.getValue(),
		}),
		columnHelper.accessor("applicationDetails.company", {
			header: () => "Company",
			cell: (info) => info.getValue(),
		}),
		columnHelper.accessor("applicationDetails.address", {
			header: () => "Address",
			cell: (info) => {
				const { line1, city, state, zipcode } = info.getValue();
				return `${line1}, ${city}, ${state}, ${zipcode}`;
			},
		}),
		columnHelper.accessor("applicationDetails", {
			header: () => "Contact",
			cell: (info) => {
				const { cell, email, office, fax } = info.getValue();
				return (
					<ContactDetailsPopover
						cell={cell}
						email={email}
						office={office}
						fax={fax}
					/>
				);
			},
		}),
		columnHelper.accessor("applicationDetails.lineOfBusiness", {
			header: () => "Line of Business",
			cell: (info) => info.getValue(),
		}),
		columnHelper.accessor("status", {
			header: () => "Application Status",
			cell: (info) => {
				const status = info.getValue();
				let colorScheme = "gray";

				switch (status.toLowerCase()) {
					case "approved":
						colorScheme = "green";
						break;
					case "rejected":
						colorScheme = "red";
						break;
					case "pending":
						colorScheme = "orange";
						break;
					// Add more cases as needed
				}
				return (
					<Badge borderRadius={"4"} colorScheme={colorScheme}>
						{status}
					</Badge>
				);
			},
		}),
		columnHelper.display({
			header: () => "Actions",
			id: "actions",
			cell: (props) => (
				<ActionsRow
					onDelete={() => onRowDelete(props.row.original)}
					actions={[
						{
							name: "Accept Application",
							icon: <FaCheck />,
							onClick: () => {
								handleResolveDealerApplication(
									props.row.original._id,
									"approved",
								);
							},
						},
						{
							name: "Reject Application",
							icon: <FaTimes />,
							onClick: () => {
								handleResolveDealerApplication(
									props.row.original._id,
									"rejected",
								);
							},
						},
					]}
				/>
			),
		}),
	];

	return (
		<AdminResponsiveLayout>
			<SearchTable
				columnsData={columns}
				tableData={dealerApplications?.items || []}
				paginationInput={paginationInput}
				setPaginationInput={setPaginationInput}
				filterSettings={filterSettings}
				handleBuildFilters={handleFiltersChange}
				loading={loading}
				error={error}
				sortInput={sortInput}
				handleSortChange={handleSortChange}
				totalCount={dealerApplications?.totalCount || 0}
				hasNextPage={dealerApplications?.hasNextPage || false}
				hasPreviousPage={dealerApplications?.hasPreviousPage || false}
			/>
			{selectedDealerApplication && (
				<DeleteDealerApplicationModal
					dealerApplication={selectedDealerApplication}
					isOpen={isDeleteModalOpen}
					onClose={closeDeleteModal}
					refetch={refetch}
				/>
			)}
		</AdminResponsiveLayout>
	);
};

export default AdminDealerApplications;
