/**
 * @module UpdateResourceForm
 * @description A form for updating a resource in the database
 */
import React from "react";
import { Form } from "components";
import { ModalWrapper } from "wrappers";
import { useUpdateResourceForm } from "hooks/form-hooks";
import { Resource } from "services/types";

/**
 * @typedef UpdateResourceFormProps
 * @property {boolean} isOpen - The state of the modal
 * @property {function} onClose - The function to close the modal
 * @property {function} refetch - The function to refetch the resources
 * @property {Resource} existingResource - The resource to be updated
 */
interface UpdateResourceFormProps {
	isOpen: boolean;
	onClose: () => void;
	refetch: () => Promise<any>;
	existingResource: Resource;
}

/**
 * @name UpdateResourceForm
 * @description A form for updating a resource in the database
 * @param {UpdateResourceFormProps} props
 * @returns {JSX.Element}
 */
const UpdateResourceForm: React.FC<UpdateResourceFormProps> = ({
	isOpen,
	onClose,
	refetch,
	existingResource,
}) => {
	// Destructure the fields, handleSubmit, and loading from the useUpdateResourceForm hook
	const { fields, handleSubmit, loading } = useUpdateResourceForm({
		refetch,
		existingResource,
	});

	// Function to submit the form and close the modal on success
	const onSubmit = async () => {
		await handleSubmit();
		onClose();
	};

	return (
		<ModalWrapper
			isOpen={isOpen}
			onClose={onClose}
			showShadow={true}
			heading="Update Resource"
			hideCloseButton={false}
		>
			<Form fields={fields} onSubmit={onSubmit} isLoading={loading} />
		</ModalWrapper>
	);
};

export default UpdateResourceForm;
