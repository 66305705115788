import { useState, useCallback, useLayoutEffect } from "react";

// Defining the type for the callback function
type CallbackFunction = (...args: any[]) => void;

const debounce = (
	limit: number,
	callback: CallbackFunction,
): CallbackFunction => {
	let timeoutId: NodeJS.Timeout | null;
	return (...args: any[]) => {
		if (timeoutId) {
			clearTimeout(timeoutId);
		}
		timeoutId = setTimeout(() => callback(...args), limit);
	};
};

interface DimensionObject {
	width: number;
	height: number;
	top: number;
	left: number;
	x: number;
	y: number;
	right: number;
	bottom: number;
}

function getDimensionObject(node: Element): DimensionObject {
	const rect = node.getBoundingClientRect();
	return {
		width: rect.width,
		height: rect.height,
		top: rect.top,
		left: rect.left,
		x: rect.x,
		y: rect.y,
		right: rect.right,
		bottom: rect.bottom,
	};
}

export default function useBoundingRect(
	limit: number = 100,
): [React.RefCallback<Element>, DimensionObject, Element | null] {
	const [dimensions, setDimensions] = useState<DimensionObject>(
		{} as DimensionObject,
	);
	const [node, setNode] = useState<Element | null>(null);

	const ref = useCallback<React.RefCallback<Element>>((node) => {
		setNode(node);
	}, []);

	useLayoutEffect(() => {
		if (typeof window !== "undefined" && node) {
			const measure = () =>
				window.requestAnimationFrame(() =>
					setDimensions(getDimensionObject(node)),
				);

			measure();

			const listener = debounce(limit || 100, measure);

			window.addEventListener("resize", listener);
			window.addEventListener("scroll", listener);
			return () => {
				window.removeEventListener("resize", listener);
				window.removeEventListener("scroll", listener);
			};
		}
	}, [node, limit]);

	return [ref, dimensions, node];
}
