import { useQuery } from "@apollo/client";
import { getPositionApplications as GET_POSITION_APPLICATIONS } from "./queries";
import {
	RetrievalInput,
	PositionApplication,
	PaginatedResult,
} from "../../types";

interface GetPositionApplicationsData {
	getPositionApplications: PaginatedResult<PositionApplication>;
}

const useGetPositionApplications = (retrievalInput: RetrievalInput) => {
	const { loading, error, data, refetch, networkStatus } =
		useQuery<GetPositionApplicationsData>(GET_POSITION_APPLICATIONS, {
			variables: { retrievalInput },
		});
	const processedData = data?.getPositionApplications;
	return { loading, error, data: processedData, refetch, networkStatus };
};
export default useGetPositionApplications;
