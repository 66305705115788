import { useContext } from "react";
import { EmployeeContext, PageChangeContext } from "contexts";
import { useLogout } from "services";
import { useToast } from "@chakra-ui/react";

const useHandleLogout = (accountType: "admin" | "employee" | "dealer") => {
	const { logout } = useLogout();
	const toast = useToast();
	const { refetch } = useContext(EmployeeContext);
	const { handlePageChange } = useContext(PageChangeContext);

	const handleLogout = async () => {
		const { data, error } = await logout(accountType);
		if (data) {
			toast({
				title: "Success",
				description: "You have been logged out successfully!",
				status: "success",
				duration: 3000,
				isClosable: true,
			});
			await refetch();
			handlePageChange("/");
		} else if (error) {
			toast({
				title: "Error logging out!",
				description: error.message,
				status: "error",
				duration: 3000,
				isClosable: true,
			});
		} else {
			toast({
				title: "Error logging out!",
				description:
					"An unexpected error occurred while trying to log you out. Please try again later.",
				status: "error",
				duration: 3000,
				isClosable: true,
			});
		}
	};

	return { handleLogout };
};

export default useHandleLogout;
