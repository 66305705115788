/**
 * @file index.ts
 * @description Chakra UI theme configuration file
 * @module Style
 * @packageDocumentation
 */
import { extendTheme, ToastProviderProps } from "@chakra-ui/react";

import { colors, fonts } from "./theme";
import { componentStyles } from "./components";

const styles = {
	global: {
		"html, body, *": {
			"::-webkit-scrollbar": {
				width: "7px", // you can adjust this to change the thickness of the scrollbar
				position: "fixed", // add this line to fix the scrollbar position
				right: "0", // add this line to align the scrollbar to the right
			},

			"::-webkit-scrollbar-track": {
				background: "transparent", // this makes the background of the scrollbar track transparent
			},

			"::-webkit-scrollbar-thumb": {
				background: "primary.300", // this is the color of the scroll thumb
				borderRadius: "20px", // this makes the scroll thumb round
			},

			"::-webkit-scrollbar-thumb:hover": {
				cursor: "pointer",
				background: "primary.400", // this changes the color of the scroll thumb when hovering
				border: `1px solid ${colors.primary[200]}`, // this adds a border to the scroll thumb
				borderRadius: "20px", // this makes the scroll thumb round
			},
		},
	},
};

const toastOptions: ToastProviderProps = {
	// Default options
	defaultOptions: {
		position: "top",
		isClosable: true,
	},
};

const theme = extendTheme({
	components: componentStyles,
	fonts: fonts,
	colors: colors,
	styles: styles,
});

export { theme, colors, fonts, componentStyles, toastOptions };
