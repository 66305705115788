/**
 * @module MultiStepForm.tsx
 * @description A multi-step form component
 */
import React, { useState, useEffect } from "react";
import { Button, Stack } from "@chakra-ui/react";
import { MultiStepFormProps, Form } from "./index";
import { FiArrowRightCircle } from "react-icons/fi";

/**
 * @param {MultiStepFormProps} props
 * @returns {JSX.Element}
 * @description A multi-step form component
 */
const MultiStepForm: React.FC<MultiStepFormProps> = ({
	steps,
	onSubmit,
	isLoading = false,
	setProgressPercent,
}) => {
	const [currentStep, setCurrentStep] = useState(0);

	useEffect(() => {
		// Update progress bar percentage
		if (setProgressPercent) {
			setProgressPercent(Math.round(((currentStep + 1) / steps.length) * 100));
		}
	}, [currentStep, steps.length, setProgressPercent]);

	const handleNext = () => {
		// Validate current step fields before moving to the next step
		if (currentStep < steps.length - 1) {
			setCurrentStep(currentStep + 1);
		} else {
			onSubmit(); // Submit the form on the last step
			setCurrentStep(0);
		}
	};

	const handlePrev = () => {
		if (currentStep > 0) {
			setCurrentStep(currentStep - 1);
		}
	};

	const SubmitButtons = () => {
		return (
			<Stack
				direction="row"
				spacing={4}
				align="center"
				justify="flex-end"
				width="100%"
				height="auto"
			>
				<Button
					variant="ghost"
					fontSize={"lg"}
					onClick={handlePrev}
					disabled={currentStep === 0}
				>
					Previous
				</Button>
				<Button
					width="115px"
					fontSize={"lg"}
					type="submit"
					isLoading={isLoading}
					variant="withIcon"
					rightIcon={<FiArrowRightCircle />}
				>
					{currentStep === steps.length - 1 ? "Submit" : "Next"}
				</Button>
			</Stack>
		);
	};

	return (
		<Form
			fields={steps?.[currentStep]}
			onSubmit={handleNext}
			isLoading={isLoading}
			submitButtons={<SubmitButtons />}
		/>
	);
};

export default MultiStepForm;
