import { useMutation } from "@apollo/client";
import { updateResource as UPDATE_RESOURCE } from "./mutations";
import { ResourceInput } from "../../types";

interface UpdateResourceData {
	updateResource: boolean;
}

const useUpdateResource = () => {
	const [updateResourceMutation, { loading, error }] =
		useMutation<UpdateResourceData>(UPDATE_RESOURCE);
	const updateResource = async (
		resourceID: string,
		resourceInput: ResourceInput,
	) => {
		try {
			const { data } = await updateResourceMutation({
				variables: {
					resourceID,
					resourceInput,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.updateResource };
		} catch (error: any) {
			return { error };
		}
	};
	return { updateResource, loading, error };
};
export default useUpdateResource;
