/**
 * @module ModalWrapper
 */
import React from "react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	ModalProps as ChakraModalProps,
	ModalContentProps,
} from "@chakra-ui/react";
import { colors } from "styles";

/**
 * @typedef ModalWrapperProps
 * @property {boolean} isOpen Whether the modal is open
 * @property {() => void} onClose The function to close the modal
 * @property {React.ReactNode} children The children of the modal
 * @property {boolean} [hideCloseButton] Whether to hide the close button
 * @property {string} [heading] The heading of the modal
 * @property {boolean} [showShadow] Whether to show a shadow on the modal
 * @property {React.ReactNode} [footer] The footer of the modal
 */
interface ModalWrapperProps extends ChakraModalProps {
	isOpen: boolean;
	onClose: () => void;
	children: React.ReactNode;
	hideCloseButton?: boolean;
	heading?: string;
	showShadow?: boolean;
	footer?: React.ReactNode;
	modalContentProps?: ModalContentProps;
}

/**
 * A wrapper component for the Chakra UI modal.
 * @param {ModalWrapperProps} props The props of the component
 * @returns {React.ReactElement} A wrapper component for the Chakra UI modal
 */
const ModalWrapper: React.FC<ModalWrapperProps> = ({
	isOpen,
	onClose,
	children,
	hideCloseButton,
	heading,
	showShadow = false,
	footer,
	modalContentProps = {},
	...chakraModalProps
}) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered {...chakraModalProps}>
			<ModalOverlay />
			<ModalContent
				boxShadow={showShadow ? "lg" : "none"}
				{...modalContentProps}
			>
				{heading && (
					<ModalHeader color={colors.primary[500]}>{heading}</ModalHeader>
				)}
				{!hideCloseButton && <ModalCloseButton />}
				<ModalBody>{children}</ModalBody>
				{footer && <ModalFooter>{footer}</ModalFooter>}
			</ModalContent>
		</Modal>
	);
};

export default ModalWrapper;
