import BaseCreate from "./BaseCreate";

import { CreateToastFnReturn } from "@chakra-ui/react";
import { GalleryImageInput } from "services";
import { GALLERY_PATH } from "consts";

class GalleryCreate extends BaseCreate<GalleryImageInput> {
	protected file: File | null;
	protected uploadFile: (file: File, path: string) => Promise<string>;

	constructor(
		toast: CreateToastFnReturn,
		createFn: (
			input: GalleryImageInput,
		) => Promise<{ data?: any; error?: any }>,
		refetch: () => Promise<void>,
		file: File | null,
		uploadFile: (file: File, path: string) => Promise<string>,
	) {
		super("Gallery", toast, createFn, refetch);
		this.file = file;
		this.uploadFile = uploadFile;
	}

	public async handleCreate(input: GalleryImageInput): Promise<boolean> {
		if (!this.createFn) throw new Error("Create function not defined");
		if (!this.uploadFile) throw new Error("Upload function not defined");
		let path = "";
		try {
			if (!this.file) throw new Error("File is required");
			path = await this.uploadFile(this.file, GALLERY_PATH);
		} catch (err: any) {
			this.toast({
				title: "Error",
				description: err.message,
				status: "error",
				duration: 5000,
				isClosable: true,
			});
			return false;
		}

		const galleryInput: GalleryImageInput = {
			...input,
			image: path,
		};

		return await super.handleCreate(galleryInput);
	}
}

export default GalleryCreate;
