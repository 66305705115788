/**
 * @module DeletePromotionModal.tsx
 * @description This file contains the modal for deleting a Promotion.
 */
import React from "react";
import DeleteConfirmationWrapper from "wrappers/DeleteConfirmationWrapper";
import { Promotion } from "services/types";
import { useDeletePromotionForm } from "hooks/form-hooks";

/**
 * The props for the DeletePromotionModal component.
 */
interface DeletePromotionModalProps {
	promotion: Promotion;
	isOpen: boolean;
	onClose: () => void;
	refetch: () => Promise<any>;
}

/**
 * This component is used to delete a Promotion.
 * @param {DeletePromotionModalProps} props The props for the component.
 * @returns {React.ReactElement} The DeletePromotionModal component.
 */
const DeletePromotionModal: React.FC<DeletePromotionModalProps> = ({
	promotion,
	isOpen,
	onClose,
	refetch,
}) => {
	const { loading, handleDelete } = useDeletePromotionForm(promotion, refetch);

	const onConfirmDelete = async () => {
		await handleDelete();
		onClose();
	};

	return (
		<DeleteConfirmationWrapper
			isOpen={isOpen}
			onClose={onClose}
			header={`Confirm Deletion: `}
			onDeleteConfirm={onConfirmDelete}
			content={promotion?.title}
			loading={loading}
		>
			{`Are you sure you want to delete this Promotion? This action cannot be undone.`}
		</DeleteConfirmationWrapper>
	);
};

export default DeletePromotionModal;
