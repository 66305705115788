import React from "react";
import { PromotionProduct, useGetProducts } from "services";
import { Flex, Skeleton } from "@chakra-ui/react";
import { ErrorMessage } from "components";
import PromotionProductItem from "./PromotionProductItem";

interface PromotionDetailsProps {
	products: PromotionProduct[];
}

const PromotionDetails: React.FC<PromotionDetailsProps> = ({ products }) => {
	const ecwidIDs = products.map((product) => product.ecwidID);
	const { data: promotionProducts, loading, error } = useGetProducts(ecwidIDs);

	return (
		<Flex
			w="100%"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			bg="transparent"
		>
			<Flex
				flexDirection={{ base: "column", xl: "row" }}
				justifyContent={"space-evenly"}
				alignItems="center"
				w="100%"
				h="100%"
				flexWrap="wrap"
			>
				{loading ? (
					<Skeleton
						width="100%"
						height="100%"
						startColor="primary.200"
						endColor="primary.400"
						speed={1.2}
					/>
				) : error ? (
					<ErrorMessage
						title="We were unable to retrieve the products in this promotion."
						description="Please try again later."
					/>
				) : (
					promotionProducts?.map((product, index) => (
						<PromotionProductItem
							key={`promotion-details-product-${product.id}`}
							product={product}
							content={products.find((p) => p.ecwidID === product.id)?.content}
							index={index}
						/>
					))
				)}
			</Flex>
		</Flex>
	);
};

export default PromotionDetails;
