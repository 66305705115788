import React, { useContext } from "react";
import { Button, Stack } from "@chakra-ui/react";
import { PageChangeContext } from "contexts";
import StoreSearchPopover from "page-components/Store/StoreSearchPopover";
import CartButton from "page-components/Store/CartButton";

interface HeaderButtonsProps {
	onClose?: () => void;
}

const HeaderButtons: React.FC<HeaderButtonsProps> = ({ onClose }) => {
	const { handlePageChange } = useContext(PageChangeContext);

	return (
		<Stack
			display={"flex"}
			direction="row"
			px={2}
			my={2}
			justifyContent={{
				base: "space-between",
				md: "flex-end",
			}}
			w="fit-content"
		>
			<StoreSearchPopover onClose={onClose} />
			<CartButton onClose={onClose} />
			<Button
				variant="primary"
				onClick={() => handlePageChange("/dealer/login")}
				flexShrink={1}
			>
				Dealer Portal
			</Button>
		</Stack>
	);
};

export default HeaderButtons;
