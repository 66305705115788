import { useMutation } from "@apollo/client";
import { updateFAQ as UPDATE_F_A_Q } from "./mutations";
import { FAQInput } from "../../types";

interface UpdateFAQData {
	updateFAQ: boolean;
}

const useUpdateFAQ = () => {
	const [updateFAQMutation, { loading, error }] =
		useMutation<UpdateFAQData>(UPDATE_F_A_Q);
	const updateFAQ = async (faqID: string, faqInput: FAQInput) => {
		try {
			const { data } = await updateFAQMutation({
				variables: {
					faqID,
					faqInput,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.updateFAQ };
		} catch (error: any) {
			return { error };
		}
	};
	return { updateFAQ, loading, error };
};
export default useUpdateFAQ;
