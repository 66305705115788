import { DashboardHeader } from "components/features/headers";
import { DashboardLayout } from "components/layouts";
import { RoutesWrapper } from "wrappers";
import { Route, Navigate } from "react-router-dom";
import { DealerDashboard } from "pages/dealer";
import { DealerSidebar } from "page-components";
import { withProtectedRoute } from "hocs";

const AuthenticatedDealerRouter: React.FC = () => {
	const DealerRoutes = () => {
		return (
			<DashboardLayout
				sidebar={<DealerSidebar />}
				header={<DashboardHeader dashboardHeading="Dashboard" />}
			>
				<RoutesWrapper
					fallbackComponent={<Navigate to="/dealer/dashboard" replace />}
				>
					<Route path="/dashboard/*" element={<DealerDashboard />} />
					<Route path="/account/*" element={null} />
					<Route path="/catalog/*" element={null} />
				</RoutesWrapper>
			</DashboardLayout>
		);
	};

	const ProtectedDealerRoutes = withProtectedRoute(DealerRoutes, "dealer");

	return <ProtectedDealerRoutes />;
};

export default AuthenticatedDealerRouter;
