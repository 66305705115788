import React from "react";
import { Button, Flex } from "@chakra-ui/react";
import { Form } from "components";
import { useModal } from "hooks";
import { useCreateCatalogForm } from "hooks/form-hooks";
import { ModalWrapper } from "wrappers";
import { FaPlus } from "react-icons/fa6";

interface CreateCatalogFormProps {
	refetch: () => Promise<any>;
}

const CreateCatalogForm: React.FC<CreateCatalogFormProps> = ({ refetch }) => {
	const { isOpen, open, close } = useModal();

	const { fields, handleSubmit, loading } = useCreateCatalogForm({ refetch });

	const onSubmit = async () => {
		await handleSubmit();
		close();
	};

	return (
		<>
			<Flex>
				<Button
					size="lg"
					aria-label="create-catalog"
					onClick={open}
					variant={"withIcon"}
					leftIcon={<FaPlus />}
					borderRadius="15px"
					mx={6}
					my={4}
				>
					Create Catalog
				</Button>
			</Flex>
			<ModalWrapper
				isOpen={isOpen}
				onClose={close}
				showShadow={true}
				heading="Create a Catalog"
				hideCloseButton={false}
			>
				<Form fields={fields} onSubmit={onSubmit} isLoading={loading} />
			</ModalWrapper>
		</>
	);
};

export default CreateCatalogForm;
