import { Box, Heading, Flex, Image, ResponsiveValue } from "@chakra-ui/react";

interface ICTAProps {
	image?: string;
	title?: string;
	height?: ResponsiveValue<string | number>;
	width?: ResponsiveValue<string | number>;
	shouldUseOverlay?: boolean;
}

const BackgroundImageCTA: React.FC<ICTAProps> = ({
	image,
	title,
	height,
	width,
	shouldUseOverlay = true,
}) => {
	return (
		<>
			<Flex
				flexDir="column"
				justifyContent={"center"}
				px={{ base: 2 }}
				pt="40px"
			>
				<Box
					bg="gray.500"
					as="section"
					position="relative"
					zIndex={0}
					w={width}
					h={height}
					minH={"320px"}
					overflow="hidden"
					alignItems="center"
					justifyContent="center"
					borderRadius={{ base: "xl", md: "lg" }}
					display="flex"
					alignSelf={"center"}
					justifySelf={"center"}
				>
					<Flex
						id="image-wrapper"
						position="absolute"
						w="full"
						h="100%"
						zIndex={-1}
						//Add slight opaque overlay to images
						_after={{
							content: '""',
							position: "absolute",
							inset: "0",
							bg: "blackAlpha.600", //"blackAlpha.600",
							zIndex: 0,
						}}
					>
						<Box
							key={image}
							position="absolute"
							w="full"
							h="full"
							display="block"
							transition={"all 0.75s cubic-bezier(.17,.67,.83,.67)"}
						>
							{image && (
								<Image
									src={image}
									w="100%"
									h="100%"
									objectFit={"cover"}
									objectPosition={"center 62.5%"}
									position="absolute"
									loading="eager"
								/>
							)}
							<Box
								position="absolute"
								w="100%"
								h="100%"
								bg={shouldUseOverlay ? "#8B8589" : "rgba(0, 0, 0, 0.5)"}
								opacity={0.28}
							/>
						</Box>
					</Flex>
					<Box py={20} position="relative" zIndex={0}>
						<Box
							maxW="100%"
							mx="auto"
							px={{ base: "6", md: "8" }}
							color="white"
						>
							<Box maxW="7xl" zIndex={10}>
								{title && (
									<Heading
										variant="boxTitle"
										color="white"
										fontSize="6xl"
										bg="rgba(0, 0, 0, 0.0)"
										pb={6}
										pt={4}
										px={4}
										textAlign={{ base: "center" }}
									>
										{title}
									</Heading>
								)}
							</Box>
						</Box>
					</Box>
				</Box>
			</Flex>
		</>
	);
};

export default BackgroundImageCTA;
