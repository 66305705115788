/**
 * @module JobPanel.tsx
 */
import React from "react";
import { Container, Flex } from "@chakra-ui/react";
import { GridLayout, AccordionList, PanelHeading } from "components";
// import { PanelHeader } from 'components';
import JobPosting from "./JobPosting";
import { Position } from "services";

interface JobListingProps {
	jobListings: Position[];
}

const JobPanel: React.FC<JobListingProps> = ({ jobListings }) => {
	return (
		<Container maxW="container.xl" py={{ base: "16", md: "24" }}>
			<GridLayout columns={{ base: 1, md: 2 }} gap={12}>
				<PanelHeading
					title="Open Positions"
					subtitle="Wayside Careers"
					content="Explore exciting job opportunities at our company and join a dynamic team dedicated to innovation and excellence."
				/>
				<AccordionList
					items={jobListings.map((listing, index) => ({
						key: index,
						buttonContent: (
							<Flex>
								<strong>{listing.positionName}</strong> -{" "}
								{listing.details.department}
							</Flex>
						),
						children: <JobPosting position={listing} />,
					}))}
					allowToggle={true}
				/>
			</GridLayout>
		</Container>
	);
};

export default JobPanel;
