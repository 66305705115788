import React, { useContext, useEffect } from "react";
import { EmployeeContext, PageChangeContext } from "contexts";

import { useToast } from "@chakra-ui/react";

import { Loader } from "components";

import { ADMIN_PANEL_ACCESS } from "consts";
import { Logger } from "utils";

type Strategy = "employee" | "admin" | "dealer";

/**
 * Higher-order function that creates a protected route component.
 *
 * @template P - The props type of the wrapped component.
 * @param {React.ComponentType<P>} WrappedComponent - The component to be wrapped.
 * @param {Strategy} strategy - The access strategy for the protected route.
 * @returns {React.FC<P>} - The protected route component.
 */
const withProtectedRoute = <P extends object>(
	WrappedComponent: React.ComponentType<P>,
	strategy: Strategy,
): React.FC<P> => {
	const EmployeeProtectedRoute: React.FC<P> = (props) => {
		const { employee, loading: employeeLoading } = useContext(EmployeeContext);
		const { handlePageChange } = useContext(PageChangeContext);

		useEffect(() => {
			if (!employee && !employeeLoading) {
				handlePageChange(`/${strategy}/login`);
			}
		}, [employee, handlePageChange, employeeLoading]);

		if (employeeLoading) {
			return <Loader />;
		} else if (employee) {
			return <WrappedComponent {...(props as P)} />;
		} else return null;
	};

	const AdminProtectedRoute: React.FC<P> = (props) => {
		const {
			employee,
			loading: employeeLoading,
			hasAccess,
		} = useContext(EmployeeContext);
		const { handlePageChange } = useContext(PageChangeContext);
		const toast = useToast();

		useEffect(() => {
			if (!employee && !employeeLoading) {
				handlePageChange(`/${strategy}/login`);
			}
		}, [employee, handlePageChange, employeeLoading]);

		if (employeeLoading) return <Loader />;
		else if (employee) {
			if (hasAccess(ADMIN_PANEL_ACCESS)) {
				return <WrappedComponent {...(props as P)} />;
			} else {
				toast({
					title: "Unauthorized",
					description: "You are not authorized to access this page.",
					status: "error",
					duration: 9000,
					isClosable: true,
				});
				handlePageChange("/admin/login");
				return null;
			}
		} else return null;
	};

	const DealerProtectedRoute: React.FC<P> = (props) => {
		Logger.error("Dealer protected route not implemented.");
		const { handlePageChange } = useContext(PageChangeContext);
		const toast = useToast();
		toast({
			title: "Error",
			description: "You are not authorized to access this page.",
			status: "error",
			duration: 9000,
			isClosable: true,
		});
		handlePageChange("/dealer/login");
		return null;
	};

	switch (strategy) {
		case "employee":
			return EmployeeProtectedRoute;
		case "admin":
			return AdminProtectedRoute;
		case "dealer":
			return DealerProtectedRoute;
		default:
			throw new Error("Invalid strategy provided to withProtectedRoute.");
	}
};

export default withProtectedRoute;
