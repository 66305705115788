import React from "react";
import {
	Box,
	Heading,
	Image,
	BoxProps,
	TextProps,
	ImageProps,
} from "@chakra-ui/react";

interface HeroProps {
	backgroundImage: string;
	titleText: string;
	boxProps?: BoxProps; // Using BoxProps to allow any style props provided by Chakra UI
	imageProps?: ImageProps; // Using imageProps to allow any style props provided by Chakra UI
	textProps?: TextProps; // Using TextProps for the same reason
	backgroundColor?: string;
}

const Hero: React.FC<HeroProps> = ({
	backgroundImage,
	titleText,
	boxProps,
	imageProps,
	textProps,
	backgroundColor,
}) => {
	return (
		<Box
			width="full"
			height="300px" // Default height
			display="flex" // Make the Box a Flex container
			alignItems="center" // Center vertically
			justifyContent="center" // Center horizontally
			position="relative"
			{...boxProps} // Spread any additional BoxProps
		>
			<Image
				src={backgroundImage}
				alt="Hero Background"
				objectFit="cover"
				width="full"
				height="full"
				position="absolute"
				zIndex="-1"
				{...imageProps} // Spread any additional ImageProps
			/>
			<Box
				position="absolute"
				top="0"
				left="0"
				right="0"
				bottom="0"
				backgroundColor={backgroundColor} // Semi-transparent black overlay
				zIndex="-1" // Above the image, but below the text
			/>
			<Heading
				variant={"hero"}
				position="relative"
				color="white"
				{...textProps} // Spread any additional TextProps
			>
				{titleText}
			</Heading>
		</Box>
	);
};

export default Hero;
