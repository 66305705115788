import { useContext } from "react";
import { ProductCollection } from "services";
import { ModalWrapper } from "wrappers";
import { useGetProducts, EcwidProduct } from "services";
import { Loader, ErrorMessage } from "components";
import { EcwidProductItem } from "page-components/Store";
import { SimpleGrid, Text, Flex, Button, Tooltip } from "@chakra-ui/react";
import { FaCartPlus } from "react-icons/fa6";
import { StoreContext, PageChangeContext } from "contexts";

interface CollectionModalProps {
	isOpen: boolean;
	onClose: () => void;
	collection: ProductCollection;
}

const CollectionModal: React.FC<CollectionModalProps> = ({
	isOpen,
	onClose,
	collection,
}) => {
	const { handlePageChange } = useContext(PageChangeContext);
	const { openPage, addProductToCart } = useContext(StoreContext);

	const {
		loading,
		error,
		data: products,
	} = useGetProducts(collection.ecwidIDs);

	const handleViewProduct = (product: EcwidProduct) => {
		handlePageChange("store");
		openPage("product", { id: product.id });
	};

	const handleAddCollectionToCart = () => {
		products?.forEach((product) => {
			addProductToCart(product.id);
		});
		handlePageChange("store");
		setTimeout(() => {
			openPage("cart");
		}, 100);
	};

	return (
		<ModalWrapper
			isOpen={isOpen}
			onClose={onClose}
			heading={collection.name}
			showShadow={true}
			size={"3xl"}
		>
			{loading ? (
				<Loader />
			) : error ? (
				<ErrorMessage
					title={"We were unable to retrieve the products in this collection."}
					description="Please try again later."
				/>
			) : !products?.length ? (
				<Text textAlign="center" fontSize="lg" color="gray.600">
					No products found in this collection.
				</Text>
			) : (
				<Flex direction={"column"} justifyContent={"center"}>
					<SimpleGrid minChildWidth={"200px"} spacing={4} p={4}>
						{products?.map((product) => (
							<Tooltip
								label={"View Product/SKU Details"}
								aria-label="View Product/SKU Details"
								placement="auto-start"
								color={"primary.400"}
								hasArrow={true} //E7E7E7
								bg="#E7E7E7"
								key={product.id}
							>
								<Flex
									cursor={"pointer"}
									onClick={() => handleViewProduct(product)}
								>
									<EcwidProductItem key={product.id} product={product} />
								</Flex>
							</Tooltip>
						))}
					</SimpleGrid>
					<Button
						variant={"base"}
						m={4}
						rightIcon={<FaCartPlus />}
						leftIcon={<FaCartPlus />}
						onClick={handleAddCollectionToCart}
					>
						Add Collection To Cart
					</Button>
				</Flex>
			)}
		</ModalWrapper>
	);
};

export default CollectionModal;
