import React from "react";

import { colors } from "styles";

import { Text, Stack } from "@chakra-ui/react";

import { IconText } from "components/common/texts";

import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";

interface InformationStackProps {
	icon?: React.ElementType;
	title: string;
	information: string[];
	withIcon?: Boolean;
}

const InformationStack: React.FC<InformationStackProps> = ({
	icon,
	title,
	information,
	withIcon,
}) => {
	return (
		<Stack spacing={2} p={2}>
			<IconText
				icon={icon}
				text={title}
				iconProps={{
					boxSize: 7,
					mr: 2,
					color: colors.primary[500],
				}}
				textProps={{
					variant: "info",
					fontSize: {
						base: "md",
						md: "lg",
					},
				}}
			/>
			{information.map((info, index) => {
				const isLink = info.startsWith("Phone") || info.startsWith("Email");
				const handleLinkClick = () => {
					if (info.startsWith("Phone")) {
						const phoneNumber = info.split(":")[1].trim();
						window.open(`tel:${phoneNumber}`, "_blank");
					} else if (info.startsWith("Email")) {
						const email = info.split(":")[1].trim();
						window.open(`mailto:${email}`);
					}
				};

				const IconComponent = info.startsWith("Phone") ? PhoneIcon : EmailIcon;

				return (
					<Stack
						key={`${title}-${index}`}
						direction={"row"}
						alignItems={"center"}
						spacing={0}
					>
						{isLink && withIcon && (
							<IconComponent color="primary.500" boxSize={4} mr={2} />
						)}
						<Text
							key={`${title}-${index}`}
							variant="label"
							fontSize={{ base: "md", lg: "lg" }}
							_hover={
								isLink
									? {
											textDecoration: "underline",
											cursor: "pointer",
									  }
									: undefined
							}
							onClick={isLink ? handleLinkClick : undefined}
						>
							{info}
						</Text>
					</Stack>
				);
			})}
		</Stack>
	);
};

export default InformationStack;
