/**
 * @module DealerRouter.tsx
 * @description Dealer Router
 */
import React from "react";
import { RoutesWrapper } from "wrappers";
import { Route } from "react-router-dom";
import { DealerLogin } from "pages";
import { ComingSoonWrapper } from "wrappers";
import AuthenticatedDealerRouter from "./AuthenticatedDealerRouter";

const DealerRouter: React.FC = () => {
	return (
		<RoutesWrapper>
			<Route
				path="/login/*"
				element={
					<ComingSoonWrapper
						comingSoonFooterText="Not a dealer yet?"
						comingSoonBtnText="Request an account"
						targetPagePath="/services/dealer-application"
					>
						<DealerLogin />
					</ComingSoonWrapper>
				}
			/>
			<Route path="/*" element={<AuthenticatedDealerRouter />} />
		</RoutesWrapper>
	);
};

export default DealerRouter;
