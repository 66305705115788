import React from "react";
import { Box, Flex, SimpleGrid } from "@chakra-ui/react";
import { IFramePreview, ImagePreview } from "components/features/previews";
import { Loader, ErrorMessage } from "components/common/ui-elements";
import { useGetAssignedContent } from "services";

// Define a prop type for the abstract component
type ResourcePreviewProps = {
	type: "Document" | "Image" | "Video"; // Extend this as needed
};

const ResourcePreview: React.FC<ResourcePreviewProps> = ({ type }) => {
	const {
		loading,
		error,
		data: paginatedResources,
	} = useGetAssignedContent({
		retrievalInput: {
			filterSettings: {
				conditional: "AND",
				filters: [
					{
						field: "type",
						operator: "eq",
						value: type,
					},
				],
			},
		},
	});

	if (loading) return <Loader />;

	if (error)
		return (
			<ErrorMessage
				title={"We're sorry, an error occurred while loading this dashboard"}
				description="Please try again later."
			/>
		);

	// Early return if the necessary data is not available
	if (!paginatedResources || !paginatedResources?.items)
		return (
			<ErrorMessage
				title={"We're sorry, there are no resources available at this time"}
				description="Please try again later."
			/>
		);

	const resources = paginatedResources?.items;

	const previews = resources.map((resource, index) => {
		switch (type) {
			case "Document":
				return (
					<IFramePreview
						key={`${type}-preview-${index}-${resource._id}`}
						src={resource.path}
						title={resource.title}
					/>
				);
			case "Video": // Assuming video uses IFramePreview like documents
				return (
					<IFramePreview
						key={`${type}-preview-${index}-${resource._id}`}
						src={resource.path}
						title={resource.title}
					/>
				);
			case "Image":
				return (
					<ImagePreview
						key={`${type}-preview-${index}-${resource._id}`}
						src={resource.path}
						alt={resource.title}
					/>
				);
			default:
				return (
					<ErrorMessage
						title={"We're sorry, there are no catalogs available at this time"}
						description="Please try again later."
					/>
				);
		}
	});

	return (
		<Box>
			<Flex justifyContent="center" mb={4}>
				<SimpleGrid
					columns={[1, 2, 3, 4]}
					spacing={6}
					justifyContent="center"
					alignItems="center"
					width="100%"
					paddingX={4}
				>
					{previews}
				</SimpleGrid>
			</Flex>
		</Box>
	);
};

export default ResourcePreview;
