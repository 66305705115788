import React from "react";
import { IconButton } from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { Tooltip } from "@chakra-ui/react";

type EditIconButtonProps = {
	onClick: () => void;
};

const EditIconButton: React.FC<EditIconButtonProps> = ({ onClick }) => {
	return (
		<Tooltip
			hasArrow
			label="Edit"
			variant="action"
			placement="left"
			openDelay={500}
		>
			<IconButton
				aria-label="edit"
				variant="action"
				onClick={onClick}
				icon={<EditIcon />}
			/>
		</Tooltip>
	);
};

export default EditIconButton;
