import { useQuery } from "@apollo/client";
import { getProductCollections as GET_PRODUCT_COLLECTIONS } from "./queries";
import {
	ProductCollection,
	PaginatedResult,
	RetrievalInput,
} from "services/types";

interface GetProductCollectionsData {
	getProductCollections: PaginatedResult<ProductCollection>;
}

const useGetProductCollections = (retrievalInput?: RetrievalInput) => {
	const { loading, error, data, refetch, networkStatus } =
		useQuery<GetProductCollectionsData>(GET_PRODUCT_COLLECTIONS, {
			variables: { retrievalInput },
		});
	const processedData = data?.getProductCollections;
	return { loading, error, data: processedData, refetch, networkStatus };
};

export default useGetProductCollections;
