import React, { createContext } from "react";
import { useGetAssignedContent, useGetCatalogs } from "services";
import {
	Resource,
	Catalog,
	PaginatedResult,
	PaginationInput,
} from "services/types";

interface EmployeeResourcesContextProps {
	loading: boolean;
	error: any;
	paginatedVideos: PaginatedResult<Resource>;
	paginatedImages: PaginatedResult<Resource>;
	paginatedDocuments: PaginatedResult<Resource>;
	paginatedCatalogs: PaginatedResult<Catalog>;
}

export const EmployeeResourcesContext =
	createContext<EmployeeResourcesContextProps>({
		loading: false,
		error: null,
		paginatedVideos: {} as PaginatedResult<Resource>,
		paginatedImages: {} as PaginatedResult<Resource>,
		paginatedDocuments: {} as PaginatedResult<Resource>,
		paginatedCatalogs: {} as PaginatedResult<Catalog>,
	});

type EmployeeResourcesProviderProps = {
	children: React.ReactNode;
};

export const EmployeeResourcesProvider = ({
	children,
}: EmployeeResourcesProviderProps) => {
	const {
		loading: loadingVideos,
		error: errorVideos,
		data: paginatedVideos,
	} = useGetAssignedContent({
		retrievalInput: {
			filterSettings: {
				conditional: "AND",
				filters: [
					{
						field: "type",
						operator: "eq",
						value: "Video",
					},
				],
			},
			pagination: {
				limit: 4,
				offset: 0,
			} as PaginationInput,
		},
	});

	const {
		loading: loadingImages,
		error: errorImages,
		data: paginatedImages,
	} = useGetAssignedContent({
		retrievalInput: {
			filterSettings: {
				conditional: "AND",
				filters: [
					{
						field: "type",
						operator: "eq",
						value: "Image",
					},
				],
			},
			pagination: {
				limit: 4,
				offset: 0,
			} as PaginationInput,
		},
	});

	const {
		loading: loadingDocuments,
		error: errorDocuments,
		data: paginatedDocuments,
	} = useGetAssignedContent({
		retrievalInput: {
			filterSettings: {
				conditional: "AND",
				filters: [
					{
						field: "type",
						operator: "eq",
						value: "Document",
					},
				],
			},
			pagination: {
				limit: 4,
				offset: 0,
			} as PaginationInput,
		},
	});

	const {
		loading: loadingCatalogs,
		error: errorCatalogs,
		data: paginatedCatalogs,
	} = useGetCatalogs({
		retrievalInput: {
			filterSettings: {
				conditional: "AND",
				filters: [
					{
						field: "isDealer",
						operator: "eq",
						value: "true",
					},
				],
			},
		},
	});

	const loadingResources =
		loadingVideos || loadingImages || loadingDocuments || loadingCatalogs;

	const errorResources =
		errorVideos || errorImages || errorDocuments || errorCatalogs;

	return (
		<EmployeeResourcesContext.Provider
			value={{
				loading: loadingResources || loadingCatalogs,
				error: errorResources || errorCatalogs,
				paginatedImages: paginatedImages || ({} as PaginatedResult<Resource>),
				paginatedVideos: paginatedVideos || ({} as PaginatedResult<Resource>),
				paginatedDocuments:
					paginatedDocuments || ({} as PaginatedResult<Resource>),
				paginatedCatalogs:
					paginatedCatalogs || ({} as PaginatedResult<Catalog>),
			}}
		>
			{children}
		</EmployeeResourcesContext.Provider>
	);
};
