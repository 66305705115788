import React from "react";
import {
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverArrow,
	PopoverCloseButton,
	PopoverHeader,
	PopoverBody,
	useDisclosure,
	IconButton,
} from "@chakra-ui/react";
import StoreSearch from "./StoreSearch";

import { FaSearch } from "react-icons/fa";

interface StoreSearchPopoverProps {
	onClose?: () => void;
}

const StoreSearchPopover: React.FC<StoreSearchPopoverProps> = ({ onClose }) => {
	const { isOpen, onClose: onClosePopover, onToggle } = useDisclosure();

	const onCloseAll = () => {
		onClosePopover();
		onClose && onClose();
	};

	return (
		<Popover isOpen={isOpen} onClose={onClosePopover}>
			<PopoverTrigger>
				<IconButton
					aria-label="Open Search"
					icon={<FaSearch />}
					onClick={onToggle}
					variant="ghost_white"
				/>
			</PopoverTrigger>
			<PopoverContent zIndex="docked">
				<PopoverArrow />
				<PopoverCloseButton />
				<PopoverHeader>Search</PopoverHeader>
				<PopoverBody zIndex="docked">
					<StoreSearch onClose={onCloseAll} />
				</PopoverBody>
			</PopoverContent>
		</Popover>
	);
};

export default StoreSearchPopover;
