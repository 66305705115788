/**
 * @module Resources
 * @description A page for managing resources from the admin dashboard
 */
import React, { useState } from "react";
import { Button, Image } from "@chakra-ui/react";
import { Resource, useGetResources, FilterSettingsInput } from "services";
import { useRetrieval } from "hooks";
import { SearchTable } from "components/features/tables";
import { createColumnHelper } from "@tanstack/react-table";
import { ActionsRow } from "components/features/tables/cells";
import {
	CreateResourceForm,
	UpdateResourceForm,
	DeleteResourceModal,
} from "page-components/admin/resources";
import { AdminResponsiveLayout } from "components";

/**
 * @returns {JSX.Element} - A page for managing resources from the admin dashboard
 * @name Resources
 */
const Resources: React.FC = () => {
	// Destructure the paginationInput, setPaginationInput, filterSettings, and handleFiltersChange from the useRetrieval hook
	const {
		paginationInput,
		setPaginationInput,
		filterSettings,
		handleFiltersChange,
		sortInput,
		handleSortChange,
	} = useRetrieval();

	// Function to handle building the filter settings for the resources
	const handleBuildFilterSettings = (
		query: string,
		conditional: "AND" | "OR" = "OR",
	) => {
		// Return the filter settings
		const filterSettings: FilterSettingsInput = {
			conditional,
			filters: [
				{
					field: "title",
					operator: "regex",
					value: query,
				},
				{
					field: "type",
					operator: "regex",
					value: query,
				},
			],
		};
		return filterSettings;
	};

	// Destructure the loading, error, data, and refetch from the useGetResources hook
	const {
		loading,
		error,
		data: resources,
		refetch,
	} = useGetResources({
		retrievalInput: {
			filterSettings: handleBuildFilterSettings(
				filterSettings.query,
				filterSettings.conditional,
			),
			pagination: paginationInput,
			sort: sortInput,
		},
	});

	// Managing state for the selected resource
	const [selectedResource, setSelectedResource] = useState<Resource | null>(
		null,
	);

	// Managing state for the update modal open state
	const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

	// Function to close the delete modal
	const closeDeleteModal = () => {
		setIsDeleteModalOpen(false);
		setSelectedResource(null); // Reset the resource to delete
	};

	// Function to close the update modal
	const closeUpdateModal = () => {
		setUpdateModalOpen(false);
		setSelectedResource(null);
	};

	// Function to handle the delete action for a resource
	const onRowDelete = (resource: Resource) => {
		setSelectedResource(resource);
		setIsDeleteModalOpen(true); // Open the modal
	};

	// Function to handle the edit action for a resource with useUpdateResourceForm
	const onRowEdit = (resource: Resource) => {
		setSelectedResource(resource);
		setUpdateModalOpen(true);
	};

	// Create a column helper for the resources table
	const columnHelper = createColumnHelper<Resource>();
	// Create the columns for the resources table
	const columns = [
		columnHelper.accessor("title", {
			header: () => "Title",
			cell: (info) => info.getValue(),
		}),
		columnHelper.accessor("type", {
			header: () => "Type",
			cell: (info) => info.getValue(),
		}),
		columnHelper.display({
			header: () => "Resource Link",
			id: "resource-path",
			cell: (info) => (
				<>
					{info.row.original.type === "Image" ? (
						<Image
							src={info.row.original.path}
							alt={info.row.original.title}
							width={"auto"}
							height={{ base: "100px", md: "auto", lg: "auto", xl: "12rem" }}
							minW="100px"
						/>
					) : info.row.original.type === "Document" ? (
						<Button
							onClick={() => window.open(info.row.original.path, "_blank")}
							variant="outline"
						>
							Open PDF
						</Button>
					) : info.row.original.type === "Video" ? (
						<iframe
							src={info.row.original.path}
							title={info.row.original.title}
							style={{ width: "auto", height: "auto", borderRadius: "10px" }}
							allowFullScreen
						/>
					) : (
						<Button
							onClick={() => window.open(info.row.original.path, "_blank")}
							variant="outline"
						>
							Open Link
						</Button>
					)}
				</>
			),
		}),
		columnHelper.display({
			header: () => "Actions",
			id: "actions",
			cell: (props) => (
				<ActionsRow
					onEdit={() => onRowEdit(props.row.original)}
					onDelete={() => onRowDelete(props.row.original)}
				/>
			),
		}),
	];

	return (
		<AdminResponsiveLayout>
			<SearchTable
				columnsData={columns}
				tableData={resources?.items || []}
				paginationInput={paginationInput}
				setPaginationInput={setPaginationInput}
				filterSettings={filterSettings}
				handleBuildFilters={handleFiltersChange}
				sortInput={sortInput}
				handleSortChange={handleSortChange}
				totalCount={resources?.totalCount || 0}
				hasNextPage={resources?.hasNextPage || false}
				hasPreviousPage={resources?.hasPreviousPage || false}
				loading={loading}
				error={error}
			/>
			<CreateResourceForm refetch={refetch} />
			{selectedResource && (
				<UpdateResourceForm
					isOpen={isUpdateModalOpen}
					onClose={closeUpdateModal}
					refetch={refetch}
					existingResource={selectedResource}
				/>
			)}
			{selectedResource && (
				<DeleteResourceModal
					resource={selectedResource}
					isOpen={isDeleteModalOpen}
					onClose={closeDeleteModal}
					refetch={refetch}
				/>
			)}
		</AdminResponsiveLayout>
	);
};

export default Resources;
