/**
 * @module WebifyTabs.tsx
 * @description A component that renders a set of tabs with content.
 */
import React from "react";
import {
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	TabsProps as ChakraTabsProps,
} from "@chakra-ui/react";
import { TabItem } from "./types";

/**
 * Type that defines the allowed options for tab alignment.
 */
type AlignOptions = "center" | "end" | "start";

/**
 * Interface defining the custom props for the ChakraTab component,
 * extending the default Chakra UI TabsProps.
 */
interface CustomTabsProps {
	items: TabItem[];
	variant?: ChakraTabsProps["variant"];
	colorScheme?: ChakraTabsProps["colorScheme"];
	align?: AlignOptions; // Use the defined type for align
	isFitted?: boolean;
	tabIndex?: number; // index for currently selected/active tab.
	onTabClick?: (index: number) => void;
	tabListProps?: object;
	tabProps?: object;
	tabPanelsProps?: object;
}

/**
 * Type guard to ensure the value is either 'vertical' or 'horizontal'.
 * @param value - The value to check.
 * @returns A boolean indicating if the value is 'vertical' or 'horizontal'.
 
function isOrientation(value: any): value is "vertical" | "horizontal" {
	return value === "vertical" || value === "horizontal";
}
*/
/**
 * The ChakraTab component wraps the Chakra UI Tabs component,
 * providing a consistent API for creating tabs across the application.
 * It accepts a list of tab items and optional props for customization.
 *
 * @param {CustomTabsProps} props - The props for the ChakraTab component.
 * @returns {React.ReactElement} - A React element of the Chakra UI Tabs with custom features.
 */
const WebifyTabs: React.FC<CustomTabsProps> = ({
	items,
	variant,
	colorScheme,
	align,
	isFitted,
	tabIndex,
	onTabClick,
	tabListProps,
	tabProps,
	tabPanelsProps,
}) => {
	return (
		// The Tabs component from Chakra UI, with additional props for customization.
		<Tabs
			variant={variant}
			colorScheme={colorScheme}
			align={align}
			isFitted={isFitted}
			index={tabIndex}
			onChange={onTabClick}
			p={{ base: 2, md: 2 }}
		>
			{/* TabList component containing individual Tab components */}
			<TabList {...tabListProps}>
				{items.map((item, index) => (
					// Tab component representing each tab item.
					<Tab
						key={index}
						{...tabProps}
						fontSize={{ base: "sm", md: "md" }}
						px={{ base: "0" }}
					>
						{item.label}
					</Tab>
				))}
			</TabList>
			{/* TabPanels component containing individual TabPanel components */}
			<TabPanels {...tabPanelsProps}>
				{items.map((item, index) => (
					// TabPanel component representing the content for each tab.
					<TabPanel key={index}>{item.content}</TabPanel>
				))}
			</TabPanels>
		</Tabs>
	);
};

export default WebifyTabs;
