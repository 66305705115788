import React, { useState } from "react";
import { ActionsRow } from "components/features/tables/cells";
import { useRetrieval } from "hooks";
import { SearchTable } from "components/features/tables";
import { createColumnHelper } from "@tanstack/react-table";
import { Partner, useGetPartners, FilterSettingsInput } from "services";
import {
	CreatePartnerForm,
	UpdatePartnerForm,
	DeletePartnerModal,
} from "page-components/admin/partners";
import { Image } from "@chakra-ui/react";
import { AdminResponsiveLayout } from "components";

const AdminPartners: React.FC = () => {
	const {
		paginationInput,
		setPaginationInput,
		filterSettings,
		handleFiltersChange,
		sortInput,
		handleSortChange,
	} = useRetrieval();

	const handleBuildFilterSettings = (
		query: string,
		conditional: "AND" | "OR" = "OR",
	) => {
		const filterSettings: FilterSettingsInput = {
			conditional,
			filters: [
				{
					field: "name",
					operator: "regex",
					value: query,
				},
				{
					field: "type",
					operator: "regex",
					value: query,
				},
			],
		};
		return filterSettings;
	};

	const {
		loading,
		error,
		data: partners,
		refetch,
	} = useGetPartners({
		filterSettings: handleBuildFilterSettings(
			filterSettings.query,
			filterSettings.conditional,
		),
		pagination: paginationInput,
		sort: sortInput,
	});

	const [selectedPartner, setSelectedPartner] = useState<Partner | null>(null);

	const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

	const closeUpdateModal = () => {
		setUpdateModalOpen(false);
		setSelectedPartner(null);
	};

	const closeDeleteModal = () => {
		setIsDeleteModalOpen(false);
		setSelectedPartner(null);
	};

	const onRowDelete = (partner: Partner) => {
		setSelectedPartner(partner);
		setIsDeleteModalOpen(true); // Open the modal
	};

	const onRowEdit = (partner: Partner) => {
		setSelectedPartner(partner);
		setUpdateModalOpen(true);
	};

	const columnHelper = createColumnHelper<Partner>();

	const columns = [
		columnHelper.accessor("name", {
			header: () => "Name",
			cell: (info) => info.getValue(),
		}),
		columnHelper.accessor("type", {
			header: () => "Type",
			cell: (info) => info.getValue(),
		}),
		columnHelper.accessor("details.subcategories", {
			header: () => "Subcategories",
			cell: (info) => info.getValue()?.join(", "),
		}),
		columnHelper.display({
			header: () => "Image",
			id: "image",
			cell: (info) => (
				<Image
					src={info.row.original.details.image}
					alt={info.row.original.details.url}
					width={"auto"}
					height={{ base: "100px", md: "auto", lg: "auto", xl: "12rem" }}
					minW="100px"
				/>
			),
		}),
		columnHelper.accessor("details.url", {
			header: () => "URL",
			cell: (info) => info.getValue(),
		}),
		columnHelper.display({
			header: () => "Actions",
			id: "actions",
			cell: (props) => (
				<ActionsRow
					onEdit={() => onRowEdit(props.row.original)}
					onDelete={() => onRowDelete(props.row.original)}
				/>
			),
		}),
	];

	return (
		<AdminResponsiveLayout>
			<SearchTable
				columnsData={columns}
				tableData={partners?.items || []}
				paginationInput={paginationInput}
				setPaginationInput={setPaginationInput}
				filterSettings={filterSettings}
				handleBuildFilters={handleFiltersChange}
				handleSortChange={handleSortChange}
				sortInput={sortInput}
				loading={loading}
				error={error}
				totalCount={partners?.totalCount || 0}
				hasNextPage={partners?.hasNextPage || false}
				hasPreviousPage={partners?.hasPreviousPage || false}
			/>
			<CreatePartnerForm refetch={refetch} />
			{selectedPartner && (
				<UpdatePartnerForm
					isOpen={isUpdateModalOpen}
					onClose={closeUpdateModal}
					refetch={refetch}
					existingPartner={selectedPartner}
				/>
			)}
			{selectedPartner && (
				<DeletePartnerModal
					partner={selectedPartner}
					isOpen={isDeleteModalOpen}
					onClose={closeDeleteModal}
					refetch={refetch}
				/>
			)}
		</AdminResponsiveLayout>
	);
};

export default AdminPartners;
