import { useUpdatePartner } from "services";
import { FieldDefinition } from "components";
import { FileInput } from "components/common/inputs";
import { Partner, PartnerInput } from "services/types";
import { useFormWithFiles } from "hooks/forms";
import { PartnerUpdate } from "cud";
import { ICUDFormHook } from "./types";
import { MultiSelect } from "components/common/inputs";
import { PARTNER_SUBCATEGORIES } from "consts";

const useUpdatePartnerForm = ({
	refetch,
	existingPartner,
}: ICUDFormHook & { existingPartner: Partner }) => {
	const initialFormState = {
		name: existingPartner.name,
		type: existingPartner.type,
		details: {
			subcategories: existingPartner.details.subcategories,
			image: existingPartner.details.image,
			url: existingPartner.details.url,
		},
	};

	const {
		formState,
		loading,
		setLoading,
		handleInputChange,
		resetFormState,
		toast,
		files: file,
		handleSetFiles: setFile,
		uploadFile,
		fileUrls,
		handleSetFileUrls,
		setFormState,
	} = useFormWithFiles<PartnerInput>({
		initialState: initialFormState,
		allowMultiple: false,
		initialFileUrls: [existingPartner.details.image],
	});

	const { updatePartner } = useUpdatePartner();

	const partnerUpdate = new PartnerUpdate(
		existingPartner,
		toast,
		updatePartner,
		refetch,
		file as File | null,
		uploadFile,
	);

	const handleSubmit = async () => {
		setLoading(true);
		const res = await partnerUpdate.handleUpdate(formState);
		if (res) resetFormState();
		setLoading(false);
	};

	const handleTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const isBrand = event.target.value.toLowerCase() === "brand";
		if (isBrand) {
			handleInputChange(event);
		} else {
			setFormState({
				...formState,
				type: event.target.value,
				details: { ...formState.details, subcategories: [] },
			});
		}
	};

	const fields: FieldDefinition[] = [
		{
			label: "Name",
			name: "name",
			type: "text",
			placeholder: "Enter the name...",
			onChange: handleInputChange,
			value: formState.name,
			variant: "base",
			size: "lg",
			width: "100%",
		},
		{
			label: "Type",
			name: "type",
			type: "select",
			onChange: handleTypeChange,
			placeholder: "Select the type of Partner...",
			options: ["Association", "Brand"],
			required: true,
			variant: "base",
			size: "lg",
			width: "100%",
			value: formState.type,
			minWidth: "100%",
		},
		{
			label: "Subcategories",
			name: "details.subcategories",
			type: "custom",
			customComponent: MultiSelect,
			customComponentProps: {
				options: PARTNER_SUBCATEGORIES,
				onChange: handleInputChange,
				value: formState.details.subcategories,
			},
			placeholder: "Select the subcategories...",
			required: true,
			variant: "base",
			size: "lg",
			width: "100%",
			isConditional: true,
			isVisible: formState.type.toLowerCase() === "brand",
			minWidth: "100%",
		},
		{
			label: "Image",
			name: "details.image",
			type: "custom",
			customComponent: FileInput,
			customComponentProps: {
				fileType: "image",
				files: file ? [file] : [],
				setFiles: setFile,
				allowMultiple: false,
				fileUrls,
				setFileUrls: handleSetFileUrls,
			},
			minWidth: "100%",
		},
		{
			label: "URL",
			name: "details.url",
			type: "text",
			placeholder: "Enter the URL...",
			value: formState.details.url,
			onChange: handleInputChange,
			variant: "base",
			size: "lg",
			width: "100%",
			required: true,
		},
	];
	return {
		fields,
		handleSubmit,
		loading,
	};
};

export default useUpdatePartnerForm;
