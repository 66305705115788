import { ChakraProvider } from "@chakra-ui/react";

import { theme as customTheme, toastOptions } from "styles";

interface ChakraProps {
	children: React.ReactNode;
}

const Chakra = ({ children }: ChakraProps) => {
	return (
		<ChakraProvider theme={customTheme} toastOptions={toastOptions}>
			{children}
		</ChakraProvider>
	);
};

export default Chakra;
