import { useMutation } from "@apollo/client";
import { applyForPosition as APPLY_FOR_POSITION } from "./mutations";
import { PositionApplicationInput } from "../../types";

interface ApplyForPositionData {
	applyForPosition: boolean;
}

const useApplyForPosition = () => {
	const [applyForPositionMutation, { loading, error }] =
		useMutation<ApplyForPositionData>(APPLY_FOR_POSITION);
	const applyForPosition = async (input: PositionApplicationInput) => {
		try {
			const { data } = await applyForPositionMutation({
				variables: {
					input,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.applyForPosition };
		} catch (error: any) {
			return { error };
		}
	};
	return { applyForPosition, loading, error };
};
export default useApplyForPosition;
