import { Button } from "@chakra-ui/react";
import { Login } from "page-components";
import { PageBackgroundWrapper } from "wrappers";
import { Flamingo } from "assets";
import { useMediaQuery } from "@chakra-ui/react";

const EmployeeLogin = () => {
	const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
	return (
		<PageBackgroundWrapper
			backgroundStyle={{
				type: "image",
				value: Flamingo,
			}}
		>
			<Login
				heading="Wayside Fence"
				strategy={"employee"} // Pass strategy prop
				bottomButton={
					<Button variant="ghost" fontWeight="bold" mb="2">
						Reset Password
					</Button>
				}
				bgColor={{ base: "white", xl: "whiteAlpha.300" }}
				style={{
					backdropFilter: isLargerThan768 ? "blur(20px)" : "none",
					WebkitBackdropFilter: isLargerThan768 ? "blur(20px)" : "none",
					border: "1px solid rgba(255, 255, 255, 0.1)",
				}}
			/>
		</PageBackgroundWrapper>
	);
};

export default EmployeeLogin;
