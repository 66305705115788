/**
 * @hook usePositionApplicationForm
 * @desc Position Application form custom hook.
 */
import { useFormWithFiles } from "hooks/forms";
import { useApplyForPosition, PositionApplicationInput } from "services";
import {
	FileInput,
	EmailInput,
	PhoneNumberInput,
} from "components/common/inputs";
import { RESUMES_PATH } from "consts";
import { FieldDefinition } from "components";
import { validateEmail, validatePhoneNumber } from "utils/validators";

const usePositionApplicationForm = (positionID: string) => {
	const initialFormState = {
		positionID,
		applicationDetails: {
			firstName: "",
			lastName: "",
			email: "",
			phone: "",
			resume: "",
		},
	};

	const {
		formState,
		loading,
		setLoading,
		handleInputChange,
		resetFormState,
		toast,
		files: file,
		handleSetFiles: setFile,
		uploadFile,
	} = useFormWithFiles<PositionApplicationInput>({
		initialState: initialFormState,
		allowMultiple: false,
	});

	const { applyForPosition } = useApplyForPosition();

	const handleSubmit = async () => {
		setLoading(true);

		let resume = "";
		try {
			if (!file) throw new Error("Resume is required");
			resume = await uploadFile(file as File, RESUMES_PATH);
		} catch (err: any) {
			toast({
				title: "Resume Upload Error",
				description: err.message,
				status: "error",
				duration: 5000,
				isClosable: true,
			});
			setLoading(false);
			return;
		}

		const applicationInput = {
			...formState,
			applicationDetails: {
				...formState.applicationDetails,
				resume,
			},
		};

		const { data, error } = await applyForPosition(applicationInput);
		if (data) {
			toast({
				title: "Application Submitted",
				description:
					"Your application has been submitted successfully! We will be in touch soon.",
				status: "success",
				duration: 5000,
				isClosable: true,
			});
			resetFormState();
		} else if (error) {
			toast({
				title: "Error",
				description: error.message,
				status: "error",
				duration: 5000,
				isClosable: true,
			});
		} else {
			toast({
				title: "Error",
				description:
					"An unexpected error occurred while submitting your application. Please try again later.",
				status: "error",
				duration: 5000,
				isClosable: true,
			});
		}

		setLoading(false);
	};

	const fields: FieldDefinition[] = [
		{
			type: "text",
			name: "applicationDetails.firstName",
			label: "First Name",
			value: formState.applicationDetails.firstName,
			placeholder: "Enter your first name...",
			required: true,
			onChange: handleInputChange,
			variant: "base",
			size: "lg",
			width: "100%",
		},
		{
			type: "text",
			name: "applicationDetails.lastName",
			label: "Last Name",
			value: formState.applicationDetails.lastName,
			placeholder: "Enter your last name...",
			required: true,
			onChange: handleInputChange,
			variant: "base",
			size: "lg",
			width: "100%",
		},
		{
			type: "custom",
			name: "applicationDetails.email",
			label: "Email",
			value: formState.applicationDetails.email,
			placeholder: "Enter your email...",
			required: true,
			onChange: handleInputChange,
			customComponent: EmailInput,
			variant: "base",
			size: "lg",
			width: "100%",
			validate: validateEmail,
		},
		{
			type: "custom",
			name: "applicationDetails.phone",
			label: "Phone",
			value: formState.applicationDetails.phone,
			placeholder: "Enter your phone number...",
			required: true,
			onChange: handleInputChange,
			customComponent: PhoneNumberInput,
			size: "lg",
			width: "100%",
			variant: "base",
			validate: validatePhoneNumber,
		},
		{
			type: "custom",
			name: "file",
			label: "Resume",
			required: true,
			customComponent: FileInput,
			customComponentProps: {
				fileType: "document",
				files: file ? [file] : [],
				setFiles: setFile,
				allowMultiple: false,
			},
		},
	];

	return { loading, fields, handleSubmit };
};

export default usePositionApplicationForm;
