import React from "react";
import {
	Alert,
	AlertIcon,
	AlertTitle,
	AlertDescription,
	AlertProps,
	AlertTitleProps,
	AlertDescriptionProps,
} from "@chakra-ui/react";

type ErrorMessageProps = {
	title: string;
	description?: string;
	alertProps?: AlertProps;
	alertTitleProps?: AlertTitleProps;
	alertDescriptionProps?: AlertDescriptionProps;
	children?: React.ReactNode;
};

const InfoMessage: React.FC<ErrorMessageProps> = ({
	title,
	description,
	alertProps,
	alertTitleProps,
	alertDescriptionProps,
	children,
}) => {
	return (
		<Alert
			status="info"
			variant="subtle"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			textAlign="center"
			height="200px"
			{...alertProps}
		>
			<AlertIcon boxSize="40px" mr={0} />
			<AlertTitle mt={4} mb={1} fontSize="lg" {...alertTitleProps}>
				{title}
			</AlertTitle>
			{(description || children) && (
				<AlertDescription maxWidth="sm" {...alertDescriptionProps}>
					{description ? description : children}
				</AlertDescription>
			)}
		</Alert>
	);
};

export default InfoMessage;
