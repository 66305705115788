import React, { useContext } from "react";
import { PageChangeContext } from "contexts";
import {
	Box,
	Heading,
	Button,
	Stack,
	Flex,
	useDisclosure,
	Center,
	useBreakpointValue,
	Image,
} from "@chakra-ui/react";

import { SlideFade } from "@chakra-ui/react";
import { openGoogleMapsDirections } from "utils";

interface LocationsProps {
	title: string;
	state: string;
	address: string;
	imageSrc: string;
	imageWidth:
		| string
		| { base: string; md?: string; sm?: string; lg?: string; xl?: string };
	imageHeight:
		| string
		| { base: string; md?: string; sm?: string; lg?: string; xl?: string };
}

const Locations: React.FC<LocationsProps> = ({
	title,
	state,
	address,
	imageSrc,
	imageWidth,
	imageHeight,
}) => {
	const { isOpen, onToggle } = useDisclosure();
	const isSmallScreen = useBreakpointValue({ base: true, md: false });

	const responsiveWidth =
		typeof imageWidth === "string" ? { base: imageWidth } : imageWidth;
	const responsiveHeight =
		typeof imageHeight === "string" ? { base: imageHeight } : imageHeight;

	const currentImageWidth = useBreakpointValue(responsiveWidth);
	const currentImageHeight = useBreakpointValue(responsiveHeight);

	const { handlePageChange } = useContext(PageChangeContext);

	const handleGetDirections = () => {
		openGoogleMapsDirections(address);
	};

	const handleMoreInfo = () => {
		handlePageChange("about/locations", { location: state });
	};

	const ButtonsContent = (
		<Stack
			direction={{ base: "column", sm: "row", md: "row" }}
			spacing={{ base: 2, sm: 4, md: 8 }}
			mb={{ base: "0", md: "4" }}
			transition="all 0.5s"
		>
			<Button variant="primary" onClick={handleMoreInfo}>
				More Info
			</Button>
			<Button
				variant="primary"
				mb={{ base: 4, sm: 0 }}
				onClick={handleGetDirections}
			>
				Get Directions
			</Button>
		</Stack>
	);

	return (
		<Box
			borderWidth="1px"
			borderRadius="lg"
			overflow="hidden"
			onMouseEnter={!isSmallScreen ? onToggle : undefined}
			onMouseLeave={!isSmallScreen ? onToggle : undefined}
			position="relative"
			m={4}
			mt={{ base: "6", md: "12", lg: "24" }}
			mb={{ base: "4", md: "12", lg: "24" }}
			transition="all 0.5s"
			_hover={{
				transform: !isSmallScreen ? "translateY(-3px)" : undefined,
				borderColor: "primary.900",
			}}
			borderColor="primary.800"
		>
			<Box
				position="absolute"
				top="0"
				left="0"
				right="0"
				bottom="0"
				bg="accent.800"
				opacity={isOpen ? 0.45 : 0.65}
				transition="opacity 0.5s"
				zIndex="1"
			/>
			<Center
				position="absolute"
				top="10"
				left="0"
				right="0"
				bottom="0"
				zIndex="2"
				flexDirection="column"
			>
				<Heading
					fontSize={{ base: "2xl", sm: "3xl", md: "5xl" }}
					color="whiteAlpha.900"
				>
					{title}
				</Heading>
				{isSmallScreen ? (
					<Flex
						direction={{ base: "column", sm: "row", md: "column" }}
						alignItems="center"
						justifyContent="center"
						mt={4}
					>
						{ButtonsContent}
					</Flex>
				) : (
					<SlideFade
						in={isOpen}
						offsetY="20px"
						reverse={true}
						transition={{ enter: { duration: 0.5 }, exit: { duration: 0.5 } }}
					>
						<Flex
							direction={{ base: "column", sm: "row", md: "column" }}
							alignItems="center"
							justifyContent="center"
							mt={4}
						>
							{ButtonsContent}
						</Flex>
					</SlideFade>
				)}
			</Center>
			<Image
				src={imageSrc}
				title={`Map for ${title}`}
				style={{
					border: "none",
					width: currentImageWidth,
					height: currentImageHeight,
				}}
				alt={`Map for ${title}`}
				objectFit="cover"
			/>
		</Box>
	);
};

export default Locations;
