import BaseUpdate from "./BaseUpdate";

import { CreateToastFnReturn } from "@chakra-ui/react";
import { Catalog, CatalogInput } from "services";
import { CATALOG_PATH } from "consts";

class CatalogUpdate extends BaseUpdate<Catalog, CatalogInput> {
	protected imageFile: File | null;
	protected srcFile: File | null;
	protected uploadFile: (file: File, path: string) => Promise<string>;

	constructor(
		object: Catalog,
		toast: CreateToastFnReturn,
		updateFn: (
			id: string,
			input: CatalogInput,
		) => Promise<{ data?: any; error?: any }>,
		refetch: () => Promise<void>,
		imageFile: File | null,
		srcFile: File | null,
		uploadFile: (file: File, path: string) => Promise<string>,
	) {
		super(object, "Catalog", toast, updateFn, refetch);
		this.imageFile = imageFile;
		this.srcFile = srcFile;
		this.uploadFile = uploadFile;
	}

	public async handleUpdate(input: CatalogInput): Promise<boolean> {
		if (!this.updateFn) throw new Error("Update function not defined");
		if (!this.uploadFile) throw new Error("Upload function not defined");

		let imagePath = "";
		let srcPath = "";
		if (this.imageFile) {
			try {
				imagePath = await this.uploadFile(this.imageFile, CATALOG_PATH);
			} catch (err: any) {
				this.toast({
					title: "Error",
					description: err.message,
					status: "error",
					duration: 5000,
					isClosable: true,
				});
				return false;
			}
		}

		if (this.srcFile) {
			try {
				srcPath = await this.uploadFile(this.srcFile, CATALOG_PATH);
			} catch (err: any) {
				this.toast({
					title: "Error",
					description: err.message,
					status: "error",
					duration: 5000,
					isClosable: true,
				});
				return false;
			}
		}

		const catalogInput: CatalogInput = { ...input };

		if (imagePath) catalogInput.image = imagePath;

		if (srcPath) catalogInput.src = srcPath;

		return await super.handleUpdate(catalogInput);
	}
}

export default CatalogUpdate;
