import React, { useState, useEffect, useRef } from "react";
import { Box, Input } from "@chakra-ui/react";
import ResourceItem from "./ResourceItem";
import { Resource, useGetResources } from "services";
import { Loader, ErrorMessage } from "components";
import { SimpleGrid } from "@chakra-ui/react";

interface ResourceMultiSelectProps {
	selectedIds: string[];
	onSelectionChange: (selectedIds: string[]) => void;
	name: string; // Add a name prop for the input field identification
	required?: boolean; // Optional prop to determine if the selection is required
}

const ResourceMultiSelect: React.FC<ResourceMultiSelectProps> = ({
	selectedIds,
	onSelectionChange,
	name,
	required = false,
}) => {
	const { loading, error, data: paginatedResources } = useGetResources();
	const resources = paginatedResources?.items;

	const [searchTerm, setSearchTerm] = useState("");
	const [filteredResources, setFilteredResources] = useState<Resource[]>([]);

	// Reference for the hidden input
	const hiddenInputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		const lowercasedSearchTerm = searchTerm.toLowerCase();
		const filtered = resources
			? resources.filter((resource) =>
					resource.title.toLowerCase().includes(lowercasedSearchTerm),
			  )
			: [];
		setFilteredResources(filtered);
	}, [searchTerm, resources]);

	const handleSelectToggle = (id: string) => {
		if (selectedIds.includes(id)) {
			onSelectionChange(selectedIds.filter((selectedId) => selectedId !== id));
		} else {
			onSelectionChange([...selectedIds, id]);
		}
	};

	return (
		<Box>
			<input
				type="hidden"
				ref={hiddenInputRef}
				name={name}
				value={selectedIds.join(",")}
				required={required}
				onChange={() => {}} // This is just to satisfy React's controlled input requirement
			/>
			<Input
				placeholder="Search resources..."
				value={searchTerm}
				onChange={(e) => setSearchTerm(e.target.value)}
				mb={4}
				isRequired={false}
			/>
			{loading && <Loader />}
			{error && (
				<ErrorMessage title="An error occurred while fetching resources" />
			)}
			<SimpleGrid
				columns={3}
				spacing={4}
				overflowY="auto"
				maxHeight="400px"
				p={2}
			>
				{filteredResources.map((resource) => (
					<Box
						key={resource._id}
						onClick={() => handleSelectToggle(resource._id)}
						cursor="pointer"
						borderRadius="md"
						borderWidth="1px"
						bgColor={
							selectedIds.includes(resource._id) ? "primary.200" : "white"
						}
						p={4}
						transition="all 0.3s"
						_hover={{
							borderColor: "primary.100",
							boxShadow: "lg",
							borderRadius: "lg",
						}}
						_active={{ transform: "translateY(5px)" }}
					>
						<ResourceItem resource={resource} />
					</Box>
				))}
			</SimpleGrid>
		</Box>
	);
};

export default ResourceMultiSelect;
