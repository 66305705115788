import { useState } from "react";
import { BaseDelete } from "cud/delete";
import { useToast } from "@chakra-ui/react";
import { useDeletePositionApplication } from "services";
import { PositionApplication } from "services";

const useDeletePositionApplicationForm = (
	positionApplication: PositionApplication,
	refetch: () => Promise<void>,
) => {
	const [loading, setLoading] = useState(false);
	const toast = useToast();
	const { deletePositionApplication } = useDeletePositionApplication();

	const baseDelete = new BaseDelete<PositionApplication>(
		positionApplication,
		"Position Application",
		toast,
		deletePositionApplication,
		refetch,
	);

	const handleDelete = async () => {
		setLoading(true);
		await baseDelete.handleDelete();
		setLoading(false);
	};

	return {
		handleDelete,
		loading,
	};
};

export default useDeletePositionApplicationForm;
