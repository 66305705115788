import { useMutation } from "@apollo/client";
import { submitFeedbackForm as SUBMIT_FEEDBACK_FORM } from "./mutations";
import { FeedbackFormInput } from "../../types";

interface SubmitFeedbackFormData {
	submitFeedbackForm: boolean;
}

const useSubmitFeedbackForm = () => {
	const [submitFeedbackFormMutation, { loading, error }] =
		useMutation<SubmitFeedbackFormData>(SUBMIT_FEEDBACK_FORM);
	const submitFeedbackForm = async (feedbackFormInput: FeedbackFormInput) => {
		try {
			const { data } = await submitFeedbackFormMutation({
				variables: {
					input: feedbackFormInput,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.submitFeedbackForm };
		} catch (error: any) {
			return { error };
		}
	};
	return { submitFeedbackForm, loading, error };
};

export default useSubmitFeedbackForm;
