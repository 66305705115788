/**
 * @module useCreateReview.ts
 * @description This file contains the logic for creating a new review in the database.
 */
import { useMutation } from "@apollo/client";
import { createReview as CREATE_REVIEW } from "./mutations";
import { ReviewInput } from "../../types";

interface CreateReviewData {
	createReview: boolean;
}

/**
 * This is the hook that is used to create a new review in the database.
 * @returns {Object} The createReview function, loading state, and error state.
 */
const useCreateReview = () => {
	const [createReviewMutation, { loading, error }] =
		useMutation<CreateReviewData>(CREATE_REVIEW);

	/**
	 * This function is used to create a new review in the database.
	 * @param {ReviewInput} reviewInput The input for the new review.
	 * @returns {Object} The data for the new review or an error.
	 */
	const createReview = async (reviewInput: ReviewInput) => {
		try {
			const { data } = await createReviewMutation({
				variables: {
					reviewInput,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.createReview };
		} catch (error: any) {
			return { error };
		}
	};

	return { createReview, loading, error };
};
export default useCreateReview;
