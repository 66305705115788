import React from "react";
import { Box, Flex, BoxProps } from "@chakra-ui/react";

interface FormLayoutProps extends BoxProps {
	headingComponent: React.ReactNode;
	formComponent: React.ReactNode;
}

const FormLayout: React.FC<FormLayoutProps> = ({
	headingComponent: HeadingComponent,
	formComponent: FormComponent,
	...props
}) => {
	return (
		<Box
			boxShadow="lg"
			borderRadius={5}
			borderWidth="1px"
			borderColor="graydient.300"
			w="100%"
			maxW="4xl"
			h={{ base: "100%", md: "100%" }}
			px={{
				base: "1rem",
				md: "2rem",
			}}
			py={4}
			{...props}
		>
			<Flex
				direction="column"
				justifyContent="flex-start"
				alignItems="center"
				w="100%"
				h={"100%"}
				gap={4}
				py={4}
			>
				<Flex
					direction="column"
					justifyContent="center"
					alignItems="center"
					w="100%"
					h={"20%"}
					gap={4}
				>
					{HeadingComponent}
				</Flex>
				<Flex
					direction="column"
					justifyContent="center"
					alignItems="center"
					w="100%"
					h="80%"
					gap={4}
				>
					{FormComponent}
				</Flex>
			</Flex>
		</Box>
	);
};

export default FormLayout;
