/**
 * @module Gallery.tsx
 */
import React from "react";
import {
	Box,
	Heading,
	useBreakpointValue,
	Flex,
	Image,
} from "@chakra-ui/react";
import { ChakraCarousel } from "components/features/carousel";
import { useGetGalleryImages } from "services";
import { Loader, ErrorMessage } from "components/common/ui-elements";

const Gallery = () => {
	const { data: galleryImages, loading, error } = useGetGalleryImages();

	const gapValue = useBreakpointValue({ base: 4, md: 8, xl: 18 }) || 4;

	const GalleryContent = () => {
		if (loading) {
			return <Loader />;
		}

		if (error) {
			return (
				<ErrorMessage
					title={"We were unable to load our gallery images."}
					description={
						"You can try refreshing your browser or try again later. We apologize for the inconvenience."
					}
					alertProps={{ minHeight: "85vh" }}
				/>
			);
		}

		const imgData = galleryImages?.items.map((img, index) => {
			return {
				title: img.title,
				imageUrl: img.image,
			};
		});

		if (galleryImages && galleryImages?.items.length < 4) {
			return (
				<Flex
					w="100%"
					alignItems="center"
					justifyContent="center"
					flexDir="row"
				>
					{imgData?.map((img, index) => (
						<Image
							key={index}
							src={img.imageUrl}
							alt={img.title}
							height="60%"
							width="40%"
							objectFit="contain"
							m={2}
						/>
					))}
				</Flex>
			);
		}

		if (imgData) {
			return (
				<ChakraCarousel
					images={imgData.map((img) => img.imageUrl)}
					gap={gapValue}
				/>
			);
		}

		return (
			<ErrorMessage
				title={"We currently don't have any gallery images."}
				description={"Please check back later."}
				alertProps={{ minHeight: "85vh" }}
			/>
		);
	};

	return (
		<Box
			padding={{
				base: "4",
				md: "24",
			}}
			bg="gray.100"
			h="auto"
			minH={{ base: "auto", xl: "80vh" }}
		>
			<Heading
				variant="title"
				fontSize={{
					base: "4xl",
					md: "5xl",
				}}
				mb={10}
				mt={2}
			>
				Photos That Inspire
			</Heading>
			<GalleryContent />
		</Box>
	);
};

export default Gallery;
