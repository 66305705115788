import React from "react";
import { Header, Footer } from "page-components";
import { Flex } from "@chakra-ui/react";

interface Props {
	children: React.ReactNode;
}

const HeaderFooterWrapper: React.FC<Props> = ({ children }) => {
	return (
		<Flex
			direction="column"
			justifyContent="space-between"
			alignItems="stretch"
			minH="100vh"
		>
			<Header />
			{children}
			<Footer />
		</Flex>
	);
};

export default HeaderFooterWrapper;
