import { useMutation } from "@apollo/client";
import { login as LOGIN } from "./mutations";

interface LoginData {
	login: boolean;
}

const useLogin = () => {
	const [loginMutation, { loading, error }] = useMutation<LoginData>(LOGIN);
	const login = async (
		email: string,
		password: string,
		accountType: string,
		rememberMe: boolean,
	) => {
		try {
			const { data } = await loginMutation({
				variables: {
					email,
					password,
					accountType,
					rememberMe,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.login };
		} catch (error: any) {
			return { error };
		}
	};
	return { login, loading, error };
};

export default useLogin;
