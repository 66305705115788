import React from "react";

import { Box, Image, BoxProps } from "@chakra-ui/react";
import WaysideLogo from "../../assets/logo/Logo_tagline.webp";

interface LogoProps {
	width?: string;
	height?: string;
	position?: BoxProps["position"];
}

const Logo: React.FC<LogoProps> = ({
	width = "180px",
	height = "auto",
	position = "absolute",
}) => {
	return (
		<Box position={position}>
			<Image
				src={WaysideLogo}
				alt="Wayside Logo"
				boxSize="100px"
				objectFit="cover"
				h={height}
				w={width}
			/>
		</Box>
	);
};

export default Logo;
