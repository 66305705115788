import { Flex, Text, Stack } from "@chakra-ui/react";
import { colors } from "styles";
import { HiPhoneIncoming, HiMail } from "react-icons/hi";
import { IconInfoBox } from "components/common/boxes";

import { PHONE_NUMBER, EMAIL } from "consts";
const ContactInfo = () => {
	return (
		<>
			<Text
				alignSelf="center"
				pt={10}
				px={4}
				fontSize={"lg"}
				color={colors.graydient[400]}
				textAlign={{ base: "center" }}
			>
				If you cannot find the answer you are looking for in our FAQ, you can
				always Contact us. We Answer in no time!
			</Text>
			<Flex
				alignItems={"center"}
				justifyContent={"center"}
				w="100%"
				h="100%"
				minH="100"
				//pb={{ base: 6, md: 20 }}
				display={{ base: "flex", md: "flex", lg: "flex" }}
				overflow={"hidden"}
			>
				<Stack spacing={4} direction={"column"} w="100%" h="100%" maxW="1200px">
					<Flex
						flexDirection={{
							base: "column",
							md: "row",
						}}
						justifyContent={{ base: "center", md: "space-evenly" }}
						alignItems={"center"}
						w={{ base: "100%", sm: "98.5%" }}
						pt={20}
						pb={20}
						gap={{ base: 16 }}
					>
						<IconInfoBox
							icon={<HiPhoneIncoming />}
							description="We are happy to help."
							link={PHONE_NUMBER}
							refer={`tel:${PHONE_NUMBER}`}
						/>
						<IconInfoBox
							icon={<HiMail />}
							description="The fastest way to get in touch."
							link={EMAIL}
							refer={`mailto:${EMAIL} noopener noreferrer`}
						/>
					</Flex>
				</Stack>
			</Flex>
		</>
	);
};

export default ContactInfo;
