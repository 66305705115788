import { useState } from "react";
import { BaseDelete } from "cud/delete";
import { useToast } from "@chakra-ui/react";
import { useDeleteDealerApplication } from "services";
import { DealerApplication } from "services";

const useDeleteDealerApplicationForm = (
	dealerApplication: DealerApplication,
	refetch: () => Promise<void>,
) => {
	const [loading, setLoading] = useState(false);
	const toast = useToast();
	const { deleteDealerApplication } = useDeleteDealerApplication();

	const baseDelete = new BaseDelete<DealerApplication>(
		dealerApplication,
		"Dealer Application",
		toast,
		deleteDealerApplication,
		refetch,
	);

	const handleDelete = async () => {
		setLoading(true);
		await baseDelete.handleDelete();
		setLoading(false);
	};

	return {
		handleDelete,
		loading,
	};
};

export default useDeleteDealerApplicationForm;
