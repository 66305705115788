import { ModalWrapper } from "wrappers";
import { useChangePasswordForm } from "hooks/form-hooks";
import { Form } from "components";
import { Button, Spinner } from "@chakra-ui/react";

interface ChangePasswordModalProps {
	heading?: string;
	email: string;
	accountType: string;
	refetch: () => void;
	isOpen: boolean;
	onClose: () => void;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
	heading = "Change your password",
	email,
	accountType,
	refetch,
	isOpen,
	onClose,
}) => {
	const { fields, loading, handleSubmit } = useChangePasswordForm({
		refetch,
		email,
		accountType,
	});

	const onSubmit = async () => {
		await handleSubmit();
		onClose();
	};

	return (
		<ModalWrapper heading={heading} isOpen={isOpen} onClose={onClose}>
			<Form
				fields={fields}
				onSubmit={onSubmit}
				submitButtons={
					<Button
						type="submit"
						variant="primary"
						width="full"
						mt="2"
						isLoading={loading}
						disabled={loading}
					>
						{loading ? <Spinner size="sm" mr="2" /> : null} Change Password
					</Button>
				}
			/>
		</ModalWrapper>
	);
};

export default ChangePasswordModal;
