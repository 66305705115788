import React from "react";
import DeleteConfirmationWrapper from "wrappers/DeleteConfirmationWrapper";
import { DealerApplication } from "services/types";
import { useDeleteDealerApplicationForm } from "hooks/form-hooks";

interface DeleteDealerApplicationModalProps {
	dealerApplication: DealerApplication;
	isOpen: boolean;
	onClose: () => void;
	refetch: () => Promise<any>;
}

const DeleteDealerApplicationModal: React.FC<
	DeleteDealerApplicationModalProps
> = ({ dealerApplication, isOpen, onClose, refetch }) => {
	const { loading, handleDelete } = useDeleteDealerApplicationForm(
		dealerApplication,
		refetch,
	);

	const onConfirmDelete = async () => {
		await handleDelete();
		onClose();
	};

	return (
		<DeleteConfirmationWrapper
			isOpen={isOpen}
			onClose={onClose}
			header={`Confirm Deletion: `}
			onDeleteConfirm={onConfirmDelete}
			content={dealerApplication?.applicationDetails.company}
			loading={loading}
		>
			{`Are you sure you want to delete this dealer application? This action cannot be undone.`}
		</DeleteConfirmationWrapper>
	);
};

export default DeleteDealerApplicationModal;
