import { useCreateFAQ } from "services";
import { FAQInput } from "services/types";
import { useToast } from "@chakra-ui/react";
import { FieldDefinition } from "components";
import { ICUDFormHook } from "./types";
import { useForm } from "hooks/forms";
import { BaseCreate } from "cud";
import { FAQ_CATEGORIES } from "consts";

const useCreateFAQForm = ({ refetch }: ICUDFormHook) => {
	// Define initial form state
	const initialFormState: FAQInput = {
		category: "",
		question: "",
		answer: "",
	};

	const { formState, loading, setLoading, handleInputChange, resetFormState } =
		useForm<FAQInput>({
			initialState: initialFormState,
		});
	const toast = useToast();

	const { createFAQ } = useCreateFAQ();

	const faqCreate = new BaseCreate<FAQInput>("FAQ", toast, createFAQ, refetch);

	const handleSubmit = async () => {
		setLoading(true);
		const success = await faqCreate.handleCreate(formState);
		if (success) resetFormState();
		setLoading(false);
	};

	const fields: FieldDefinition[] = [
		{
			label: "Category",
			name: "category",
			type: "select",
			onChange: handleInputChange,
			placeholder: "Select a Category...",
			options: FAQ_CATEGORIES.map((category) => category.name),
			required: true,
			variant: "base",
			size: "lg",
			width: "100%",
			value: formState.category,
		},
		{
			label: "Question",
			name: "question",
			type: "text",
			placeholder: "Enter the question...",
			onChange: handleInputChange,
			value: formState.question,
			required: true,
			variant: "base",
			size: "lg",
			width: "100%",
		},
		{
			label: "Answer",
			name: "answer",
			type: "textarea",
			placeholder: "Enter the answer to the question...",
			onChange: handleInputChange,
			value: formState.answer,
			required: true,
			variant: "base",
			size: "lg",
			width: "100%",
		},
	];

	return { loading, fields, handleSubmit };
};

export default useCreateFAQForm;
