import React from "react";
import {
	InputGroup,
	Input,
	InputLeftElement,
	InputProps,
} from "@chakra-ui/react";
import { FaMapMarkerAlt } from "react-icons/fa";

interface ZipCodeInputProps {
	name: string;
	value: string;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	placeholder?: string;
	maxLength?: number;
	props?: InputProps;
}

const ZipCodeInput: React.FC<ZipCodeInputProps> = ({
	name,
	value,
	onChange,
	placeholder = "Enter a Zip code",
	maxLength = 9,
	...props
}) => {
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = event.target.value;

		if (!inputValue || /^\d{0,9}$/.test(inputValue)) {
			onChange(event);
		}
	};

	return (
		<InputGroup>
			<InputLeftElement pointerEvents="none">
				<FaMapMarkerAlt />
			</InputLeftElement>
			<Input
				type="text"
				value={value}
				onChange={handleChange}
				placeholder={placeholder}
				name={name}
				maxLength={maxLength}
				{...props}
			/>
		</InputGroup>
	);
};

export default ZipCodeInput;
