import {
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerProps,
	DrawerHeader,
	DrawerOverlay,
	VStack,
	Image,
	Box,
} from "@chakra-ui/react";
import React from "react";
import { NavItem, MapNavButtons } from "components/features/navbar";
import HeaderButtons from "./HeaderButtons";

interface MobileDrawerProps {
	isOpen: boolean;
	onClose: () => void;
	placement: DrawerProps["placement"];
	finalFocusRef: React.RefObject<HTMLElement>;
	navItems: NavItem[];
	bottomImageSrc?: string;
}

export const MobileDrawer: React.FC<MobileDrawerProps> = ({
	navItems,
	bottomImageSrc,
	...props
}) => {
	return (
		<Drawer {...props}>
			<DrawerOverlay />
			<DrawerContent
				borderWidth="1px"
				borderColor="accent.300"
				boxShadow="md"
				rounded={"md"}
				bgColor={"accent.500"}
			>
				<DrawerHeader width="100%" alignSelf={"center"} justifySelf={"center"}>
					<HeaderButtons onClose={props.onClose} />
				</DrawerHeader>
				<DrawerBody mt="0" zIndex={3}>
					<VStack spacing={6} align="flex-start" pb={10}>
						<MapNavButtons navItems={navItems} />
					</VStack>
				</DrawerBody>
				{bottomImageSrc && (
					<Box position="absolute" bottom="0" w="full">
						<Image src={bottomImageSrc} alt="Bottom Image" w="full" />
					</Box>
				)}
			</DrawerContent>
		</Drawer>
	);
};

export default MobileDrawer;

// import Grass3 from "assets/navbar/Grass3.png";
