import React, { useContext } from "react";
import { IconButton } from "@chakra-ui/react";

import { FaShoppingCart } from "react-icons/fa";

import { StoreContext, PageChangeContext } from "contexts";

interface CartButtonProps {
	onClose?: () => void;
}

/**
 * @function CartButton
 * @returns React component for the cart button.
 */
const CartButton: React.FC<CartButtonProps> = ({ onClose }) => {
	const { handlePageChange } = useContext(PageChangeContext);
	const { openPage } = useContext(StoreContext);

	const handleGoToCart = () => {
		handlePageChange("store");
		setTimeout(() => {
			openPage("cart");
		}, 100);
		onClose && onClose();
	};

	return (
		<IconButton
			aria-label="Go to Cart"
			icon={<FaShoppingCart />}
			onClick={handleGoToCart}
			variant="ghost_white"
		/>
	);
};

export default CartButton;
