import React, { useContext } from "react";
import { Heading, Text, Flex, Stack } from "@chakra-ui/react";
import { StoreContext, PageChangeContext } from "contexts";
import { EcwidProduct } from "services";
import { BaseCard } from "components";

interface PromotionProductItemProps {
	product: EcwidProduct;
	content?: string;
	index: number;
}

const PromotionProductItem: React.FC<PromotionProductItemProps> = ({
	product,
	index,
	content,
}) => {
	const { handlePageChange } = useContext(PageChangeContext);
	const { openPage } = useContext(StoreContext);

	const handleViewProduct = () => {
		handlePageChange("store");
		openPage("product", { id: product.id });
	};

	return (
		<Flex
			flexDirection={{
				base: "column",
				xl: index % 2 === 0 ? "row" : "row-reverse",
			}}
			justifyContent={{ base: "space-evenly", md: "space-between" }}
			alignItems="center"
			w="100%"
			h="100%"
		>
			<Stack
				direction={{ base: "column", md: "row" }}
				gap={"4"}
				py={{ base: "0", md: "10" }}
				px={{ base: "0", xl: "5" }}
				alignItems="center"
				justifyContent={"space-evenly"}
				w={{
					base: "100%",
					xl: "35%",
				}}
				maxWidth={"8xl"}
				flexGrow={1}
			>
				<BaseCard
					width={"60%"}
					height={{ base: "200px", md: "270px" }}
					backgroundImage={product.media?.images[0].image400pxUrl}
					backgroundSize={"100%"}
					backgroundPosition={"center"}
					backgroundRepeat={"no-repeat"}
					transition="transform 0.4s"
					_hover={{
						transform: "translateY(-5px) scale(1.05)",
						cursor: "pointer",
					}}
					hoverText="View Product"
					textColor="white"
					onClick={handleViewProduct}
					borderRadius="lg"
				/>
			</Stack>
			<Stack
				direction="column"
				alignItems="center"
				justifyContent={"center"}
				w={{
					base: "100%",
					xl: "65%",
				}}
				maxW="4xl"
				minW="auto"
				p={{ base: "4", md: "6" }}
				flexShrink={1}
			>
				<Stack
					direction="column"
					width="max-content"
					justifyContent={{
						base: "center",
						md: index % 2 === 1 ? "flex-start" : "flex-end",
					}}
				>
					<Heading
						variant="cta"
						fontSize={{ base: "3xl", lg: "5xl" }}
						mt={{ base: "2", md: "0" }}
						color="white"
						textAlign={index % 2 === 1 ? "left" : "right"}
						w="100%"
					>
						{product.name}
					</Heading>
					{content && (
						<Text
							variant="sectionTitle"
							as="pre"
							whiteSpace="pre-wrap"
							overflowWrap="break-word"
							wordBreak="break-word"
							fontFamily={"body"}
							textAlign={index % 2 === 1 ? "left" : "right"}
							mt={{
								base: "4",
								md: "2",
							}}
							fontSize={{ base: "2xl", md: "xl" }}
							textShadow="1px 1px 2px rgba(0, 0, 0, 0.45)"
							color="white"
							w="100%"
						>
							{content}
						</Text>
					)}
					{product.defaultDisplayedPrice && (
						<Text
							fontSize={{ base: "3xl", md: "2xl" }}
							color="primary.500"
							fontWeight={"bold"}
							textAlign={index % 2 === 1 ? "left" : "right"}
							w="100%"
						>
							${product.defaultDisplayedPrice}
						</Text>
					)}
				</Stack>
			</Stack>
		</Flex>
	);
};

export default PromotionProductItem;
