import React, { useContext } from "react";
import { PageChangeContext } from "contexts";

import { Flex, Heading, Box, Image, Text, Button } from "@chakra-ui/react";

import notFoundImage from "assets/not-found/404.png"; // make sure to import the image correctly

const NotFound: React.FC = () => {
	const { handlePageChange } = useContext(PageChangeContext);

	return (
		<>
			<Flex
				w="100%"
				h="100%"
				justifyContent="center"
				alignItems="center"
				flexDirection="column"
				marginY="8"
				gap="4"
			>
				<Heading
					as="h1"
					variant="primary"
					size="2xl"
					//Italicize the text
					fontStyle="italic"
					//Add a text shadow
					textShadow="1px 1px #000000AA"
				>
					404
				</Heading>
				<Box w="100%" maxW="500px" borderRadius="lg">
					<Image
						src={notFoundImage}
						alt="404 Not Found"
						mx="auto"
						borderRadius="lg"
					/>
				</Box>
				<Text fontSize="2xl">
					Hmm... this page doesn't exist. Try searching for something else!
				</Text>
				<Button
					size="lg"
					marginTop="4"
					variant="primary"
					onClick={() => handlePageChange("/home")}
					w="20%"
				>
					Home
				</Button>
			</Flex>
		</>
	);
};

export default NotFound;
