import { Button, Box, Heading, Flex } from "@chakra-ui/react";
import { CC, AccountApplication, WoodChecking } from "assets";

const forms = [
	{
		name: "Account App-Agreement Form",
		src: AccountApplication,
	},
	{
		name: "CC Authorization Form",
		src: CC,
	},
	{
		name: "Report On Wood Checking Form",
		src: WoodChecking,
	},
];

const Forms = () => {
	const handleButtonClick = (src: string) => {
		window.open(src);
	};

	return (
		<>
			<Heading
				display={"flex"}
				alignItems={"center"}
				justifyContent={"center"}
				variant={"primary"}
				size={{ base: "3xl", md: "4xl" }}
				pt={{ base: 10, md: 20 }}
				mx={4}
				textAlign="center"
				letterSpacing={".0005em"}
			>
				Policies & Account Forms
			</Heading>
			<Flex
				justifyContent={"center"}
				alignItems={"center"}
				flexDirection={"column"}
				p={10}
				overflow={"hidden"}
			>
				<Box
					display="flex"
					alignItems={"center"}
					flexDirection={"column"}
					backgroundColor="white"
					p={{ base: 8, md: 10 }}
					w={{ base: "auto", md: "60%" }}
					gap={4}
					borderRadius={10}
					boxShadow="inset 1px 1px 2px #CBD5E0, inset -1px -1px 2px #CBD5E0"
				>
					{forms.map((form, index) => (
						<Button
							key={index}
							w="100%"
							h={{ base: "90px", md: "45px" }}
							variant={"outline"}
							fontWeight={"semibold"}
							onClick={() => handleButtonClick(form.src)}
						>
							{form.name}
						</Button>
					))}
				</Box>
			</Flex>
		</>
	);
};

export default Forms;
