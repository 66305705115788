/**
 * @module DeleteReviewModal.tsx
 * @description This file contains the modal for deleting a review.
 */
import React from "react";
import DeleteConfirmationWrapper from "wrappers/DeleteConfirmationWrapper";
import { Review } from "services/types";
import { useDeleteReviewForm } from "hooks/form-hooks";

/**
 * The props for the DeleteReviewModal component.
 */
interface DeleteReviewModalProps {
	review: Review;
	isOpen: boolean;
	onClose: () => void;
	refetch: () => Promise<any>;
}

/**
 * This component is used to delete a review.
 * @param {DeleteReviewModalProps} props The props for the component.
 * @returns {React.ReactElement} The DeleteReviewModal component.
 */
const DeleteReviewModal: React.FC<DeleteReviewModalProps> = ({
	review,
	isOpen,
	onClose,
	refetch,
}) => {
	const { loading, handleDelete } = useDeleteReviewForm(review, refetch);

	const onConfirmDelete = async () => {
		await handleDelete();
		onClose();
	};

	return (
		<DeleteConfirmationWrapper
			isOpen={isOpen}
			onClose={onClose}
			header={`Confirm Deletion: `}
			onDeleteConfirm={onConfirmDelete}
			content={review?.content}
			loading={loading}
		>
			{`Are you sure you want to delete this review? This action cannot be undone.`}
		</DeleteConfirmationWrapper>
	);
};

export default DeleteReviewModal;
