import useCreateEmployee from "services/admin/mutations/useCreateEmployee";
import { EmployeeInput } from "services/types";
import { useToast } from "@chakra-ui/react";
import { FieldDefinition } from "components";
import { ICUDFormHook } from "./types";
import { useForm } from "hooks/forms";
import { BaseCreate } from "cud";

import {
	MultiSelect,
	EmailInput,
	PhoneNumberInput,
} from "components/common/inputs";
import { validateEmail, validatePhoneNumber } from "utils/validators";
import { ACCESS_LEVEL_OPTIONS } from "consts";

const useCreateEmployeeForm = ({ refetch }: ICUDFormHook) => {
	// Define initial form state
	const initialFormState: EmployeeInput = {
		firstName: "",
		lastName: "",
		email: "",
		password: "",
		phone: "",
		role: "",
		accessLevel: [],
	};

	// Initialize form hook and toast
	const { formState, loading, setLoading, handleInputChange, resetFormState } =
		useForm<EmployeeInput>({
			initialState: initialFormState,
		});
	const toast = useToast();

	// Employee creation logic
	const { createEmployee } = useCreateEmployee();
	const employeeCreate = new BaseCreate<EmployeeInput>(
		"Employee", // objectType
		toast, // toast function
		createEmployee, // createFn
		refetch, // refetch function
	);

	// Handle form submission
	const handleSubmit = async () => {
		setLoading(true);
		const success = await employeeCreate.handleCreate(formState);
		if (success) resetFormState();
		setLoading(false);
	};

	// Define form fields
	const fields: FieldDefinition[] = [
		{
			label: "Access Level",
			name: "accessLevel",
			type: "custom",
			customComponent: MultiSelect,
			customComponentProps: {
				options: ACCESS_LEVEL_OPTIONS,
				onChange: handleInputChange,
				value: formState.accessLevel,
			},
			required: true,
		},
		{
			label: "First Name",
			name: "firstName",
			type: "text",
			required: true,
			value: formState.firstName,
			onChange: handleInputChange,
		},
		{
			label: "Last Name",
			name: "lastName",
			type: "text",
			required: true,
			value: formState.lastName,
			onChange: handleInputChange,
		},
		{
			label: "Email",
			name: "email",
			type: "custom",
			customComponent: EmailInput,
			value: formState.email,
			onChange: handleInputChange,
			validate: validateEmail,
			required: true,
		},
		{
			label: "Password",
			name: "password",
			type: "password",
			value: formState.password,
			onChange: handleInputChange,
			required: true,
		},
		{
			label: "Phone",
			name: "phone",
			type: "custom",
			customComponent: PhoneNumberInput,
			value: formState.phone,
			onChange: handleInputChange,
			validate: validatePhoneNumber,
			required: true,
		},
		{
			label: "Role",
			name: "role",
			type: "text",
			value: formState.role,
			onChange: handleInputChange,
			required: true,
		},
	];

	return { loading, fields, handleSubmit };
};

export default useCreateEmployeeForm;
