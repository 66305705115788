//Index file for all contexts, will export Providers which will wrap the App component
import React from "react";

import Chakra from "./providers/Chakra";
import Apollo from "./providers/Apollo";
import { PageChangeProvider, PageChangeContext } from "./PageChangeContext";
import { ScrollProvider, ScrollContext } from "./ScrollContext";
import { EmployeeProvider, EmployeeContext } from "./EmployeeContext";
import { StoreProvider, StoreContext } from "./StoreContext";
import {
	EmployeeResourcesContext,
	EmployeeResourcesProvider,
} from "./EmployeeResourcesContext";

interface ProvidersProps {
	children: React.ReactNode;
}

/**
 * Renders a set of providers for the application.
 *
 * @param {ProvidersProps} props - The props for the Providers component.
 * @param {React.ReactNode} props.children - The child components to be wrapped by the providers.
 * @returns {React.ReactNode} The rendered providers and their child components.
 */
const Providers = ({ children }: ProvidersProps) => {
	return (
		<Chakra>
			<Apollo>
				<PageChangeProvider>
					<ScrollProvider>{children}</ScrollProvider>
				</PageChangeProvider>
			</Apollo>
		</Chakra>
	);
};

export {
	Providers,
	PageChangeContext,
	ScrollContext,
	EmployeeProvider,
	EmployeeContext,
	StoreProvider,
	StoreContext,
	EmployeeResourcesContext,
	EmployeeResourcesProvider,
};
