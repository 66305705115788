/**
 * @module CustomInspiration.tsx
 * @description A component that displays a set of tabs with images as content.
 */
import React, { useState } from "react";
import { Box, Heading, useBreakpointValue } from "@chakra-ui/react";
import { WebifyTabs } from "../../components/common/tabs";
import { tabTransitionVariants } from "utils";
import { motion, AnimatePresence } from "framer-motion";
import { Loader, ErrorMessage } from "components/common/ui-elements";
import useGroupedImages from "hooks/useGroupedImages";
import {
	CategoryCarousel,
	CarouselControls,
} from "components/features/carousel";
import { useCarousel } from "hooks";

/**
 * @interface CustomInspirationProps
 * @description Interface for the props of the CustomInspiration component.
 * @property {string} title - The title of the CustomInspiration component.
 */
interface CustomInspirationProps {
	title: string;
}

/**
 * @param {CustomInspirationProps} props - The props of the component.
 * @returns {React.ReactElement} A set of tabs with images as content.
 * @exports CustomInspiration
 */
const CustomInspiration: React.FC<CustomInspirationProps> = ({ title }) => {
	const { groupedImages, loading, error } = useGroupedImages();
	const [currentTabIndex, setCurrentTabIndex] = useState(0);
	const categories = Object.keys(groupedImages);
	const currentCategory = categories[currentTabIndex];
	const images = groupedImages[currentCategory] || [];

	const batchSize = useBreakpointValue({ base: 1, md: 3 }, { ssr: false }) || 1;

	const {
		currentImages,
		pause,
		toggleCarousel,
		isPaused,
		setCurrentIndex,
		handleNextImage,
	} = useCarousel({
		images,
		interval: 5000,
		batchSize: batchSize,
		shouldLoop: true,
		onCycleComplete: () => {
			setCurrentTabIndex((prevIndex) => (prevIndex + 1) % categories.length);
			setCurrentIndex(0);
		},
	});

	const handleTabClick = (index: number) => {
		pause();
		setCurrentTabIndex(index);
		setCurrentIndex(0);
	};

	if (loading) {
		return <Loader />;
	}

	if (error) {
		return (
			<ErrorMessage
				title={"We were unable to load our Custom Work Images."}
				description={
					"You can try refreshing your browser or try again later. We apologize for the inconvenience."
				}
				alertProps={{ minHeight: "85vh" }}
			/>
		);
	}

	const duration = 0.5;

	const tabItems = Object.entries(groupedImages).map(([category], index) => ({
		label: category,
		content: (
			<CategoryCarousel
				category={category}
				images={images}
				currentImages={currentImages}
				duration={duration}
			/>
		),
	}));

	return (
		<Box
			w="100%"
			mx={{ base: "auto", md: "auto" }}
			mt={{ base: "10", md: "12" }}
			mb={{ base: "4", md: "8" }}
			p={{ base: "2", md: "8" }}
			bg="white"
		>
			<Heading variant="cta" mb={{ base: "2", md: "8" }}>
				{title}
			</Heading>
			<WebifyTabs
				items={tabItems.map((item, index) => ({
					...item,
					content: (
						<AnimatePresence mode="wait" key={index}>
							<motion.div
								key={currentTabIndex}
								initial="initial"
								animate="animate"
								exit="exit"
								variants={tabTransitionVariants}
								transition={{ duration: 0.3 }}
							>
								{item.content}
							</motion.div>
						</AnimatePresence>
					),
				}))}
				variant="enclosed"
				colorScheme="white"
				align="center"
				isFitted={true}
				tabIndex={currentTabIndex}
				onTabClick={handleTabClick}
				tabListProps={{
					borderBottomColor: "transparent",
					justifyContent: "space-around",
				}}
				tabProps={{
					_selected: {
						color: "primary.500",
						borderBottomColor: "primary.700",
						borderBottomWidth: ".5px",
					},
					_focus: {
						boxShadow: "none",
					},
					_hover: {
						cursor: "pointer",
						transform: "translateY(-5px)",
						color: "primary.700",
					},
				}}
				tabPanelsProps={{}}
			/>
			<CarouselControls
				isPaused={isPaused}
				onToggle={toggleCarousel}
				onNext={handleNextImage}
			/>
		</Box>
	);
};

export default CustomInspiration;
