import React from "react";
import { RoutesWrapper } from "wrappers";
import { Route } from "react-router-dom";

import History from "pages/about/OurHistory";
import Locations from "pages/about/Locations";
import Careers from "pages/about/Careers";

const AboutRouter: React.FC = () => {
	return (
		<RoutesWrapper>
			<Route path="/history" element={<History />} />
			<Route path="/locations" element={<Locations />} />
			<Route path="/careers" element={<Careers />} />
		</RoutesWrapper>
	);
};

export default AboutRouter;
