import { useMutation } from "@apollo/client";
import { deleteGalleryImage as DELETE_GALLERY_IMAGE } from "./mutations";
interface DeleteGalleryImageData {
	deleteGalleryImage: boolean;
}

const useDeleteGalleryImage = () => {
	const [deleteGalleryImageMutation, { loading, error }] =
		useMutation<DeleteGalleryImageData>(DELETE_GALLERY_IMAGE);
	const deleteGalleryImage = async (galleryImageID: string) => {
		try {
			const { data } = await deleteGalleryImageMutation({
				variables: {
					galleryImageID,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.deleteGalleryImage };
		} catch (error: any) {
			return { error };
		}
	};
	return { deleteGalleryImage, loading, error };
};
export default useDeleteGalleryImage;
