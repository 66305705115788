import { EcwidProductImage } from "services";

export const getViableEcwidProductImage = (images: EcwidProductImage[]) => {
	if (!images || !images.length) return null;

	//Find main image if it exists
	const mainImage = images.find((image) => image.isMain);
	if (mainImage && mainImage.image160pxUrl) return mainImage.image160pxUrl;

	//Find first image with 160pxUrl
	const viableImage = images.find((image) => image.image160pxUrl);
	return viableImage?.image160pxUrl || null;
};
