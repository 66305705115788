/**
 * @module useDeletePromotion.ts
 * @description This file contains the logic for deleting an existing promotion in the database.
 */
import { useMutation } from "@apollo/client";
import { deletePromotion as DELETE_PROMOTION } from "./mutations";

interface DeletePromotionData {
	deletePromotion: boolean;
}

/**
 * This is the hook that is used to delete an existing promotion in the database.
 * @returns {Object} The deletePromotion function, loading state, and error state.
 */
const useDeletePromotion = () => {
	const [deletePromotionMutation, { loading, error }] =
		useMutation<DeletePromotionData>(DELETE_PROMOTION);

	/**
	 * This function is used to delete an existing promotion in the database.
	 * @param {string} promotionID The ID of the promotion to delete.
	 * @returns {Object} The data for the deleted promotion or an error.
	 */
	const deletePromotion = async (promotionID: string) => {
		try {
			const { data } = await deletePromotionMutation({
				variables: {
					promotionID,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.deletePromotion };
		} catch (error: any) {
			return { error };
		}
	};

	return { deletePromotion, loading, error };
};

export default useDeletePromotion;
