/**
 * @file - OurHistory.tsx
 * Represents the OurHistory page component.
 * This component displays the history, products, mission, and partnerships of Wayside Fence Company.
 */
import React from "react";
import { Flex } from "@chakra-ui/react";
import CustomDivider from "../../components/common/divider/CustomDivider";
import {
	HistorySection,
	ProductsSection,
	MissionSection,
	PartnershipsSection,
} from "../../page-components/About/OurHistory";

/**
 * @returns {React.ReactElement} The OurHistory page component
 */
const OurHistory: React.FC = () => {
	return (
		<>
			<Flex direction="column" p={4} mt={6} mb={6} overflow="hidden">
				<HistorySection
					title="Our History"
					subtitle="Serving the community for over 70 years"
					content="
                        Since 1951, Wayside Fence, a 3rd Generation Family-owned company, has been a cornerstone of the community, serving from its headquarters in Bay Shore, NY. Complementing this base, the company operates a distribution center in Newington, CT. Specializing in both retail and wholesale fencing, Wayside Fence, a Family-owned business, not only caters to local customers in New York, New Jersey, and New England but also extends its services nationwide through efficient shipping options.
                    "
				/>
				<CustomDivider />
				<ProductsSection
					productCategories={[
						"PVC Vinyl",
						"Ornamental Aluminum",
						"White & Red Custom Cedar",
						"Wood",
						"Ornamental Iron",
						"Deer Fence & Welded Wire",
						"Cellular PVC",
						"Custom Estate Gates",
						"Aluminum & PVC Railings",
						"Arbors & Pergolas",
						"Safety & Snow Fence",
						"Pool Safety Fence",
						"Chain Link",
						"Sports Netting & Screening",
						"Posts, Caps, and Sleeves",
						"Gates",
						"& More...",
					]}
				/>
				<CustomDivider />
				<MissionSection
					paragraphs={[
						"Firstly, we are committed to supplying the highest quality fence and related products to our trade partners. This ensures that businesses and contractors have access to premium materials that meet their project requirements.",
						"Secondly, our ultimate goal is to create consistent customer satisfaction. We achieve this by delivering excellent service and reliable products that exceed our customers' expectations. Whether you're a trade partner or a homeowner, Wayside Fence Company is dedicated to providing you with the best possible fencing solutions.",
					]}
				/>
				<CustomDivider />
				<PartnershipsSection
					description="
                    At Wayside Fence, we understand that the success of a company is not solely determined by the quality of its products and services, but also by the depth and strength of its industry partnerships. We are proud to be actively involved with several highly respected organizations. We invite you to visit their websites to learn more about these partnerships and our commitment to excellence"
				/>
			</Flex>
		</>
	);
};

export default OurHistory;
