import React, { createContext, useCallback, useRef, ReactNode } from "react";

// TypeScript interface for context
interface ScrollContextProps {
	topOfPage: React.RefObject<HTMLDivElement>;
	scrollToTop: () => void;
}

interface ScrollProviderProps {
	children: ReactNode;
}

// Create the context with default values
export const ScrollContext = createContext<ScrollContextProps>({
	topOfPage: { current: null },
	scrollToTop: () => {},
});

export const ScrollProvider: React.FC<ScrollProviderProps> = ({ children }) => {
	const topOfPage = useRef<HTMLDivElement>(null);

	const scrollToTop = useCallback(() => {
		if (topOfPage.current) {
			topOfPage.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [topOfPage]);

	return (
		<ScrollContext.Provider value={{ topOfPage, scrollToTop }}>
			{children}
		</ScrollContext.Provider>
	);
};
