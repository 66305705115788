import React from "react";
import DeleteConfirmationWrapper from "wrappers/DeleteConfirmationWrapper";
import { ProductCollection } from "services/types";
import { useDeleteProductCollectionForm } from "hooks/form-hooks";

interface DeleteProductCollectionModalProps {
	productCollection: ProductCollection;
	isOpen: boolean;
	onClose: () => void;
	refetch: () => Promise<any>;
}

const DeleteProductCollectionModal: React.FC<
	DeleteProductCollectionModalProps
> = ({ productCollection, isOpen, onClose, refetch }) => {
	const { loading, handleDelete } = useDeleteProductCollectionForm(
		productCollection,
		refetch,
	);

	const onConfirmDelete = async () => {
		await handleDelete();
		onClose();
	};

	return (
		<DeleteConfirmationWrapper
			isOpen={isOpen}
			onClose={onClose}
			header={`Confirm Deletion: `}
			onDeleteConfirm={onConfirmDelete}
			content={productCollection?.name}
			loading={loading}
		>
			{`Are you sure you want to delete this product collection? This action cannot be undone.`}
		</DeleteConfirmationWrapper>
	);
};

export default DeleteProductCollectionModal;
