import { gql } from "@apollo/client";

const getEmployees = gql`
	query getEmployees($retrievalInput: RetrievalInput) {
		getEmployees(retrievalInput: $retrievalInput) {
			items {
				_id
				firstName
				lastName
				email
				phone
				role
				accessLevel
				assignedContent
			}
			totalCount
			hasNextPage
			hasPreviousPage
		}
	}
`;

const getDealers = gql`
	query getDealers($retrievalInput: RetrievalInput) {
		getDealers(retrievalInput: $retrievalInput) {
			items {
				_id
				name
				email
				password
			}
			totalCount
			hasNextPage
			hasPreviousPage
		}
	}
`;

const getResources = gql`
	query getResources($retrievalInput: RetrievalInput) {
		getResources(retrievalInput: $retrievalInput) {
			items {
				_id
				title
				type
				path
			}
			totalCount
			hasNextPage
			hasPreviousPage
		}
	}
`;

const getCatalogs = gql`
	query getCatalogs($retrievalInput: RetrievalInput) {
		getCatalogs(retrievalInput: $retrievalInput) {
			items {
				_id
				name
				src
				image
				isDealer
			}
			totalCount
			hasNextPage
			hasPreviousPage
		}
	}
`;

export { getEmployees, getDealers, getResources, getCatalogs };
