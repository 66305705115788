import { useMutation } from "@apollo/client";
import { updatePosition as UPDATE_POSITION } from "./mutations";
import { PositionInput } from "../../types";

interface UpdatePositionData {
	updatePosition: boolean;
}

const useUpdatePosition = () => {
	const [updatePositionMutation, { loading, error }] =
		useMutation<UpdatePositionData>(UPDATE_POSITION);
	const updatePosition = async (
		positionId: string,
		positionInput: PositionInput,
	) => {
		try {
			const { data } = await updatePositionMutation({
				variables: {
					positionId,
					positionInput,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.updatePosition };
		} catch (error: any) {
			return { error };
		}
	};
	return { updatePosition, loading, error };
};
export default useUpdatePosition;
