/**
 * @module useLoginForm
 * @description Hook for login form component
 */
import { useState, useContext } from "react";
import { FieldDefinition } from "../../components/common/forms/index";

import { PageChangeContext, EmployeeContext } from "contexts";

import { UNKNOWN_ERROR_MSG } from "consts";

import { EmailInput } from "components/common/inputs";
import { validateEmail } from "utils/validators";

import { useToast } from "@chakra-ui/react";
import { useLogin } from "services";
/**
 * @typedef {Object} UseLoginFormProps
 * @property {string} strategy - Login strategy
 */
interface UseLoginFormProps {
	strategy: "admin" | "employee" | "dealer";
}

/**
 * @typedef {Object} UseLoginFormReturn
 * @property {boolean} loading - Loading state
 * @property {function} handleLogin - Login function
 * @property {FieldDefinition[]} loginFields - Login form fields
 */
interface UseLoginFormReturn {
	loading: boolean;
	handleLogin: () => void;
	loginFields: FieldDefinition[];
}

/**
 *
 * @param   {UseLoginFormProps} props - Login form props
 * @returns {UseLoginFormReturn} - Object containing loading state, login function, and login form fields
 */
export const useLoginForm = ({
	strategy,
}: UseLoginFormProps): UseLoginFormReturn => {
	const [inputs, setInputs] = useState({
		email: "",
		password: "",
		rememberMe: false,
	});
	const [loading, setLoading] = useState(false); // Example loading state

	const { handlePageChange } = useContext(PageChangeContext);

	const ContextClass =
		strategy === "admin" || strategy === "employee"
			? EmployeeContext
			: EmployeeContext;
	const { refetch } = useContext(ContextClass);

	const { login } = useLogin();
	const toast = useToast();

	const handleUpdateInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value, checked, type } = e.target;
		setInputs((prevInputs) => ({
			...prevInputs,
			[name]: type === "checkbox" ? checked : value,
		}));
	};

	const handleLogin = async () => {
		setLoading(true);
		const { data, error } = await login(
			inputs.email,
			inputs.password,
			strategy,
			inputs.rememberMe,
		);
		if (error) {
			toast({
				title: "Error",
				description: error.message,
				status: "error",
				duration: 5000,
				isClosable: true,
			});
		} else if (data) {
			toast({
				title: "Success",
				description: "You have successfully logged in!",
				status: "success",
				duration: 5000,
				isClosable: true,
			});
			await refetch();
			handlePageChange(`/${strategy}/dashboard`);
		} else {
			toast({
				title: "Error",
				description: UNKNOWN_ERROR_MSG,
				status: "error",
				duration: 5000,
				isClosable: true,
			});
		}
		setLoading(false);
	};

	const loginFields: FieldDefinition[] = [
		{
			type: "custom",
			name: "email",
			label: "Email",
			value: inputs.email,
			customComponent: EmailInput,
			placeholder: "Enter your email",
			required: true,
			variant: strategy === "admin" ? "outline" : "base",
			onChange: handleUpdateInput,
			validate: validateEmail,
		},
		{
			type: "password",
			name: "password",
			label: "Password",
			value: inputs.password,
			placeholder: "Enter your password",
			required: true,
			variant: strategy === "admin" ? "outline" : "base",
			onChange: handleUpdateInput,
		},
		{
			type: "switch",
			name: "rememberMe",
			label: "Remember Me",
			value: inputs.rememberMe,
			variant: "login",
			onChange: handleUpdateInput,
		},
	];

	return {
		loading,
		handleLogin,
		loginFields,
	};
};

export default useLoginForm;
