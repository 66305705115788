/**
 * @module useCarousel.ts
 * @description A custom hook that creates a carousel effect for a set of images.
 */
import { useState, useEffect, useRef, useCallback } from "react";

/**
 * Configuration object for the useCarousel hook.
 * @typedef {Object} CarouselConfig
 * @property {string[]} images - Array of image URLs to cycle through.
 * @property {number} interval - Time in milliseconds between image transitions.
 * @property {number | (() => number)} batchSize - Number of images to show per batch or a function returning the batch size.
 * @property {boolean} shouldLoop - Flag to determine if the carousel should loop back to the beginning.
 * @property {() => void} onCycleComplete - Callback function to call when all images have been shown.
 */
interface CarouselConfig {
	images: string[];
	interval: number;
	batchSize: number | (() => number);
	shouldLoop: boolean;
	onCycleComplete?: () => void;
}

/**
 * Custom hook to handle carousel functionality.
 * Cycles through images in batches and calls a callback when all images have been shown.
 *
 * @param {CarouselConfig} config - Configuration object for the carousel.
 * @returns {Object} - The current batch of images and control functions (pause, resume, toggleCarousel).
 */
const useCarousel = ({
	images,
	interval,
	batchSize,
	shouldLoop,
	onCycleComplete,
}: CarouselConfig) => {
	const [currentIndex, setCurrentIndex] = useState(0);
	const [isPaused, setIsPaused] = useState(false);
	const intervalRef = useRef<NodeJS.Timeout | null>(null);

	const getCurrentBatchSize = useCallback(
		() => (typeof batchSize === "function" ? batchSize() : batchSize),
		[batchSize],
	);

	const pause = useCallback(() => {
		if (intervalRef.current) clearInterval(intervalRef.current);
		setIsPaused(true);
	}, []);

	const startCarousel = useCallback(() => {
		const currentBatchSize = getCurrentBatchSize();
		intervalRef.current = setInterval(() => {
			setCurrentIndex((prevIndex) => {
				const nextIndex = prevIndex + 1;
				if (nextIndex + currentBatchSize > images.length) {
					if (onCycleComplete) onCycleComplete();
					return 0;
				}
				return nextIndex;
			});
		}, interval);
	}, [images, interval, getCurrentBatchSize, onCycleComplete]);

	useEffect(() => {
		if (!isPaused) {
			startCarousel();
		}

		return () => {
			if (intervalRef.current) clearInterval(intervalRef.current);
		};
	}, [images, interval, isPaused, getCurrentBatchSize, startCarousel]);

	const resume = useCallback(() => {
		setIsPaused(false);
	}, []);

	const toggleCarousel = useCallback(() => {
		isPaused ? resume() : pause();
	}, [isPaused, pause, resume]);

	const handleNextImage = () => {
		pause();
		if (images.length > 0) {
			setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
		}
	};

	return {
		currentImages: images
			.slice(currentIndex, currentIndex + getCurrentBatchSize())
			.concat(
				images.slice(
					0,
					Math.max(0, currentIndex + getCurrentBatchSize() - images.length),
				),
			),
		pause,
		resume,
		toggleCarousel,
		isPaused,
		setCurrentIndex,
		currentIndex,
		handleNextImage,
	};
};

export default useCarousel;
