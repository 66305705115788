import React, { useState, useContext } from "react";
import { EmployeeContext } from "contexts";
import { FiLogOut, FiLock } from "react-icons/fi";
import { UserProfileCard } from "components";
import { useHandleLogout } from "hooks";
import { ChangePasswordModal } from "page-components/Auth";

const AdminProfileCard = () => {
	const { employee, refetch } = useContext(EmployeeContext);
	const { handleLogout } = useHandleLogout("admin");
	const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
	if (!employee) return null;

	const menuItems = [
		{
			label: "Logout",
			onClick: handleLogout,
			icon: FiLogOut,
		},
		{
			label: "Change Password",
			onClick: () => setShowChangePasswordModal(true),
			icon: FiLock,
		},
	];

	return (
		<>
			<UserProfileCard
				userName={`${employee.firstName} ${employee.lastName}`}
				userEmail={employee.email}
				menuItems={menuItems}
			/>
			<ChangePasswordModal
				email={employee.email}
				accountType="admin"
				refetch={refetch}
				isOpen={showChangePasswordModal}
				onClose={() => setShowChangePasswordModal(false)}
			/>
		</>
	);
};

export default AdminProfileCard;
