import React from "react";
import {
	DropzoneStrategy,
	ImageStrategy,
	VideoStrategy,
	DocumentStrategy,
	GenericStrategy,
} from "./strategies";
import Dropzone from "./Dropzone"; // Assuming Dropzone is a previously created component

interface FileInputProps {
	fileType: "image" | "video" | "document" | "generic";
	files: File[];
	setFiles: (files: File[]) => void;
	allowMultiple?: boolean;
	fileUrls?: string[];
	setFileUrls?: (fileUrls: string[]) => void;
	required?: boolean;
}

/**
 * React functional component for a file input component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.fileType - The type of file to accept (image, video, or document).
 * @param {File[]} props.files - The array of selected files.
 * @param {Function} props.setFiles - The function to update the selected files.
 * @param {boolean} [props.allowMultiple=false] - Whether to allow multiple file selection.
 * @returns {JSX.Element} The rendered FileInput component.
 */
const FileInput: React.FC<FileInputProps> = ({
	fileType,
	files,
	setFiles,
	allowMultiple = false,
	fileUrls = [],
	setFileUrls,
	required = true,
}) => {
	let strategy: DropzoneStrategy;

	switch (fileType) {
		case "image":
			strategy = new ImageStrategy(files, setFiles, fileUrls, setFileUrls);
			break;
		case "video":
			strategy = new VideoStrategy(files, setFiles, fileUrls, setFileUrls);
			break;
		case "document":
			strategy = new DocumentStrategy(files, setFiles, fileUrls, setFileUrls);
			break;
		case "generic":
			strategy = new GenericStrategy(files, setFiles, fileUrls, setFileUrls);
			break;
		default:
			return null;
	}

	return (
		<Dropzone
			strategy={strategy}
			allowMultiple={allowMultiple}
			required={required}
		/>
	);
};

export default FileInput;
