/**
 * @module useDeleteResourceForm
 * @desc A custom hook that handles the logic for deleting a resource.
 * @handles Opening and closing the delete dialog, and handling the delete action.
 */
import { useState } from "react";
import { BaseDelete } from "cud/delete"; // Import the BaseDelete class
import { useToast } from "@chakra-ui/react";
import { useDeleteEmployee } from "services";
import { Employee } from "services";

/**
 * @typedef UseDeleteResourceFormReturn
 * @property {boolean} isDialogOpen - Indicates whether the delete dialog is open.
 * @property {function} openDialog - Opens the delete dialog.
 * @property {function} closeDialog - Closes the delete dialog.
 * @property {function} handleDelete - Handles the delete action.
 * @property {boolean} loading - Indicates whether the delete action is in progress.
 * @property {any} error - The error object, if any.
 * @desc The return type of the useDeleteResourceForm hook.
 * @category Hooks
 */
const useDeleteEmployeeForm = (
	employee: Employee,
	refetch: () => Promise<void>,
) => {
	const [loading, setLoading] = useState(false);
	const toast = useToast();
	const { deleteEmployee } = useDeleteEmployee();

	const baseDelete = new BaseDelete<Employee>(
		employee,
		"Employee",
		toast,
		deleteEmployee,
		refetch,
	);

	// Define the state variables and functions
	const handleDelete = async () => {
		setLoading(true);
		await baseDelete.handleDelete();
		setLoading(false);
	};

	// Return the state variables and functions
	return {
		handleDelete,
		loading,
	};
};

export default useDeleteEmployeeForm;
