import { gql } from "@apollo/client";

const getPositions = gql`
	query getPositions($retrievalInput: RetrievalInput) {
		getPositions(retrievalInput: $retrievalInput) {
			items {
				_id
				positionName
				description
				details {
					jobTitle
					department
					location
					commitmentLevel
				}
			}
			totalCount
			hasNextPage
			hasPreviousPage
		}
	}
`;

const getPositionApplications = gql`
	query getPositionApplications($retrievalInput: RetrievalInput) {
		getPositionApplications(retrievalInput: $retrievalInput) {
			items {
				_id
				position {
					_id
					positionName
					description
					details {
						jobTitle
						department
						location
						commitmentLevel
					}
				}
				applicationDetails {
					firstName
					lastName
					email
					phone
					resume
				}
				status
			}
			totalCount
			hasNextPage
			hasPreviousPage
		}
	}
`;

const getDealerApplications = gql`
	query getDealerApplications($retrievalInput: RetrievalInput) {
		getDealerApplications(retrievalInput: $retrievalInput) {
			items {
				_id
				status
				applicationDetails
			}
			totalCount
			hasNextPage
			hasPreviousPage
		}
	}
`;

export { getPositions, getPositionApplications, getDealerApplications };
