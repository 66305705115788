import { useEffect, useState } from "react";
import { useGetCustomWorkGalleryImages } from "services";

/**
 * @interface GroupedImages
 * @description Interface for the grouped images object.
 * @property {string[]} - The array of image paths.
 */
interface GroupedImages {
	[key: string]: string[];
}

/**
 * @function useGroupedImages
 * @description A custom hook that fetches and groups images by category.
 * @returns {object} An object containing the grouped images, loading state, and error state.
 * @returns {GroupedImages} groupedImages - The grouped images object.
 * @returns {boolean} loading - The loading state.
 * @returns {Error} error - The error state.
 * @example const { groupedImages, loading, error } = useGroupedImages();
 * @example const imagesByCategory = groupedImages[category];
 */
const useGroupedImages = () => {
	const {
		data: customWorkGalleryImages,
		loading,
		error,
	} = useGetCustomWorkGalleryImages();
	const [groupedImages, setGroupedImages] = useState<GroupedImages>({});

	useEffect(() => {
		if (customWorkGalleryImages) {
			const grouped = customWorkGalleryImages.items.reduce(
				(acc: GroupedImages, item) => {
					const { category, image } = item;
					if (!acc[category]) {
						acc[category] = [];
					}
					acc[category].push(image);
					return acc;
				},
				{} as GroupedImages,
			);
			setGroupedImages(grouped);
		}
	}, [customWorkGalleryImages]);

	return { groupedImages, loading, error };
};

export default useGroupedImages;
