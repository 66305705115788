import { useUpdateEmployee } from "services";
import { FieldDefinition } from "components";

import { Employee, EmployeeInput } from "services/types";

import { MultiSelect } from "components/common/inputs";
import { useForm } from "hooks/forms";
import BaseUpdate from "cud/update/BaseUpdate";
import { ICUDFormHook } from "./types";

import { EmailInput, PhoneNumberInput } from "components/common/inputs";
import { validateEmail, validatePhoneNumber } from "utils/validators";
import { ACCESS_LEVEL_OPTIONS } from "consts";

const useUpdateEmployeeForm = ({
	refetch,
	existingEmployee,
}: ICUDFormHook & { existingEmployee: Employee }) => {
	const initialFormState = {
		firstName: existingEmployee.firstName,
		lastName: existingEmployee.lastName,
		email: existingEmployee.email,
		phone: existingEmployee.phone,
		role: existingEmployee.role,
		accessLevel: existingEmployee.accessLevel,
	};

	const {
		formState,
		loading,
		setLoading,
		handleInputChange,
		resetFormState,
		toast,
	} = useForm<EmployeeInput>({
		initialState: initialFormState,
	});

	const { updateEmployee } = useUpdateEmployee();

	const employeeUpdate = new BaseUpdate(
		existingEmployee,
		"Employee",
		toast,
		updateEmployee,
		refetch,
	);

	const handleSubmit = async () => {
		setLoading(true);
		const res = await employeeUpdate.handleUpdate(formState);
		if (res) resetFormState();
		setLoading(false);
	};

	const fields: FieldDefinition[] = [
		{
			name: "firstName",
			label: "First Name",
			type: "text",
			value: formState.firstName,
			onChange: handleInputChange,
			required: true,
		},
		{
			name: "lastName",
			label: "Last Name",
			type: "text",
			value: formState.lastName,
			onChange: handleInputChange,
			required: true,
		},
		{
			name: "email",
			label: "Email",
			type: "custom",
			customComponent: EmailInput,
			value: formState.email,
			onChange: handleInputChange,
			validate: validateEmail,
			required: true,
		},
		{
			name: "role",
			label: "Role",
			type: "text",
			value: formState.role,
			onChange: handleInputChange,
			required: true,
		},
		{
			name: "phone",
			label: "Phone",
			type: "custom",
			customComponent: PhoneNumberInput,
			value: formState.phone,
			onChange: handleInputChange,
			validate: validatePhoneNumber,
			required: true,
		},
		{
			name: "accessLevel",
			label: "Access Level",
			type: "custom",
			customComponent: MultiSelect,
			customComponentProps: {
				options: ACCESS_LEVEL_OPTIONS,
				onChange: handleInputChange,
				value: formState.accessLevel,
			},
			required: true,
		},
	];

	return { loading, fields, handleSubmit };
};

export default useUpdateEmployeeForm;
