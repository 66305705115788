import { MainContentsLayout } from "wrappers";
import { QuoteForm } from "page-components";

const RequestQuote = () => {
	return (
		<MainContentsLayout>
			<QuoteForm />
		</MainContentsLayout>
	);
};

export default RequestQuote;
