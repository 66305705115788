import { FC } from "react";
import {
	Popover,
	PopoverTrigger,
	Button,
	PopoverContent,
	PopoverArrow,
	PopoverCloseButton,
	PopoverHeader,
	PopoverBody,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	Box,
} from "@chakra-ui/react";

interface ContactDetailsPopoverProps {
	cell?: string;
	email?: string;
	office?: string;
	fax?: string;
}

const expandedStyles = {
	color: "primary.100",
	fontWeight: "bold",
};

const ContactDetailsPopover: FC<ContactDetailsPopoverProps> = ({
	cell,
	email,
	office,
	fax,
}) => (
	<Popover>
		<PopoverTrigger>
			<Button size="sm" variant="outline">
				Contact Info
			</Button>
		</PopoverTrigger>
		<PopoverContent border="2px solid" borderColor="primary.100">
			<PopoverArrow />
			<PopoverCloseButton />
			<PopoverHeader border="none">Contact Details</PopoverHeader>
			<PopoverBody w="100%" p={0} border="none">
				<Accordion allowToggle w="100%" p={0}>
					{email && (
						<AccordionItem borderBottom={"none"}>
							<AccordionButton _expanded={expandedStyles}>
								<Box flex="1" textAlign="left">
									Email
								</Box>
								<AccordionIcon />
							</AccordionButton>
							<AccordionPanel pb={4}>{email}</AccordionPanel>
						</AccordionItem>
					)}
					{cell && (
						<AccordionItem borderBottom={"none"}>
							<AccordionButton _expanded={expandedStyles}>
								<Box flex="1" textAlign="left">
									Cell #
								</Box>
								<AccordionIcon />
							</AccordionButton>
							<AccordionPanel pb={4}>{cell}</AccordionPanel>
						</AccordionItem>
					)}
					{office && (
						<AccordionItem borderBottom={"none"}>
							<AccordionButton _expanded={expandedStyles}>
								<Box flex="1" textAlign="left">
									Office #
								</Box>
								<AccordionIcon />
							</AccordionButton>
							<AccordionPanel pb={4}>{office}</AccordionPanel>
						</AccordionItem>
					)}
					{fax && (
						<AccordionItem borderBottom={"none"}>
							<AccordionButton _expanded={expandedStyles}>
								<Box flex="1" textAlign="left">
									Fax #
								</Box>
								<AccordionIcon />
							</AccordionButton>
							<AccordionPanel pb={4}>{fax}</AccordionPanel>
						</AccordionItem>
					)}
				</Accordion>
			</PopoverBody>
		</PopoverContent>
	</Popover>
);

export default ContactDetailsPopover;
