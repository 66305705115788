import { useQuery } from "@apollo/client";
import { getProducts as GET_PRODUCTS } from "./queries";
import { EcwidProduct } from "services/types";

interface GetProductsData {
	getProducts: EcwidProduct[];
}

const useGetProducts = (productIds?: number[]) => {
	const variables = productIds ? { productIds } : {};

	const { loading, error, data, refetch, networkStatus } =
		useQuery<GetProductsData>(GET_PRODUCTS, {
			variables: variables,
		});
	const processedData = data?.getProducts;
	return { loading, error, data: processedData, refetch, networkStatus };
};

export default useGetProducts;
