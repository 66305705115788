import { useMutation } from "@apollo/client";
import { resolvePositionApplication as RESOLVE_POSITION_APPLICATION } from "./mutations";
interface ResolvePositionApplicationData {
	resolvePositionApplication: boolean;
}

const useResolvePositionApplication = () => {
	const [resolvePositionApplicationMutation, { loading, error }] =
		useMutation<ResolvePositionApplicationData>(RESOLVE_POSITION_APPLICATION);
	const resolvePositionApplication = async (
		positionApplicationId: string,
		status: string,
	) => {
		try {
			const { data } = await resolvePositionApplicationMutation({
				variables: {
					positionApplicationId,
					status,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.resolvePositionApplication };
		} catch (error: any) {
			return { error };
		}
	};
	return { resolvePositionApplication, loading, error };
};
export default useResolvePositionApplication;
