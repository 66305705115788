/**
 * @module Mission.tsx
 */
import React from "react";
import { Text, Heading } from "@chakra-ui/react";
import { StackLayout } from "components";

/**
 * MissionSection is a component that displays the company's mission statement.
 * This component is straightforward and primarily intended for displaying text content.
 * It is designed to be flexible and reusable by accepting the mission statement as a prop,
 * allowing it to adapt to different contexts where a mission statement or similar text content
 * is needed.
 *
 * @returns {React.ReactElement} A component representing the mission section on a page.
 *
 */

interface MissionSectionProps {
	paragraphs: string[];
}

const MissionSection: React.FC<MissionSectionProps> = ({ paragraphs }) => {
	return (
		<>
			<Heading
				variant="sectionTitle"
				mb={6}
				alignSelf="center"
				mx={{ base: "4", md: "48" }}
			>
				Our Mission at Wayside Fence Company is twofold
			</Heading>
			<StackLayout spacing={4}>
				{paragraphs.map((paragraph, index) => (
					<Text
						key={index}
						variant="statement"
						alignSelf="center"
						as="p"
						mb={{ base: "4", md: "8" }}
						px={{ base: "4", md: "48" }}
						textAlign="center"
						w="65%"
					>
						{paragraph}
					</Text>
				))}
			</StackLayout>
		</>
	);
};

export default MissionSection;
