import { useCreatePromotion } from "services";
import { PromotionInput, PromotionProduct } from "services/types";
import { FieldDefinition } from "components";
import { FileInput } from "components/common/inputs";
import { ICUDFormHook } from "./types";
import { useFormWithFiles } from "hooks/forms";
import { PromotionCreate } from "cud";
import ProductContentMultiSelect from "page-components/Store/ProductContentMultiSelect";

const useCreatePromotionForm = ({ refetch }: ICUDFormHook) => {
	// Define initial form state
	const initialFormState: PromotionInput = {
		title: "",
		subtitle: "",
		products: [],
		images: [],
		callToAction: "",
	};

	const {
		formState,
		loading,
		setLoading,
		handleInputChange,
		resetFormState,
		toast,
		files,
		handleSetFiles,
		uploadFile,
	} = useFormWithFiles<PromotionInput>({
		initialState: initialFormState,
		allowMultiple: true,
	});

	const { createPromotion } = useCreatePromotion();

	const promotionCreate = new PromotionCreate(
		toast,
		createPromotion,
		refetch,
		files as File[] | null,
		uploadFile,
	);

	const handleSubmit = async () => {
		setLoading(true);
		const success = await promotionCreate.handleCreate(formState);
		if (success) resetFormState();
		setLoading(false);
	};
	const fields: FieldDefinition[] = [
		{
			type: "text",
			name: "title",
			label: "Title",
			value: formState.title,
			onChange: handleInputChange,
			required: true,
		},
		{
			type: "text",
			name: "subtitle",
			label: "Subtitle",
			value: formState.subtitle,
			onChange: handleInputChange,
			required: false,
		},
		{
			label: "Images",
			name: "images",
			type: "custom",
			customComponent: FileInput,
			customComponentProps: {
				fileType: "image",
				files: files ? (Array.isArray(files) ? files : [files]) : [],
				setFiles: handleSetFiles,
				allowMultiple: true,
			},
			minWidth: "100%",
		},
		{
			type: "text",
			name: "callToAction",
			label: "Call to Action",
			value: formState.callToAction,
			onChange: handleInputChange,
			required: false,
		},
		{
			label: "Products",
			name: "ecwidIDs",
			type: "custom",
			customComponent: ProductContentMultiSelect,
			customComponentProps: {
				selectedProducts: formState.products,
				onSelectionChange: (products: PromotionProduct[]) =>
					handleInputChange({ target: { name: "products", value: products } }),
			},
			required: true,
			minWidth: "100%",
		},
	];

	return { fields, handleSubmit, loading };
};

export default useCreatePromotionForm;
