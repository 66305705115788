import { useMutation } from "@apollo/client";
import { updatePartner as UPDATE_PARTNER } from "./mutations";
import { PartnerInput } from "../../types";

interface UpdatePartnerData {
	updatePartner: boolean;
}

const useUpdatePartner = () => {
	const [updatePartnerMutation, { loading, error }] =
		useMutation<UpdatePartnerData>(UPDATE_PARTNER);
	const updatePartner = async (
		partnerID: string,
		partnerInput: PartnerInput,
	) => {
		try {
			const { data } = await updatePartnerMutation({
				variables: {
					partnerID,
					partnerInput,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.updatePartner };
		} catch (error: any) {
			return { error };
		}
	};
	return { updatePartner, loading, error };
};
export default useUpdatePartner;
