import React, { useState, useEffect, useRef } from "react";
import { Box, Input, Textarea } from "@chakra-ui/react";
import EcwidProductItem from "./EcwidProductItem"; // Ensure this import path matches your file structure
import { EcwidProduct, useGetProducts } from "services";
import { Loader, ErrorMessage } from "components";
import { SimpleGrid } from "@chakra-ui/react";

interface ProductContent {
	ecwidID: number;
	content: string;
}

interface ProductContentMultiSelectProps {
	selectedProducts: ProductContent[];
	onSelectionChange: (selectedProducts: ProductContent[]) => void;
	name: string; // Add a name prop for the input field identification
	required?: boolean; // Optional prop to determine if the selection is required
}

const ProductContentMultiSelect: React.FC<ProductContentMultiSelectProps> = ({
	selectedProducts,
	onSelectionChange,
	name,
	required = false,
}) => {
	const { loading, error, data: products } = useGetProducts();

	const [searchTerm, setSearchTerm] = useState("");
	const [activeProducts, setActiveProducts] = useState<EcwidProduct[]>([]);
	const [filteredProducts, setFilteredProducts] = useState<EcwidProduct[]>([]);

	// Reference for the hidden input
	const hiddenInputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		const lowercasedSearchTerm = searchTerm.toLowerCase();
		const filtered = products
			? products.filter(
					(product) =>
						product.name.toLowerCase().includes(lowercasedSearchTerm) &&
						!selectedProducts.some((p) => p.ecwidID === product.id),
			  )
			: [];

		const active = products
			? products.filter((product) =>
					selectedProducts.some((p) => p.ecwidID === product.id),
			  )
			: [];

		setFilteredProducts(filtered);
		setActiveProducts(active);
	}, [searchTerm, products, selectedProducts]);

	const handleSelectToggle = (id: number) => {
		const existingProduct = selectedProducts.find(
			(product) => product.ecwidID === id,
		);

		if (existingProduct) {
			onSelectionChange(
				selectedProducts.filter((product) => product.ecwidID !== id),
			);
		} else {
			onSelectionChange([...selectedProducts, { ecwidID: id, content: "" }]);
		}
	};

	const handleContentChange = (id: number, content: string) => {
		onSelectionChange(
			selectedProducts.map((product) =>
				product.ecwidID === id ? { ...product, content } : product,
			),
		);
	};

	return (
		<Box>
			<input
				type="hidden"
				ref={hiddenInputRef}
				name={name}
				value={JSON.stringify(selectedProducts)}
				required={required}
				onChange={() => {}} // This is just to satisfy React's controlled input requirement
			/>
			<Input
				placeholder="Search products..."
				value={searchTerm}
				onChange={(e) => setSearchTerm(e.target.value)}
				mb={4}
				isRequired={false}
			/>
			<SimpleGrid
				columns={{ base: 2, md: 3 }}
				spacing={4}
				p={2}
				maxHeight="600px"
				overflowY="auto"
			>
				{loading ? (
					<Loader />
				) : error ? (
					<ErrorMessage title="Error loading products" />
				) : (
					<>
						{activeProducts.map((product) => (
							<Box key={product.id}>
								<Box
									onClick={() => handleSelectToggle(product.id)}
									cursor="pointer"
									borderWidth="1px"
									borderRadius="lg"
									p={4}
									_hover={{ bg: "gray.50" }}
									bgColor={"primary.500"}
								>
									<EcwidProductItem product={product} />
								</Box>
								<Textarea
									placeholder="Enter content..."
									value={
										selectedProducts.find((p) => p.ecwidID === product.id)
											?.content || ""
									}
									onChange={(e) =>
										handleContentChange(product.id, e.target.value)
									}
									mt={2}
								/>
							</Box>
						))}
						{filteredProducts.map((product) => (
							<Box key={product.id}>
								<Box
									onClick={() => handleSelectToggle(product.id)}
									cursor="pointer"
									borderWidth="1px"
									borderRadius="lg"
									p={4}
									_hover={{ bg: "gray.50" }}
									bgColor={"white"}
								>
									<EcwidProductItem product={product} />
								</Box>
							</Box>
						))}
					</>
				)}
			</SimpleGrid>
		</Box>
	);
};

export default ProductContentMultiSelect;
