import React, { createContext, useState, useContext, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { ScrollContext } from "./ScrollContext";

// TypeScript interfaces for context and provider props
interface PageChangeContextProps {
	page: string;
	handlePageChange: (
		page: string,
		searchParams?: Record<string, string>,
	) => void;
	redirectToLogin: () => void;
	goBack: () => void; // goBack is added to the interface
}

interface PageChangeProviderProps {
	children: ReactNode;
}

// Create the context with default values
export const PageChangeContext = createContext<PageChangeContextProps>({
	page: "",
	handlePageChange: () => {},
	redirectToLogin: () => {},
	goBack: () => {}, //goBack is initialized as an empty function
});

export const PageChangeProvider: React.FC<PageChangeProviderProps> = ({
	children,
}) => {
	const { scrollToTop } = useContext(ScrollContext);
	const navigate = useNavigate();

	const initialPage = window.location.pathname.slice(1);
	const [page, setPage] = useState<string>(initialPage);

	const handlePageChange = (
		newPage: string,
		searchParams?: Record<string, string>,
	) => {
		if (newPage.charAt(0) === "/") {
			newPage = newPage.slice(1);
		}
		setPage(newPage);
		let searchParamsString = "";
		if (searchParams) {
			const urlSearchParams = new URLSearchParams(searchParams).toString();
			searchParamsString = `?${urlSearchParams}`;
		}
		navigate(`/${newPage}${searchParamsString}`);
		try {
			scrollToTop();
		} catch (error) {
			console.error(error);
		}
	};

	const redirectToLogin = () => {
		handlePageChange("login", { redirect: page });
	};

	// goBack function is defined
	const goBack = () => {
		navigate(-1);
	};

	return (
		<PageChangeContext.Provider
			value={{ page, handlePageChange, redirectToLogin, goBack }} // goBack is then finally added to the value
		>
			{children}
		</PageChangeContext.Provider>
	);
};
