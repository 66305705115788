import React, { useRef, useState } from "react";
import {
	Button,
	Box,
	Center,
	Icon,
	Input,
	VStack,
	HStack,
	Text,
	Square,
	Collapse,
	Flex,
	useToast,
} from "@chakra-ui/react";
import { FiUploadCloud } from "react-icons/fi";

import { DropzoneStrategy } from "./strategies";

import { IFramePreview, ImagePreview } from "components/features/previews";

interface DropzoneProps {
	strategy: DropzoneStrategy;
	allowMultiple?: boolean;
	required: boolean;
	limit?: number;
}

/**
 * React functional component for a dropzone.
 *
 * @component
 * @param {DropzoneProps} props - The props for the dropzone component.
 * @param {DropzoneStrategy} props.strategy - The strategy for handling dropped files.
 * @param {boolean} [props.allowMultiple=false] - Whether multiple files are allowed to be dropped.
 * @returns {JSX.Element} The dropzone component.
 */
const Dropzone: React.FC<DropzoneProps> = ({
	strategy,
	allowMultiple = false,
	required,
	limit = 10,
}) => {
	const [showFiles, setShowFiles] = useState(false);

	const toast = useToast();

	const fileInputRef = useRef<HTMLInputElement>(null);
	const handleFileInputClick = () => fileInputRef.current?.click();

	/**
	 * Validates and filters an array of files, returning only the valid files.
	 *
	 * @param {File[]} files - The array of files to validate and filter.
	 * @returns {File[]} The array of valid files.
	 */
	const getValidFiles = (files: File[]) => {
		const validFiles = [];
		for (const file of files) {
			try {
				strategy.validateFile(file);
				validFiles.push(file);
			} catch (err: any) {
				toast({
					title: `${file.name} was not added`,
					description: err.message,
					status: "error",
					duration: 5000,
					isClosable: true,
				});
			}
		}
		return validFiles;
	};

	/**
	 * Handles the event when files are added to the input element.
	 *
	 * @param {React.ChangeEvent<HTMLInputElement>} event - The event object containing the added files.
	 * @returns {void}
	 */
	const onFilesAdded = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		const files = Array.from(event.target.files || []);
		const currentTotal =
			strategy.files.length + (strategy.fileUrls?.length || 0);
		if (currentTotal >= limit) {
			toast({
				title: "File limit reached",
				description: `You cannot upload more than ${limit} files.`,
				status: "error",
				duration: 5000,
				isClosable: true,
			});
			return;
		}

		const validFiles = getValidFiles(files);
		const allowedNewFiles = validFiles.slice(0, limit - currentTotal);
		strategy.onFilesAdded(allowedNewFiles);
	};

	/**
	 * Handles the event when files are dropped onto the dropzone.
	 *
	 * @param {React.DragEvent<HTMLDivElement>} event - The event object containing the dropped files.
	 * @returns {void}
	 */
	const onFilesDropped = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		const files = Array.from(event.dataTransfer.files);
		const currentTotal =
			strategy.files.length + (strategy.fileUrls?.length || 0);
		if (currentTotal >= limit) {
			toast({
				title: "File limit reached",
				description: `You cannot upload more than ${limit} files.`,
				status: "error",
				duration: 5000,
				isClosable: true,
			});
			return;
		}

		const validFiles = getValidFiles(files);
		const allowedNewFiles = validFiles.slice(0, limit - currentTotal);
		strategy.onFilesAdded(allowedNewFiles);
		if (fileInputRef.current) {
			fileInputRef.current.files = event.dataTransfer.files;
		}
	};
	/**
	 * A function to handle the drag over.
	 *
	 * @param {React.DragEvent<HTMLDivElement>} evt - The drag event object.
	 * @returns {void}
	 */
	const handleDragOver = (evt: React.DragEvent<HTMLDivElement>): void => {
		evt.preventDefault();
	};

	return (
		<Center
			onDrop={onFilesDropped}
			onDragOver={handleDragOver}
			borderWidth="1px"
			borderRadius="lg"
			px="6"
			py="4"
			bg={"white"}
		>
			<VStack spacing="3">
				<Square size="10" bg="bg-subtle" borderRadius="lg">
					<Icon as={FiUploadCloud} boxSize="5" color="muted" />
				</Square>
				<VStack spacing="1">
					<HStack spacing="1" whiteSpace="nowrap">
						<Button
							variant="link"
							size="sm"
							onClick={handleFileInputClick}
							color="white"
							p={"1"}
						>
							Click to upload
						</Button>
						<Text fontSize="sm" color="muted">
							or drag and drop
						</Text>
						<Input
							name="File"
							type="file"
							ref={fileInputRef}
							onChange={onFilesAdded}
							multiple={allowMultiple}
							accept={strategy.getAcceptedFileTypes()}
							style={{
								opacity: 0,
								position: "absolute",
								pointerEvents: "none",
								height: 0,
								width: 0,
							}}
							tabIndex={-1}
							required={
								required &&
								!(strategy.files.length > 0 || strategy.fileUrls?.length > 0)
							}
						/>
					</HStack>
					<Text fontSize="xs" color="muted">
						{strategy.acceptedString}
					</Text>
				</VStack>
				{strategy.files.length > 0 && (
					<Flex
						direction="column"
						marginTop="4"
						px="4"
						w="100%"
						justifyContent="center"
						alignItems="center"
						rowGap="1rem"
					>
						<Button
							variant="link"
							size="sm"
							onClick={() => setShowFiles(!showFiles)}
							color="white"
							p={"1"}
						>
							{showFiles ? "Hide" : "Show"} file
							{strategy.files.length > 1 && "s"}
						</Button>
						<Collapse in={showFiles}>
							<VStack spacing="1">
								{strategy?.files.map((file) => (
									<HStack key={file?.name} spacing="1">
										<Text fontSize="xs" color="muted">
											{file?.name}
										</Text>
										<Button
											variant="link"
											size="xs"
											onClick={() => strategy.onFileDeleted(file)}
											color="white"
											bg="red.500"
											p={"0.70"}
											_hover={{
												bg: "red.600",
											}}
										>
											Delete
										</Button>
									</HStack>
								))}
							</VStack>
						</Collapse>
					</Flex>
				)}
				<Flex
					direction="column"
					marginTop="4"
					px="4"
					justifyContent="center"
					alignItems="center"
					rowGap="1rem"
				>
					<VStack spacing="1">
						{(allowMultiple || strategy.files.length === 0) &&
							strategy.fileUrls?.map((url) => (
								<HStack key={url} spacing="1">
									{strategy.type === "image" ? (
										<Box w="150px" h="150px">
											<ImagePreview src={url} alt={`Preview of ${url}`} />
										</Box>
									) : (
										<Box w="200px" h="200px">
											<IFramePreview
												src={url}
												title={`Preview of ${url}`}
												height="auto"
											/>
										</Box>
									)}
									<Button
										variant="link"
										size="md"
										onClick={() => strategy.onFileUrlDeleted(url)}
									>
										Delete
									</Button>
								</HStack>
							))}
					</VStack>
				</Flex>
			</VStack>
		</Center>
	);
};

export default Dropzone;
