/**
 * @module CreatePromotionForm.tsx
 * @description This file contains the form for creating a new Promotion.
 */
import React from "react";
import { Button, Flex } from "@chakra-ui/react";
import { Form } from "components";
import { useModal } from "hooks";
import { useCreatePromotionForm } from "hooks/form-hooks";
import { ModalWrapper } from "wrappers";
import { FaPlus } from "react-icons/fa6";

/**
 * The props for the CreatePromotionForm component.
 */
interface CreatePromotionFormProps {
	refetch: () => Promise<any>;
}

/**
 * This component is used to create a new Promotion.
 * @param {CreatePromotionFormProps} props The props for the component.
 * @returns {React.ReactElement} The CreatePromotionForm component.
 */
const CreatePromotionForm: React.FC<CreatePromotionFormProps> = ({
	refetch,
}) => {
	const { isOpen, open, close } = useModal();

	const { fields, handleSubmit, loading } = useCreatePromotionForm({ refetch });

	const onSubmit = async () => {
		await handleSubmit();
		close();
	};

	return (
		<>
			<Flex>
				<Button
					size="lg"
					aria-label="create-Promotion"
					onClick={open}
					variant={"withIcon"}
					leftIcon={<FaPlus />}
					borderRadius="15px"
					mx={6}
					my={4}
				>
					Create Promotion
				</Button>
			</Flex>
			<ModalWrapper
				isOpen={isOpen}
				onClose={close}
				showShadow={true}
				heading="Create a Promotion"
				hideCloseButton={false}
				size={"full"}
				modalContentProps={{
					maxHeight: "100vh",
					overflowY: "auto",
					pb: "8",
				}}
			>
				<Form
					fields={fields}
					onSubmit={onSubmit}
					isLoading={loading}
					maxHeight={"85vh"}
				/>
			</ModalWrapper>
		</>
	);
};

export default CreatePromotionForm;
