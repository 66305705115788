import { Flex } from "@chakra-ui/react";

interface AdminResponsiveLayoutProps {
	children: React.ReactNode;
}

const AdminResponsiveLayout: React.FC<AdminResponsiveLayoutProps> = ({
	children,
}) => {
	return (
		<Flex
			direction={{ base: "column-reverse", md: "column" }}
			justify="flex-start"
			align="flex-start"
			py="1rem"
			pr="1rem"
			w="100%"
			margin="0 auto"
			maxW="95vw"
		>
			{children}
		</Flex>
	);
};

export default AdminResponsiveLayout;
