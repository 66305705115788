import React, { useState } from "react";
import {
	ActionsRow,
	ContactDetailsPopover,
} from "components/features/tables/cells";
import { useRetrieval } from "hooks";
import { SearchTable } from "components/features/tables";
import { createColumnHelper } from "@tanstack/react-table";
import {
	PositionApplication,
	useGetPositionApplications,
	FilterSettingsInput,
} from "services";
import { Button, useToast } from "@chakra-ui/react";
import { DeletePositionApplicationModal } from "page-components/admin/position-applications";
import { AdminResponsiveLayout } from "components";
import { Badge } from "@chakra-ui/react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { useResolvePositionApplication } from "services";

const AdminPositionApplications: React.FC = () => {
	const {
		paginationInput,
		setPaginationInput,
		filterSettings,
		handleFiltersChange,
		sortInput,
		handleSortChange,
	} = useRetrieval();

	const handleBuildFilterSettings = (
		query: string,
		conditional: "AND" | "OR" = "OR",
	) => {
		const filterSettings: FilterSettingsInput = {
			conditional,
			filters: [
				{
					field: "positionName",
					operator: "regex",
					value: query,
				},
				{
					field: "description",
					operator: "regex",
					value: query,
				},
			],
		};
		return filterSettings;
	};

	const {
		loading,
		error,
		data: positionApplications,
		refetch,
	} = useGetPositionApplications({
		filterSettings: handleBuildFilterSettings(
			filterSettings.query,
			filterSettings.conditional,
		),
		pagination: paginationInput,
		sort: sortInput,
	});

	const [selectedPositionApplication, setSelectedPositionApplication] =
		useState<PositionApplication | null>(null);

	//const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

	const closeDeleteModal = () => {
		setIsDeleteModalOpen(false);
		setSelectedPositionApplication(null);
	};

	const onRowDelete = (pos: PositionApplication) => {
		setSelectedPositionApplication(pos);
		setIsDeleteModalOpen(true); // Open the modal
	};

	const toast = useToast();
	const { resolvePositionApplication } = useResolvePositionApplication();

	const handleResolvePositionApplication = async (
		positionApplicationId: string,
		status: string,
	) => {
		const { data, error } = await resolvePositionApplication(
			positionApplicationId,
			status,
		);
		if (error) {
			toast({
				title: "Error",
				description: error.message,
				status: "error",
				isClosable: true,
			});
		} else if (data) {
			toast({
				title: "Success",
				description: "Position application status updated successfully",
				status: "success",
				isClosable: true,
			});
			await refetch();
		} else {
			toast({
				title: "Error",
				description: "An unexpected error occurred. Please try again later.",
				status: "error",
				isClosable: true,
			});
		}
	};

	const columnHelper = createColumnHelper<PositionApplication>();

	const columns = [
		columnHelper.accessor("applicationDetails.firstName", {
			header: () => "First Name",
			cell: (info) => info.getValue(),
		}),
		columnHelper.accessor("applicationDetails.lastName", {
			header: () => "Last Name",
			cell: (info) => info.getValue(),
		}),
		columnHelper.accessor("position", {
			header: () => "Position Name",
			cell: (info) => info.getValue()?.positionName || "Position Deleted",
		}),
		columnHelper.display({
			header: () => "Contact Info",
			id: "contactInfo",
			cell: (props) => {
				const { email, phone } = props.row.original.applicationDetails;
				return <ContactDetailsPopover email={email} cell={phone} />;
			},
		}),
		columnHelper.accessor("status", {
			header: () => "Application Status",
			cell: (info) => {
				const status = info.getValue();
				let colorScheme = "gray";

				switch (status.toLowerCase()) {
					case "approved":
						colorScheme = "green";
						break;
					case "rejected":
						colorScheme = "red";
						break;
					case "pending":
						colorScheme = "orange";
						break;
				}
				return (
					<Badge borderRadius={"4"} colorScheme={colorScheme}>
						{status}
					</Badge>
				);
			},
		}),
		columnHelper.accessor("applicationDetails.resume", {
			header: () => "Resume / CV",
			cell: (info) => (
				<Button
					onClick={() =>
						window.open(info.row.original.applicationDetails.resume, "_blank")
					}
					variant="outline"
				>
					Open PDF
				</Button>
			),
		}),
		columnHelper.display({
			header: () => "Actions",
			id: "actions",
			cell: (props) => (
				<ActionsRow
					onDelete={() => onRowDelete(props.row.original)}
					actions={[
						{
							name: "Accept Application",
							icon: <FaCheck />,
							onClick: () => {
								handleResolvePositionApplication(
									props.row.original._id,
									"approved",
								);
							},
						},
						{
							name: "Reject Application",
							icon: <FaTimes />,
							onClick: () => {
								handleResolvePositionApplication(
									props.row.original._id,
									"rejected",
								);
							},
						},
					]}
				/>
			),
		}),
	];

	return (
		<AdminResponsiveLayout>
			<SearchTable
				columnsData={columns}
				tableData={positionApplications?.items || []}
				paginationInput={paginationInput}
				setPaginationInput={setPaginationInput}
				filterSettings={filterSettings}
				handleBuildFilters={handleFiltersChange}
				loading={loading}
				error={error}
				sortInput={sortInput}
				handleSortChange={handleSortChange}
				totalCount={positionApplications?.totalCount || 0}
				hasNextPage={positionApplications?.hasNextPage || false}
				hasPreviousPage={positionApplications?.hasPreviousPage || false}
			/>
			{selectedPositionApplication && (
				<DeletePositionApplicationModal
					positionApplication={selectedPositionApplication}
					isOpen={isDeleteModalOpen}
					onClose={closeDeleteModal}
					refetch={refetch}
				/>
			)}
		</AdminResponsiveLayout>
	);
};

export default AdminPositionApplications;
