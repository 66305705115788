/**
 * @module useUpdateReview.ts
 * @description This file contains the logic for updating a review in the database.
 */
import { useMutation } from "@apollo/client";
import { updateReview as UPDATE_REVIEW } from "./mutations";
import { ReviewInput } from "../../types";

interface UpdateReviewData {
	updateReview: boolean;
}

/**
 * This is the hook that is used to update a review in the database.
 * @returns {Object} The updateReview function, loading state, and error state.
 */
const useUpdateReview = () => {
	const [updateReviewMutation, { loading, error }] =
		useMutation<UpdateReviewData>(UPDATE_REVIEW);

	/**
	 * This function is used to update a review in the database.
	 * @param {string} reviewID The ID of the review to update.
	 * @param {ReviewInput} reviewInput The input for the updated review.
	 * @returns {Object} The data for the updated review or an error.
	 */
	const updateReview = async (reviewID: string, reviewInput: ReviewInput) => {
		try {
			const { data } = await updateReviewMutation({
				variables: {
					reviewID,
					reviewInput,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.updateReview };
		} catch (error: any) {
			return { error };
		}
	};

	return { updateReview, loading, error };
};
export default useUpdateReview;
