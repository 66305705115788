import { gql } from "@apollo/client";

const submitCustomInquiryForm = gql`
	mutation submitCustomInquiryForm($input: CustomInquiryFormInput!) {
		submitCustomInquiryForm(input: $input)
	}
`;

const submitRequestQuoteForm = gql`
	mutation submitRequestQuoteForm($input: RequestQuoteFormInput!) {
		submitRequestQuoteForm(input: $input)
	}
`;

const submitFeedbackForm = gql`
	mutation submitFeedbackForm($input: FeedbackFormInput!) {
		submitFeedbackForm(input: $input)
	}
`;

export { submitCustomInquiryForm, submitRequestQuoteForm, submitFeedbackForm };
