import React from "react";
import { Resource } from "services";
import { useState } from "react";
import { Box, Center, Heading, Text, Button } from "@chakra-ui/react";

type ResourceItemProps = {
	resource: Resource;
};

const ResourceItem: React.FC<ResourceItemProps> = ({ resource }) => {
	const [hover, setHover] = useState(false);
	return (
		<Box
			border="1px solid"
			borderColor="gray.200"
			borderRadius="md"
			p={4}
			justifyContent="space-between"
			alignItems="center"
			backgroundColor="white"
			backgroundImage={`url(${resource.path})`}
			backgroundSize="cover"
			backgroundPosition="center"
			h="100%"
			position={"relative"}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
			_before={
				resource.type.toLowerCase() === "image"
					? {
							content: '""',
							display: hover ? "block" : "none",
							position: "absolute",
							top: 0,
							right: 0,
							bottom: 0,
							left: 0,
							backgroundColor: "rgba(0, 0, 0, 0.65)",
					  }
					: {}
			}
		>
			<Center flexDirection="column" w="100%" h="100%">
				{resource.type.toLowerCase() === "image" && hover && (
					<Center flexDirection="column" zIndex={1}>
						<Heading size="sm" color="primary.300">
							{resource.title}
						</Heading>
						<Text fontSize="sm" color="white">
							{resource.type}
						</Text>
					</Center>
				)}
				{resource.type.toLowerCase() === "document" && (
					<Center flexDirection="column">
						<Heading size="sm">{resource.title}</Heading>
						<Text fontSize="sm" pb={2}>
							{resource.type}
						</Text>
						<Button
							variant="action"
							size="sm"
							onClick={() => window.open(resource.path, "_blank")}
						>
							View
						</Button>
					</Center>
				)}
				{resource.type.toLowerCase() === "video" && (
					<Center flexDirection="column" h="100%">
						<Heading size="sm">{resource.title}</Heading>
						<Text fontSize="sm" pb={2}>
							{resource.type}
						</Text>
						<iframe
							width="100%"
							title="video"
							height="65%"
							style={{ borderRadius: "10px" }}
							src={resource.path}
							allowFullScreen
						/>
					</Center>
				)}
			</Center>
		</Box>
	);
};

export default ResourceItem;
