/**
 * @file strategies.tsx
 *
 * @short Contains the DropzoneStrategy interface and its implementations.
 *
 * @description This file contains the DropzoneStrategy interface and its implementations.
 */

/**
 * Interface for DropzoneStrategy.
 * @interface
 * Defines methods and properties required for handling file uploads and deletions in a dropzone.
 *
 * @property {string[]} acceptedFileTypes - The MIME types accepted by the strategy.
 * @property {string} acceptedString - A human-readable string describing the accepted file types.
 * @property {File[]} files - An array of File objects currently managed by the strategy.
 *
 * @method validateFile(file): boolean - Determines if a given file is valid.
 * @method onFilesAdded(files): void - Handles the addition of files.
 * @method onFileDeleted(file): void - Handles the deletion of a file.
 * @method getAcceptedFileTypes(): string - Returns a string representing accepted file types.
 */
interface DropzoneStrategy {
	validateFile: (file: File) => boolean;
	onFilesAdded: (files: File[]) => void;
	onFileDeleted: (file: File) => void;
	getAcceptedFileTypes: () => string;
	acceptedString: string;
	files: File[];
	fileUrls: string[];
	onFileUrlDeleted: (url: string) => void;
	type: string;
}

/**
 * Abstract class implementing DropzoneStrategy.
 * @abstract
 * Provides common functionality and structure for specific file handling strategies.
 *
 * @constructor
 * @param {File[]} files - Initial array of File objects.
 * @param {(files: File[]) => void} updateStateCallback - Callback to update state with current files.
 *
 * @property {File[]} files - Files currently managed by the strategy.
 * @property {(files: File[]) => void} updateStateCallback - Callback to update state.
 * @property {string[]} acceptedFileTypes - MIME types accepted by this strategy.
 * @property {string} acceptedString - Description of accepted file types.
 *
 * @method validateFile(file): boolean - Abstract method for file validation.
 * @method onFilesAdded(files): void - Abstract method for handling added files.
 * @method onFileDeleted(file): void - Handles file deletion.
 * @method getAcceptedFileTypes(): string - Returns accepted file types as a string.
 */
abstract class BaseStrategy implements DropzoneStrategy {
	public files: File[] = [];
	public updateStateCallback: (files: File[]) => void;
	public fileUrls: string[];
	public updateFileUrlsCallback?: (urls: string[]) => void;
	abstract acceptedFileTypes: string[];
	abstract type: string;

	public acceptedString: string = "";

	constructor(
		files: File[],
		updateStateCallback: (files: File[]) => void,
		fileUrls?: string[],
		updateFileUrlsCallback?: (urls: string[]) => void,
	) {
		this.files = files;
		this.updateStateCallback = updateStateCallback;
		this.fileUrls = fileUrls || [];
		this.updateFileUrlsCallback = updateFileUrlsCallback;
	}

	abstract validateFile(file: File): boolean;

	onFilesAdded(files: File[]): void {
		const newFiles = this.files.concat(files);
		this.updateStateCallback(newFiles);
	}

	onFileDeleted(file: File): void {
		const newFiles = this.files.filter((f) => f.name !== file.name);
		this.updateStateCallback(newFiles);
	}

	getAcceptedFileTypes(): string {
		return this.acceptedFileTypes.join(", ");
	}

	onFileUrlDeleted(url: string): void {
		const newUrls = this.fileUrls.filter((u) => u !== url);
		this.updateFileUrlsCallback!(newUrls);
	}
}

/**
 * Strategy for handling image file uploads.
 * @class
 * Extends BaseStrategy and specifies accepted file types for images.
 *
 * @property {string[]} acceptedFileTypes - Accepted MIME types for images.
 * @property {number} maxFileSize - Maximum file size allowed for images (5 MB).
 * @property {string} acceptedString - Description of accepted image types and size limit.
 *
 * @method validateFile(file): boolean - Validates if file meets image criteria.
 */
class ImageStrategy extends BaseStrategy {
	acceptedFileTypes = ["image/jpeg", "image/jpg", "image/png", "image/webp"];
	private mb = 5;
	private maxFileSize = this.mb * 1024 * 1024; // 5 MB
	public acceptedString = `JPG, JPEG, PNG, or WEBP up to ${this.mb} MB`;
	public type = "image";

	validateFile(file: File): boolean {
		const isImage = this.acceptedFileTypes.includes(file.type);
		if (!isImage) throw new Error("Invalid file type, please upload an image");
		const isSizeValid = file.size <= this.maxFileSize;
		if (!isSizeValid)
			throw new Error(
				`File size too large. Please upload a smaller image under ${this.mb} MB`,
			);
		return true;
	}
}

/**
 * Strategy for handling video file uploads.
 * @class
 * Extends BaseStrategy and specifies accepted file types for videos.
 *
 * @property {string[]} acceptedFileTypes - Accepted MIME types for videos.
 * @property {number} maxFileSize - Maximum file size allowed for videos (100 MB).
 * @property {string} acceptedString - Description of accepted video types and size limit.
 *
 * @method validateFile(file): boolean - Validates if file meets video criteria.
 */
class VideoStrategy extends BaseStrategy {
	acceptedFileTypes = ["video/mp4", "video/webm", "video/ogg"];
	private mb = 100;
	private maxFileSize = this.mb * 1024 * 1024; // 100 MB
	public acceptedString = `MP4, WEBM, or OGG up to ${this.mb} MB`;
	public type = "video";
	validateFile(file: File): boolean {
		const isVideo = this.acceptedFileTypes.includes(file.type);
		if (!isVideo) throw new Error("Invalid file type, please upload a video");
		const isSizeValid = file.size <= this.maxFileSize;
		if (!isSizeValid)
			throw new Error(
				`Video size too large. Please upload a video under ${this.mb} MB`,
			);
		return true;
	}
}

/**
 * Strategy for handling document file uploads.
 * @class
 * Extends BaseStrategy and specifies accepted file types for documents.
 *
 * @property {string[]} acceptedFileTypes - Accepted MIME types for documents.
 * @property {number} maxFileSize - Maximum file size allowed for documents (10 MB).
 * @property {string} acceptedString - Description of accepted document types and size limit.
 *
 * @method validateFile(file): boolean - Validates if file meets document criteria.
 */
class DocumentStrategy extends BaseStrategy {
	acceptedFileTypes = ["application/pdf", "application/msword"];
	private mb = 10;
	private maxFileSize = this.mb * 1024 * 1024; // 10 MB
	public acceptedString = `PDF or DOC up to ${this.mb} MB`;
	public type = "document";
	validateFile(file: File): boolean {
		const isDocument = this.acceptedFileTypes.includes(file.type);
		if (!isDocument)
			throw new Error("Invalid file type, please upload a document");
		const isSizeValid = file.size <= this.maxFileSize;
		if (!isSizeValid)
			throw new Error(
				`Document size too large. Please upload a document under ${this.mb} MB`,
			);
		return true;
	}
}

/**
 * Strategy for handling generic file uploads.
 * @class
 * Extends BaseStrategy and allows for any file type upload.
 *
 * This strategy is designed to accept any file type, making it versatile for
 * scenarios where a wide range of file types needs to be handled, such as
 * mixed resources uploads.
 *
 * @property {string[]} acceptedFileTypes - Accepted MIME types for any files.
 * @property {number} maxFileSize - Maximum file size allowed for any files.
 * @property {string} acceptedString - Description of the lenient file type and size limit.
 *
 * @method validateFile(file): boolean - Validates if the file meets general criteria.
 */
class GenericStrategy extends BaseStrategy {
	acceptedFileTypes = [
		"application/pdf",
		"application/msword",
		"video/mp4",
		"video/webm",
		"video/ogg",
		"image/jpeg",
		"image/jpg",
		"image/png",
		"image/webp",
	];
	private mb = 50;
	private maxFileSize = this.mb * 1024 * 1024; // 50 MB, or set as appropriate
	public acceptedString = `PDF, DOC, MP4, WEBM, OGG, JPG, JPEG, PNG, or WEBP up to ${this.mb} MB`;
	public type = "generic";

	validateFile(file: File): boolean {
		const isValidFile = this.acceptedFileTypes.includes(file.type);
		if (!isValidFile) {
			throw new Error("Invalid file type. Please upload a valid file");
		}
		const isSizeValid = file.size <= this.maxFileSize;
		if (!isSizeValid) {
			throw new Error(
				`File size too large. Please upload a file under ${this.mb} MB`,
			);
		}
		return true;
	}
}

export {
	DropzoneStrategy,
	ImageStrategy,
	VideoStrategy,
	DocumentStrategy,
	GenericStrategy,
};
