import { useQuery } from "@apollo/client";
import { getPositions as GET_POSITIONS } from "./queries";
import { RetrievalInput, Position, PaginatedResult } from "../../types";

interface GetPositionsData {
	getPositions: PaginatedResult<Position>;
}

const useGetPositions = (retrievalInput?: RetrievalInput) => {
	const { loading, error, data, refetch, networkStatus } =
		useQuery<GetPositionsData>(GET_POSITIONS, {
			variables: { retrievalInput },
		});
	const processedData = data?.getPositions;
	return { loading, error, data: processedData, refetch, networkStatus };
};
export default useGetPositions;
