import React, { useContext } from "react";
import { PageChangeContext } from "contexts";
import { Flex, Heading, Box, Image, Text, Button } from "@chakra-ui/react";

// Import a generic error image
import errorImage from "assets/site-error/SiteErrorImage.webp";

const ErrorFallback: React.FC = () => {
	const { handlePageChange } = useContext(PageChangeContext);

	return (
		<Flex
			w="100%"
			h="100%"
			justifyContent="center"
			alignItems="center"
			flexDirection="column"
			marginY="8"
			p={8}
			gap="4"
		>
			<Heading
				as="h1"
				variant="primary"
				size="2xl"
				fontStyle="italic"
				textShadow="1px 1px #000000AA"
			>
				Error
			</Heading>
			<Box w="100%" maxW="90vw" maxH="50vh" borderRadius="lg">
				<Image
					src={errorImage}
					alt="Unexpected Error Occurred"
					mx="auto"
					borderRadius="lg"
					w="100%"
					h="100%"
				/>
			</Box>
			<Text fontSize="2xl">
				Oops! Something went wrong. We're working to fix the issue.
			</Text>
			<Button
				size="lg"
				marginTop="4"
				variant="primary"
				onClick={() => handlePageChange("/home")} // Modify as needed, possibly to refresh
				w="20%"
			>
				Try Again
			</Button>
		</Flex>
	);
};

export default ErrorFallback;
