/**
 * @module useGetPromotions.ts
 * @description This file contains the logic for fetching a list of promotions from the database.
 */
import { useQuery } from "@apollo/client";
import { getPromotions as GET_PROMOTIONS } from "./queries";
import { Promotion, PaginatedResult, RetrievalInput } from "../../types";

interface GetPromotionsData {
	getPromotions: PaginatedResult<Promotion>;
}

/**
 * This is the hook that is used to fetch a list of promotions from the database.
 * @returns {Object} The getPromotions function, loading state, and error state.
 */
const useGetPromotions = (retrievalInput?: RetrievalInput) => {
	const variables = retrievalInput ? { retrievalInput } : {};
	const { data, loading, error, refetch, networkStatus } =
		useQuery<GetPromotionsData>(GET_PROMOTIONS, { variables });

	return { data: data?.getPromotions, loading, error, refetch, networkStatus };
};

export default useGetPromotions;
