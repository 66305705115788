import React from "react";
import {
	Alert,
	AlertIcon,
	AlertTitle,
	AlertDescription,
	AlertProps,
	AlertTitleProps,
	AlertDescriptionProps,
} from "@chakra-ui/react";

type ErrorMessageProps = {
	title?: string;
	description?: string;
	alertProps?: AlertProps;
	alertTitleProps?: AlertTitleProps;
	alertDescriptionProps?: AlertDescriptionProps;
	children?: React.ReactNode;
};

const ErrorMessage: React.FC<ErrorMessageProps> = ({
	title,
	description,
	alertProps,
	alertTitleProps,
	alertDescriptionProps,
	children,
}) => {
	return (
		<Alert
			status="error"
			variant="subtle"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			textAlign="center"
			height="200px"
			{...alertProps}
		>
			<AlertIcon boxSize="40px" mr={0} />
			<AlertTitle mt={4} mb={1} fontSize="lg" {...alertTitleProps}>
				{title ? title : "An unexpected error has occurred."}
			</AlertTitle>
			<AlertDescription maxWidth="sm" {...alertDescriptionProps}>
				{description
					? description
					: children
					? children
					: "Please try again later."}
			</AlertDescription>
		</Alert>
	);
};

export default ErrorMessage;
