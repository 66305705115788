import { useContext } from "react";
import { EmployeeContext } from "contexts";
import { AdminSidebar } from "page-components";
import { DashboardHeader } from "components/features/headers";
import { DashboardLayout } from "components/layouts";
import { RoutesWrapper } from "wrappers";
import { Route, Navigate } from "react-router-dom";
import {
	AdminDashboard,
	AdminResources,
	AdminEmployees,
	AdminReviews,
	AdminFAQ,
	AdminCatalogs,
	AdminPartners,
	AdminGallery,
	AdminPositions,
	AdminCustomWorkGallery,
	AdminProductCollections,
	AdminPositionApplications,
	AdminDealerApplications,
	AdminPromotions,
} from "pages/admin";
import { withProtectedRoute } from "hocs";

/**
 * AuthenticatedAdminRouter component.
 *
 * This component is responsible for rendering the authenticated admin routes.
 * It uses the DashboardLayout component to provide a consistent layout for the admin pages.
 * The AdminSidebar component is used as the sidebar for the admin pages.
 * The DashboardHeader component is used as the header for the admin pages.
 * The ProtectedAdminDashboard, ProtectedAdminResources, ProtectedAdminEmployees, and ProtectedAdminFAQ components are used as the protected routes for the admin pages.
 *
 * @returns The rendered AuthenticatedAdminRouter component.
 */
const AuthenticatedAdminRouter: React.FC = () => {
	const AdminRoutes = () => {
		const { pageLabel } = useContext(EmployeeContext);

		return (
			<DashboardLayout
				sidebar={<AdminSidebar />}
				header={<DashboardHeader dashboardHeading={pageLabel} />}
			>
				<RoutesWrapper
					fallbackComponent={<Navigate to="/admin/dashboard" replace />}
				>
					<Route path="/dashboard/*" element={<AdminDashboard />} />
					<Route
						path="/dealer-applications/*"
						element={<AdminDealerApplications />}
					/>
					<Route path="/career" element={<AdminPositionApplications />} />
					<Route path="/resources/*" element={<AdminResources />} />
					<Route path="/employees/*" element={<AdminEmployees />} />
					<Route path="/custom/*" element={<AdminCustomWorkGallery />} />
					<Route path="/partners/*" element={<AdminPartners />} />
					<Route path="/jobs/*" element={<AdminPositions />} />
					<Route path="/catalogs/*" element={<AdminCatalogs />} />
					<Route path="/trending/*" element={<AdminProductCollections />} />
					<Route path="/reviews/*" element={<AdminReviews />} />
					<Route path="/faq/*" element={<AdminFAQ />} />
					<Route path="/gallery/*" element={<AdminGallery />} />
					<Route path="/promotions/*" element={<AdminPromotions />} />
				</RoutesWrapper>
			</DashboardLayout>
		);
	};

	const ProtectedAdminRoutes = withProtectedRoute(AdminRoutes, "admin");

	return <ProtectedAdminRoutes />;
};

export default AuthenticatedAdminRouter;
