import React from "react";
import DeleteConfirmationWrapper from "wrappers/DeleteConfirmationWrapper";
import { PositionApplication } from "services/types";
import { useDeletePositionApplicationForm } from "hooks/form-hooks";

interface DeletePositionApplicationModalProps {
	positionApplication: PositionApplication;
	isOpen: boolean;
	onClose: () => void;
	refetch: () => Promise<any>;
}

const DeletePositionApplicationModal: React.FC<
	DeletePositionApplicationModalProps
> = ({ positionApplication, isOpen, onClose, refetch }) => {
	const { loading, handleDelete } = useDeletePositionApplicationForm(
		positionApplication,
		refetch,
	);

	const onConfirmDelete = async () => {
		await handleDelete();
		onClose();
	};

	return (
		<DeleteConfirmationWrapper
			isOpen={isOpen}
			onClose={onClose}
			header={`Confirm Deletion: `}
			onDeleteConfirm={onConfirmDelete}
			content={positionApplication?.applicationDetails.firstName}
			loading={loading}
		>
			{`Are you sure you want to delete this position application? This action cannot be undone.`}
		</DeleteConfirmationWrapper>
	);
};

export default DeletePositionApplicationModal;
