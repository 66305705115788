import { useMutation } from "@apollo/client";
import { createPartner as CREATE_PARTNER } from "./mutations";
import { PartnerInput } from "../../types";

interface CreatePartnerData {
	createPartner: boolean;
}

const useCreatePartner = () => {
	const [createPartnerMutation, { loading, error }] =
		useMutation<CreatePartnerData>(CREATE_PARTNER);
	const createPartner = async (partnerInput: PartnerInput) => {
		try {
			const { data } = await createPartnerMutation({
				variables: {
					partnerInput,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.createPartner };
		} catch (error: any) {
			return { error };
		}
	};
	return { createPartner, loading, error };
};
export default useCreatePartner;
