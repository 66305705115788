import { useQuery } from "@apollo/client";
import { getAssignedContent as GET_ASSIGNED_CONTENT } from "./queries";
import { RetrievalInput, Resource, PaginatedResult } from "../../types";

interface GetAssignedContentParams {
	retrievalInput?: RetrievalInput;
}

interface GetAssignedContentData {
	getAssignedContent: PaginatedResult<Resource>;
}

const useGetAssignedContent = (input?: GetAssignedContentParams) => {
	const { loading, error, data, refetch, networkStatus } =
		useQuery<GetAssignedContentData>(GET_ASSIGNED_CONTENT, {
			variables: input ? input : {},
		});
	const processedData = data?.getAssignedContent;
	return { loading, error, data: processedData, refetch, networkStatus };
};
export default useGetAssignedContent;
