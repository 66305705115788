/**
 * @module LargeHero
 * @description A component that displays a large hero section with a title.
 */
import {
	Flex,
	Center,
	Heading,
	BoxProps,
	Box,
	Image,
	useDisclosure,
	useBreakpointValue,
} from "@chakra-ui/react";
import { ContactInfoModal } from "../modals";
import { OverlayBox } from "../../common/boxes";

/**
 * @typedef HeroProps
 * @property {string} title - The title of the hero section.
 * @property {string} backgroundImage - The background image of the hero section.
 * @property {string} [imagePosition="center"] - The position of the background image.
 * @property {object} [titleSize={ base: "4xl", md: "7xl" }] - The size of the title.
 * @property {object} [titleWeight={ base: "semibold", md: "bold" }] - The weight of the title.
 * @property {BoxProps} [boxProps] - Additional props to be spread on the Box component.
 */
interface HeroProps extends BoxProps {
	title: string;
	backgroundImage: string;
	imageSize?: {
		base: string;
		md: string;
	};
	imagePosition?: {
		base: string;
		md: string;
	};
	titleSize?: {
		base: string;
		md: string;
	};
	titleWeight?: {
		base: string;
		md: string;
	};
	contactPhone: string;
	contactEmail: string;
	// You can add additional props such as subtitle, button texts, etc.
	heroImage?: string;
}

/**
 * A large hero section with a title.
 * @param {HeroProps} props - The props of the component.
 * @returns {React.ReactElement} A large hero section with a title.
 */
export const Hero: React.FC<HeroProps> = ({
	title,
	backgroundImage,
	imagePosition = "center",
	imageSize = "cover",
	titleSize = { base: "2.1rem", md: "7xl" },
	titleWeight = { base: "semibold", md: "bold" },
	contactPhone,
	contactEmail,
	heroImage,
	...boxProps
}) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const isDesktop = useBreakpointValue(
		{ base: false, xl: true },
		{ ssr: false },
	);
	return (
		<Flex
			as="section"
			py={{ base: "8", md: "14" }}
			position="relative"
			h={{ base: "280px", md: "490px" }}
			bgImage={[
				`linear-gradient(to top, rgba(0, 0, 0, 0.096), rgba(0, 0, 0, 0))`,
				`url(${backgroundImage})`,
			].join(",")}
			bgSize={imageSize}
			bgPosition={imagePosition}
			w={{ base: "auto", md: "100%" }}
			minW={{ base: "auto", md: "100%" }}
			maxW={{ base: "auto", md: "100%" }}
			zIndex={"base"}
			{...boxProps}
		>
			<OverlayBox color="blackAlpha.200" />
			<Center
				maxW={{ base: "100%", md: "container.2xl" }}
				mx="auto"
				px={{ base: "6", md: "8" }}
				h="full"
				zIndex={10}
				position="relative"
				color="white"
			>
				<Box p={{ base: "4", md: "6" }}>
					{heroImage && isDesktop && (
						<Image
							src={heroImage}
							alt={title}
							w={{ base: "100%", md: "500px" }}
							h={{ base: "auto", md: "auto" }}
							objectFit="contain"
							mt={{ base: "4", md: "8" }}
							mb={{ base: "4", md: "6" }}
							mx="auto"
						/>
					)}
					<Heading
						as="h1"
						variant="hero"
						fontSize={titleSize}
						fontWeight={titleWeight}
						color="accent.500"
						textAlign="center"
						mb={{ base: "4", md: "6" }}
					>
						{title}
					</Heading>
					<ContactInfoModal
						phone={contactPhone}
						email={contactEmail}
						contactButtonText="Contact Us Today!"
						isOpen={isOpen}
						onOpen={onOpen}
						onClose={onClose}
						showButton={true}
					/>
				</Box>
			</Center>
		</Flex>
	);
};

export default Hero;
