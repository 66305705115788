import React, { useState } from "react";
import { Flex } from "@chakra-ui/react";

interface ItemProps {
	setTrackIsActive: (isActive: boolean) => void;
	setActiveItem: (next: number) => void;
	activeItem: number;
	constraint: number;
	itemWidth: number;
	positions: number[];
	children: React.ReactNode;
	index: number;
	gap: number;
}

const Item: React.FC<ItemProps> = ({
	setTrackIsActive,
	setActiveItem,
	activeItem,
	constraint,
	itemWidth,
	positions,
	children,
	index,
	gap,
}) => {
	const [userDidTab, setUserDidTab] = useState(false);

	const displayedImagesRangeStart = activeItem;
	const displayedImagesRangeEnd = activeItem + constraint;
	//Make the main items the ones in the middle
	const isMainItem =
		index >= displayedImagesRangeStart && index <= displayedImagesRangeEnd - 1;
	//const isMainItem = index >= (activeItem+(constraint/2)) && index < (activeItem+(constraint*2));

	const handleFocus = () => setTrackIsActive(true);

	const handleBlur = () => {
		if (userDidTab && index + 1 === positions.length) {
			setTrackIsActive(false);
		}
		setUserDidTab(false);
	};

	const handleKeyUp = (event: React.KeyboardEvent) => {
		if (
			event.key === "Tab" &&
			!(activeItem === positions.length - constraint)
		) {
			setActiveItem(index);
		}
	};

	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === "Tab") {
			setUserDidTab(true);
		}
	};

	return (
		<Flex
			onFocus={handleFocus}
			onBlur={handleBlur}
			onKeyUp={handleKeyUp}
			onKeyDown={handleKeyDown}
			w={`${itemWidth}px`}
			transform={isMainItem ? "scale(1)" : "scale(0.75)"} // Scale down if not main item
			//Add blur filter if not main item
			filter={isMainItem ? "none" : "blur(2px)"}
			transition="transform 0.3s ease-in-out"
			_notLast={{
				mr: `${gap}px`,
			}}
			py="4px"
			zIndex={isMainItem ? 2 : 1} // Ensure main items are on top
		>
			{children}
		</Flex>
	);
};

export default Item;
