import { useMutation } from "@apollo/client";
import { deletePositionApplication as DELETE_POSITION_APPLICATION } from "./mutations";

interface DeletePositionApplicationData {
	deletePositionApplication: boolean;
}

const useDeletePositionApplication = () => {
	const [deletePositionApplicationMutation, { loading, error }] =
		useMutation<DeletePositionApplicationData>(DELETE_POSITION_APPLICATION);
	const deletePositionApplication = async (positionApplicationId: string) => {
		try {
			const { data } = await deletePositionApplicationMutation({
				variables: {
					positionApplicationId,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.deletePositionApplication };
		} catch (error: any) {
			return { error };
		}
	};
	return { deletePositionApplication, loading, error };
};

export default useDeletePositionApplication;
