import { useMutation } from "@apollo/client";
import { createEmployee as CREATE_EMPLOYEE } from "./mutations";
import { EmployeeInput } from "../../types";

interface CreateEmployeeData {
	createEmployee: boolean;
}

const useCreateEmployee = () => {
	const [createEmployeeMutation, { loading, error }] =
		useMutation<CreateEmployeeData>(CREATE_EMPLOYEE);
	const createEmployee = async (employeeInput: EmployeeInput) => {
		try {
			const { data } = await createEmployeeMutation({
				variables: {
					employeeInput,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.createEmployee };
		} catch (error: any) {
			return { error };
		}
	};
	return { createEmployee, loading, error };
};
export default useCreateEmployee;
