import React from "react";
import { Heading, Text, Flex } from "@chakra-ui/react";
import { StackLayout } from "components";

interface HistorySectionProps {
	title: string;
	subtitle: string;
	content: string;
}

const HistorySection: React.FC<HistorySectionProps> = ({
	title,
	subtitle,
	content,
}) => {
	return (
		<StackLayout
			spacing={2}
			align="center"
			justify="center"
			px={{ base: "4", md: "48" }}
			py={{ base: "auto", md: "8" }}
		>
			<Heading variant="title" alignSelf="center">
				{title}
			</Heading>
			<Heading variant="subtitle" alignSelf="center" mb={4}>
				{subtitle}
			</Heading>
			<Flex w={{ base: "100%", md: "70%" }}>
				<Text variant="sectionParagraph" align="center" as="p">
					{content}
				</Text>
			</Flex>
		</StackLayout>
	);
};

export default HistorySection;
