/**
 * @module Employees
 * @description A page for managing employees from the admin dashboard
 */
import React, { useState } from "react";
import { ActionsRow } from "components/features/tables/cells";
import { useRetrieval } from "hooks";
import { SearchTable } from "components/features/tables";
import { createColumnHelper } from "@tanstack/react-table";
import { Employee, useGetEmployees, FilterSettingsInput } from "services"; //FilterSettingsInput, useGetEmployees
import {
	CreateEmployeeForm,
	UpdateEmployeeForm,
	AssignResourcesModal,
	DeleteEmployeeModal,
} from "page-components/admin/employees";
import { FaRegFileAlt } from "react-icons/fa";
import { PiPassword } from "react-icons/pi";
import { AdminResponsiveLayout } from "components";
import { ChangePasswordModal } from "page-components";

const AdminEmployees: React.FC = () => {
	// Destructure the paginationInput, setPaginationInput, filterSettings, and handleFiltersChange from the useRetrieval hook
	const {
		paginationInput,
		setPaginationInput,
		filterSettings,
		handleFiltersChange,
		sortInput,
		handleSortChange,
	} = useRetrieval();

	const handleBuildFilterSettings = (
		query: string,
		conditional: "AND" | "OR" = "OR",
	) => {
		const filterSettings: FilterSettingsInput = {
			conditional,
			filters: [
				{
					field: "firstName",
					operator: "regex",
					value: query,
				},
				{
					field: "lastName",
					operator: "regex",
					value: query,
				},
				{
					field: "email",
					operator: "regex",
					value: query,
				},
				{
					field: "role",
					operator: "regex",
					value: query,
				},
				{
					field: "phone",
					operator: "regex",
					value: query,
				},
				{
					field: "accessLevel",
					operator: "regex",
					value: query,
				},
			],
		};
		return filterSettings;
	};

	const {
		loading,
		error,
		data: employees,
		refetch,
	} = useGetEmployees({
		filterSettings: handleBuildFilterSettings(
			filterSettings.query,
			filterSettings.conditional,
		),
		pagination: paginationInput,
		sort: sortInput,
	});

	const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(
		null,
	);

	// Managing state for the update modal open state
	const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
	const [isAssignResourcesModalOpen, setAssignResourcesModalOpen] =
		useState(false);
	const [isChangePasswordModalOpen, setChangePasswordModalOpen] =
		useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

	// Function to close the update modal
	const closeUpdateModal = () => {
		setUpdateModalOpen(false);
		setSelectedEmployee(null);
	};

	// Function to close the assign resources modal
	const closeAssignResourcesModal = () => {
		setAssignResourcesModalOpen(false);
		setSelectedEmployee(null);
	};

	const closeChangePasswordModal = () => {
		setChangePasswordModalOpen(false);
		setSelectedEmployee(null);
	};

	// Function to close the delete modal
	const closeDeleteModal = () => {
		setIsDeleteModalOpen(false);
		setSelectedEmployee(null); // Reset the resource to delete
	};

	const onRowEdit = (employee: Employee) => {
		setSelectedEmployee(employee);
		setUpdateModalOpen(true);
	};

	const onRowAssignResources = (employee: Employee) => {
		setSelectedEmployee(employee);
		setAssignResourcesModalOpen(true);
	};

	const onRowChangePassword = (employee: Employee) => {
		setSelectedEmployee(employee);
		setChangePasswordModalOpen(true);
	};

	const onRowDelete = (employee: Employee) => {
		setSelectedEmployee(employee);
		setIsDeleteModalOpen(true); // Open the modal
	};

	// Column helper for the Employee table
	const columnHelper = createColumnHelper<Employee>();
	// Column definition for SearchTable
	const columns = [
		columnHelper.accessor("firstName", {
			header: () => "First Name",
			cell: (info) => info.getValue(),
		}),
		columnHelper.accessor("lastName", {
			header: () => "Last Name",
			cell: (info) => info.getValue(),
		}),
		columnHelper.accessor("email", {
			header: () => "Email",
			cell: (info) => info.getValue(),
		}),
		columnHelper.accessor("role", {
			header: () => "Role",
			cell: (info) => info.getValue(),
		}),
		columnHelper.accessor("phone", {
			header: () => "Phone",
			cell: (info) => info.getValue(),
		}),

		columnHelper.accessor("accessLevel", {
			header: () => "Access Level",
			cell: (info) =>
				Array.isArray(info.getValue())
					? info.getValue().join(", ")
					: info.getValue(),
		}),
		columnHelper.display({
			header: () => "Actions",
			id: "actions",
			cell: (props) => (
				<ActionsRow
					onEdit={() => onRowEdit(props.row.original)}
					onDelete={() => onRowDelete(props.row.original)}
					actions={[
						{
							name: "Assign Resources",
							icon: <FaRegFileAlt />,
							onClick: () => onRowAssignResources(props.row.original),
						},
						{
							name: "Change Password",
							icon: <PiPassword />,
							onClick: () => onRowChangePassword(props.row.original),
						},
					]}
				/>
			),
		}),
	];

	return (
		<AdminResponsiveLayout>
			<SearchTable
				columnsData={columns}
				tableData={employees?.items || []}
				paginationInput={paginationInput}
				setPaginationInput={setPaginationInput}
				filterSettings={filterSettings}
				handleBuildFilters={handleFiltersChange}
				sortInput={sortInput}
				handleSortChange={handleSortChange}
				loading={loading}
				error={error}
				totalCount={employees?.totalCount || 0}
				hasNextPage={employees?.hasNextPage || false}
				hasPreviousPage={employees?.hasPreviousPage || false}
			/>
			{selectedEmployee && (
				<UpdateEmployeeForm
					isOpen={isUpdateModalOpen}
					onClose={closeUpdateModal}
					refetch={refetch}
					existingEmployee={selectedEmployee}
				/>
			)}
			{selectedEmployee && (
				<AssignResourcesModal
					employee={selectedEmployee}
					isOpen={isAssignResourcesModalOpen}
					onClose={closeAssignResourcesModal}
					refetch={refetch}
				/>
			)}
			{selectedEmployee && (
				<ChangePasswordModal
					heading={`Change ${selectedEmployee.firstName} ${selectedEmployee.lastName}'s Password?`}
					isOpen={isChangePasswordModalOpen}
					onClose={closeChangePasswordModal}
					email={selectedEmployee.email}
					accountType="employee"
					refetch={refetch}
				/>
			)}
			{selectedEmployee && (
				<DeleteEmployeeModal
					employee={selectedEmployee}
					isOpen={isDeleteModalOpen}
					onClose={closeDeleteModal}
					refetch={refetch}
				/>
			)}
			<CreateEmployeeForm refetch={refetch} />
		</AdminResponsiveLayout>
	);
};

export default AdminEmployees;
