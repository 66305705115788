import React, { useState } from "react";
import {
	Flex,
	Button,
	Collapse,
	Skeleton,
	Heading,
	Stack,
	Text,
	Box,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { useGetPromotion } from "services";
import PromotionDetails from "./PromotionDetails";

const PromotionCollapse: React.FC = () => {
	const [showPromotion, setShowPromotion] = useState(false);
	const { loading, error, data: promotion } = useGetPromotion();

	if (loading) return <Skeleton height="20px" />;
	if (error || !promotion) return null;

	return (
		<Flex
			w="100%"
			h="100%"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			bg="transparent"
		>
			<Flex
				flexDirection={{ base: "column", xl: "row" }}
				justifyContent={{ base: "space-evenly", md: "space-between" }}
				alignItems="center"
				w="100%"
				h="100%"
			>
				{promotion.images.length > 0 && (
					<Stack
						direction={{ base: "column", md: "row" }}
						gap={"4"}
						py={{ base: "0", md: "10" }}
						px={{ base: "0", xl: "5" }}
						alignItems="center"
						justifyContent={"space-evenly"}
						w={{
							base: "100%",
							xl: "35%",
						}}
						maxWidth={"8xl"}
						flexGrow={1}
						//Wrap
						flexWrap="wrap"
					>
						{promotion.images.map((image, index) => (
							<Box
								key={`promotion-image-${index}`}
								p={2}
								boxShadow="md"
								borderRadius="lg"
								bg="white"
								position="relative"
								width={"40%"}
								minWidth="270px"
								height={{ base: "175px", md: "200px" }}
								backgroundImage={image}
								backgroundSize={"cover"}
								backgroundPosition={"center"}
								backgroundRepeat={"no-repeat"}
							/>
						))}
					</Stack>
				)}
				<Stack
					direction="column"
					alignItems="center"
					justifyContent="center"
					w={{
						base: "100%",
						xl: "65%",
					}}
					maxW="4xl"
					minW="auto"
					p={{ base: "4", md: "6" }}
					pr={{
						md: 0,
					}}
					flexShrink={1}
				>
					<Heading
						variant="cta"
						fontSize={{ base: "3xl", lg: "5xl" }}
						mt={{ base: "2", md: "0" }}
						color="white"
						textAlign="center"
					>
						{promotion.title}
					</Heading>
					{promotion.subtitle && (
						<Text
							variant="sectionTitle"
							color="white"
							textAlign="center"
							mt={{
								base: "4",
								md: "2",
							}}
							p={{ base: 2 }}
							fontSize={{ base: "2xl", md: "xl" }}
							textShadow="1px 1px 2px rgba(0, 0, 0, 0.45)"
						>
							{promotion.subtitle}
						</Text>
					)}
					{promotion.callToAction && (
						<Text
							variant="sectionTitle"
							color="white"
							textAlign="center"
							mt={{
								base: "4",
								md: "2",
							}}
							p={{ base: 2 }}
							fontSize={{ base: "2xl", md: "xl" }}
							textShadow="1px 1px 2px rgba(0, 0, 0, 0.45)"
						>
							{promotion.callToAction}
						</Text>
					)}
				</Stack>
			</Flex>
			{promotion.products.length > 0 && (
				<>
					<Button
						onClick={() => setShowPromotion(!showPromotion)}
						rightIcon={showPromotion ? <ChevronUpIcon /> : <ChevronDownIcon />}
						variant="primary"
						colorScheme="accent"
						size="sm"
						w="50%"
						mb={4}
					>
						{showPromotion ? "Hide Products" : "Show Products"}
					</Button>
					<Collapse in={showPromotion} animateOpacity style={{ width: "100%" }}>
						<PromotionDetails products={promotion.products} />
					</Collapse>
				</>
			)}
		</Flex>
	);
};

export default PromotionCollapse;
