import { ComponentStyleConfig } from "@chakra-ui/react";
import { colors } from "styles";

const sizes = {
	xl: {
		fontSize: "xl",
		h: "14",
		minW: "14",
		px: "8",
	},
	wide: {
		fontSize: "lg",
		h: "12",
		minW: "64",
		px: "8",
	},
};

const buttonStyles: ComponentStyleConfig = {
	baseStyle: {
		//make bg linear gradient that transitions on hover, values should be 500 - 600 on hover 600 - 700
		bg: "linear-gradient(180deg, #bf9139 0%, #a0702e 100%)",
		transition: "all 0.2s ease-in-out",
		color: "white",
		boxShadow: "md",
		fontWeight: "normal",
		_focus: {
			boxShadow: "none",
		},
		_hover: {
			transform: "scale(1.025) translateY(-2px)",
		},
	},
	variants: {
		outline: {
			bg: "white",
			borderColor: colors.primary[500],
			borderWidth: "1px",
			color: colors.primary[500],
			transition: "all 0.2s ease-in-out",
			_hover: {
				color: colors.primary[400],
				borderColor: colors.primary[400],
				borderWidth: "1px",
				transform: "scale(1.025) translateY(-2px)",
			},
		},
		ghost: {
			bg: "transparent",
			color: colors.primary[500],
			boxShadow: "none",
			_hover: {
				bg: "transparent",
				color: colors.primary[600],
			},
		},
		ghost_white: {
			bg: "transparent",
			color: colors.white,
			boxShadow: "none",
			_hover: {
				bg: "transparent",
				color: colors.primary[200],
			},
		},
		black: {
			bg: "transparent",
			color: "black",
			boxShadow: "none",
			_hover: {
				bg: "transparent",
			},
		},
		withIcon: {
			rightIcon: {
				justifyContent: "space-between",
				"& > svg": {
					ml: 2,
				},
			},
			leftIcon: {
				justifyContent: "space-between",
				"& > svg": {
					mr: 2,
				},
			},
			belowIcon: {
				flexDirection: "column",
				alignItems: "center",
				"& > svg": {
					mb: 2,
				},
			},
		},
		icon: {
			color: "black",
			bg: colors.white,
			boxShadow: "md",
			borderWidth: "1px",
			borderColor: colors.graydient[200],
			_hover: {
				boxShadow: "xl",
				borderColor: colors.graydient[300],
				color: colors.primary[500],
				bg: colors.white,
				transform: "none",
			},
		},
	},
	sizes: sizes,
};

export default buttonStyles;
