import React from "react";
import { RoutesWrapper } from "wrappers";
import { Route } from "react-router-dom";
import {
	DealerApplication,
	RequestQuote,
	Commercial,
	Residential,
	Feedback,
} from "pages";

const ServicesRouter: React.FC = () => {
	return (
		<RoutesWrapper>
			<Route path="/dealer-application" element={<DealerApplication />} />
			<Route path="/commercial" element={<Commercial />} />
			<Route path="/residential" element={<Residential />} />
			<Route path="/quote" element={<RequestQuote />} />
			<Route path="/feedback" element={<Feedback />} />
		</RoutesWrapper>
	);
};

export default ServicesRouter;
