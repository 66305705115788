import React from "react";
import { RoutesWrapper } from "wrappers";
import { Route } from "react-router-dom";
import { EmployeeProvider } from "contexts";
import { AdminLogin } from "pages";
import AuthenticatedAdminRouter from "./AuthenticatedAdminRouter";
// eslint-disable-next-line @typescript-eslint/no-unused-vars

/**
 * Renders the admin router component.
 *
 * @returns {React.ReactElement} The rendered admin router component.
 */
const AdminRouter: React.FC = () => {
	// EX Usage: const ProtectedOurPartners = withProtectedRoute(OurPartners, "admin");
	// <Route path="/dashboard/*" element={<ProtectedOurPartners />} />

	return (
		<EmployeeProvider>
			<RoutesWrapper>
				<Route path="/login/*" element={<AdminLogin />} />
				<Route path="/*" element={<AuthenticatedAdminRouter />} />
			</RoutesWrapper>
		</EmployeeProvider>
	);
};

export default AdminRouter;
