import React from "react";
import { Flex, Box, Icon, SimpleGrid, Text } from "@chakra-ui/react";
import FooterFence from "../../assets/footer/FooterFence.png";
import SocialIcons from "../../components/features/footer/SocialIcons";
import { FaFacebook, FaInstagram, FaArrowUp } from "react-icons/fa";
import Logo from "../../components/common/Logo";
import LinkColumn from "../../components/features/footer/LinkColumn";
import { PageChangeContext } from "contexts";

const Footer: React.FC = () => {
	const quickLinks = {
		title: "Quick Links",
		items: [
			{ label: "Products", href: "/store" },
			{ label: "Search Store", href: "/products/security#!/~/search" },
			{ label: "Shopping Cart", href: "/products/Custom#!/~/cart" },
		],
	};

	const aboutStack = {
		title: "About",
		items: [
			{ label: "History", href: "/about/history" },
			{ label: "Locations", href: "/about/locations" },
			{ label: "Careers", href: "/about/careers" },
		],
	};

	const resourcesStack = {
		title: "Resources",
		items: [
			{ label: "Help Center", href: "/resources/help-center" },
			{ label: "Catalogs", href: "/resources/catalogs" },
			{ label: "Partners", href: "/resources/our-partners" },
		],
	};

	const servicesStack = {
		title: "Services",
		items: [
			{ label: "Dealer Application", href: "/services/dealer-application" },
			{ label: "Collections", href: "/resources/collections" },
			{ label: "Gallery", href: "/resources/gallery" },
		],
	};

	const socialIconLinks = [
		{
			element: <FaFacebook />,
			url: "https://www.facebook.com/profile.php?id=100041371454815",
		},
		{
			element: <FaInstagram />,
			url: "https://www.instagram.com/waysidefenceco/",
		},
	];

	const linkStacks = [aboutStack, resourcesStack, servicesStack, quickLinks];

	const { handlePageChange } = React.useContext(PageChangeContext);

	return (
		<Flex
			position={"relative"}
			direction="column"
			justify="center"
			align="center"
			pt={"10px"}
			pb={"40px"}
			bgImage={`url(${FooterFence})`}
			bgPosition="bottom"
			bgRepeat="repeat-x"
			bgSize="fit-content"
			flex={1}
			bgColor="accent.500"
			minH={"30vh"}
		>
			<Box
				color={"primary.500"}
				cursor={"pointer"}
				mb={{ base: 1, xl: 0 }}
				mt={{ base: 0, xl: 2 }}
				onClick={() => {
					window.scrollTo({ top: 0, behavior: "smooth" });
				}}
				transition="all 0.2s ease-in-out"
				_hover={{
					color: "primary.600",
					transform: "translateY(-3px)",
				}}
				alignSelf={{
					base: "center",
					md: "flex-start",
				}}
				px={4}
				fontWeight="bold"
			>
				Back To Top
				<Icon as={FaArrowUp} ml={2} />
			</Box>

			<Flex
				direction={{
					base: "column",
					xl: "row",
				}}
				justify="center"
				align="center"
				w="100%"
			>
				<Flex
					direction="column"
					justify="center"
					align="center"
					h="100%"
					w="auto"
					p={{ base: 0, sm: 4 }}
					pt={{ base: 4, sm: 4, xl: 0 }}
					pb={{ base: 8, sm: 0 }}
					ml={{ base: 0, xl: "auto" }}
					_hover={{ cursor: "pointer" }}
					onClick={() => {
						handlePageChange("/home");
					}}
				>
					<Logo width={"350px"} position="relative" />
				</Flex>

				<SimpleGrid
					columns={{ base: 2, lg: 4 }}
					spacing={{ base: 4, md: 6 }}
					w="100%"
					pb={{ base: "10", md: "0" }}
					py={{ base: 4, sm: 6, lg: 0 }}
					px={{ base: 4, sm: 8 }}
					flex={1}
					mr={{ base: 0, xl: "auto" }}
					color="white"
				>
					{linkStacks.map((linkStack, index) => (
						<LinkColumn
							key={index}
							title={linkStack.title}
							links={linkStack.items}
						/>
					))}
				</SimpleGrid>
			</Flex>

			<Flex
				direction={{ base: "column", lg: "row" }}
				justify="space-between"
				align="center"
				w="100%"
				px={{ base: 0, sm: 10 }}
				pt={{ base: 8, sm: 1 }}
				pb={{ base: 10, sm: 8 }}
			>
				<Text
					fontSize={{ base: "sm", md: "md" }}
					color={"primary.500"}
					textAlign={{ base: "center", lg: "left" }}
					mb={{ base: 2, lg: 0 }}
				>
					<strong>© Copyright 2023 Wayside Fence Company</strong>
				</Text>

				<Text
					fontSize={{ base: "sm", md: "md" }}
					color={"primary.500"}
					textAlign={{ base: "center", lg: "right" }}
				>
					<strong>Made with 🧡 by Webify Services</strong>
				</Text>
			</Flex>
			<Flex position={"absolute"} bottom="0" mb={0}>
				<SocialIcons icons={socialIconLinks} />
			</Flex>
		</Flex>
	);
};

export default Footer;
