import { UseToastOptions } from "@chakra-ui/react";
import { IconType } from "react-icons";
import { FiBookOpen, FiBox, FiTruck, FiUsers } from "react-icons/fi";

interface FAQCategory {
	name: string;
	icon: IconType;
}

const UNKNOWN_ERROR_MSG =
	"Something went wrong. Please refresh the page and try again.";

const UNKNOWN_ERROR_TOAST: UseToastOptions = {
	title: "Error",
	description: UNKNOWN_ERROR_MSG,
	status: "error",
	duration: 5000,
	isClosable: true,
};

const RESOURCE_PATH = "resources/";
const CATALOG_PATH = "catalogs/";
const PARTNER_PATH = "partners/";
const GALLERY_PATH = "gallery/";
const PROMOTION_PATH = "promotions/";
const CUSTOM_WORK_GALLERY_PATH = "custom-work-gallery/";
const PRODUCT_COLLECTION_PATH = "product-collections/";
const RESUMES_PATH = "resumes/";
const REQUEST_QUOTE_PATH = "forms/request-quote/";
const CUSTOM_INQUIRY_PATH = "forms/custom-inquiry/";

const ECWID_CATEGORY_MAPPING: Record<string, number> = {
	vinyl: 125630226,
	aluminum: 125630203,
	chain: 133532208,
	deer: 125630219,
	"welded-wire": 125630238,
	wood: 125630196,
};

const ECWID_SEARCH_MAPPING: Record<string, string> = {
	pool: "pool",
	privacy: "privacy",
	"semi-privacy": "semi-privacy",
	decoration: "decoration",
	security: "security",
	agricultural: "agricultural",
};

const FAQ_CATEGORIES: FAQCategory[] = [
	{
		name: "General",
		icon: FiBookOpen,
	},
	{
		name: "Products",
		icon: FiBox,
	},
	{
		name: "Delivery",
		icon: FiTruck,
	},
	{
		name: "Dealers",
		icon: FiUsers,
	},
];

const PARTNER_SUBCATEGORIES: { value: string; label: string }[] = [
	{ value: "Ornamental", label: "Ornamental" },
	{ value: "Composite", label: "Composite" },
	{ value: "PVC/Vinyl", label: "PVC/Vinyl" },
	{
		value: "Fence Hardware & Accessories",
		label: "Fence Hardware & Accessories",
	},
];

const ACCESS_LEVEL_OPTIONS: { value: string; label: string }[] = [
	{ value: "Admin", label: "Admin" },
	{ value: "Employee", label: "Employee" },
	{ value: "Content Manager", label: "Content Manager" },
	{ value: "Application Manager", label: "Application Manager" },
];

const ACCESS_LEVELS = {
	admin: "Admin",
	content_manager: "Content Manager",
	application_manager: "Application Manager",
	employee: "Employee",
};
const CONTENT_MANAGEMENT_ACCESS = [
	ACCESS_LEVELS.admin,
	ACCESS_LEVELS.content_manager,
];
const APPLICATION_MANAGEMENT_ACCESS = [
	ACCESS_LEVELS.admin,
	ACCESS_LEVELS.application_manager,
];
const EMPLOYEE_ACCESS = [
	ACCESS_LEVELS.admin,
	ACCESS_LEVELS.content_manager,
	ACCESS_LEVELS.application_manager,
	ACCESS_LEVELS.employee,
];
const ADMIN_ACCESS = [ACCESS_LEVELS.admin];
const ADMIN_PANEL_ACCESS = [
	ACCESS_LEVELS.admin,
	ACCESS_LEVELS.content_manager,
	ACCESS_LEVELS.application_manager,
];

const PHONE_NUMBER = "1 (800) 847-7789";
const EMAIL = "sales@waysidefence.com";

const LOCATIONS = [
	{
		name: "New York",
		address: "63 Third Avenue, Bay Shore, NY 11706",
		phone: PHONE_NUMBER,
		email: EMAIL,
		description: "Our main location servicing all of New York & Long Island.",
		hours: ["Weekdays: 7:30 AM - 4PM", "Saturday: 8AM - 12PM"],
		iframeSrc:
			"https://maps.google.com/maps?q=63%20Third%20Avenue,%20Bay%20Shore,%20NY%2011706&t=&z=13&ie=UTF8&iwloc=&output=embed",
		iframeWidth: "100%",
		iframeHeight: "100%",
	},
	{
		name: "Connecticut",
		address: "56 Fenn Road, Newington, CT 06111",
		phone: PHONE_NUMBER,
		email: EMAIL,
		description: "Our sister location servicing the entire north-east region.",
		hours: ["Weekdays: 7:30 AM - 4PM", "Saturday: 8AM - 12PM"],
		iframeSrc:
			"https://maps.google.com/maps?q=56%20Fenn%20Road,%20Newington,%20CT%2006111&t=&z=13&ie=UTF8&iwloc=&output=embed",
		iframeWidth: "100%",
		iframeHeight: "100%",
	},
];

export {
	UNKNOWN_ERROR_MSG,
	UNKNOWN_ERROR_TOAST,
	RESOURCE_PATH,
	ECWID_CATEGORY_MAPPING,
	ECWID_SEARCH_MAPPING,
	CATALOG_PATH,
	PARTNER_PATH,
	GALLERY_PATH,
	PROMOTION_PATH,
	PRODUCT_COLLECTION_PATH,
	CUSTOM_WORK_GALLERY_PATH,
	RESUMES_PATH,
	REQUEST_QUOTE_PATH,
	CUSTOM_INQUIRY_PATH,
	FAQ_CATEGORIES,
	PARTNER_SUBCATEGORIES,
	ACCESS_LEVEL_OPTIONS,
	ADMIN_PANEL_ACCESS,
	CONTENT_MANAGEMENT_ACCESS,
	APPLICATION_MANAGEMENT_ACCESS,
	ADMIN_ACCESS,
	EMPLOYEE_ACCESS,
	LOCATIONS,
	PHONE_NUMBER,
	EMAIL,
};
