import { useQuery } from "@apollo/client";
import { getCatalogs as GET_CATALOGS } from "./queries";
import { RetrievalInput, Catalog, PaginatedResult } from "../../types";

interface GetCatalogsParams {
	retrievalInput?: RetrievalInput;
}

interface GetCatalogsData {
	getCatalogs: PaginatedResult<Catalog>;
}

const useGetCatalogs = (input?: GetCatalogsParams) => {
	const { loading, error, data, refetch, networkStatus } =
		useQuery<GetCatalogsData>(GET_CATALOGS, { variables: input ? input : {} });
	const processedData = data?.getCatalogs;
	return { loading, error, data: processedData, refetch, networkStatus };
};

export default useGetCatalogs;
