import React from "react";
import { Flex, Heading, Image } from "@chakra-ui/react";
import { colors } from "styles";

interface SidebarHeaderProps {
	companyName?: string;
	companyLogoPath?: string; // Path to the logo in the assets folder
	sidebarHeading?: React.ReactNode;
}

const SidebarHeader: React.FC<SidebarHeaderProps> = ({
	companyName,
	companyLogoPath,
	sidebarHeading,
}) => {
	return (
		<Flex
			direction="column"
			alignItems="center"
			justifyContent="center"
			px={{ base: 4, md: 0 }}
			pt={{ base: 0, md: "2" }}
			maxH="10vh"
		>
			{companyLogoPath ? (
				<Image src={companyLogoPath} alt={companyName} maxWidth="50%" mb={2} />
			) : (
				<>
					{companyName && (
						<Heading
							size={{ base: "md", md: "lg" }}
							color="white"
							textShadow="1px 1px 2px rgba(0, 0, 0, 0.25)"
							fontWeight="medium"
							fontFamily="montserrat"
							textAlign="center"
							mb={2}
						>
							{companyName}
						</Heading>
					)}
				</>
			)}
			<Heading
				size={{ base: "2xl", md: "xl" }}
				color={sidebarHeading ? "white" : colors.graydient[500]}
				textShadow="1px 1px 2px rgba(0, 0, 0, 0.25)"
				fontWeight="medium"
				fontFamily="montserrat"
				textAlign="center"
			>
				{sidebarHeading}
			</Heading>
		</Flex>
	);
};

export default SidebarHeader;
