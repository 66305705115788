import { useQuery } from "@apollo/client";
import { getGalleryImages as GET_GALLERY_IMAGES } from "./queries";
import { RetrievalInput, GalleryImage, PaginatedResult } from "../../types";

interface GetGalleryImagesData {
	getGalleryImages: PaginatedResult<GalleryImage>;
}

const useGetGalleryImages = (retrievalInput?: RetrievalInput) => {
	const { loading, error, data, refetch, networkStatus } =
		useQuery<GetGalleryImagesData>(GET_GALLERY_IMAGES, {
			variables: { retrievalInput },
		});
	const processedData = data?.getGalleryImages;
	return { loading, error, data: processedData, refetch, networkStatus };
};
export default useGetGalleryImages;
