import { useState } from "react";
import { useUpdateCatalog } from "services";
import { FieldDefinition } from "components";
import { FileInput } from "components/common/inputs";

import { Catalog, CatalogInput } from "services/types";
import { useFormWithFiles } from "hooks/forms";
import CatalogUpdate from "cud/update/CatalogUpdate";
import { ICUDFormHook } from "./types";

const useUpdateCatalogForm = ({
	refetch,
	existingCatalog,
}: ICUDFormHook & { existingCatalog: Catalog }) => {
	const initialFormState = {
		name: existingCatalog.name,
		src: existingCatalog.src,
		image: existingCatalog.image,
		isDealer: existingCatalog.isDealer,
	};

	const {
		formState,
		loading,
		setLoading,
		handleInputChange,
		resetFormState,
		toast,
		uploadFile,
	} = useFormWithFiles<CatalogInput>({
		initialState: initialFormState,
		allowMultiple: false,
	});

	const [imageFile, setImageFile] = useState<File | null>(null);
	const [srcFile, setSrcFile] = useState<File | null>(null);
	const [imageFileUrl, setImageFileUrl] = useState<string | null>(
		existingCatalog.image,
	);
	const [srcFileUrl, setSrcFileUrl] = useState<string | null>(
		existingCatalog.src,
	);

	const handleUpdateImageFile = (files: File[]) => {
		if (files.length > 0) {
			setImageFile(files[0]);
		} else {
			setImageFile(null);
		}
	};

	const handleUpdateImageFileUrls = (fileUrls: string[]) => {
		if (fileUrls.length > 0) {
			setImageFileUrl(fileUrls[0]);
		} else {
			setImageFileUrl(null);
		}
	};

	const handleUpdateSrcFile = (files: File[]) => {
		if (files.length > 0) {
			setSrcFile(files[0]);
		} else {
			setSrcFile(null);
		}
	};

	const handleUpdateSrcFileUrls = (fileUrls: string[]) => {
		if (fileUrls.length > 0) {
			setSrcFileUrl(fileUrls[0]);
		} else {
			setSrcFileUrl(null);
		}
	};

	const { updateCatalog } = useUpdateCatalog();

	const catalogUpdate = new CatalogUpdate(
		existingCatalog,
		toast,
		updateCatalog,
		refetch,
		imageFile as File | null,
		srcFile as File | null,
		uploadFile,
	);

	const handleSubmit = async () => {
		setLoading(true);
		const success = await catalogUpdate.handleUpdate(formState);
		if (success) {
			resetFormState();
		}
		setLoading(false);
	};

	const fields: FieldDefinition[] = [
		{
			type: "text",
			name: "name",
			label: "Name",
			value: formState.name,
			placeholder: "Enter a name for the catalog...",
			required: true,
			onChange: handleInputChange,
			variant: "base",
			size: "lg",
		},
		{
			type: "custom",
			name: "image",
			label: "Image",
			required: true,
			customComponent: FileInput,
			customComponentProps: {
				fileType: "image",
				files: imageFile ? [imageFile] : [],
				setFiles: handleUpdateImageFile,
				allowMultiple: false,
				fileUrls: imageFileUrl ? [imageFileUrl] : [],
				setFileUrls: handleUpdateImageFileUrls,
			},
		},
		{
			type: "custom",
			name: "src",
			label: "Catalog Source",
			customComponent: FileInput,
			required: true,
			customComponentProps: {
				fileType: "document",
				files: srcFile ? [srcFile] : [],
				setFiles: handleUpdateSrcFile,
				allowMultiple: false,
				fileUrls: srcFileUrl ? [srcFileUrl] : [],
				setFileUrls: handleUpdateSrcFileUrls,
			},
		},
		{
			type: "switch",
			name: "isDealer",
			label: "Is Dealer?",
			value: formState.isDealer,
			onChange: handleInputChange,
			variant: "login",
			size: "sm",
		},
	];

	return {
		loading,
		handleSubmit,
		fields,
	};
};

export default useUpdateCatalogForm;
