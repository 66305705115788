/**
 * @notes
 * - Make responsive for Ipad Pro resolution (1285px)
 */
//Component File for the Header Component
import React, { useContext } from "react";
import { PageChangeContext } from "contexts";
import {
	Flex,
	Image,
	useDisclosure,
	IconButton,
	useBreakpointValue,
} from "@chakra-ui/react";
// import Logo from "../../assets/logo/WaysideLogo.png"
import { Navbar, NavItem, HeaderButtons } from "components/features/navbar";
import MobileNavbar from "components/features/navbar/MobileNavbar";
import Logo from "../../assets/logo/Logo.webp";
import GrassImage from "assets/navbar/Grass3.png";
import HeaderLayout from "components/layouts/HeaderLayout";
import { ContactInfoModal } from "components/features/modals";

import { HamburgerIcon, PhoneIcon } from "@chakra-ui/icons";

import { PHONE_NUMBER, EMAIL } from "consts";

const navItems: NavItem[] = [
	{
		label: "About",
		items: [
			{ label: "Our History", to: "/about/history" },
			{ label: "Locations", to: "/about/locations" },
			{ label: "Careers", to: "/about/careers" },
		],
	},
	{
		label: "Products",
		to: "/products",
		items: [
			{
				label: "Custom Inquiries",
				to: "/products/custom-inquiry",
			},
			{
				label: "By Material",
				to: "/products/By-Material",
				items: [
					{ label: "Aluminum", to: "/products/aluminum" },
					{ label: "Chain Link", to: "products/chain" },
					{ label: "Deer Fence", to: "products/deer" },
					{ label: "Vinyl", to: "products/vinyl" },
					{ label: "Wood", to: "products/wood" },
					{ label: "Welded Wire", to: "products/welded-wire" },
				],
			},
			{
				label: "By Solution",
				to: "/products/By-Solution",
				items: [
					{ label: "Pool", to: "/products/pool" },
					{ label: "Privacy", to: "products/privacy" },
					{ label: "Semi-Privacy", to: "products/semi-privacy" },
					{ label: "Decoration", to: "products/decoration" },
					{ label: "Security", to: "products/security" },
					{ label: "Cintoflex", to: "products/cintoflex" },
				],
			},
		],
	},
	{
		label: "Services",
		// to: "/services",
		items: [
			{ label: "Dealer Application", to: "/services/dealer-application" },
			{ label: "Commercial", to: "/services/commercial" },
			{ label: "Residential", to: "/services/residential" },
			{ label: "Feedback", to: "/services/feedback" },
		],
	},
	{
		label: "Resources",
		// to: "/resources",
		items: [
			{ label: "Help Center", to: "/resources/help-center" },
			{ label: "Trending", to: "/resources/trending" },
			{ label: "Gallery", to: "/resources/gallery" },
			{ label: "Catalogs", to: "/resources/catalogs" },
			{ label: "Our Partners", to: "/resources/our-partners" },
			{ label: "Our Policies", to: "/resources/our-policies" },
		],
	},
	{
		label: "Buy Now",
		to: "/store",
	},
	{
		label: "Request a quote",
		to: "/services/quote",
	},
];

const Header: React.FC = () => {
	const phoneModalDisclosure = useDisclosure();
	const mobileNavDisclosure = useDisclosure();

	const { handlePageChange } = useContext(PageChangeContext);

	const isMobile = useBreakpointValue(
		{
			base: true,
			xl: false,
		},
		{ ssr: false },
	);

	const hamburgerRef = React.useRef<HTMLButtonElement>(null);

	if (isMobile === undefined) {
		return null; // Or a loader/spinner if needed
	}

	return (
		<HeaderLayout>
			<IconButton
				variant="ghost_white"
				display={{ base: "inline-flex", xl: "none" }}
				aria-label="Call Us"
				icon={<PhoneIcon />}
				size="md"
				onClick={phoneModalDisclosure.onOpen}
			/>
			<Image
				src={Logo}
				alt="Company Logo"
				height={{ base: "60px", md: "38px" }}
				cursor="pointer"
				onClick={() => handlePageChange("/home")}
				mx={{ base: "auto", md: "0" }}
				my={{ base: 2, md: 0 }}
			/>
			<IconButton
				variant="ghost_white"
				display={{ base: "inline-flex", xl: "none" }}
				onClick={mobileNavDisclosure.onOpen}
				aria-label="Open Menu"
				icon={<HamburgerIcon />}
				size="lg"
				ref={hamburgerRef}
			/>

			<Flex
				display={{ base: "none", xl: "flex" }} // This now displays only on lg and above
				flexGrow={1}
				justifyContent="center"
				alignItems="center"
				p={2}
				px={0}
			>
				<Navbar navItems={navItems} />
			</Flex>
			{!isMobile && <HeaderButtons />}
			{isMobile && (
				<MobileNavbar
					isOpen={mobileNavDisclosure.isOpen}
					onClose={mobileNavDisclosure.onClose}
					placement="right"
					finalFocusRef={hamburgerRef}
					navItems={navItems}
					bottomImageSrc={GrassImage}
				/>
			)}
			{isMobile && (
				<ContactInfoModal
					phone={PHONE_NUMBER}
					email={EMAIL}
					contactButtonText="Contact Us Today!"
					isOpen={phoneModalDisclosure.isOpen}
					onClose={phoneModalDisclosure.onClose}
					showButton={false}
				/>
			)}
		</HeaderLayout>
	);
};

export default Header;
