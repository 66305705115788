import React, { useState } from "react";
import { Flex } from "@chakra-ui/react";
import { SidebarItem } from "../../features/sidebar/types";
import SidebarButton from "./SidebarButton";
import { FiChevronDown } from "react-icons/fi";

interface SidebarDropdownButtonProps {
	label: string;
	icon: React.ElementType;
	items: SidebarItem[];
	onClick?: (label: string) => void;
}

const SidebarDropdownButton: React.FC<SidebarDropdownButtonProps> = ({
	label,
	icon,
	items,
	onClick,
}) => {
	const [isOpen] = useState(true);

	return (
		<>
			<SidebarButton
				label={label}
				icon={icon}
				rightIcon={FiChevronDown}
				onClick={onClick}
			/>
			{isOpen && (
				<Flex
					direction="column"
					pl={2}
					alignItems="flex-start"
					justifyContent="flex-start"
					px={2}
				>
					{items.map((subItem, subIndex) => (
						<Flex key={`sidebar-dropdown-${label}-${subIndex}`}>
							<SidebarButton
								label={subItem.label}
								icon={subItem.icon}
								onClick={subItem.onClick}
							/>
						</Flex>
					))}
				</Flex>
			)}
		</>
	);
};
export default SidebarDropdownButton;
