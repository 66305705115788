/**
 * @module UpdateReviewForm.tsx
 * @description This file contains the form for updating a review.
 */
import React from "react";
import { Form } from "components";
import { ModalWrapper } from "wrappers";
import { useUpdateReviewForm } from "hooks/form-hooks";
import { Review } from "services/types";

interface UpdateReviewFormProps {
	isOpen: boolean;
	onClose: () => void;
	refetch: () => Promise<any>;
	existingReview: Review;
}

const UpdateReviewForm: React.FC<UpdateReviewFormProps> = ({
	isOpen,
	onClose,
	refetch,
	existingReview,
}) => {
	const { fields, handleSubmit, loading } = useUpdateReviewForm({
		refetch,
		existingReview,
	});

	const onSubmit = async () => {
		await handleSubmit();
		onClose();
	};

	return (
		<ModalWrapper
			isOpen={isOpen}
			onClose={onClose}
			showShadow={true}
			heading="Update Review"
			hideCloseButton={false}
		>
			<Form fields={fields} onSubmit={onSubmit} isLoading={loading} />
		</ModalWrapper>
	);
};

export default UpdateReviewForm;
