import { ComponentStyleConfig } from "@chakra-ui/react";
import { colors } from "styles";

const selectStyles: ComponentStyleConfig = {
	parts: ["field"],
	baseStyle: {
		// Base styles for the select
	},
	variants: {
		outline: () => ({
			select: {
				borderColor: "gray.300",
				_hover: {
					borderColor: "gray.400",
				},
				_focus: {
					borderColor: "blue.500",
					boxShadow: "0 0 0 1px blue.500",
				},
				padding: "1",
				borderRadius: "md",
			},
		}),
		// Add more variants as needed
		base: () => ({
			field: {
				borderColor: colors.graydient[300],
				borderWidth: "1px",
				boxShadow: "base",
				_hover: {
					borderColor: "gray.400",
					boxShadow: "lg",
				},
				_focus: {
					borderColor: "blue.500",
					boxShadow: "0 0 0 1px blue.500",
				},
			},
		}),
	},
	// defaultProps can be added if needed
};

export default selectStyles;
