import React from "react";
import { Box, keyframes } from "@chakra-ui/react";

import { colors } from "styles";

// Define the keyframes for rotation
const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loader: React.FC = () => {
	const loaderStyle = {
		width: "60px",
		height: "60px",
		borderRadius: "50%",
		display: "inline-block",
		borderTop: "4px solid #fff",
		borderRight: "4px solid transparent",
		boxSizing: "border-box" as const,
		animation: `${rotate} 1s linear infinite`,
		position: "relative" as const,
	};

	const afterStyle = {
		content: '""',
		boxSizing: "border-box" as const,
		position: "absolute" as const,
		left: 0,
		top: 0,
		width: "60px",
		height: "60px",
		borderRadius: "50%",
		borderBottom: `4px solid ${colors.primary[500]}`,
		borderLeft: "4px solid transparent",
	};

	return (
		<Box
			display="flex"
			height="100%"
			width="100%"
			minHeight="50vh"
			justifyContent="center"
			alignItems="center"
		>
			<Box {...loaderStyle}>
				<Box {...afterStyle} />
			</Box>
		</Box>
	);
};

export default Loader;
