import { useState } from "react";
import { BaseDelete } from "cud/delete";
import { useToast } from "@chakra-ui/react";
import useDeleteGalleryImage from "services/content-management/mutations/useDeleteGalleryImage";
import { GalleryImage } from "services";

const useDeleteGalleryForm = (
	galleryImage: GalleryImage,
	refetch: () => Promise<void>,
) => {
	const [loading, setLoading] = useState(false);
	const toast = useToast();
	const { deleteGalleryImage } = useDeleteGalleryImage();

	const baseDelete = new BaseDelete<GalleryImage>(
		galleryImage,
		"gallery image",
		toast,
		deleteGalleryImage,
		refetch,
	);

	const handleDelete = async () => {
		setLoading(true);
		await baseDelete.handleDelete();
		setLoading(false);
	};

	return {
		handleDelete,
		loading,
	};
};

export default useDeleteGalleryForm;
