/**
 * @module Store.tsx
 * @description A component that displays a store with a set of cards.
 */
import React from "react";
import { BaseCard } from "components";
import { Flex, Heading, Text, Stack } from "@chakra-ui/react";
import Vinyl from "../../assets/Home/VinylSection.webp";
import PoolSide from "../../assets/services/collections/PoolSide.jpg";
import { PageChangeContext } from "contexts";
import PromotionCollapse from "./Promotion/PromotionCollapse";

const Store: React.FC = () => {
	const { handlePageChange } = React.useContext(PageChangeContext);

	const handleStoreClick = () => {
		handlePageChange("store");
	};

	const handleCollectionsClick = () => {
		handlePageChange("resources/trending");
	};

	return (
		<Flex
			flexDirection={"column"}
			mx="auto"
			overflow={"hidden"}
			bgGradient="linear(to-tr, accent.300, accent.400, accent.600, accent.700)"
			boxShadow="dark-lg"
			borderRadius="md"
			p={{
				base: "4",
				md: "2",
			}}
			mt={{ base: "4", md: "24" }}
			mb={{
				base: "4",
				md: "24",
			}}
			width={{
				base: "90%",
				lg: "95%",
				xl: "95%",
			}}
			justifyContent={"center"}
			alignItems="center"
		>
			<Flex
				flexDirection={{ base: "column", xl: "row" }}
				justifyContent={{ base: "space-evenly", md: "space-between" }}
				alignItems="center"
				w="100%"
				h="100%"
			>
				<Stack
					direction="column"
					alignItems="center"
					justifyContent="center"
					w={{
						base: "100%",
						xl: "65%",
					}}
					maxW="4xl"
					minW="auto"
					p={{ base: "4", md: "6" }}
					pr={{
						md: 0,
					}}
					flexShrink={1}
				>
					<Heading
						variant="cta"
						fontSize={{ base: "3xl", lg: "5xl" }}
						mt={{ base: "2", md: "0" }}
						color="white"
						textAlign="center"
					>
						Buying Fence, Made Simple.
					</Heading>
					<Text
						variant="sectionTitle"
						color="white"
						textAlign="center"
						mt={{
							base: "4",
							md: "2",
						}}
						p={{ base: 2 }}
						fontSize={{ base: "2xl", md: "xl" }}
						textShadow="1px 1px 2px rgba(0, 0, 0, 0.45)"
					>
						Shop high-quality fence materials & collections right from your
						browser.
					</Text>
				</Stack>
				<Stack
					direction={{ base: "column", md: "row" }}
					gap={"4"}
					py={{ base: "0", md: "10" }}
					px={{ base: "0", xl: "5" }}
					alignItems="center"
					justifyContent={"space-evenly"}
					w={{
						base: "100%",
						xl: "35%",
					}}
					maxWidth={"8xl"}
					flexGrow={1}
				>
					<BaseCard
						width={{ base: "190px", md: "240px" }}
						height={{ base: "175px", md: "200px" }}
						backgroundImage={Vinyl}
						backgroundSize={"contain"}
						backgroundPosition={"center"}
						backgroundRepeat={"no-repeat"}
						transition="transform 0.4s"
						_hover={{
							transform: "translateY(-5px) scale(1.05)",
							cursor: "pointer",
						}}
						hoverText="Shop Now"
						textColor="white"
						onClick={handleStoreClick}
					/>
					<BaseCard
						width={{ base: "190px", md: "240px" }}
						height={{ base: "175px", md: "200px" }}
						backgroundImage={PoolSide}
						backgroundSize={"cover"}
						backgroundPosition={"center center"}
						backgroundRepeat={"no-repeat"}
						borderRadius="md"
						boxShadow="lg"
						transition="transform 0.4s"
						_hover={{
							transform: "translateY(-5px) scale(1.05)",
							cursor: "pointer",
						}}
						hoverText="View Trending"
						textColor="white"
						onClick={handleCollectionsClick}
					/>
				</Stack>
			</Flex>
			<PromotionCollapse />
		</Flex>
	);
};

export default Store;
