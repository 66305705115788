import React from "react";
import { Flex, Box } from "@chakra-ui/react";

type HeaderContentsLayoutProps = {
	header: React.ReactNode;
	children: React.ReactNode;
};

/**
 * @component HeaderContentsLayout
 *
 * @description `HeaderContentsLayout` is a functional component for rendering a page layout with a header and a main content area.
 * It utilizes Chakra UI's `Flex` and `Box` components to create a vertical layout structure.
 *
 * Preconditions:
 * - The `header` and `children` props must be valid React nodes.
 * - Chakra UI's theme provider should be in the React tree for proper styling.
 *
 * Postconditions:
 * - Renders a vertical layout with two sections: a header and a main content area.
 * - The header occupies 10% of the vertical space, and the main content occupies the remaining 90%.
 *
 * @param {HeaderContentsLayoutProps} props - The component props.
 * @param {React.ReactNode} props.header - The content for the header section of the layout.
 * @param {React.ReactNode} props.children - The main content of the layout.
 *
 * @returns {React.ReactElement} A React element representing a vertical layout with a header and a main content area.
 */
const HeaderContentsLayout: React.FC<HeaderContentsLayoutProps> = ({
	header: Header,
	children,
}) => {
	return (
		<Flex w="100%" h="100%" direction="column">
			{/* Header */}
			<Box h="auto" display={"flex"}>
				{Header}
			</Box>
			{/* Main section */}
			<Box h="90%" display={"flex"} flexDirection="column">
				{children}
			</Box>
		</Flex>
	);
};

export default HeaderContentsLayout;
