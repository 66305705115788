/**
 * @module Residential.tsx
 * @desc Residential page component
 */
import React from "react";
import { Flex, VStack } from "@chakra-ui/react";
import Hero from "components/features/heros/ComponentHero";
import { HorizontalCta } from "components";
import {
	Residential_Hero,
	Residential_Pool,
	Residential_Privacy,
	Residential_Semi,
	Residential_Demo,
	Residential_Safety,
	Residential_Farm,
} from "assets";

const residentialCTAs = [
	{
		title: "Pool Enclosures",
		description:
			"Having a safe and secure pool area starts with the right fencing thats up to code. Shop from our selection pool-certified fencing solutions.",
		buttonText: "Shop Now",
		imageUrl: Residential_Pool,
		imageAlt: "Residential Services",
		textPosition: "left",
		buttonLink: "/products/pool",
	},
	{
		title: "Privacy Fencing",
		description:
			" Need a little more privacy? From wood to vinyl, Wayside Fence Company offers a variety of privacy fencing solutions for your property.",
		buttonText: "Shop Now",
		imageUrl: Residential_Privacy,
		imageAlt: "High Security",
		textPosition: "right",
		buttonLink: "/products/privacy",
	},
	{
		title: "Semi-Privacy Fencing",
		description:
			"Offering a perfect balance between privacy and visibility, semi-privacy fencing is a great option for homeowners looking for a little bit of both.",
		buttonText: "Shop Now",
		imageUrl: Residential_Semi,
		imageAlt: "Residential Gates",
		textPosition: "left",
		buttonLink: "/products/semi-privacy",
	},
	{
		title: "Decorative",
		description:
			"Enhance the aesthetic appeal of your property and overall curb appeal with decorative fencing ranging from classic to modern styles.",
		buttonText: "Shop Now",
		imageUrl: Residential_Demo,
		imageAlt: "Parks, Recreation, and Sports Facilities",
		textPosition: "right",
		buttonLink: "/products/decoration",
	},
	{
		title: "Safety & Security",
		description:
			"Ensure the safety and security of your entire property with both fence and gate solutions of the highest quality. ",
		buttonText: "Shop Now",
		imageUrl: Residential_Safety,
		imageAlt: "High Security",
		textPosition: "left",
		buttonLink: "/products/security",
	},
	{
		title: "Agricultural",
		description:
			"From gardens to paddocks, our selection of agricultural fencing solutions that are both durable and reliable.",
		buttonText: "Shop Now",
		imageUrl: Residential_Farm,
		imageAlt: "High Security",
		textPosition: "right",
		buttonLink: "/products/agricultural",
	},
];

/**
 * Residential page component
 * @returns {React.ReactElement} - Rendered component
 */
const Residential = () => {
	return (
		<Flex direction="column" gap={5}>
			<Hero
				titleText="Residential"
				backgroundImage={Residential_Hero}
				backgroundColor="rgba(0, 0, 0, 0.36)"
				boxProps={{
					height: { base: "250px", md: "350px" },
					bgPos: "center",
					bgSize: "cover",
					bgPosition: "center",
				}}
				imageProps={{ objectFit: "cover", objectPosition: "center 38%" }}
				textProps={{
					fontSize: { base: "3xl", md: "5xl" },
					textAlign: "center",
					textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
				}}
			/>
			<VStack
				spacing={14}
				px="8"
				py="2"
				w="100%"
				h="100%"
				mb={{ base: "2", md: "10" }}
			>
				{residentialCTAs.map((cta, index) => (
					<HorizontalCta
						key={index}
						title={cta.title}
						description={cta.description}
						buttonText={cta.buttonText}
						imageUrl={cta.imageUrl}
						imageAlt={cta.imageAlt}
						textPosition={cta.textPosition as "left" | "right"}
						buttonLink={cta.buttonLink}
					/>
				))}
			</VStack>
		</Flex>
	);
};

export default Residential;
