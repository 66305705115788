/**
 * @module CollectionRow
 * @desc This module provides a collection row component.
 * - This component is used to display a collection of items in a row.
 */
import React from "react";
import { Box, SimpleGrid } from "@chakra-ui/react";
import { InfoMessage } from "components/common";

// /**
//  * @module ContentItem
//  * @desc props for ContentItem component
//  * @prop {number} id - the id of the content item
//  * @prop {string} altTitle - the alt title for the content item
//  */
// interface ContentItem {
// 	id: number;
// 	altTitle: string;
// 	contentUrl: string;
// 	type: "image" | "video" | "text";
// 	imageUrl?: string;
// 	textContent?: string;
// }

/**
 * @module CollectionRowProps
 * @desc props for CollectionRow component
 * @prop {ContentItem[]} items - the collection of content items
 */
interface CollectionRowProps {
	items: JSX.Element[];
}

const CollectionRow: React.FC<CollectionRowProps> = ({ items }) => {
	return (
		<>
			<SimpleGrid
				columns={[1, 2, 3, 4]}
				spacing={6}
				justifyContent="center"
				alignItems="center"
				width="100%"
				paddingX={4}
			>
				{items.map((item, index) => (
					<Box
						key={`collection-row-item-${index}`}
						borderRadius="md"
						width="auto"
						boxShadow="md"
						borderWidth="1px"
						borderColor="graydient.200"
					>
						{item}
					</Box>
				))}
			</SimpleGrid>
			{items.length === 0 && (
				<InfoMessage
					title="No items found"
					description="No items found in this collection"
				/>
			)}
		</>
	);
};

export default CollectionRow;
