import React from "react";
import { Input, InputProps } from "@chakra-ui/react";

interface PhoneNumberInputProps extends InputProps {
	name: string;
	value: string;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	placeholder?: string;
}

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
	name,
	value,
	onChange,
	placeholder = "Enter a phone number",
	...props
}) => {
	return (
		<Input
			type="tel"
			name={name}
			value={value}
			onChange={onChange}
			placeholder={placeholder}
			{...props}
		/>
	);
};

export default PhoneNumberInput;
