// Sidebar.tsx
import React from "react";
import {
	FlexProps,
	IconButton,
	Tooltip,
	useBreakpointValue,
} from "@chakra-ui/react";
import { SidebarItem } from "./types";
import { SidebarShell } from "components/layouts";
import { SidebarHeader } from "../headers";
import { SidebarContent } from "components/common/content";
import { motion } from "framer-motion";
//import { Text } from "@chakra-ui/react";
//import { FiMoreVertical } from "react-icons/fi";
import { useState } from "react";
import { colors } from "styles";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

interface SidebarProps extends FlexProps {
	sidebarHeading?: React.ReactNode;
	items: SidebarItem[];
	ProfileCard?: React.FC;
}

/**
 * Sidebar component.
 *
 * @component
 * @example
 * ```typescript-react
 * <Sidebar items={items} heading={Heading} {...props} />
 * ```
 *
 * @param {SidebarProps} props - The props for the Sidebar component.
 * @param {React.ReactNode} props.heading - The heading of the sidebar.
 * @param {SidebarItem[]} props.items - The items to be displayed in the sidebar.
 * @returns {React.ReactElement} The rendered Sidebar component.
 */
const Sidebar: React.FC<SidebarProps> = ({
	items,
	sidebarHeading,
	ProfileCard,
	...props
}: SidebarProps): React.ReactElement => {
	const [isOpen, setIsOpen] = useState(true);
	const [hidden, setHidden] = useState(!isOpen);

	const isMobile = useBreakpointValue({ base: true, md: true, lg: false });

	const sidebarWidth = useBreakpointValue({
		base: "100vw",
		md: "40vw",
		lg: "42vw",
	});
	const maxSidebarWith = isMobile ? "calc(100vw - 40px)" : "315px";

	const toggle = () => {
		setIsOpen(!isOpen);
	};

	return (
		<div style={{ display: "flex" }}>
			<motion.div
				hidden={hidden}
				initial={false}
				onAnimationStart={() => setHidden(false)}
				onAnimationComplete={() => setHidden(!isOpen)}
				animate={{ width: isOpen ? sidebarWidth : "0vw" }}
				style={{
					overflow: "hidden",
					whiteSpace: "nowrap",
					position: "relative",
					maxWidth: maxSidebarWith,
					height: "100vh",
					display: "flex",
				}}
			>
				<SidebarShell
					Header={
						sidebarHeading && (
							<SidebarHeader sidebarHeading={sidebarHeading} companyName={""} />
						)
					}
					Content={<SidebarContent items={items} />}
					Footer={
						ProfileCard && <ProfileCard />
						// <UserProfileCard
						// 	userName="John Doe"
						// 	profileCardBackground="whiteAlpha.300"
						// 	avatarSize={"20px"}
						// 	avatarSrc={"https://bit.ly/broken-link"}
						// 	userNameColor={"white"}
						// 	userEmail={""}
						// 	userEmailColor={colors.graydient[400]}
						// />
					}
					sidebarBgColor={colors.primary[500]}
				/>
			</motion.div>
			<Tooltip
				label={isOpen ? "Close Sidebar" : "Open Sidebar"}
				aria-label="Sidebar Nav"
				placement="right"
				color={colors.primary[400]}
				hasArrow={true} //E7E7E7
				bg="#E7E7E7"
			>
				<IconButton
					zIndex={1}
					top={"50%"}
					onClick={toggle}
					aria-label="Open Menu"
					icon={isOpen ? <FiChevronLeft /> : <FiChevronRight />}
					transition="all 0.2s ease-in-out"
					fontSize="1.5rem"
					_hover={{ transform: "scale(1.2)" }}
					color={colors.primary[500]}
					bgColor="transparent"
					boxShadow="none"
				/>
			</Tooltip>
		</div>
	);
};

export default Sidebar;
