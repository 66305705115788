/**
 * @module useQuoteForm
 */
import { useState, useContext } from "react";
import { PageChangeContext } from "contexts";
import { useToast } from "@chakra-ui/react";
import { FieldDefinition } from "components";
import { FileInput } from "components/common/inputs";
import {
	EmailInput,
	PhoneNumberInput,
	ZipCodeInput,
	StateInput,
} from "components/common/inputs";
import {
	validateEmail,
	validatePhoneNumber,
	validateZipCode,
} from "utils/validators";

import { useSubmitRequestQuoteForm } from "services";
import useUploadFile from "../useUploadFile";
import { REQUEST_QUOTE_PATH } from "consts";

/**
 * @typedef useQuoteForm
 * @property {function} handleFieldChange - Handles changes to form fields
 * @property {function} onSubmit - Handles form submission
 * @property {FieldDefinition[][]} steps - Form steps and fields
 * @constant {array} steps 0 - Account and Contact Information
 * @constant {array} steps 1 - Project Details
 * @constant {array} steps 2 - Fence & Gate Details
 * @constant {array} steps 3 - Logistics
 * @constant {array} steps 4 - File Upload
 * @constant {array} steps 5 - Special Requests
 */
const useQuoteForm = () => {
	const [formData, setFormData] = useState({
		// Step 0
		name: "",
		email: "",
		phoneNumber: "",
		accountType: "",

		// Step 1
		matchExisting: "",
		measurements: "",

		// Step 2
		materialType: "",
		fenceStyle: "",
		fenceHeight: "",
		fenceDetails: "",

		// Step 3
		gateIncluded: "No",
		gateType: "",
		gateStyle: "",
		gateSize: "",
		gateDetails: "",

		// Step 4
		deliveryOption: "",
		address: "",
		address2: "",
		city: "",
		state: "",
		zip: "",

		// Step 5
		referenceDetails: "",
	});
	const toast = useToast();

	const [loading, setLoading] = useState(false);
	const { handlePageChange } = useContext(PageChangeContext);

	// Gate Style options mapping
	const gateStyleOptionsMapping: Record<string, string[]> = {
		Single: ["Flat Top", "Arched", "Scallop", "Other"],
		Double: ["Flat Top", "Arched", "Scallop", "Other"],
		Sliding: ["Flat Top", "Arched", "Scallop", "Other"],
		Rolling: ["Flat Top", "Arched", "Scallop", "Other"],
		Other: ["Flat Top", "Arched", "Scallop", "Other"],
	};

	// Mapping of materialType to fenceStyle options
	const fenceStyleOptionsMapping: { [key: string]: string[] } = {
		Aluminum: ["Pool", "Privacy", "Decorative", "Security"],
		"Chain Link": ["Vinyl coated", "Galvanized"],
		Vinyl: ["Privacy", "Semi-Privacy", "Decorative", "Spaced Picket"],
		Wood: [
			"Stockade",
			"Tongue & Groove",
			"Spaced Picket",
			"Victorian Picket",
			"Split Rail",
			"Cedar Post & Rail",
			"Board-onboard",
			"Shadowbox",
			"Lattice Top",
		],
	};

	// State to hold the source files
	const [srcFiles, setSrcFiles] = useState<File[]>([]);

	// Handle form field changes
	const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		if (name === "deliveryOption" && value.includes("No")) {
			setFormData({
				...formData,
				[name]: value,
				address: "",
				address2: "",
				city: "",
				state: "",
				zip: "",
			});
			return;
		}
		if (name === "gateIncluded" && value.includes("No")) {
			setFormData({
				...formData,
				[name]: value,
				gateType: "",
				gateStyle: "",
				gateSize: "",
				gateDetails: "",
			});
			return;
		}
		setFormData({ ...formData, [name]: value });
	};

	// Handle file upload changes
	const handleUpdateSrcFile = (files: File[]) => {
		setSrcFiles(files);
	};

	const { uploadFile } = useUploadFile();
	const { submitRequestQuoteForm } = useSubmitRequestQuoteForm();

	const resetStates = () => {
		setLoading(false);
		setFormData({
			name: "",
			email: "",
			phoneNumber: "",
			accountType: "",
			matchExisting: "",
			measurements: "",
			materialType: "",
			fenceStyle: "",
			fenceHeight: "",
			fenceDetails: "",
			gateIncluded: "No",
			gateType: "",
			gateStyle: "",
			gateSize: "",
			gateDetails: "",
			deliveryOption: "",
			address: "",
			address2: "",
			city: "",
			state: "",
			zip: "",
			referenceDetails: "",
		});
		setSrcFiles([]);
	};

	// Function to handle form submission
	const onSubmit = async () => {
		setLoading(true);
		const fileUrls = [];
		try {
			for (let i = 0; i < srcFiles.length; i++) {
				const file = srcFiles[i];
				const url = await uploadFile(file, REQUEST_QUOTE_PATH);
				fileUrls.push(url);
			}
		} catch (error) {
			toast({
				title: "Error Uploading Files",
				description: "Please try again later.",
				status: "error",
				position: "bottom-left",
				duration: 7500,
				isClosable: true,
			});
			setLoading(false);
			return;
		}

		const input = {
			...formData,
			files: fileUrls,
		};

		const { data, error } = await submitRequestQuoteForm(input);
		if (error) {
			toast({
				title: "Error Submitting Application",
				description: error.message,
				status: "error",
				position: "bottom-left",
				duration: 7500,
				isClosable: true,
			});
			setLoading(false);
		} else if (data) {
			toast({
				title: "Application Submitted!",
				description: "A representative will be with you shortly.",
				status: "success",
				position: "bottom-left",
				duration: 7500,
				isClosable: true,
			});
			resetStates();
			handlePageChange("home");
		} else {
			toast({
				title: "Error Submitting Application",
				description:
					"An unexpected error occurred while processing your application. Please try again later.",
				status: "error",
				position: "bottom-left",
				duration: 7500,
				isClosable: true,
			});
			setLoading(false);
		}
	};

	/**
	 * @array steps for `useQuoteForm`
	 * @constant {array} steps - Form steps and fields
	 */
	const steps: FieldDefinition[][] = [
		// Step 0: Account and Contact Information
		[
			{
				type: "text",
				name: "name",
				label: "Name",
				value: formData.name,
				placeholder: "Jane McFence",
				required: true,
				variant: "base",
				size: "lg",
				width: "100%",
				onChange: handleFieldChange,
			},
			{
				type: "custom",
				name: "email",
				label: "Email",
				value: formData.email,
				customComponent: EmailInput,
				placeholder: "Your Email Address",
				required: true,
				variant: "base",
				size: "lg",
				width: "100%",
				onChange: handleFieldChange,
				validate: validateEmail,
			},
			{
				type: "custom",
				name: "phoneNumber",
				label: "Phone Number",
				customComponent: PhoneNumberInput,
				value: formData.phoneNumber,
				placeholder: "Your Phone Number",
				required: true,
				variant: "base",
				size: "lg",
				width: "100%",
				onChange: handleFieldChange,
				validate: validatePhoneNumber,
			},
			{
				type: "select",
				name: "accountType",
				label: "Do you have an account with us?",
				placeholder: "Select your account status",
				options: ["Yes, I'm an existing customer.", "No, I'm a new customer."],
				value: formData.accountType,
				required: true,
				variant: "base",
				size: "lg",
				width: "100%",
				onChange: handleFieldChange,
			},
		],
		// Step 2: Fence Details
		[
			{
				type: "select",
				name: "materialType",
				label: "Material Type",
				options: ["Aluminum", "Chain Link", "Vinyl", "Wood"],
				placeholder: "Select a material",
				value: formData.materialType,
				required: true,
				variant: "base",
				size: "lg",
				width: "100%",
				onChange: handleFieldChange,
			},
			{
				type: "select",
				name: "fenceStyle",
				label: "Fence Style",
				options: fenceStyleOptionsMapping[formData.materialType],
				placeholder: "Select a fence style",
				value: formData.fenceStyle,
				required: true,
				variant: "base",
				size: "lg",
				width: "100%",
				onChange: handleFieldChange,
			},
			{
				type: "select",
				name: "fenceHeight",
				label: "Fence Height",
				placeholder: "Select a height",
				options: ["4ft", "4.5ft", "5ft", "5.5ft", "6ft", "6.5ft", "Other"],
				value: formData.fenceHeight,
				required: true,
				variant: "base",
				size: "lg",
				width: "100%",
				onChange: handleFieldChange,
			},
			{
				type: "text",
				name: "measurements",
				label: "Measurements",
				value: formData.measurements,
				placeholder: "e.g., 150ft linear",
				required: true,
				variant: "base",
				size: "lg",
				width: "100%",
				onChange: handleFieldChange,
			},
			{
				type: "textarea",
				name: "fenceDetails",
				label: "Additional Details",
				value: formData.fenceDetails,
				placeholder: "e.g., Matching existing fence, etc.",
				required: false,
				variant: "base",
				size: "lg",
				minWidth: "100%",
				onChange: handleFieldChange,
			},
		],
		// Step 3: Gate Details
		[
			{
				type: "select",
				name: "gateIncluded",
				label: "Do you need a gate?",
				options: ["Yes", "No"],
				value: formData.gateIncluded,
				required: true,
				variant: "base",
				size: "lg",
				width: "100%",
				minWidth: "100%",
				onChange: handleFieldChange,
			},
			{
				type: "select",
				name: "gateType",
				label: "Gate Type",
				options: ["Single", "Double", "Sliding", "Rolling", "Other"],
				placeholder: "Select a gate type",
				value: formData.gateType,
				required: formData.gateIncluded === "Yes",
				variant: "base",
				size: "lg",
				width: "100%",
				onChange: handleFieldChange,
				isConditional: true,
				isVisible: formData.gateIncluded === "Yes",
			},
			{
				type: "select",
				name: "gateStyle",
				label: "Gate Style",
				options: gateStyleOptionsMapping[formData.gateType],
				placeholder: "Select a gate style",
				value: formData.gateStyle,
				required: formData.gateIncluded === "Yes",
				variant: "base",
				size: "lg",
				width: "100%",
				onChange: handleFieldChange,
				isConditional: true,
				isVisible: formData.gateIncluded === "Yes",
			},
			{
				type: "text",
				name: "gateSize",
				label: "Gate Dimensions",
				value: formData.gateSize,
				placeholder: "Height x Width (e.g., 6ft x 4ft)",
				required: formData.gateIncluded === "Yes",
				variant: "base",
				size: "lg",
				width: "100%",
				onChange: handleFieldChange,
				isConditional: true,
				isVisible: formData.gateIncluded === "Yes",
			},
			{
				type: "textarea",
				name: "gateDetails",
				label: "Additional Gate Details",
				value: formData.gateDetails,
				placeholder: "e.g., Gate color, material, etc.",
				required: formData.gateIncluded === "Yes",
				variant: "base",
				size: "lg",
				width: "100%",
				onChange: handleFieldChange,
				isConditional: true,
				isVisible: formData.gateIncluded === "Yes",
			},
		],
		// Step 4: Logistics
		[
			{
				type: "select",
				name: "deliveryOption",
				label: "Do you need delivery?",
				options: ["No, I'll pick it up.", "Yes"],
				placeholder: "Select a delivery option",
				value: formData.deliveryOption,
				required: true,
				variant: "base",
				size: "lg",
				width: "100%",
				minWidth: "100%",
				onChange: handleFieldChange,
			},
			{
				type: "text",
				name: "address",
				label: "Address",
				value: formData.address,
				placeholder: "123 Main St",
				required: formData.deliveryOption === "Yes",
				variant: "base",
				size: "lg",
				width: "100%",
				onChange: handleFieldChange,
				isConditional: true,
				isVisible: formData.deliveryOption === "Yes",
			},
			{
				type: "text",
				name: "address2",
				label: "Address Line 2",
				value: formData.address2,
				placeholder: "Apt, Suite, etc.",
				required: false,
				variant: "base",
				size: "lg",
				width: "100%",
				onChange: handleFieldChange,
				isConditional: true,
				isVisible: formData.deliveryOption === "Yes",
			},
			{
				type: "text",
				name: "city",
				label: "City",
				value: formData.city,
				placeholder: "City",
				required: formData.deliveryOption === "Yes",
				variant: "base",
				size: "lg",
				width: "100%",
				onChange: handleFieldChange,
				isConditional: true,
				isVisible: formData.deliveryOption === "Yes",
			},
			{
				type: "custom",
				name: "state",
				label: "State",
				value: formData.state,
				customComponent: StateInput,
				placeholder: "Select your State",
				required: formData.deliveryOption === "Yes",
				variant: "base",
				size: "lg",
				width: "100%",
				onChange: handleFieldChange,
				isConditional: true,
				isVisible: formData.deliveryOption === "Yes",
			},
			// Zip code validation (`utils/validatots.tsx`) is preventing users from going onto the next step for some weird reason
			{
				type: "custom",
				name: "zip",
				label: "Zip Code",
				value: formData.zip,
				customComponent: ZipCodeInput,
				placeholder: "Enter your Zip Code",
				required: false,
				variant: "base",
				size: "lg",
				width: "100%",
				onChange: handleFieldChange,
				validate: validateZipCode,
				isConditional: true,
				isVisible: formData.deliveryOption === "Yes",
			},
		],
		// Step 5: File Upload and Special Requests
		[
			{
				type: "custom",
				name: "src",
				label: "Upload relevant photos / videos / documents",
				customComponent: FileInput,
				required: false,
				customComponentProps: {
					fileType: "generic",
					files: srcFiles,
					setFiles: handleUpdateSrcFile,
					allowMultiple: true,
				},
			},
			{
				type: "textarea",
				name: "referenceDetails",
				label: "Reference Details",
				value: formData.referenceDetails,
				placeholder: "e.g., Delivery instructions, special requests, etc.",
				required: false,
				variant: "base",
				size: "lg",
				width: "100%",
				minWidth: "100%",
				onChange: handleFieldChange,
			},
		],
	];

	return { loading, onSubmit, steps };
};

export default useQuoteForm;
