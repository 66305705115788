import { Login } from "page-components";
import { PageBackgroundWrapper } from "wrappers";
import { colors } from "styles";

const AdminLogin = () => {
	return (
		<PageBackgroundWrapper
			backgroundStyle={{
				type: "gradient",
				value: `linear-gradient(23.98deg, ${colors.primary[600]} 1.29%, ${colors.primary[500]} 
					25.73%, ${colors.primary[400]} 60.76%, 
					${colors.primary[300]} 94.15%, 
					${colors.primary[200]} 112.67%, 
					${colors.primary[100]} 147.84%)`,
			}}
		>
			<Login
				heading="Wayside Fence"
				strategy={"admin"} // Pass strategy prop
				bgColor="white"
				// bottomButton={
				// 	<Button variant="ghost" fontWeight="bold" mb="2">
				// 		Forgot Password
				// 	</Button>
				// }
				style={{
					boxShadow: "0px 2px 2px rgba(255, 255, 255, 0.75)",
				}}
			/>
		</PageBackgroundWrapper>
	);
};

export default AdminLogin;
