import React from "react";
import { Box, AbsoluteCenter } from "@chakra-ui/react";
import { BackButton } from "components";

interface PageBackgroundWrapperProps {
	children: React.ReactNode;
	backgroundStyle?: {
		type: "gradient" | "image";
		value: string;
	};
	height?: string;
}

const PageBackgroundWrapper: React.FC<PageBackgroundWrapperProps> = ({
	children,
	backgroundStyle,
	height = "100vh",
}) => {
	const bgStyle =
		backgroundStyle?.type === "image"
			? {
					bgImage: `url(${backgroundStyle.value})`,
					bgSize: "cover",
					bgRepeat: "no-repeat",
					bgPosition: "center",
			  }
			: { bgGradient: backgroundStyle?.value };

	return (
		<Box {...bgStyle} width="100%" height={height}>
			<BackButton
				position="absolute"
				variant="primary"
				top="4"
				left="4"
				m="0"
				colorScheme="white"
				size="lg"
				boxShadow="xl"
				borderWidth="1px"
				borderColor="whiteAlpha.600"
			/>
			<AbsoluteCenter>{children}</AbsoluteCenter>
		</Box>
	);
};

export default PageBackgroundWrapper;
