import React, { ReactNode } from "react";
import { Alert, AlertIcon, AlertDescription } from "@chakra-ui/react";
import { Loader } from "components";

const DEFAULT_ERROR_MESSAGE =
	"An unexpected error has occurred. Please try again later";

interface LoadingErrorDataWrapperProps {
	loading: boolean;
	error: string | null | undefined;
	data: any; // Specify a more concrete type based on your use case
	children: ReactNode;
}

const LoadingErrorDataWrapper: React.FC<LoadingErrorDataWrapperProps> = ({
	loading,
	error,
	data,
	children,
}) => {
	if (loading) {
		return <Loader />;
	}

	if (error) {
		const displayableError =
			typeof error === "string" && error.trim() !== ""
				? error
				: DEFAULT_ERROR_MESSAGE;
		return (
			<Alert status="error">
				<AlertIcon />
				<AlertDescription>{displayableError}</AlertDescription>
			</Alert>
		);
	}

	if (data) {
		return <>{children}</>;
	}

	// If neither loading, error, or data, return null or some default state
	return null;
};

export default LoadingErrorDataWrapper;
