import React from "react";

import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";

const BACKEND_LOCATION = process.env.REACT_APP_BACKEND_LOCATION;
const cache = new InMemoryCache({
	dataIdFromObject: (object) => {
		if (object._id) return `${object.__typename}:${object._id}`;
		else return `${object.__typename}:${object.id}`;
	},
	typePolicies: {
		PaginatedResources: {
			fields: {
				items: {
					merge(existing, incoming) {
						if (existing) return [...existing, ...incoming];
					},
				},
			},
		},
		PaginatedEmployees: {
			fields: {
				items: {
					merge(existing, incoming) {
						if (existing) return [...existing, ...incoming];
					},
				},
			},
		},
		PaginatedDealers: {
			fields: {
				items: {
					merge(existing, incoming) {
						if (existing) return [...existing, ...incoming];
					},
				},
			},
		},
		PaginatedPositions: {
			fields: {
				items: {
					merge(existing, incoming) {
						if (existing) return [...existing, ...incoming];
					},
				},
			},
		},
		PaginatedPositionApplications: {
			fields: {
				items: {
					merge(existing, incoming) {
						if (existing) return [...existing, ...incoming];
					},
				},
			},
		},
		PaginatedDealerApplications: {
			fields: {
				items: {
					merge(existing, incoming) {
						if (existing) return [...existing, ...incoming];
					},
				},
			},
		},
		PaginatedContent: {
			fields: {
				items: {
					merge(existing, incoming) {
						if (existing) return [...existing, ...incoming];
					},
				},
			},
		},
		PaginatedFAQs: {
			fields: {
				items: {
					merge(existing, incoming) {
						if (existing) return [...existing, ...incoming];
					},
				},
			},
		},
		PaginatedPartners: {
			fields: {
				items: {
					merge(existing, incoming) {
						if (existing) return [...existing, ...incoming];
					},
				},
			},
		},
		PaginatedGalleryImages: {
			fields: {
				items: {
					merge(existing, incoming) {
						if (existing) return [...existing, ...incoming];
					},
				},
			},
		},
		PaginatedCustomWorkGalleryImages: {
			fields: {
				items: {
					merge(existing, incoming) {
						if (existing) return [...existing, ...incoming];
					},
				},
			},
		},
		PaginatedCatalogs: {
			fields: {
				items: {
					merge(existing, incoming) {
						if (existing) return [...existing, ...incoming];
					},
				},
			},
		},
		EcwidProduct: {
			keyFields: ["id"],
		},
		EcwidProductImage: {
			keyFields: ["id"],
		},
		EcwidVariation: {
			keyFields: ["id"],
		},
		EcwidOriginalImage: {
			keyFields: ["url"],
		},
		EcwidGalleryImage: {
			keyFields: ["id"],
		},
		EcwidMedia: {
			fields: {
				images: {
					merge(existing, incoming) {
						if (existing) return [...existing, ...incoming];
					},
				},
			},
		},
		PromotionProduct: {
			keyFields: ["ecwidID"],
		},
	},
});
const client = new ApolloClient({
	uri: BACKEND_LOCATION,
	credentials: "include",
	cache,
});

interface ApolloProps {
	children: React.ReactNode;
}

const Apollo = ({ children }: ApolloProps) => {
	return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default Apollo;
