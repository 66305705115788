import { gql } from "@apollo/client";

const getProducts = gql`
	query getProducts($productIds: [Int!]) {
		getProducts(productIds: $productIds) {
			id
			sku
			name
			price
			defaultDisplayedPrice
			enabled
			originalImage {
				url
				width
				height
			}
			description
			galleryImages {
				id
				url
				thumbnail
				originalImageUrl
				imageUrl
				hdThumbnailUrl
				thumbnailUrl
				smallThumbnailUrl
				width
				height
				orderBy
			}
			media {
				images {
					id
					alt {
						main
					}
					orderBy
					isMain
					image160pxUrl
					image400pxUrl
					image800pxUrl
					image1500pxUrl
					imageOriginalUrl
				}
			}
			combinations {
				id
				combinationNumber
				options {
					name
					value
				}
				sku
				thumbnailUrl
				imageUrl
				smallThumbnailUrl
				hdThumbnailUrl
				originalImageUrl
				quantity
				price
				weight
			}
		}
	}
`;

const getProductCollections = gql`
	query getProductCollections($retrievalInput: RetrievalInput) {
		getProductCollections(retrievalInput: $retrievalInput) {
			items {
				_id
				name
				description
				image
				ecwidIDs
			}
			totalCount
			hasNextPage
			hasPreviousPage
		}
	}
`;

export { getProducts, getProductCollections };
