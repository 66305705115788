import { useMutation } from "@apollo/client";
import { deletePartner as DELETE_PARTNER } from "./mutations";
interface DeletePartnerData {
	deletePartner: boolean;
}

const useDeletePartner = () => {
	const [deletePartnerMutation, { loading, error }] =
		useMutation<DeletePartnerData>(DELETE_PARTNER);
	const deletePartner = async (partnerID: string) => {
		try {
			const { data } = await deletePartnerMutation({
				variables: {
					partnerID,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.deletePartner };
		} catch (error: any) {
			return { error };
		}
	};
	return { deletePartner, loading, error };
};
export default useDeletePartner;
