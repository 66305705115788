import { useMutation } from "@apollo/client";
import { changePassword as CHANGE_PASSWORD } from "./mutations";

interface ChangePasswordData {
	changePassword: boolean;
}

const useChangePassword = () => {
	const [changePasswordMutation, { loading, error }] =
		useMutation<ChangePasswordData>(CHANGE_PASSWORD);
	const changePassword = async (
		email: string,
		password: string,
		accountType: string,
	) => {
		try {
			const { data } = await changePasswordMutation({
				variables: {
					email,
					password,
					accountType,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.changePassword };
		} catch (error: any) {
			return { error };
		}
	};
	return { changePassword, loading, error };
};

export default useChangePassword;
