/**
 * @file fonts.ts
 *
 * @description Fonts configuration file
 */
import "@fontsource/montserrat";

const fonts = {
	heading: `'Montserrat', sans-serif`,
	body: `'Montserrat', sans-serif`,
	mono: `'Montserrat', sans-serif`,
};

export default fonts;
