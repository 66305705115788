import { useQuery } from "@apollo/client";
import { authEmployee as AUTH_EMPLOYEE } from "./queries";
import { Employee } from "../../types";

interface AuthEmployeeData {
	authEmployee: Employee;
}

const useAuthEmployee = () => {
	const { loading, error, data, refetch, networkStatus } =
		useQuery<AuthEmployeeData>(AUTH_EMPLOYEE);
	const processedData = data?.authEmployee;
	return { loading, error, data: processedData, refetch, networkStatus };
};
export default useAuthEmployee;
