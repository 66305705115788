import { useState } from "react";
import { BaseDelete } from "cud/delete"; // Import the BaseDelete class
import { useToast } from "@chakra-ui/react";
import { useDeletePartner } from "services";
import { Partner } from "services";

const useDeletePartnerForm = (
	partner: Partner,
	refetch: () => Promise<void>,
) => {
	const [loading, setLoading] = useState(false);
	const toast = useToast();
	const { deletePartner } = useDeletePartner();

	const baseDelete = new BaseDelete<Partner>(
		partner,
		"Partner",
		toast,
		deletePartner,
		refetch,
	);

	// Define the state variables and functions
	const handleDelete = async () => {
		setLoading(true);
		await baseDelete.handleDelete();
		setLoading(false);
	};

	// Return the state variables and functions
	return {
		handleDelete,
		loading,
	};
};

export default useDeletePartnerForm;
