import { useMutation } from "@apollo/client";
import { logout as LOGOUT } from "./mutations";

interface LogoutData {
	logout: boolean;
}

const useLogout = () => {
	const [logoutMutation, { loading, error }] = useMutation<LogoutData>(LOGOUT);
	const logout = async (accountType: string) => {
		try {
			const { data } = await logoutMutation({
				variables: {
					accountType,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.logout };
		} catch (error: any) {
			return { error };
		}
	};
	return { logout, loading, error };
};

export default useLogout;
