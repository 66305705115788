import React, { useContext } from "react";
import { PageChangeContext } from "contexts";
import { Button, Icon } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

interface SidebarButtonProps {
	label: string;
	icon?: React.ElementType;
	onClick?: (label: string) => void;
	rightIcon?: React.ElementType;
	route?: string;
}

/**
 * Renders a button for the sidebar with an optional icon and label.
 *
 * @param {string} label - The label text for the button.
 * @param {React.ElementType} icon - The icon component to be displayed on the left side of the button.
 * @param {() => void} onClick - The function to be called when the button is clicked.
 * @returns {React.ReactElement} The rendered button component.
 */
const SidebarButton: React.FC<SidebarButtonProps> = ({
	label,
	icon,
	onClick,
	rightIcon,
	route,
}) => {
	const { handlePageChange } = useContext(PageChangeContext);
	const location = useLocation();

	const handleClick = () => {
		onClick?.(label);
		if (!onClick) {
			const lowercasedLabel = label.toLowerCase();
			handlePageChange(lowercasedLabel);
		}
	};

	const isActive = location.pathname.includes(
		route ? route : label.toLowerCase(),
	);

	return (
		<Button
			variant="ghost"
			w="auto"
			h="auto"
			py="0.5rem"
			justifyContent="center"
			leftIcon={icon && <Icon as={icon} />}
			onClick={handleClick}
			rightIcon={rightIcon && <Icon as={rightIcon} />}
			color="white"
			fontSize={{ base: "2xl", md: "md" }}
			bgColor={isActive ? "primary.800" : "transparent"}
			aria-label={`Navigate to ${label}`}
		>
			{label}
		</Button>
	);
};

export default SidebarButton;
