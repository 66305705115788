import React from "react";
import { Stack, Input, Button } from "@chakra-ui/react";

import { colors } from "styles";

interface SearchInputProps {
	query: string;
	onChange: (value: string) => void;
}

const SearchInput: React.FC<SearchInputProps> = ({ query, onChange }) => {
	const handleButtonClick = () => {
		// Trigger onChange with the input value here
		const input = document.getElementById(
			"global-search-input",
		) as HTMLInputElement;
		onChange(input.value);
	};

	const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter") {
			// Trigger onChange with the input value here
			const input = document.getElementById(
				"global-search-input",
			) as HTMLInputElement;
			onChange(input.value);
		}
	};

	return (
		<Stack direction="row">
			<Input
				id="global-search-input"
				color="gray.400"
				borderWidth="1px"
				borderColor="#ECECEC"
				boxShadow="md"
				borderRadius="md"
				type="text"
				placeholder="Search..."
				aria-label="Search"
				minW="75px"
				maxW="175px"
				fontSize="sm"
				_focus={{
					borderColor: colors.primary[500],
				}}
				defaultValue={query}
				onKeyDown={handleKeyPress}
			/>
			<Button onClick={handleButtonClick}>Search</Button>
		</Stack>
	);
};

export default SearchInput;
