import { Box, BoxProps } from "@chakra-ui/react";

interface OverlayBoxProps extends BoxProps {
	color?: string;
}

export const OverlayBox: React.FC<OverlayBoxProps> = ({
	color = "blackAlpha.200",
	...props
}) => {
	return (
		<Box
			position="absolute"
			top={0}
			right={0}
			bottom={0}
			left={0}
			bg={color}
			{...props}
		/>
	);
};

export default OverlayBox;
