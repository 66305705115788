import React from "react";
import { RoutesWrapper } from "wrappers";
import { Route } from "react-router-dom";
import { EmployeeProvider } from "contexts";
import { EmployeeLogin } from "pages";
import AuthenticatedEmployeeRouter from "./AuthenticatedEmployeeRouter";
/**
 * Renders the EmployeeRouter component.
 *
 * @returns {React.ReactElement} The rendered EmployeeRouter component.
 */
const EmployeeRouter: React.FC = () => {
	return (
		<EmployeeProvider>
			<RoutesWrapper>
				<Route path="/login/*" element={<EmployeeLogin />} />
				<Route path="/*" element={<AuthenticatedEmployeeRouter />} />
			</RoutesWrapper>
		</EmployeeProvider>
	);
};

export default EmployeeRouter;
