/**
 * @module BaseCard.tsx
 * @category Common
 */
import React, { useState } from "react";
import {
	Box,
	BoxProps,
	Button,
	SlideFade,
	useMediaQuery,
} from "@chakra-ui/react";
import { colors } from "styles";

/**
 * Interface defining the props for the BaseCard component.
 * @interface BaseCardProps
 * @extends BoxProps
 * @property {string} [hoverText] - Optional prop for custom hover text
 * @property {string} [textColor] - Optional prop for custom text color
 * @property {React.ReactNode} children - The children to be rendered inside the BaseCard
 */
interface BaseCardProps extends BoxProps {
	hoverText?: string; // Optional prop for custom hover text
	textColor?: string; // Optional prop for custom text color
}

/**
 * @component BaseCard
 * @description A component that displays a card with a hover effect.
 * @param {BaseCardProps} props - The props for the BaseCard component.
 * @returns {React.ReactElement} - A React component of the BaseCard.
 */
const BaseCard: React.FC<BaseCardProps> = ({
	children,
	hoverText = "Click Me",
	textColor = "white",
	...props
}) => {
	const [isHovered, setIsHovered] = useState(false);
	const [isMobile] = useMediaQuery("(max-width: 600px)");

	return (
		<Box
			position="relative"
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			p={4}
			transition="all 0.4s ease"
			_hover={{ transform: "scale(1.05)" }}
			{...props} // Applying all passed BoxProps for flexibility
		>
			{children}
			<SlideFade
				in={isHovered || isMobile}
				style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
			>
				<Box
					position="absolute"
					top="0"
					left="0"
					right="0"
					bottom="0"
					borderRadius="md"
					display="flex"
					justifyContent="center"
					alignItems="center"
					p={4}
				>
					<Button
						variant="primary"
						size="lg"
						fontSize="md"
						color={textColor}
						textShadow="1px 1px 2px rgba(0, 0, 0, 0.55)"
						boxShadow="dark-lg"
						borderWidth="1px"
						borderColor={colors.primary[100]}
					>
						{hoverText}
					</Button>
				</Box>
			</SlideFade>
		</Box>
	);
};

export default BaseCard;
