import React from "react";
import { Flex } from "@chakra-ui/react";
import { SidebarItem } from "../../features/sidebar/types";
import { SidebarButton, SidebarDropdownButton } from "components/common";
import { colors } from "styles";

interface SidebarContentProps {
	items: SidebarItem[];
}

const SidebarContent: React.FC<SidebarContentProps> = ({ items }) => {
	return (
		<Flex
			as="nav"
			direction="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			w="100%"
			h="auto"
			px={4}
			py={8}
			gap={6}
			overflowY="auto"
			overflowX="hidden"
			css={{
				"&::-webkit-scrollbar": {
					width: "1px",
				},
				"&::-webkit-scrollbar-track": {
					backgroundColor: "white",
				},
				"&::-webkit-scrollbar-thumb": {
					backgroundColor: colors.primary[50],
					borderRadius: "4px",
				},
				"&::-webkit-scrollbar-thumb:hover": {
					backgroundColor: colors.primary[200],
				},
			}}
		>
			{items.map(({ label, icon, onClick, items, route }, index) =>
				items ? (
					<SidebarDropdownButton
						key={`sidebar-dropdown-${label}-${index}`}
						label={label}
						icon={icon && (icon as any)}
						items={items}
						onClick={onClick}
					/>
				) : (
					<SidebarButton
						key={`sidebar-${label}-${index}`}
						label={label}
						icon={icon}
						onClick={onClick && (() => onClick(label))}
						route={route}
					/>
				),
			)}
		</Flex>
	);
};

export default SidebarContent;
