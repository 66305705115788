/**
 * @module useGetReviews.ts
 * @description This file contains the logic for getting reviews from the database.
 */
import { useQuery } from "@apollo/client";
import { getReviews as GET_REVIEWS } from "./queries";
import { RetrievalInput, Review, PaginatedResult } from "../../types";

interface GetReviewsData {
	getReviews: PaginatedResult<Review>;
}

/**
 * This is the hook that is used to get reviews from the database.
 * @returns {Object} The getReviews function, loading state, and error state.
 */
const useGetReviews = (retrievalInput?: RetrievalInput) => {
	const variables = retrievalInput ? { retrievalInput } : {};
	const { loading, error, data, refetch, networkStatus } =
		useQuery<GetReviewsData>(GET_REVIEWS, { variables: variables });
	const processedData = data?.getReviews;
	return { loading, error, data: processedData, refetch, networkStatus };
};
export default useGetReviews;
