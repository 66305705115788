import { useChangePassword } from "services";
import { FieldDefinition } from "components";

import { useForm } from "hooks/forms";
import { validatePassword } from "utils";

const useChangePasswordForm = ({
	refetch,
	email,
	accountType,
}: {
	refetch: () => void;
	email: string;
	accountType: string;
}) => {
	const initialFormState = {
		password: "",
		confirmPassword: "",
	};

	const {
		formState,
		loading,
		setLoading,
		handleInputChange,
		resetFormState,
		toast,
	} = useForm({
		initialState: initialFormState,
	});

	const { changePassword } = useChangePassword();

	const handleSubmit = async () => {
		setLoading(true);
		const { error, data } = await changePassword(
			email,
			formState.password,
			accountType,
		);
		if (data) {
			resetFormState();
			toast({
				title: "Password Changed",
				description: "The password has been successfully changed.",
				status: "success",
			});
			refetch();
		} else if (error) {
			toast({
				title: "Error",
				description: error.message,
				status: "error",
			});
		} else {
			toast({
				title: "Error",
				description: "An unknown error occurred.",
				status: "error",
			});
		}
		setLoading(false);
	};

	const validatePasswordsMatch = () => {
		if (formState.password !== formState.confirmPassword) {
			return "Your passwords do not match.";
		}
		return true;
	};

	const fields: FieldDefinition[] = [
		{
			name: "password",
			label: "Password",
			type: "password",
			onChange: handleInputChange,
			placeholder: "Enter your new password",
			required: true,
			variant: "base",
			size: "lg",
			width: "100%",
			value: formState.password,
			validate: validatePassword,
		},
		{
			name: "confirmPassword",
			label: "Confirm Password",
			type: "password",
			onChange: handleInputChange,
			placeholder: "Confirm your new password",
			required: true,
			variant: "base",
			size: "lg",
			width: "100%",
			value: formState.confirmPassword,
			validate: validatePasswordsMatch,
		},
	];

	return {
		fields,
		handleSubmit,
		loading,
	};
};

export default useChangePasswordForm;
