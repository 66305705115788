import React from "react";
import {
	IconButton,
	useBreakpointValue,
	Stack,
	VStack,
	Box,
	Wrap,
	WrapItem,
	Button,
} from "@chakra-ui/react";
import { colors } from "styles";
import { PhoneIcon, InfoIcon } from "@chakra-ui/icons";
import { IoIosPin, IoIosExpand } from "react-icons/io";
import { InformationStack, ButtonStack } from "components";
import { TooltipWrapper } from "wrappers";
import { openGoogleMapsDirections } from "utils";

export type LocationProps = {
	name: string;
	address: string;
	phone: string;
	email: string;
	description: string;
	hours: string[];
	iframeSrc: string;
	iframeWidth: string;
	iframeHeight: string;
};

const LocationInfo: React.FC<LocationProps> = ({
	name,
	address,
	phone,
	email,
	hours,
	iframeSrc,
}) => {
	const isMobile = useBreakpointValue({ base: true, lg: false });
	const iFrameMinHeight = isMobile ? "100%" : "415px";

	return (
		<Stack
			direction="column"
			spacing={4}
			align="center"
			w="100%"
			h="100%"
			mb={{ base: "0", md: "6" }}
		>
			<VStack spacing={4} align="stretch" w="full" mt={4}>
				<Box
					width="100%"
					mb={"10"}
					mt={"6"}
					maxHeight={iFrameMinHeight}
					position={"relative"}
				>
					<iframe
						src={iframeSrc}
						title={`Map for ${name}`}
						allowFullScreen
						loading="lazy"
						style={{
							borderTop: "1px solid",
							borderBottom: "1px solid",
							width: "100%",
							height: "100%",
							minHeight: iFrameMinHeight,
							borderColor: colors.primary[100],
							boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.35)",
						}}
					/>
					<TooltipWrapper
						openDelay={5}
						label="Expand Map"
						placement="auto"
						bg="primary.500"
					>
						<IconButton
							icon={<IoIosExpand size={"30px"} />}
							aria-label="expand map"
							position={"absolute"}
							left="50%"
							bottom="1rem"
							variant="outline"
							onClick={() => {
								const iframe = document.querySelector("iframe");
								if (iframe && iframe.requestFullscreen) {
									iframe.requestFullscreen();
								}
							}}
							transform={"translateX(-50%,-50%)"}
							zIndex={1}
							_hover={{
								cursor: "pointer",
								color: "white",
								bgColor: colors.primary[500],
								transform: "scale(1.05)",
							}}
						/>
					</TooltipWrapper>
				</Box>
				<Wrap
					display="stretch"
					justify={"space-evenly"}
					align="flex-start"
					spacing={8}
					w="100%"
					h="100%"
					mx={"auto"}
					px="2rem"
				>
					<WrapItem>
						<InformationStack
							icon={IoIosPin}
							title={"ADDRESS"}
							information={[address]}
						/>
					</WrapItem>
					<WrapItem>
						<InformationStack
							icon={PhoneIcon}
							title={"CONTACT INFORMATION"}
							information={[`Phone: ${phone}`, `Email: ${email}`]}
							withIcon={false}
						/>
					</WrapItem>
					<WrapItem>
						<InformationStack
							icon={InfoIcon}
							title={"HOURS OF OPERATION"}
							information={hours}
						/>
					</WrapItem>
					<WrapItem>
						<ButtonStack>
							<Button
								mt={6}
								variant="base"
								size="wide"
								fontWeight="semi"
								aria-label="Get Directions"
								onClick={() => openGoogleMapsDirections(address)}
							>
								Directions
							</Button>
						</ButtonStack>
					</WrapItem>
				</Wrap>
			</VStack>
		</Stack>
	);
};

export default LocationInfo;
