import React, { useState, useEffect, useRef } from "react";
import { Box, Input } from "@chakra-ui/react";
import EcwidProductItem from "./EcwidProductItem"; // Ensure this import path matches your file structure
import { EcwidProduct, useGetProducts } from "services";
import { Loader, ErrorMessage } from "components";
import { SimpleGrid } from "@chakra-ui/react";

interface ProductMultiSelectProps {
	selectedIds: number[];
	onSelectionChange: (selectedIds: number[]) => void;
	name: string; // Add a name prop for the input field identification
	required?: boolean; // Optional prop to determine if the selection is required
}

const ProductMultiSelect: React.FC<ProductMultiSelectProps> = ({
	selectedIds,
	onSelectionChange,
	name,
	required = false,
}) => {
	const { loading, error, data: products } = useGetProducts();

	const [searchTerm, setSearchTerm] = useState("");
	const [filteredProducts, setFilteredProducts] = useState<EcwidProduct[]>([]);

	// Reference for the hidden input
	const hiddenInputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		const lowercasedSearchTerm = searchTerm.toLowerCase();
		const filtered = products
			? products.filter((product) =>
					product.name.toLowerCase().includes(lowercasedSearchTerm),
			  )
			: [];
		setFilteredProducts(filtered);
	}, [searchTerm, products]);

	const handleSelectToggle = (id: number) => {
		if (selectedIds.includes(id)) {
			onSelectionChange(selectedIds.filter((selectedId) => selectedId !== id));
		} else {
			onSelectionChange([...selectedIds, id]);
		}
	};

	return (
		<Box>
			<input
				type="hidden"
				ref={hiddenInputRef}
				name={name}
				value={selectedIds.join(",")}
				required={required}
				onChange={() => {}} // This is just to satisfy React's controlled input requirement
			/>
			<Input
				placeholder="Search products..."
				value={searchTerm}
				onChange={(e) => setSearchTerm(e.target.value)}
				mb={4}
				isRequired={false}
			/>
			<SimpleGrid
				columns={{ base: 2, md: 3 }}
				spacing={4}
				overflowY={"auto"}
				maxH={"400px"}
				p={2}
			>
				{loading ? (
					<Loader />
				) : error ? (
					<ErrorMessage title="Error loading products" />
				) : (
					filteredProducts.map((product) => (
						<Box
							key={product.id}
							onClick={() => handleSelectToggle(product.id)}
							cursor="pointer"
							borderWidth="1px"
							borderRadius="lg"
							p={4}
							_hover={{ bg: "gray.50" }}
							bgColor={
								selectedIds.includes(product.id) ? "primary.500" : "white"
							}
						>
							<EcwidProductItem product={product} />
						</Box>
					))
				)}
			</SimpleGrid>
		</Box>
	);
};

export default ProductMultiSelect;
