/**
 * @module Store.tsx
 * @description Store page component that renders the Ecwid store
 * @version 1.0.0
 */
import React from "react";
import { Flex } from "@chakra-ui/react";
import { EcwidStore } from "page-components/Store";

/**
 * @function Store
 * @returns {ReactElement}
 * @description React component that renders the Store page component
 */
const Store: React.FC = () => {
	return (
		<Flex zIndex={1}>
			<EcwidStore />
		</Flex>
	);
};

export default Store;
