/**
 * @module useCustomInquiryForm
 */
import { useState, useContext } from "react";
import { PageChangeContext } from "contexts";
import { useToast } from "@chakra-ui/react";
import { FieldDefinition } from "components";
import { FileInput } from "components/common/inputs";

import { EmailInput, PhoneNumberInput } from "components/common/inputs";
import { validateEmail, validatePhoneNumber } from "utils/validators";

import { useSubmitCustomInquiryForm } from "services";
import useUploadFile from "../useUploadFile";
import { CUSTOM_INQUIRY_PATH } from "consts";

/**
 * @typedef CustomInquiryForm
 * @property {Function} handleFieldChange - The function to handle field changes
 * @property {Function} onSubmit - The function to handle form submission
 * @property {FieldDefinition[][]} steps - The form steps
 * @constant {array} step 0 - Account and Contact Information
 * @constant {array} step 1 - Project Details
 * @constant {array} step 2 - Fence & Gate Details
 * @constant {array} step 3 - File Upload
 */
const useCustomInquiryForm = () => {
	const [formData, setFormData] = useState({
		// Step 0
		name: "",
		email: "",
		phoneNumber: "",
		accountType: "",

		// Step 1
		materialType: "",
		fenceStyle: "",
		fenceHeight: "",
		measurements: "",
		referenceFenceDetails: "",

		// Step 2
		gateIncluded: "No",
		gateSize: "",
		gateUtility: "",
		gateType: "",
		gateStyle: "",
		gateDetails: "",
		// Step 3
		referenceProjectDetails: "",
	});
	const toast = useToast();

	const [loading, setLoading] = useState(false);
	const { uploadFile } = useUploadFile();
	const { submitCustomInquiryForm } = useSubmitCustomInquiryForm();
	const { handlePageChange } = useContext(PageChangeContext);

	// State to hold the source files
	const [srcFiles, setSrcFiles] = useState<File[]>([]);

	// Function to handle field changes
	const handleFieldChange = (event: {
		target: { name: any; value: any; type: any };
	}) => {
		const { name, value, type } = event.target;
		if (type !== "file") {
			// Update formData for non-file inputs
			setFormData((prevFormData) => ({
				...prevFormData,
				[name]: value,
			}));
		}
	};

	// Function to handle file changes
	const handleUpdateSrcFile = (files: File[]) => {
		setSrcFiles(files);
	};

	const resetStates = () => {
		setLoading(false);
		setFormData({
			// Step 0
			name: "",
			email: "",
			phoneNumber: "",
			accountType: "",

			// Step 1
			materialType: "",
			fenceStyle: "",
			fenceHeight: "",
			measurements: "",
			referenceFenceDetails: "",

			// Step 2
			gateIncluded: "No",
			gateSize: "",
			gateUtility: "",
			gateType: "",
			gateStyle: "",
			gateDetails: "",
			// Step 3
			referenceProjectDetails: "",
		});
		setSrcFiles([]);
	};

	// Function to handle form submission
	const onSubmit = async () => {
		setLoading(true);
		const fileUrls = [];
		try {
			for (let i = 0; i < srcFiles.length; i++) {
				const file = srcFiles[i];
				const url = await uploadFile(file, CUSTOM_INQUIRY_PATH);
				fileUrls.push(url);
			}
		} catch (error) {
			toast({
				title: "Error Uploading Files",
				description: "Please try again later.",
				status: "error",
				position: "bottom-left",
				duration: 7500,
				isClosable: true,
			});
			setLoading(false);
			return;
		}

		const input = {
			...formData,
			files: fileUrls,
		};

		const { data, error } = await submitCustomInquiryForm(input);
		if (error) {
			toast({
				title: "Error Submitting Form",
				description: "Please try again later.",
				status: "error",
				position: "bottom-left",
				duration: 7500,
				isClosable: true,
			});
			setLoading(false);
			return;
		} else if (data) {
			toast({
				title: "Custom Inquiry Submitted!",
				description:
					"Thank you for your inquiry! A representative will be in touch soon.",
				status: "success",
				position: "bottom-left",
				duration: 7500,
				isClosable: true,
			});
			resetStates();
			handlePageChange("home");
		} else {
			toast({
				title: "Error Submitting Form",
				description: "Please try again later.",
				status: "error",
				position: "bottom-left",
				duration: 7500,
				isClosable: true,
			});
			setLoading(false);
			return;
		}
	};

	/**
	 * @array steps for `useCustomInquiryForm`
	 * @constant {array} steps - The form steps
	 */
	const steps: FieldDefinition[][] = [
		// Step 0: Account and Contact Information
		[
			{
				type: "text",
				name: "name",
				label: "Name",
				value: formData.name,
				placeholder: "John McFence",
				required: true,
				variant: "base",
				size: "lg",
				width: "100%",
				onChange: handleFieldChange,
			},
			{
				type: "custom",
				name: "email",
				label: "Email",
				value: formData.email,
				customComponent: EmailInput,
				placeholder: "Email",
				required: true,
				variant: "base",
				size: "lg",
				width: "100%",
				onChange: handleFieldChange,
				validate: validateEmail,
			},
			{
				type: "custom",
				name: "phoneNumber",
				label: "Phone Number",
				value: formData.phoneNumber,
				customComponent: PhoneNumberInput,
				placeholder: "Phone Number",
				required: true,
				variant: "base",
				size: "lg",
				width: "100%",
				onChange: handleFieldChange,
				validate: validatePhoneNumber,
			},
			{
				type: "select",
				name: "accountType",
				label: "Do you have an account with us?",
				placeholder: "Select your account status",
				options: ["Yes, I'm an existing customer.", "No, I'm a new customer."],
				value: formData.accountType,
				required: true,
				variant: "base",
				size: "lg",
				onChange: handleFieldChange,
			},
		],
		// Step 1: Project & Fence Details
		[
			{
				type: "select",
				name: "materialType",
				label: "Material Type",
				options: [
					"Aluminum",
					"Azek",
					"cPVC",
					"Chain Link",
					"Vinyl",
					"Wood",
					"Other",
				],
				placeholder: "Select Material Type",
				value: formData.materialType,
				required: true,
				variant: "base",
				size: "lg",
				width: "100%",
				onChange: handleFieldChange,
			},
			{
				type: "select",
				name: "fenceStyle",
				label: "Fence Style",
				options: ["Privacy", "Semi-Privacy", "Decorative", "Security", "Other"],
				placeholder: "Select Fence Style",
				value: formData.fenceStyle,
				required: true,
				variant: "base",
				size: "lg",
				width: "100%",
				onChange: handleFieldChange,
			},
			{
				type: "select",
				name: "fenceHeight",
				label: "Approx. Height",
				placeholder: "Select Height",
				options: ["4ft", "4.5ft", "5ft", "5.5ft", "6ft", "6.5ft", "Other"],
				value: formData.fenceHeight,
				required: true,
				variant: "base",
				size: "lg",
				width: "100%",
				onChange: handleFieldChange,
			},
			{
				type: "text",
				name: "measurements",
				label: "Measurements",
				value: formData.measurements,
				placeholder: "e.g., 150ft linear",
				required: true,
				variant: "base",
				size: "lg",
				width: "100%",
				onChange: handleFieldChange,
			},
			// Reference - Fence Details
			{
				type: "textarea",
				name: "referenceFenceDetails",
				label: "Reference Details",
				value: formData.referenceFenceDetails,
				placeholder: "e.g., Fence color, material, etc.",
				required: false,
				variant: "base",
				size: "lg",
				minWidth: "100%",
				onChange: handleFieldChange,
			},
		],
		// Step 2: Gate Details
		[
			{
				type: "select",
				name: "gateIncluded",
				label: "Do you need a gate?",
				options: ["Yes", "No"],
				value: formData.gateIncluded,
				required: true,
				variant: "base",
				size: "lg",
				width: "100%",
				minWidth: "100%",
				onChange: handleFieldChange,
			},
			// Gate Utility
			{
				type: "select",
				name: "gateType",
				label: "Gate Type",
				options: [
					"Aluminum",
					"Azek",
					"cPVC",
					"Chain Link",
					"Vinyl",
					"Wood",
					"Other",
				],
				placeholder: "Select Gate Type",
				value: formData.gateType,
				required: formData.gateIncluded === "Yes",
				variant: "base",
				size: "lg",
				width: "100%",
				onChange: handleFieldChange,
				isConditional: true,
				isVisible: formData.gateIncluded === "Yes",
			},
			// Gate Type
			{
				type: "select",
				name: "gateUtility",
				label: "Utility Type",
				options: ["Single", "Double", "Sliding", "Rolling", "Other"],
				placeholder: "Select Gate Utility Type",
				value: formData.gateUtility,
				required: formData.gateIncluded === "Yes",
				variant: "base",
				size: "lg",
				width: "100%",
				onChange: handleFieldChange,
				isConditional: true,
				isVisible: formData.gateIncluded === "Yes",
			},
			// Gate Style (dependent on Gate Type)
			{
				type: "select",
				name: "gateStyle",
				label: "Gate Style",
				options: ["Flat Top", "Arched", "Scallop", "Other"],
				placeholder: "Select Gate Style",
				value: formData.gateStyle,
				required: formData.gateIncluded === "Yes",
				variant: "base",
				size: "lg",
				width: "100%",
				onChange: handleFieldChange,
				isConditional: true,
				isVisible: formData.gateIncluded === "Yes",
			},
			// Gate Width (dependent on Gate Type)
			{
				type: "text",
				name: "gateSize",
				label: "Gate Dimensions",
				value: formData.gateSize,
				placeholder: "Height x Width (e.g., 6ft x 4ft)",
				required: formData.gateIncluded === "Yes",
				variant: "base",
				size: "lg",
				width: "100%",
				onChange: handleFieldChange,
				isConditional: true,
				isVisible: formData.gateIncluded === "Yes",
			},
			// Reference - Gate Details
			{
				type: "textarea",
				name: "gateDetails",
				label: "Additional Gate Details",
				value: formData.gateDetails,
				placeholder: "e.g., Gate color, material, etc.",
				required: false,
				variant: "base",
				size: "lg",
				width: "100%",
				onChange: handleFieldChange,
				isConditional: true,
				isVisible: formData.gateIncluded === "Yes",
			},
		],
		// Step 3: File Upload
		[
			{
				type: "custom",
				name: "src",
				label: "Upload relevant photos / videos / documents",
				customComponent: FileInput,
				required: true,
				customComponentProps: {
					fileType: "generic",
					files: srcFiles,
					setFiles: handleUpdateSrcFile,
					allowMultiple: true,
				},
			},
			// Reference - Project Details
			{
				type: "textarea",
				name: "referenceProjectDetails",
				label: "Project Details",
				value: formData.referenceProjectDetails,
				placeholder: "e.g., Timeline, compliance requirements, etc.",
				required: false,
				variant: "base",
				size: "lg",
				width: "100%",
				minWidth: "100%",
				onChange: handleFieldChange,
			},
		],
	];

	return { loading, onSubmit, steps };
};

export default useCustomInquiryForm;
