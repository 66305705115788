import { useMutation } from "@apollo/client";
import { deleteResource as DELETE_RESOURCE } from "./mutations";
interface DeleteResourceData {
	deleteResource: boolean;
}

const useDeleteResource = () => {
	const [deleteResourceMutation, { loading, error }] =
		useMutation<DeleteResourceData>(DELETE_RESOURCE);
	const deleteResource = async (resourceID: string) => {
		try {
			const { data } = await deleteResourceMutation({
				variables: {
					resourceID,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.deleteResource };
		} catch (error: any) {
			return { error };
		}
	};
	return { deleteResource, loading, error };
};
export default useDeleteResource;
