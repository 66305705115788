import BaseCreate from "./BaseCreate";

import { CreateToastFnReturn } from "@chakra-ui/react";
import { ProductCollectionInput } from "services";
import { PRODUCT_COLLECTION_PATH } from "consts";

class ProductCollectionCreate extends BaseCreate<ProductCollectionInput> {
	protected file: File | null;
	protected uploadFile: (file: File, path: string) => Promise<string>;

	constructor(
		toast: CreateToastFnReturn,
		createFn: (
			input: ProductCollectionInput,
		) => Promise<{ data?: any; error?: any }>,
		refetch: () => Promise<void>,
		file: File | null,
		uploadFile: (file: File, path: string) => Promise<string>,
	) {
		super("Product Collection", toast, createFn, refetch);
		this.file = file;
		this.uploadFile = uploadFile;
	}

	public async handleCreate(input: ProductCollectionInput): Promise<boolean> {
		if (!this.createFn) throw new Error("Create function not defined");
		if (!this.uploadFile) throw new Error("Upload function not defined");
		let path = "";
		try {
			if (!this.file) throw new Error("Image is required");
			path = await this.uploadFile(this.file, PRODUCT_COLLECTION_PATH);
		} catch (err: any) {
			this.toast({
				title: "Error",
				description: err.message,
				status: "error",
				duration: 5000,
				isClosable: true,
			});
			return false;
		}

		const productCollectionInput: ProductCollectionInput = {
			...input,
			image: path,
		};

		return await super.handleCreate(productCollectionInput);
	}
}

export default ProductCollectionCreate;
