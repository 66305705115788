import { gql } from "@apollo/client";

const createPromotion = gql`
	mutation createPromotion($promotionInput: PromotionInput!) {
		createPromotion(promotionInput: $promotionInput)
	}
`;

const updatePromotion = gql`
	mutation updatePromotion(
		$promotionID: ID!
		$promotionInput: PromotionInput!
	) {
		updatePromotion(promotionID: $promotionID, promotionInput: $promotionInput)
	}
`;

const deletePromotion = gql`
	mutation deletePromotion($promotionID: ID!) {
		deletePromotion(promotionID: $promotionID)
	}
`;

const createResource = gql`
	mutation createResource($resourceInput: ResourceInput!) {
		createResource(resourceInput: $resourceInput)
	}
`;

const updateResource = gql`
	mutation updateResource($resourceID: ID!, $resourceInput: ResourceInput!) {
		updateResource(resourceID: $resourceID, resourceInput: $resourceInput)
	}
`;

const deleteResource = gql`
	mutation deleteResource($resourceID: ID!) {
		deleteResource(resourceID: $resourceID)
	}
`;

const createReview = gql`
	mutation createReview($reviewInput: ReviewInput!) {
		createReview(reviewInput: $reviewInput)
	}
`;

const updateReview = gql`
	mutation updateReview($reviewID: ID!, $reviewInput: ReviewInput!) {
		updateReview(reviewID: $reviewID, reviewInput: $reviewInput)
	}
`;

const deleteReview = gql`
	mutation deleteReview($reviewID: ID!) {
		deleteReview(reviewID: $reviewID)
	}
`;

const createFAQ = gql`
	mutation createFAQ($faqInput: FAQInput!) {
		createFAQ(faqInput: $faqInput)
	}
`;

const updateFAQ = gql`
	mutation updateFAQ($faqID: ID!, $faqInput: FAQInput!) {
		updateFAQ(faqID: $faqID, faqInput: $faqInput)
	}
`;

const deleteFAQ = gql`
	mutation deleteFAQ($faqID: ID!) {
		deleteFAQ(faqID: $faqID)
	}
`;

const createPartner = gql`
	mutation createPartner($partnerInput: PartnerInput!) {
		createPartner(partnerInput: $partnerInput)
	}
`;

const updatePartner = gql`
	mutation updatePartner($partnerID: ID!, $partnerInput: PartnerInput!) {
		updatePartner(partnerID: $partnerID, partnerInput: $partnerInput)
	}
`;

const deletePartner = gql`
	mutation deletePartner($partnerID: ID!) {
		deletePartner(partnerID: $partnerID)
	}
`;

const createGalleryImage = gql`
	mutation createGalleryImage($galleryImageInput: GalleryImageInput!) {
		createGalleryImage(galleryImageInput: $galleryImageInput)
	}
`;

const deleteGalleryImage = gql`
	mutation deleteGalleryImage($galleryImageID: ID!) {
		deleteGalleryImage(galleryImageID: $galleryImageID)
	}
`;

const createCustomWorkGalleryImage = gql`
	mutation createCustomWorkGalleryImage(
		$customWorkGalleryImageInput: CustomWorkGalleryImageInput!
	) {
		createCustomWorkGalleryImage(
			customWorkGalleryImageInput: $customWorkGalleryImageInput
		)
	}
`;

const deleteCustomWorkGalleryImage = gql`
	mutation deleteCustomWorkGalleryImage($customWorkGalleryImageID: ID!) {
		deleteCustomWorkGalleryImage(
			customWorkGalleryImageID: $customWorkGalleryImageID
		)
	}
`;

const createCatalog = gql`
	mutation createCatalog($catalogInput: CatalogInput!) {
		createCatalog(catalogInput: $catalogInput)
	}
`;

const updateCatalog = gql`
	mutation updateCatalog($catalogID: ID!, $catalogInput: CatalogInput!) {
		updateCatalog(catalogID: $catalogID, catalogInput: $catalogInput)
	}
`;

const deleteCatalog = gql`
	mutation deleteCatalog($catalogID: ID!) {
		deleteCatalog(catalogID: $catalogID)
	}
`;

const createProductCollection = gql`
	mutation createProductCollection(
		$productCollectionInput: ProductCollectionInput!
	) {
		createProductCollection(productCollectionInput: $productCollectionInput)
	}
`;

const updateProductCollection = gql`
	mutation updateProductCollection(
		$productCollectionID: ID!
		$productCollectionInput: ProductCollectionInput!
	) {
		updateProductCollection(
			productCollectionID: $productCollectionID
			productCollectionInput: $productCollectionInput
		)
	}
`;

const deleteProductCollection = gql`
	mutation deleteProductCollection($productCollectionID: ID!) {
		deleteProductCollection(productCollectionID: $productCollectionID)
	}
`;

export {
	createPromotion,
	updatePromotion,
	deletePromotion,
	createResource,
	updateResource,
	deleteResource,
	createReview,
	updateReview,
	deleteReview,
	createFAQ,
	updateFAQ,
	deleteFAQ,
	createPartner,
	updatePartner,
	deletePartner,
	createGalleryImage,
	deleteGalleryImage,
	createCustomWorkGalleryImage,
	deleteCustomWorkGalleryImage,
	createCatalog,
	updateCatalog,
	deleteCatalog,
	createProductCollection,
	updateProductCollection,
	deleteProductCollection,
};
