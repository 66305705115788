/**
 * @module useDeleteReviewForm.tsx
 * @description This file contains the hook for deleting a review.
 */
import { useState } from "react";
import { BaseDelete } from "cud/delete"; // Import the BaseDelete class
import { useToast } from "@chakra-ui/react";
import { useDeleteReview } from "services";
import { Review } from "services";

/**
 * This hook is used to delete a review.
 * @param {Review} review The review to delete.
 * @param {() => Promise<void>} refetch The function to refetch the reviews.
 * @returns {Object} An object containing the state variables and functions for deleting a review.
 */
const useDeleteReviewForm = (review: Review, refetch: () => Promise<void>) => {
	const [loading, setLoading] = useState(false);
	const toast = useToast();
	const { deleteReview } = useDeleteReview();

	const baseDelete = new BaseDelete<Review>(
		review,
		"Review",
		toast,
		deleteReview,
		refetch,
	);

	// Define the state variables and functions
	const handleDelete = async () => {
		setLoading(true);
		await baseDelete.handleDelete();
		setLoading(false);
	};

	// Return the state variables and functions
	return {
		handleDelete,
		loading,
	};
};

export default useDeleteReviewForm;
