import { MainContentsLayout } from "wrappers";
import { CustomInquiryForm } from "page-components";

const CustomInquiry = () => {
	return (
		<MainContentsLayout>
			<CustomInquiryForm />
		</MainContentsLayout>
	);
};

export default CustomInquiry;
