import React from "react";
import { Box, ResponsiveValue } from "@chakra-ui/react";

interface IFramePreviewProps {
	src: string;
	title: string;
	props?: React.DetailedHTMLProps<
		React.IframeHTMLAttributes<HTMLIFrameElement>,
		HTMLIFrameElement
	>;
	height?: ResponsiveValue<string | number>;
}

const IFramePreview: React.FC<IFramePreviewProps> = ({
	src,
	title,
	height,
	...props
}) => {
	const defaultHeight = { base: "315px", md: "425px" };

	return (
		<Box
			width="100%"
			height={height || defaultHeight}
			style={{ borderRadius: "10px" }}
			overflowY={"hidden"}
		>
			<iframe
				title={title}
				src={src}
				loading="lazy"
				allowFullScreen
				style={{ borderRadius: "10px", width: "100%", height: "100%" }}
				{...props}
			/>
		</Box>
	);
};

export default IFramePreview;
