/**
 * @module animations.ts
 * @description Refined animation variants for a smoother and more engaging three-image carousel, utilizing Framer Motion's capabilities.
 */

export const carouselAnimations = {
	initial: { opacity: 1, scale: 0.95, transition: { duration: 0.5 } },
	animate: {
		opacity: 1,
		scale: 1,
		transition: { duration: 0.5, ease: "easeOut" },
	},
	exit: {
		opacity: 1,
		scale: 0.95,
		transition: { duration: 0.5, ease: "easeIn" },
	},
};

export const tabTransitionVariants = {
	initial: { opacity: 0, y: -20, transition: { duration: 0.3 } },
	animate: { opacity: 1, y: 0, transition: { duration: 0.3, ease: "easeOut" } },
	exit: { opacity: 0, y: 20, transition: { duration: 0.3, ease: "easeIn" } },
};
