import React from "react";
import { Heading, Stack, Text } from "@chakra-ui/react";

interface PanelHeadingProps {
	title: string;
	subtitle?: string;
	content?: string;
}

const PanelHeading: React.FC<PanelHeadingProps> = ({
	title,
	subtitle,
	content,
}) => (
	<Stack spacing={{ base: "4", md: "6" }}>
		{subtitle && <Text variant="sectionParagraph">{subtitle}</Text>}
		<Heading variant="sectionTitle" as="h1">
			{title}
		</Heading>
		{content && <Text variant="sectionParagraph">{content}</Text>}
	</Stack>
);

export default PanelHeading;
