/**
 * @module AdminPageTemplate replace with the associated tab name
 * @description
 */
import React, { useState } from "react";
import { ActionsRow } from "components/features/tables/cells";
import { useRetrieval } from "hooks";
import { SearchTable } from "components/features/tables";
import { createColumnHelper } from "@tanstack/react-table";
import { Catalog, useGetCatalogs, FilterSettingsInput } from "services";
import {
	CreateCatalogForm,
	UpdateCatalogForm,
	DeleteCatalogModal,
} from "page-components/admin/catalogs";
import { Button, Image } from "@chakra-ui/react";
import { AdminResponsiveLayout } from "components";

const AdminCatalogs: React.FC = () => {
	const {
		paginationInput,
		setPaginationInput,
		filterSettings,
		handleFiltersChange,
		sortInput,
		handleSortChange,
	} = useRetrieval();

	const handleBuildFilterSettings = (
		query: string,
		conditional: "AND" | "OR" = "OR",
	) => {
		// Return the filter settings
		const filterSettings: FilterSettingsInput = {
			conditional,
			filters: [
				{
					field: "title",
					operator: "regex",
					value: query,
				},
				{
					field: "type",
					operator: "regex",
					value: query,
				},
			],
		};
		return filterSettings;
	};

	const {
		loading,
		error,
		data: catalogs,
		refetch,
	} = useGetCatalogs({
		retrievalInput: {
			filterSettings: handleBuildFilterSettings(
				filterSettings.query,
				filterSettings.conditional,
			),
			pagination: paginationInput,
			sort: sortInput,
		},
	});

	const [selectedCatalog, setSelectedCatalog] = useState<Catalog | null>(null);

	const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

	const closeDeleteModal = () => {
		setIsDeleteModalOpen(false);
		setSelectedCatalog(null); // Reset the resource to delete
	};

	// Function to close the update modal
	const closeUpdateModal = () => {
		setUpdateModalOpen(false);
		setSelectedCatalog(null);
	};

	// Function to handle the delete action for a resource
	const onRowDelete = (catalog: Catalog) => {
		setSelectedCatalog(catalog);
		setIsDeleteModalOpen(true); // Open the modal
	};

	// Function to handle the edit action for a resource with useUpdateResourceForm
	const onRowEdit = (catalog: Catalog) => {
		setSelectedCatalog(catalog);
		setUpdateModalOpen(true);
	};

	// Adjust column helper for your item type
	const columnHelper = createColumnHelper<Catalog>();

	const columns = [
		columnHelper.accessor("name", {
			header: () => "Name",
			cell: (info) => info.getValue(),
		}),
		columnHelper.display({
			header: () => "Cover Photo",
			id: "image",
			cell: (info) => (
				<Image
					src={info.row.original.image}
					alt={info.row.original.name}
					width={"auto"}
					height={{ base: "100px", md: "auto", lg: "auto", xl: "12rem" }}
					minW="100px"
				/>
			),
		}),
		columnHelper.display({
			header: () => "Catlog PDF-url",
			id: "src",
			cell: (info) => (
				<Button
					onClick={() => window.open(info.row.original.src, "_blank")}
					variant="outline"
				>
					Open PDF
				</Button>
			),
		}),
		columnHelper.accessor("isDealer", {
			header: () => "Dealer Status",
			cell: (info) => (info.getValue() ? "Dealer" : "Not Dealer"),
		}),
		columnHelper.display({
			header: () => "Actions",
			id: "actions",
			cell: (props) => (
				<ActionsRow
					onEdit={() => onRowEdit(props.row.original)}
					onDelete={() => onRowDelete(props.row.original)}
				/>
			),
		}),
	];

	return (
		<AdminResponsiveLayout>
			<SearchTable
				columnsData={columns}
				tableData={catalogs?.items || []}
				paginationInput={paginationInput}
				setPaginationInput={setPaginationInput}
				filterSettings={filterSettings}
				handleBuildFilters={handleFiltersChange}
				sortInput={sortInput}
				handleSortChange={handleSortChange}
				totalCount={catalogs?.totalCount || 0}
				hasNextPage={catalogs?.hasNextPage || false}
				hasPreviousPage={catalogs?.hasPreviousPage || false} // Adjust based on actual pagination logic
				loading={loading}
				error={error}
			/>
			<CreateCatalogForm refetch={refetch} />
			{selectedCatalog && (
				<UpdateCatalogForm
					isOpen={isUpdateModalOpen}
					onClose={closeUpdateModal}
					refetch={refetch}
					existingCatalog={selectedCatalog}
				/>
			)}
			{selectedCatalog && (
				<DeleteCatalogModal
					catalog={selectedCatalog}
					isOpen={isDeleteModalOpen}
					onClose={closeDeleteModal}
					refetch={refetch}
				/>
			)}
		</AdminResponsiveLayout>
	);
};

export default AdminCatalogs;
