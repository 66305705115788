/**
 * @file SearchTable.tsx
 */
import React, { useMemo } from "react";
import {
	Flex,
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	IconButton,
} from "@chakra-ui/react";

import {
	useReactTable,
	getSortedRowModel,
	getCoreRowModel,
	ColumnDef,
	flexRender,
} from "@tanstack/react-table";

import { PaginationInput, FilterFields, SortInput } from "services";
import {
	TiArrowSortedDown,
	TiArrowSortedUp,
	TiArrowUnsorted,
} from "react-icons/ti";

import { LoadingErrorDataWrapper } from "wrappers";

import PageSizeSelector from "./PageSizeSelector";
import SearchInput from "./SearchInput";
import Pagination from "./Pagination";
import { Alert, AlertIcon } from "@chakra-ui/react";

interface SearchTableProps {
	columnsData: ColumnDef<any, any>[];
	tableData: {}[];
	paginationInput: PaginationInput;
	setPaginationInput: React.Dispatch<React.SetStateAction<PaginationInput>>;
	filterSettings: FilterFields;
	handleBuildFilters: (query: string, conditional?: "AND" | "OR") => void;
	sortInput: SortInput;
	handleSortChange: (field: string, order: "asc" | "desc") => void;
	totalCount: number;
	hasNextPage: boolean;
	hasPreviousPage: boolean;
	loading: boolean;
	error: any;
}

const SearchTable: React.FC<SearchTableProps> = ({
	columnsData,
	tableData,
	paginationInput: { limit, offset },
	setPaginationInput,
	filterSettings,
	handleBuildFilters,
	sortInput,
	handleSortChange,
	totalCount,
	hasNextPage,
	hasPreviousPage,
	loading,
	error,
}) => {
	const columns = useMemo(() => columnsData, [columnsData]);
	const data = useMemo(() => tableData, [tableData]);

	const pageCount = Math.ceil(totalCount / limit);
	const pageIndex = offset / limit;

	const handlePreviousPage = () => {
		if (offset > 0) {
			setPaginationInput((prev) => ({ ...prev, offset: offset - limit }));
		}
	};

	const handleNextPage = () => {
		if (offset + limit < totalCount) {
			setPaginationInput((prev) => ({ ...prev, offset: offset + limit }));
		}
	};

	const handleGoToPage = (page: number) => {
		setPaginationInput((prev) => ({ ...prev, offset: page * limit }));
	};

	const handleSetEntries = (number: number) => {
		//Handle ensuring that offset will be a multiple of limit, and that offset will not exceed totalCount or be less than 0
		let newOffset = Math.min(
			Math.max(0, Math.floor(offset / number) * number),
			totalCount - number,
		);
		if (newOffset < 0) newOffset = 0;
		setPaginationInput((prev) => ({
			...prev,
			limit: number,
			offset: newOffset,
		}));
	};

	const setGlobalFilter = (query: string) => {
		handleBuildFilters(query);
	};

	const setSort = (field: string, order: "asc" | "desc") => {
		handleSortChange(field, order);
	};

	const tableInstance = useReactTable({
		columns,
		data,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		manualPagination: true,
		pageCount,
	});

	const { getPageCount, setPageSize } = tableInstance;

	const createPages = (count: number) => {
		const arrPageCount = [];

		for (let i = 1; i <= count; i++) {
			arrPageCount.push(i);
		}

		return arrPageCount;
	};

	return (
		<>
			{data && data.length === 0 && !loading ? (
				<Alert status="warning" borderRadius={4} m={4} p={10}>
					<AlertIcon />
					No data found.
				</Alert>
			) : (
				<>
					<Flex
						direction="column"
						w="100%"
						h="100%"
						justifyContent="flex-start"
						px={{ base: 0, lg: 6 }}
						overflowX="auto"
					>
						<>
							<Flex
								direction={{
									base: "column",
									lg: "row",
								}}
								justify="space-between"
								align="flex-start"
								w="100%"
								px="22px"
								gap={{
									base: "1rem",
									lg: "0",
								}}
								pb="2.5rem"
							>
								<PageSizeSelector
									pageSize={limit}
									setPageSize={handleSetEntries}
								/>
								<SearchInput
									query={filterSettings.query}
									onChange={setGlobalFilter}
								/>
							</Flex>
						</>
						{/* ACTUAL TABLE, With Table Props, Header Groups  */}
						<LoadingErrorDataWrapper
							loading={loading}
							error={error?.message}
							data={data}
						>
							<Table variant="simple" color="gray.500" mb="24px">
								<Thead>
									{tableInstance.getHeaderGroups().map((headerGroup, index) => (
										<Tr {...headerGroup} key={index}>
											{headerGroup.headers.map((header, index) => (
												<Th
													key={header.id}
													colSpan={header.colSpan}
													borderColor="#56577A"
													pe="0px"
												>
													<Flex
														justify="space-between"
														align="center"
														fontSize={{ sm: "10px", lg: "12px" }}
														color="gray.400"
													>
														{flexRender(
															header.column.columnDef.header,
															header.getContext(),
														)}
														{header.column.accessorFn && (
															<IconButton
																w={{ sm: "10px", md: "14px" }}
																h={{ sm: "10px", md: "14px" }}
																color={"gray.500"}
																float="right"
																icon={
																	header.column.id === sortInput.field ? (
																		sortInput.order === "asc" ? (
																			<TiArrowSortedDown />
																		) : (
																			<TiArrowSortedUp />
																		)
																	) : (
																		<TiArrowUnsorted />
																	)
																}
																onClick={() => {
																	setSort(
																		header.column.id,
																		sortInput.field === header.column.id
																			? sortInput.order === "asc"
																				? "desc"
																				: "asc"
																			: "asc",
																	);
																}}
																variant="ghost"
																aria-label={`Sort by ${header.column.columnDef.header}`}
															/>
														)}
													</Flex>
												</Th>
											))}
										</Tr>
									))}
								</Thead>
								<Tbody>
									{tableInstance.getRowModel().rows.map((row, index) => {
										return (
											<Tr key={row.id}>
												{row.getVisibleCells().map((cell, index) => {
													return (
														<Td
															key={cell.id}
															borderColor="#56577A"
															color="gray.500"
															fontSize={{ sm: "14px", lg: "16px" }}
														>
															{flexRender(
																cell.column.columnDef.cell,
																cell.getContext(),
															)}
														</Td>
													);
												})}
											</Tr>
										);
									})}
								</Tbody>
							</Table>
						</LoadingErrorDataWrapper>
						<Pagination
							pageSize={limit}
							pageCount={getPageCount()}
							pageIndex={pageIndex}
							totalCount={totalCount}
							previousPage={handlePreviousPage}
							canPreviousPage={hasPreviousPage}
							gotoPage={handleGoToPage}
							nextPage={handleNextPage}
							canNextPage={hasNextPage}
							setPageSize={setPageSize}
							createPages={createPages}
						/>
					</Flex>
				</>
			)}
		</>
	);
};

export default SearchTable;
