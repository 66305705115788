/**
 * @module RatingDisplay
 * @description This module provides a component for displaying a rating.
 */
import { HStack, Icon, IconProps } from "@chakra-ui/react";

/**
 * @interface RatingDisplayProps
 * @description The props for the RatingDisplay component.
 * @prop {number} rating The rating value.
 * @prop {number} [maxRating=5] The maximum rating value.
 * @prop {string} [activeColor="yellow.400"] The color for active rating icons.
 * @prop {string} [inactiveColor="gray.300"] The color for inactive rating icons.
 */
interface RatingDisplayProps {
	rating: number;
	maxRating?: number;
	RatingIcon: React.ElementType;
	activeColor?: IconProps["color"];
	inactiveColor?: IconProps["color"];
}

const RatingDisplay: React.FC<RatingDisplayProps> = ({
	rating,
	maxRating = 5,
	RatingIcon,
	activeColor = "yellow.400",
	inactiveColor = "gray.300",
}) => {
	return (
		<HStack spacing={1}>
			{Array.from({ length: maxRating }, (_, i) => i + 1).map((value) => (
				<Icon
					key={value}
					as={RatingIcon}
					color={value <= rating ? activeColor : inactiveColor}
				/>
			))}
		</HStack>
	);
};

export default RatingDisplay;
