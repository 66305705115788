// Locations.tsx
import React from "react";
import { Flex } from "@chakra-ui/react";
import LocationCarousel from "page-components/About/Locations/LocationCarousel";
import { LOCATIONS } from "consts";

const Locations: React.FC = () => {
	return (
		<>
			<Flex
				mt={{ base: 8, md: 10 }}
				mb={{ base: 14, md: 10 }}
				overflow="hidden"
				justifyContent="center"
				flexDirection={{ base: "column", md: "row" }}
			>
				<LocationCarousel locations={LOCATIONS} />
			</Flex>
		</>
	);
};

export default Locations;
