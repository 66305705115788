import React from "react";
import { RoutesWrapper } from "wrappers";
import { Route } from "react-router-dom";

import { StoreProvider } from "contexts";

import { AboutRouter, ServicesRouter, ResourcesRouter } from "routes";

import { Home, Store, StoreCategory, CustomInquiry } from "pages";

// Extend the Window interface to include xProductBrowser as a function
// xProductBrowser is a required function used to initialize the store

const UserRouter: React.FC = () => {
	return (
		<StoreProvider>
			{/* <HeaderFooterWrapper> */}
			<RoutesWrapper>
				<Route path="/" element={<Home />} />
				<Route path="/home" element={<Home />} />
				<Route path="/about/*" element={<AboutRouter />} />
				<Route path="/products/:category" element={<StoreCategory />} />
				<Route path="/products/custom-inquiry" element={<CustomInquiry />} />
				<Route path="/services/*" element={<ServicesRouter />} />
				<Route path="/resources/*" element={<ResourcesRouter />} />
				<Route path="/store" element={<Store />} />
			</RoutesWrapper>
			{/* </HeaderFooterWrapper> */}
		</StoreProvider>
	);
};

export default UserRouter;
