import React, { useState } from "react";
import { ActionsRow } from "components/features/tables/cells";
import { useRetrieval } from "hooks";
import { SearchTable } from "components/features/tables";
import { createColumnHelper } from "@tanstack/react-table";
import { Position, useGetPositions, FilterSettingsInput } from "services";
import {
	CreatePositionForm,
	UpdatePositionForm,
	DeletePositionModal,
} from "page-components/admin/positions";
import { AdminResponsiveLayout } from "components";

const AdminPositions: React.FC = () => {
	const {
		paginationInput,
		setPaginationInput,
		filterSettings,
		handleFiltersChange,
		sortInput,
		handleSortChange,
	} = useRetrieval();

	const handleBuildFilterSettings = (
		query: string,
		conditional: "AND" | "OR" = "OR",
	) => {
		const filterSettings: FilterSettingsInput = {
			conditional,
			filters: [
				{
					field: "positionName",
					operator: "regex",
					value: query,
				},
				{
					field: "description",
					operator: "regex",
					value: query,
				},
			],
		};
		return filterSettings;
	};

	const {
		loading,
		error,
		data: positions,
		refetch,
	} = useGetPositions({
		filterSettings: handleBuildFilterSettings(
			filterSettings.query,
			filterSettings.conditional,
		),
		pagination: paginationInput,
		sort: sortInput,
	});

	const [selectedPosition, setSelectedPosition] = useState<Position | null>(
		null,
	);

	const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

	const closeUpdateModal = () => {
		setUpdateModalOpen(false);
		setSelectedPosition(null);
	};

	const closeDeleteModal = () => {
		setIsDeleteModalOpen(false);
		setSelectedPosition(null);
	};

	const onRowDelete = (pos: Position) => {
		setSelectedPosition(pos);
		setIsDeleteModalOpen(true); // Open the modal
	};

	const onRowEdit = (pos: Position) => {
		setSelectedPosition(pos);
		setUpdateModalOpen(true);
	};

	const columnHelper = createColumnHelper<Position>();

	const columns = [
		columnHelper.accessor("positionName", {
			header: () => "Position Title",
			cell: (info) => info.getValue(),
		}),
		columnHelper.accessor("description", {
			header: () => "Job Description",
			cell: (info) => info.getValue(),
		}),
		columnHelper.accessor("details.jobTitle", {
			header: () => "Job Title",
			cell: (info) => info.getValue(),
		}),
		columnHelper.accessor("details.department", {
			header: () => "Job Department",
			cell: (info) => info.getValue(),
		}),
		columnHelper.accessor("details.location", {
			header: () => "Job Location",
			cell: (info) => info.getValue(),
		}),
		columnHelper.accessor("details.commitmentLevel", {
			header: () => "Position Commitment Level",
			cell: (info) => info.getValue(),
		}),
		columnHelper.display({
			header: () => "Actions",
			id: "actions",
			cell: (props) => (
				<ActionsRow
					onEdit={() => onRowEdit(props.row.original)}
					onDelete={() => onRowDelete(props.row.original)}
				/>
			),
		}),
	];

	return (
		<AdminResponsiveLayout>
			<SearchTable
				columnsData={columns}
				tableData={positions?.items || []}
				paginationInput={paginationInput}
				setPaginationInput={setPaginationInput}
				filterSettings={filterSettings}
				handleBuildFilters={handleFiltersChange}
				sortInput={sortInput}
				handleSortChange={handleSortChange}
				loading={loading}
				error={error}
				totalCount={positions?.totalCount || 0}
				hasNextPage={positions?.hasNextPage || false}
				hasPreviousPage={positions?.hasPreviousPage || false}
			/>
			<CreatePositionForm refetch={refetch} />
			{selectedPosition && (
				<UpdatePositionForm
					isOpen={isUpdateModalOpen}
					onClose={closeUpdateModal}
					refetch={refetch}
					existingPosition={selectedPosition}
				/>
			)}
			{selectedPosition && (
				<DeletePositionModal
					position={selectedPosition}
					isOpen={isDeleteModalOpen}
					onClose={closeDeleteModal}
					refetch={refetch}
				/>
			)}
		</AdminResponsiveLayout>
	);
};

export default AdminPositions;
