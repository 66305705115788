import { useState } from "react";
import { BaseDelete } from "cud/delete";
import { useToast } from "@chakra-ui/react";
import { useDeletePosition } from "services";
import { Position } from "services";

const useDeletePositionForm = (
	position: Position,
	refetch: () => Promise<void>,
) => {
	const [loading, setLoading] = useState(false);
	const toast = useToast();
	const { deletePosition } = useDeletePosition();

	const baseDelete = new BaseDelete<Position>(
		position,
		"Position",
		toast,
		deletePosition,
		refetch,
	);

	const handleDelete = async () => {
		setLoading(true);
		await baseDelete.handleDelete();
		setLoading(false);
	};

	return {
		handleDelete,
		loading,
	};
};

export default useDeletePositionForm;
