import React, { useState } from "react";
import { ActionsRow } from "components/features/tables/cells";
import { useRetrieval } from "hooks";
import { SearchTable } from "components/features/tables";
import { createColumnHelper } from "@tanstack/react-table";
import {
	ProductCollection,
	useGetProductCollections,
	FilterSettingsInput,
} from "services";
import {
	CreateProductCollectionForm,
	UpdateProductCollectionForm,
	DeleteProductCollectionModal,
} from "page-components/admin/collections";
import { Image } from "@chakra-ui/react";
import { AdminResponsiveLayout } from "components";

const ProductCollections: React.FC = () => {
	const {
		paginationInput,
		setPaginationInput,
		filterSettings,
		handleFiltersChange,
		sortInput,
		handleSortChange,
	} = useRetrieval();

	const handleBuildFilterSettings = (
		query: string,
		conditional: "AND" | "OR" = "OR",
	) => {
		const filterSettings: FilterSettingsInput = {
			conditional,
			filters: [
				{
					field: "name",
					operator: "regex",
					value: query,
				},
				{
					field: "description",
					operator: "regex",
					value: query,
				},
			],
		};
		return filterSettings;
	};

	const {
		loading,
		error,
		data: productCollections,
		refetch,
	} = useGetProductCollections({
		filterSettings: handleBuildFilterSettings(
			filterSettings.query,
			filterSettings.conditional,
		),
		pagination: paginationInput,
		sort: sortInput,
	});

	const [selectedProductCollection, setSelectedProductCollection] =
		useState<ProductCollection | null>(null);

	const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

	const onRowEdit = (productCollection: ProductCollection) => {
		setSelectedProductCollection(productCollection);
		setUpdateModalOpen(true);
	};

	const closeUpdateModal = () => {
		setUpdateModalOpen(false);
		setSelectedProductCollection(null);
	};

	const onRowDelete = (productCollection: ProductCollection) => {
		setSelectedProductCollection(productCollection);
		setDeleteModalOpen(true);
	};

	const closeDeleteModal = () => {
		setDeleteModalOpen(false);
		setSelectedProductCollection(null);
	};

	const columnHelper = createColumnHelper<ProductCollection>();

	const columns = [
		columnHelper.accessor("name", {
			header: () => "Name",
			cell: (info) => info.getValue(),
		}),
		columnHelper.accessor("description", {
			header: () => "Description",
			cell: (info) => info.getValue(),
		}),
		columnHelper.display({
			header: () => "Image",
			id: "image",
			cell: (info) => (
				<Image
					src={info.row.original.image}
					alt={info.row.original.name}
					width={"auto"}
					height={{ base: "100px", md: "auto", lg: "auto", xl: "12rem" }}
					minW="100px"
				/>
			),
		}),
		columnHelper.display({
			header: () => "Actions",
			id: "actions",
			cell: (props) => (
				<ActionsRow
					onEdit={() => onRowEdit(props.row.original)}
					onDelete={() => onRowDelete(props.row.original)}
				/>
			),
		}),
	];

	return (
		<AdminResponsiveLayout>
			<SearchTable
				columnsData={columns}
				tableData={productCollections?.items || []}
				paginationInput={paginationInput}
				setPaginationInput={setPaginationInput}
				filterSettings={filterSettings}
				handleBuildFilters={handleFiltersChange}
				sortInput={sortInput}
				handleSortChange={handleSortChange}
				loading={loading}
				error={error}
				totalCount={productCollections?.totalCount || 0}
				hasNextPage={productCollections?.hasNextPage || false}
				hasPreviousPage={productCollections?.hasPreviousPage || false}
			/>
			<CreateProductCollectionForm refetch={refetch} />
			{selectedProductCollection && (
				<UpdateProductCollectionForm
					isOpen={isUpdateModalOpen}
					onClose={closeUpdateModal}
					existingProductCollection={selectedProductCollection}
					refetch={refetch}
				/>
			)}
			{selectedProductCollection && (
				<DeleteProductCollectionModal
					isOpen={isDeleteModalOpen}
					onClose={closeDeleteModal}
					productCollection={selectedProductCollection}
					refetch={refetch}
				/>
			)}
		</AdminResponsiveLayout>
	);
};

export default ProductCollections;
