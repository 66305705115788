import { useQuery } from "@apollo/client";
import { getFAQs as GET_F_A_QS } from "./queries";
import { RetrievalInput, FAQ, PaginatedResult } from "../../types";

interface GetFAQsData {
	getFAQs: PaginatedResult<FAQ>;
}

const useGetFAQs = (retrievalInput?: RetrievalInput) => {
	const variables = retrievalInput ? { retrievalInput } : {};
	const { loading, error, data, refetch, networkStatus } =
		useQuery<GetFAQsData>(GET_F_A_QS, { variables: variables });
	const processedData = data?.getFAQs;
	return { loading, error, data: processedData, refetch, networkStatus };
};
export default useGetFAQs;
