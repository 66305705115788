import { useMutation } from "@apollo/client";
import { resolveDealerApplication as RESOLVE_DEALER_APPLICATION } from "./mutations";
interface ResolveDealerApplicationData {
	resolveDealerApplication: boolean;
}

const useResolveDealerApplication = () => {
	const [resolveDealerApplicationMutation, { loading, error }] =
		useMutation<ResolveDealerApplicationData>(RESOLVE_DEALER_APPLICATION);
	const resolveDealerApplication = async (
		dealerApplicationId: string,
		status: string,
	) => {
		try {
			const { data } = await resolveDealerApplicationMutation({
				variables: {
					dealerApplicationId,
					status,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.resolveDealerApplication };
		} catch (error: any) {
			return { error };
		}
	};
	return { resolveDealerApplication, loading, error };
};
export default useResolveDealerApplication;
