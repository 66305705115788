import { useCreateReview } from "services";
import { ReviewInput } from "services/types";
import { useToast } from "@chakra-ui/react";
import { FieldDefinition } from "components";
import { ICUDFormHook } from "./types";
import { useForm } from "hooks/forms";
import { BaseCreate } from "cud";
import { RatingInput } from "components/common/inputs";
import { FaStar } from "react-icons/fa";

const useCreateReviewForm = ({ refetch }: ICUDFormHook) => {
	// Define initial form state
	const initialFormState: ReviewInput = {
		author: "",
		content: "",
		date: "",
		rating: 0,
	};

	const { formState, loading, setLoading, handleInputChange, resetFormState } =
		useForm<ReviewInput>({
			initialState: initialFormState,
		});
	const toast = useToast();

	const { createReview } = useCreateReview();

	const reviewCreate = new BaseCreate<ReviewInput>(
		"Review",
		toast,
		createReview,
		refetch,
	);

	const handleSubmit = async () => {
		setLoading(true);
		const input = {
			...formState,
			rating: parseInt(formState.rating.toString()),
		};
		const success = await reviewCreate.handleCreate(input);
		if (success) resetFormState();
		setLoading(false);
	};

	const fields: FieldDefinition[] = [
		{
			label: "Author",
			name: "author",
			type: "text",
			placeholder: "Enter the author...",
			onChange: handleInputChange,
			value: formState.author,
			required: true,
			variant: "base",
			size: "lg",
			width: "100%",
		},
		{
			label: "Content",
			name: "content",
			type: "text",
			placeholder: "Enter the content...",
			onChange: handleInputChange,
			value: formState.content,
			required: true,
			variant: "base",
			size: "lg",
			width: "100%",
		},
		{
			label: "Date",
			name: "date",
			type: "date",
			placeholder: "Enter the date...",
			onChange: handleInputChange,
			value: formState.date,
			required: true,
			variant: "base",
			size: "lg",
			width: "100%",
		},
		{
			label: "Rating",
			name: "rating",
			type: "custom",
			customComponent: RatingInput,
			customComponentProps: {
				rating: formState.rating,
				onChange: handleInputChange,
				RatingIcon: FaStar,
				activeColor: "yellow.400",
				inactiveColor: "gray.300",
			},
			required: true,
		},
	];

	return { fields, handleSubmit, loading };
};

export default useCreateReviewForm;
