/**
 * @file useUploadFile.ts
 */
import { useGetUploadURL } from "services/files";

/**
 * @hook useUploadFile
 *
 * @description
 * Custom hook that handles file upload functionality.
 *
 * @returns
 * - uploadFile function
 */
const useUploadFile = () => {
	const { getUploadURL } = useGetUploadURL();

	const uploadFile = async (file: File, path: string): Promise<string> => {
		let file_url = "";

		const input_params = {
			path: path,
			fileType: file.type,
		};

		const { error, data: url } = await getUploadURL(input_params);
		if (error) {
			throw error;
		} else if (url) {
			//Determine content type to send to S3
			const result = await fetch(url, {
				method: "PUT",
				headers: {
					"Content-Type": file.type,
				},
				body: file,
			});
			if (!result.ok)
				throw new Error(
					"An unexpected error occurred while uploading the file. Please try again later.",
				);
			file_url = url.split("?")[0];
			return file_url;
		} else {
			throw new Error(
				"An unexpected error occurred while obtaining the upload URL. Please try again later.",
			);
		}
	};

	return { uploadFile };
};

export default useUploadFile;
