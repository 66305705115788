import { IconButton, Box, Heading } from "@chakra-ui/react";
import { colors } from "styles";
import { ReactComponentElement } from "react";

interface IContactInfoProps {
	refer?: string;
	link?: string;
	description?: string;
	icon?: ReactComponentElement<any>;
}

const IconInfoBox: React.FC<IContactInfoProps> = ({
	icon,
	refer,
	link,
	description,
}) => {
	return (
		<Box
			display={{ base: "flex", md: "flex", lg: "flex" }}
			textColor={colors.graydient[400]}
			justifyContent={"center"}
			alignItems={"center"}
			w="100%"
			h="100%"
			flexDirection={"column"}
			gap={4}
		>
			<IconButton
				aria-label="Contact Us"
				icon={icon}
				colorScheme={"gray"}
				fontSize={"65px"}
				h="100%"
				variant={"ghost"}
				_hover={{ color: colors.primary[400] }}
				_active={{ color: colors.primary[400] }}
				_focus={{ color: colors.primary[400] }}
			/>
			{description}
			<Heading
				color={colors.primary[500]}
				_hover={{ textDecoration: "underline" }}
			>
				<a href={refer}>{link}</a>
			</Heading>
		</Box>
	);
};

export default IconInfoBox;
