import React from "react";
import DeleteConfirmationWrapper from "wrappers/DeleteConfirmationWrapper";
import { CustomWorkGalleryImage } from "services";
import { useDeleteCustomWorkGalleryForm } from "hooks/form-hooks";

interface DeleteCustomWorkGalleryModalProps {
	galleryImage: CustomWorkGalleryImage;
	isOpen: boolean;
	onClose: () => void;
	refetch: () => Promise<any>;
}

const DeleteCustomWorkGalleryModal: React.FC<
	DeleteCustomWorkGalleryModalProps
> = ({ galleryImage, isOpen, onClose, refetch }) => {
	const { loading, handleDelete } = useDeleteCustomWorkGalleryForm(
		galleryImage,
		refetch,
	);

	const onConfirmDelete = async () => {
		await handleDelete();
		onClose();
	};

	return (
		<DeleteConfirmationWrapper
			isOpen={isOpen}
			onClose={onClose}
			header={`Confirm Deletion: `}
			onDeleteConfirm={onConfirmDelete}
			content={galleryImage?.category + " Gallery Image"}
			loading={loading}
		>
			{`Are you sure you want to delete this gallery image? This action cannot be undone.`}
		</DeleteConfirmationWrapper>
	);
};

export default DeleteCustomWorkGalleryModal;
