/**
 * @module useUpdatePromotion.ts
 * @description This file contains the logic for updating an existing promotion in the database.
 */
import { useMutation } from "@apollo/client";
import { updatePromotion as UPDATE_PROMOTION } from "./mutations";
import { PromotionInput } from "../../types";

interface UpdatePromotionData {
	updatePromotion: boolean;
}

/**
 * This is the hook that is used to update an existing promotion in the database.
 * @returns {Object} The updatePromotion function, loading state, and error state.
 */
const useUpdatePromotion = () => {
	const [updatePromotionMutation, { loading, error }] =
		useMutation<UpdatePromotionData>(UPDATE_PROMOTION);

	/**
	 * This function is used to update an existing promotion in the database.
	 * @param {string} promotionID The ID of the promotion to update.
	 * @param {PromotionInput} promotionInput The updated input for the promotion.
	 * @returns {Object} The data for the updated promotion or an error.
	 */
	const updatePromotion = async (
		promotionID: string,
		promotionInput: PromotionInput,
	) => {
		try {
			const { data } = await updatePromotionMutation({
				variables: {
					promotionID,
					promotionInput,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.updatePromotion };
		} catch (error: any) {
			return { error };
		}
	};

	return { updatePromotion, loading, error };
};

export default useUpdatePromotion;
