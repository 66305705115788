import { gql } from "@apollo/client";

const login = gql`
	mutation login(
		$email: String!
		$password: String!
		$accountType: String!
		$rememberMe: Boolean!
	) {
		login(
			email: $email
			password: $password
			accountType: $accountType
			rememberMe: $rememberMe
		)
	}
`;

const logout = gql`
	mutation logout($accountType: String!) {
		logout(accountType: $accountType)
	}
`;

const changePassword = gql`
	mutation changePassword(
		$email: String!
		$password: String!
		$accountType: String!
	) {
		changePassword(
			email: $email
			password: $password
			accountType: $accountType
		)
	}
`;

export { login, logout, changePassword };
