/**
 * @module useGetPromotion.ts
 * @description This file contains the logic for fetching a promotion from the database.
 */
import { useQuery } from "@apollo/client";
import { getPromotion as GET_PROMOTION } from "./queries";
import { Promotion } from "../../types";

interface GetPromotionData {
	getPromotion: Promotion;
}

/**
 * This is the hook that is used to fetch a promotion from the database.
 * @returns {Object} The getPromotion function, loading state, and error state.
 */
const useGetPromotion = () => {
	const { data, loading, error } = useQuery<GetPromotionData>(GET_PROMOTION);

	return { data: data?.getPromotion, loading, error };
};

export default useGetPromotion;
