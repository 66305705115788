import BaseCreate from "./BaseCreate";

import { CreateToastFnReturn } from "@chakra-ui/react";
import { ResourceInput } from "services/types";
import { RESOURCE_PATH } from "consts";

/**
 * ## Summary
	The `ResourceCreate` class is a subclass of the `BaseCreate` class. It extends the functionality of the `BaseCreate` class by adding support for uploading files. It overrides the `handleCreate` method to handle the file upload before calling the parent class's `handleCreate` method.

	## Example Usage
	```javascript
	import { useToast } from "@chakra-ui/react";

	// Create a toast function
	const toast = useToast();

	// Create a function to create a resource
	const createResource = async (input) => {
	// Implementation to create a resource
	};

	// Create a function to refetch resources
	const refetchResources = async () => {
	// Implementation to refetch resources
	};

	// Create a function to upload a file
	const uploadFile = async (file, path) => {
	// Implementation to upload a file
	};

	// Create an instance of the ResourceCreate class
	const resourceCreate = new ResourceCreate(
	toast,
	createResource,
	refetchResources,
	file,
	uploadFile
	);

	// Call the handleCreate method to create a resource
	const success = await resourceCreate.handleCreate(input);

	if (success) {
	// Resource created successfully
	} else {
	// Error creating resource
	}
	```

	## Code Analysis
	### Main functionalities
	- Extends the functionality of the `BaseCreate` class
	- Adds support for uploading files
	- Handles file upload before creating a resource
	___
	### Methods
	- `constructor`: Initializes the `ResourceCreate` class with the necessary parameters, including the file and upload function.
	- `handleCreate`: Overrides the `handleCreate` method from the parent class to handle the file upload before calling the parent class's `handleCreate` method.
	___
	### Fields
	- `file`: Stores the file to be uploaded.
	- `uploadFile`: Stores the function to upload a file.
	___

 */
class ResourceCreate extends BaseCreate<ResourceInput> {
	//ResourceCreate Expands upon the BaseCreate by adding in support for uploading files
	//The constructor here will take in an additional uploadFile function in the constructor that it will use to upload the files
	protected file: File | null;
	protected uploadFile: (file: File, path: string) => Promise<string>;

	constructor(
		toast: CreateToastFnReturn,
		createFn: (input: ResourceInput) => Promise<{ data?: any; error?: any }>,
		refetch: () => Promise<void>,
		file: File | null,
		uploadFile: (file: File, path: string) => Promise<string>,
	) {
		super("Resource", toast, createFn, refetch);
		this.file = file;
		this.uploadFile = uploadFile;
	}

	public async handleCreate(input: ResourceInput): Promise<boolean> {
		if (!this.createFn) throw new Error("Create function not defined");
		if (!this.uploadFile) throw new Error("Upload function not defined");
		let path = "";
		try {
			if (!this.file) throw new Error("File is required");
			path = await this.uploadFile(this.file, RESOURCE_PATH);
		} catch (err: any) {
			this.toast({
				title: "Error",
				description: err.message,
				status: "error",
				duration: 5000,
				isClosable: true,
			});
			return false;
		}

		const resourceInput: ResourceInput = {
			...input,
			path,
		};

		return await super.handleCreate(resourceInput);
	}
}

export default ResourceCreate;
