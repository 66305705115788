import React from "react";
import { Form } from "components";
import { ModalWrapper } from "wrappers";
import { useUpdatePartnerForm } from "hooks/form-hooks";
import { Partner } from "services/types";

interface UpdatePartnerFormProps {
	isOpen: boolean;
	onClose: () => void;
	refetch: () => Promise<any>;
	existingPartner: Partner;
}

const UpdatePartnerForm: React.FC<UpdatePartnerFormProps> = ({
	isOpen,
	onClose,
	refetch,
	existingPartner,
}) => {
	const { fields, handleSubmit, loading } = useUpdatePartnerForm({
		refetch,
		existingPartner,
	});

	const onSubmit = async () => {
		await handleSubmit();
		onClose();
	};

	return (
		<ModalWrapper
			isOpen={isOpen}
			onClose={onClose}
			showShadow={true}
			heading="Update Partner"
			hideCloseButton={false}
		>
			<Form fields={fields} onSubmit={onSubmit} isLoading={loading} />
		</ModalWrapper>
	);
};

export default UpdatePartnerForm;
