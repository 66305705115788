import React from "react";
import { Box, IconButton } from "@chakra-ui/react";
import { MdSkipNext, MdPlayCircle, MdPauseCircle } from "react-icons/md";

/**
 * @interface CarouselControlsProps
 * @description Interface for the props of the CarouselControls component.
 * @property {boolean} isPaused - A boolean indicating if the carousel is paused.
 * @property {() => void} onToggle - A function to toggle the carousel.
 * @property {() => void} onNext - A function to move to the next image.
 */
interface CarouselControlsProps {
	isPaused: boolean;
	onToggle: () => void;
	onNext: () => void;
}

/**
 * The CarouselControls component renders the controls for the carousel.
 *
 * @param {CarouselControlsProps} props - The props for the CarouselControls component.
 * @returns {React.ReactElement} - A React element of the CarouselControls component.
 */
const CarouselControls: React.FC<CarouselControlsProps> = ({
	isPaused,
	onToggle,
	onNext,
}) => {
	return (
		<Box
			position="relative"
			display="flex"
			justifyContent="center"
			alignItems="center"
			mt={{ base: "4", md: "8" }}
			mb={{ base: "12", md: "8" }}
		>
			<IconButton
				icon={isPaused ? <MdPlayCircle /> : <MdPauseCircle />}
				aria-label={isPaused ? "Play" : "Pause"}
				variant="ghost"
				fontSize="3xl"
				onClick={onToggle}
				mr={4}
			/>
			<IconButton
				icon={<MdSkipNext />}
				aria-label="Next image"
				variant="ghost"
				fontSize="3xl"
				onClick={onNext}
			/>
		</Box>
	);
};

export default CarouselControls;
