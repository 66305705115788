import { colors } from "styles";
import { getTextShadowString } from "utils";
import { ComponentStyleConfig } from "@chakra-ui/react";

/**
 * Font sizes for different heading levels.
 * Adjust the sizes according to your design specifications.
 */
const fontSizes = {
	h1: ["xl", "6xl"],
	h2: ["lg", "2xl"],
	h3: ["md", "xl"],
	h4: ["sm", "md"],
};

/**
 * Font weights for different heading levels.
 * Replace with actual weights from your design.
 */
const fontWeights = {
	h1: "bold",
	h2: "semibold",
	h3: "medium",
	h4: "bold",
};

/**
 * Style configuration for heading components.
 * Includes base styles and variants for different use cases.
 */
const headingStyles: ComponentStyleConfig = {
	baseStyle: () => ({
		as: "h1",
		fontWeight: fontWeights.h1,
		fontSize: fontSizes.h1,
		lineHeight: "110%",
		letterSpacing: "tight",
		color: colors.primary[500],
	}),
	variants: {
		primary: {
			as: "h1",
			color: "primary.500",
			textShadow: getTextShadowString(colors.shadow, 2),
		},
		hero: {
			as: "h1",
			fontSize: ["2xl", "4xl", "8xl"],
			fontWeight: ["semibold", "semibold", "bold"],
			textAlign: "center",
			color: colors.primary[500],
			textShadow: [
				getTextShadowString(colors.secondary[900], 0),
				getTextShadowString(colors.secondary[900], 4),
				getTextShadowString(colors.secondary[900], 4),
			],
		},
		cta: {
			as: "h2",
			color: colors.primary[500],
			fontWeight: ["semibold", "semibold", "bold"],
			fontSize: ["4xl", "4xl", "6xl"],
			textAlign: "center",
			textShadow: [
				getTextShadowString(colors.shadow, 2),
				getTextShadowString(colors.shadow, 2),
				getTextShadowString(colors.shadow, 4),
			],
		},
		title: {
			as: "h2",
			fontSize: ["2xl", "3xl", "5xl"],
			fontWeight: ["semibold", "bold", "extrabold"],
			textAlign: "center",
			textShadow: [
				getTextShadowString(colors.shadow, 2),
				getTextShadowString(colors.shadow, 2),
				getTextShadowString(colors.shadow, 4),
			],
		},
		subtitle: {
			as: "h3",
			fontSize: ["lg", "xl", "2xl"],
			fontWeight: ["normal", "semibold"],
			textAlign: "center",
			color: colors.graydient,
		},
		sectionTitle: {
			as: "h3",
			fontSize: ["xl", "2xl", "3xl", "4xl"],
			fontWeight: ["semibold", "bold", "extrabold"],
			textAlign: "left",
		},
		linkTitle: {
			fontsize: "h4",
			fontWeight: "h4",
			color: colors.secondary[50],
			textShadow: [
				getTextShadowString(colors.shadow, 2),
				getTextShadowString(colors.shadow, 2),
				getTextShadowString(colors.shadow, 4),
			],
		},
		boxTitle: {
			as: "h3",
			fontSize: ["lg", "xl"],
			textShadow: [
				getTextShadowString(colors.shadow, 0),
				getTextShadowString(colors.shadow, 4),
				getTextShadowString(colors.shadow, 4),
			],
		},
		ComponentTitle: {
			as: "h2",
			fontSize: ["lg", "xl", "2xl", "4xl", "6xl"],
			fontWeight: ["bold", "extrabold"],
			textAlign: "left",
			textShadow: [
				getTextShadowString(colors.shadow, 0),
				getTextShadowString(colors.shadow, 2),
				getTextShadowString(colors.shadow, 2),
			],
		},
		ComponentSubHeading: {
			as: "h2",
			fontSize: ["lg", "xl", "2xl"],
			fontWeight: ["semibold"],
			textAlign: "center",
			color: colors.graydient,
		},
	},
	// sizes can be defined if there are different sizes for each heading variant
	sizes: {
		// ...
	},
};

export default headingStyles;
