/**
 * @module EcwidStore.tsx
 * @description EcwidStore component that loads the Ecwid store script
 * @version 1.0.0
 */
import React, { useState, useEffect, useContext } from "react";

import { StoreContext } from "contexts";

import { Loader } from "components";

interface EcwidStoreProps {
	categoryId?: number;
	search?: string;
}

/**
 * @function EcwidStore
 * @returns EcwidStore component
 * @description EcwidStore component that loads the Ecwid store script
 */
const EcwidStore: React.FC<EcwidStoreProps> = ({
	categoryId = 0,
	search = "",
}: EcwidStoreProps) => {
	const {
		loading: ecwidLoading,
		setupEcwid,
		openPage,
		renderStore,
		hideStore,
	} = useContext(StoreContext);

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setupEcwid();
		if (categoryId || search) {
			openPage("category", {
				id: categoryId,
				search,
			});
		}
		renderStore();
		setLoading(false);
		return () => {
			hideStore();
		};
	}, [setupEcwid, openPage, renderStore, hideStore, categoryId, search]);

	return (
		<>
			{
				// If the store is not loaded, display a loader
				// <Loader />
				(ecwidLoading || loading) && <Loader />
			}
			{/* <div id={process.env.REACT_APP_STORE_ID || "my-store-69679755"}></div> */}
		</>
	);
};

export default EcwidStore;
