import BaseUpdate from "./BaseUpdate";

import { CreateToastFnReturn } from "@chakra-ui/react";
import { Partner, PartnerInput } from "services/types";
import { PARTNER_PATH } from "consts";

class PartnerUpdate extends BaseUpdate<Partner, PartnerInput> {
	//PartnerUpdate Expands upon the BaseUpdate by adding in support for uploading files
	//The constructor here will take in an additional uploadFile function in the constructor that it will use to upload the files
	protected file: File | null;
	protected uploadFile: (file: File, path: string) => Promise<string>;

	constructor(
		object: Partner,
		toast: CreateToastFnReturn,
		updateFn: (
			id: string,
			input: PartnerInput,
		) => Promise<{ data?: any; error?: any }>,
		refetch: () => Promise<void>,
		file: File | null,
		uploadFile: (file: File, path: string) => Promise<string>,
	) {
		super(object, "Partner", toast, updateFn, refetch);
		this.file = file;
		this.uploadFile = uploadFile;
	}

	public async handleUpdate(input: PartnerInput): Promise<boolean> {
		if (!this.updateFn) throw new Error("Update function not defined");
		if (!this.uploadFile) throw new Error("Upload function not defined");

		let path = this.object.details.image;

		if (this.file) {
			try {
				path = await this.uploadFile(this.file, PARTNER_PATH);
			} catch (err: any) {
				this.toast({
					title: "Error",
					description: err.message,
					status: "error",
					duration: 5000,
					isClosable: true,
				});
				return false;
			}
		}

		const partnerInput: PartnerInput = {
			...input,
			details: { ...input.details, image: path },
		};

		return await super.handleUpdate(partnerInput);
	}
}

export default PartnerUpdate;
