import React from "react";
import { Heading, Flex, Text, Box } from "@chakra-ui/react";
import { StackLayout, CustomDivider } from "components";
import FeedbackForm from "./FeedbackForm";

const Feedback: React.FC = () => {
	return (
		<Flex direction="column" p={4} mt={6} mb={6} overflow="hidden">
			<StackLayout
				spacing={2}
				align="center"
				justify="center"
				px={{ base: "4", md: "48" }}
				pt={{ base: "auto", md: "8" }}
			>
				<Heading variant="title" alignSelf="center">
					Feedback
				</Heading>
				<Heading variant="subtitle" alignSelf="center" mb={4}>
					We want to hear from you!
				</Heading>
				<Flex w={{ base: "100%", md: "70%" }}>
					<Text variant="sectionParagraph" align="center" as="p">
						We value your feedback and would love to hear from you. Please use
						this form to tell us about your experience!
					</Text>
				</Flex>
			</StackLayout>
			<CustomDivider />
			<Box
				boxShadow="lg"
				borderRadius={5}
				borderWidth="1px"
				borderColor="graydient.300"
				w="80%"
				h={{ base: "100%", md: "100%" }}
				px={{
					base: "1rem",
					md: "2rem",
				}}
				py={4}
				justifySelf="center"
				alignSelf="center"
			>
				<FeedbackForm />
			</Box>
		</Flex>
	);
};

export default Feedback;
