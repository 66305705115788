import { useQuery } from "@apollo/client";
import { getDealerApplications as GET_DEALER_APPLICATIONS } from "./queries";
import {
	RetrievalInput,
	DealerApplication,
	PaginatedResult,
} from "../../types";

interface GetDealerApplicationsData {
	getDealerApplications: PaginatedResult<DealerApplication>;
}

const useGetDealerApplications = (retrievalInput: RetrievalInput) => {
	const { loading, error, data, refetch, networkStatus } =
		useQuery<GetDealerApplicationsData>(GET_DEALER_APPLICATIONS, {
			variables: { retrievalInput },
		});
	const processedData = data?.getDealerApplications;
	return { loading, error, data: processedData, refetch, networkStatus };
};
export default useGetDealerApplications;
