import React from "react";
import { Button, Flex } from "@chakra-ui/react";
import { Form } from "components";
import { useModal } from "hooks";
import { useCreateGalleryForm } from "hooks/form-hooks";
import { ModalWrapper } from "wrappers";
import { FaPlus } from "react-icons/fa6";

interface CreateGalleryFormProps {
	refetch: () => Promise<any>;
}

const CreateGalleryForm: React.FC<CreateGalleryFormProps> = ({ refetch }) => {
	const { isOpen, open, close } = useModal();

	const { fields, handleSubmit, loading } = useCreateGalleryForm({ refetch });

	const onSubmit = async () => {
		await handleSubmit();
		close();
	};

	return (
		<>
			<Flex>
				<Button
					size="lg"
					aria-label="create-gallery"
					onClick={open}
					variant={"withIcon"}
					leftIcon={<FaPlus />}
					borderRadius="15px"
					mx={6}
					my={4}
				>
					Create Gallery Image
				</Button>
			</Flex>
			<ModalWrapper
				isOpen={isOpen}
				onClose={close}
				showShadow={true}
				heading="Create a New Gallery Image"
				hideCloseButton={false}
			>
				<Form fields={fields} onSubmit={onSubmit} isLoading={loading} />
			</ModalWrapper>
		</>
	);
};

export default CreateGalleryForm;
