import { Container, Heading, Text, Center } from "@chakra-ui/react";

const AdminDashboard: React.FC = () => {
	return (
		<Container maxW="container.lg" mt={10} p={5}>
			<Center flexDirection="column" mt={10}>
				<Heading as="h1" size="lg">
					Welcome to the Admin Dashboard!
				</Heading>
				<Text mt={5}>
					Please select a tab from the sidebar to manage site content.
				</Text>
			</Center>
		</Container>
	);
};

export default AdminDashboard;
