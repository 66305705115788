import { useState, useCallback } from "react";

/**
 * useModal hook for TypeScript
 * @returns isOpen, open, close
 * isOpen: boolean
 * open: () => void
 * close: () => void
 */
const useModal = () => {
	const [isOpen, setIsOpen] = useState(false);

	const open = useCallback(() => setIsOpen(true), []);
	const close = useCallback(() => setIsOpen(false), []);

	return { isOpen, open, close };
};

export default useModal;
