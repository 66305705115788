/**
 * @file useDeleteProductCollection.ts
 */
import { useMutation } from "@apollo/client";
import { deleteProductCollection as deleteProductCollectionGQL } from "./mutations";

// Define the structure of the data returned by the mutation
interface DeleteProductCollectionResultData {
	deleteProductCollection: boolean; // Adjust based on your actual return type (e.g., boolean if it returns a success flag)
}

/**
 * @hook useDeleteProductCollection
 *
 * @description
 * Custom hook that handles the deleteProductCollection mutation functionality.
 * This hook can be used to delete a product collection.
 *
 * @returns An object containing the deleteProductCollection execution, loading state, and error
 */
const useDeleteProductCollection = () => {
	const [deleteCollectionMutation, { loading, error }] =
		useMutation<DeleteProductCollectionResultData>(deleteProductCollectionGQL);

	/**
	 * Executes the deleteProductCollection mutation.
	 *
	 * @param params - Parameters for the deleteProductCollection mutation
	 * @returns An object containing the deleteProductCollection result data or an error object.
	 */
	const deleteProductCollection = async (productCollectionID: string) => {
		try {
			const { data } = await deleteCollectionMutation({
				variables: { productCollectionID },
			});
			if (error) {
				throw new Error(error.message);
			}
			// Process the returned data as required
			return { data: data?.deleteProductCollection }; // Adjust according to the actual field from your mutation
		} catch (error) {
			return { error };
		}
	};

	return { deleteProductCollection, loading, error };
};

export default useDeleteProductCollection;
