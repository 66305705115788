/**
 * @module useCreatePromotion.ts
 * @description This file contains the logic for creating a new promotion in the database.
 */
import { useMutation } from "@apollo/client";
import { createPromotion as CREATE_PROMOTION } from "./mutations";
import { PromotionInput } from "../../types";

interface CreatePromotionData {
	createPromotion: boolean;
}

/**
 * This is the hook that is used to create a new promotion in the database.
 * @returns {Object} The createPromotion function, loading state, and error state.
 */
const useCreatePromotion = () => {
	const [createPromotionMutation, { loading, error }] =
		useMutation<CreatePromotionData>(CREATE_PROMOTION);

	/**
	 * This function is used to create a new promotion in the database.
	 * @param {PromotionInput} promotionInput The input for the new promotion.
	 * @returns {Object} The data for the new promotion or an error.
	 */
	const createPromotion = async (promotionInput: PromotionInput) => {
		try {
			const { data } = await createPromotionMutation({
				variables: {
					promotionInput,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.createPromotion };
		} catch (error: any) {
			return { error };
		}
	};

	return { createPromotion, loading, error };
};

export default useCreatePromotion;
