import { useState } from "react";
import { BaseDelete } from "cud/delete"; // Import the BaseDelete class
import { useToast } from "@chakra-ui/react";
import { useDeletePromotion } from "services";
import { Promotion } from "services";

const useDeletePromotionForm = (
	promotion: Promotion,
	refetch: () => Promise<void>,
) => {
	const [loading, setLoading] = useState(false);
	const toast = useToast();
	const { deletePromotion } = useDeletePromotion();

	const baseDelete = new BaseDelete<Promotion>(
		promotion,
		"Promotion",
		toast,
		deletePromotion,
		refetch,
	);

	// Define the state variables and functions
	const handleDelete = async () => {
		setLoading(true);
		await baseDelete.handleDelete();
		setLoading(false);
	};

	// Return the state variables and functions
	return {
		handleDelete,
		loading,
	};
};

export default useDeletePromotionForm;
