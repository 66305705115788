/**
 * @module SimpleGridLayout .tsx
 * @description A component that displays a grid layout.
 * @example <SimpleGridLayout  columns={{ base: 1, md: 2, lg: 3 }} gap={6}>
 */
import React from "react";
import { SimpleGrid, SimpleGridProps } from "@chakra-ui/react";

// Extend the SimpleGridProps to include children
interface SimpleGridLayoutProps extends SimpleGridProps {
	children: React.ReactNode;
}

const SimpleGridLayout: React.FC<SimpleGridLayoutProps> = ({
	children,
	...simpleGridProps
}) => {
	return <SimpleGrid {...simpleGridProps}>{children}</SimpleGrid>;
};

export default SimpleGridLayout;
