import React from "react";
import { Stack, IconButton } from "@chakra-ui/react";
import { EditIconButton, DeleteIconButton } from "components/common/buttons";
import { Tooltip } from "@chakra-ui/react";

interface Action {
	name: string;
	icon: React.ReactElement;
	onClick: () => void;
}

interface ActionsRowProps {
	onEdit?: () => void;
	onDelete?: () => void;
	actions?: Action[];
}

const ActionsRow: React.FC<ActionsRowProps> = ({
	onEdit,
	onDelete,
	actions,
}) => {
	return (
		<Stack
			direction={{
				base: "column",
				md: "row",
			}}
			spacing={2}
			alignItems="flex-start"
			justifyContent="flex-start"
		>
			{onEdit && <EditIconButton onClick={onEdit} />}
			{actions &&
				actions.map((action, index) => (
					<Tooltip
						hasArrow
						label={action.name}
						variant="action"
						placement="bottom"
						openDelay={500}
					>
						<IconButton
							key={index}
							aria-label={action.name}
							variant="action"
							onClick={action.onClick}
							icon={action.icon}
						/>
					</Tooltip>
				))}
			{onDelete && <DeleteIconButton onClick={onDelete} />}
		</Stack>
	);
};

export default ActionsRow;
