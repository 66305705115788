import React, { useCallback, useEffect, useState, useMemo } from "react";

import { useMediaQuery, useTheme, Button, Image } from "@chakra-ui/react";

import { Slider, Track, Item } from "./index";

import { FaPlay, FaPause } from "react-icons/fa";

/**
 * Props for the ChakraCarousel component.
 * @typedef {Object} CarouselProps
 * @property {string[]} images - Array of image URLs to be displayed in the carousel.
 * @property {number} gap - The space between items in the carousel, in pixels.
 */
interface CarouselProps {
	images: string[]; // Array of image URLs
	gap: number;
}

/**
 * A carousel component that displays a series of images.
 *
 * Utilizes Chakra UI components and responsive design to adapt to different screen sizes.
 * The carousel supports autoplay functionality and adjusts the number of items displayed based on screen width.
 *
 * @param {CarouselProps} props - The props for the ChakraCarousel component.
 * @returns {JSX.Element} The JSX code for carousel component.
 */
const ChakraCarousel: React.FC<CarouselProps> = ({ images, gap }) => {
	// State to manage whether the track is active or not.
	const [trackIsActive, setTrackIsActive] = useState(false);

	// State to manage the scale factor for item size adjustments.
	const [multiplier, setMultiplier] = useState(0.35);

	// State to manage the width of the carousel.
	const [sliderWidth, setSliderWidth] = useState(0);

	// State to manage the active item in the carousel.
	const [activeItem, setActiveItem] = useState(0);

	// State to manage the number of items displayed in the carousel.
	const [constraint, setConstraint] = useState(0);

	// State to manage the width of each item in the carousel.
	const [itemWidth, setItemWidth] = useState(0);

	// State to manage whether the carousel is playing or not.
	const [isPlaying, setIsPlaying] = useState(true);

	// Callback to initialize the width of the slider.
	const initSliderWidth = useCallback(
		(width: React.SetStateAction<number>) => setSliderWidth(width),
		[],
	);

	// Accessing breakpoints from the theme for responsive design.
	const { breakpoints } = useTheme();

	// Media queries to determine the number of items displayed based on screen width.
	const [isBetweenBaseAndMd] = useMediaQuery(
		`(min-width: ${breakpoints.base}) and (max-width: ${breakpoints.md})`,
	);

	// Media queries to determine the number of items displayed based on screen width.
	const [isBetweenMdAndXl] = useMediaQuery(
		`(min-width: ${breakpoints.md}) and (max-width: ${breakpoints.xl})`,
	);

	// Media queries to determine the number of items displayed based on screen width.
	const [isGreaterThanXL] = useMediaQuery(`(min-width: ${breakpoints.xl})`);

	// Memoized value to determine the number of items displayed based on screen width.
	const numItemsDisplayed = useMemo(() => {
		if (isBetweenBaseAndMd) return 1;
		if (isBetweenMdAndXl) return 3;
		if (isGreaterThanXL) return 5;
		else return 1;
	}, [isBetweenBaseAndMd, isBetweenMdAndXl, isGreaterThanXL]);

	// Memoized value to determine the offset of the carousel based on screen width.
	const offset = isBetweenBaseAndMd ? 0 : itemWidth + gap;

	// Memoized value to determine the positions of the items in the carousel based on screen width.
	const positions = useMemo(
		() =>
			images.map((_, index) => -Math.abs((itemWidth + gap) * index) + offset),
		[images, itemWidth, gap, offset],
	);

	// Memoized value to determine the width of the carousel based on screen width.
	useEffect(() => {
		if (isBetweenBaseAndMd) {
			setItemWidth(sliderWidth / numItemsDisplayed - gap);
			setMultiplier(0.65);
			setConstraint(1);
		}
		if (isBetweenMdAndXl) {
			setItemWidth(sliderWidth / numItemsDisplayed - gap);
			setMultiplier(0.5);
			setConstraint(1);
		}
		if (isGreaterThanXL) {
			setItemWidth(sliderWidth / numItemsDisplayed - gap);
			setMultiplier(0.35);
			setConstraint(3);
		}
	}, [
		isBetweenBaseAndMd,
		isBetweenMdAndXl,
		isGreaterThanXL,
		sliderWidth,
		gap,
		numItemsDisplayed,
	]);

	// Memoized value to determine the width of the carousel based on screen width.
	useEffect(() => {
		const interval = setInterval(() => {
			if (
				isPlaying &&
				activeItem < images.length - 1 &&
				activeItem < positions.length - constraint
			) {
				setActiveItem((prev) => prev + 1);
			}
			if (isPlaying && activeItem === images.length - constraint) {
				setActiveItem(0);
			}
		}, 3000); // Change the interval as needed

		return () => clearInterval(interval);
	}, [isPlaying, activeItem, images.length, positions.length, constraint]);

	// Props for the slider component.
	const sliderProps = {
		setTrackIsActive,
		initSliderWidth,
		setActiveItem,
		activeItem,
		constraint,
		itemWidth,
		positions,
		gap,
	};

	// Props for the track component.
	const trackProps = {
		setTrackIsActive,
		trackIsActive,
		setActiveItem,
		sliderWidth,
		activeItem,
		constraint,
		multiplier,
		itemWidth,
		positions,
		gap,
	};

	// Props for the item component.
	const itemProps = {
		setTrackIsActive,
		trackIsActive,
		setActiveItem,
		activeItem,
		constraint,
		itemWidth,
		positions,
		gap,
	};

	return (
		<Slider
			{...sliderProps}
			button={
				<Button
					variant="withIcon"
					onClick={() => setIsPlaying(!isPlaying)}
					width="145px"
					leftIcon={!isPlaying ? <FaPlay /> : <FaPause />}
					mt={12}
					fontSize={["xs", "sm", "md", "lg"]}
				>
					{isPlaying ? "Pause" : "Play"}
				</Button>
			}
		>
			<Track {...trackProps}>
				{images.map((imageUrl, index) => (
					<Item {...itemProps} index={index} key={index}>
						<Image
							src={imageUrl}
							height="400px"
							width="600px"
							objectFit="cover"
							draggable={false}
							borderRadius={10}
						/>
					</Item>
				))}
			</Track>
		</Slider>
	);
};

export default ChakraCarousel;
