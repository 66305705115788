/**
 * @module ReviewCarousel
 */
import React from "react";
import { Box, Button, Flex, Grid } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useGetReviews } from "services";
import ErrorMessage from "components/common/ui-elements/ErrorMessage";
import Loader from "components/common/ui-elements/Loader";
import ReviewsCard from "components/common/cards/ReviewCard";
import { FcGoogle } from "react-icons/fc";
import { useConveyorBeltCarousel } from "hooks";

const ReviewCarousel: React.FC = () => {
	const { loading, error, data: reviews } = useGetReviews();

	const reviewItems =
		reviews?.items.map((review) => ({
			author: review.author,
			content: review.content,
			date: review.date,
			rating: review.rating,
		})) || [];

	const carouselConfig = {
		scrollDurationFactor: 12,
	};

	const { totalWidth, scrollAnimation } = useConveyorBeltCarousel(
		reviewItems,
		carouselConfig,
	);

	if (!loading && !error && reviewItems.length === 0) {
		return null;
	}

	return (
		<>
			<Box
				position="relative"
				overflow="hidden"
				w="100%"
				maxW="90vw"
				mx="auto"
				p={2}
			>
				{loading && <Loader />}
				{error && (
					<ErrorMessage
						title="Failed to load reviews"
						description="Please try again later."
					/>
				)}
				{!loading && !error && reviewItems.length > 0 && (
					<Box
						px={
							totalWidth > window.innerWidth
								? 0
								: (window.innerWidth - totalWidth) / 2
						}
					>
						{reviewItems.length < 4 ? (
							<Grid
								templateColumns={{
									base: "1fr",
									md: `repeat(${reviewItems.length}, 1fr)`,
								}}
								gap={4}
							>
								{reviewItems.map((review, index) => (
									<Box key={index} w="100%" px={{ base: "6", md: "10" }}>
										<ReviewsCard
											author={review.author}
											content={review.content}
											date={review.date}
											rating={review.rating}
											icon={FcGoogle}
										/>
									</Box>
								))}
							</Grid>
						) : (
							<Flex overflow="hidden" position="relative">
								<motion.div
									style={{ display: "flex", whiteSpace: "nowrap" }}
									{...scrollAnimation}
								>
									{reviewItems.concat(reviewItems).map((review, index) => (
										<Box
											key={index}
											flex="none"
											w={
												reviewItems.length <= 4
													? "30%"
													: reviewItems.length === 5
													? "26%"
													: reviewItems.length >= 10
													? "15%"
													: "18%"
											}
										>
											<ReviewsCard
												author={review.author}
												content={review.content}
												date={review.date}
												rating={review.rating}
												icon={FcGoogle}
											/>
										</Box>
									))}
								</motion.div>
							</Flex>
						)}
					</Box>
				)}
				<Box
					position="absolute"
					top="0"
					left="0"
					h="100%"
					w="50px"
					bgGradient="linear(to-r, white, transparent)"
					pointerEvents="none"
				/>
				<Box
					position="absolute"
					top="0"
					right="0"
					h="100%"
					w="50px"
					bgGradient="linear(to-l, white, transparent)"
					pointerEvents="none"
				/>
				<Flex justify="center" mt={4}>
					<Button
						variant="primary"
						as="a"
						href="https://search.google.com/local/writereview?placeid=ChIJyciwo_Uy6IkRdVu1o6xPEjU"
						target="_blank"
					>
						Add Your Review
					</Button>
				</Flex>
			</Box>
		</>
	);
};

export default ReviewCarousel;
