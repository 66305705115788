import { useMutation } from "@apollo/client";
import { createFAQ as CREATE_F_A_Q } from "./mutations";
import { FAQInput } from "../../types";

interface CreateFAQData {
	createFAQ: boolean;
}

const useCreateFAQ = () => {
	const [createFAQMutation, { loading, error }] =
		useMutation<CreateFAQData>(CREATE_F_A_Q);
	const createFAQ = async (faqInput: FAQInput) => {
		try {
			const { data } = await createFAQMutation({
				variables: {
					faqInput,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.createFAQ };
		} catch (error: any) {
			return { error };
		}
	};
	return { createFAQ, loading, error };
};
export default useCreateFAQ;
