/**
 * @module NavBar.tsx
 */
import React from "react";
import { Flex, Container, Center } from "@chakra-ui/react";

import { MapNavButtons, NavItem } from "components/features/navbar";

interface NavbarProps {
	navItems: NavItem[];
}

const Navbar: React.FC<NavbarProps> = ({ navItems }) => {
	return (
		<Flex
			as="nav"
			align="center"
			justify="space-between"
			wrap="wrap"
			bg="secondary"
			color="white"
			w="100%"
			px={0}
		>
			<Container maxW="100%" width="90%" bgColor="accent.500">
				<Flex justifyContent="space-between" alignItems="center" width="100%">
					<Center w="100%" zIndex={"dropdown"}>
						<MapNavButtons navItems={navItems} />
					</Center>
				</Flex>
			</Container>
		</Flex>
	);
};

export default Navbar;
