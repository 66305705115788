/**
 * @module DealerLogin.tsx
 *
 * @description Dealer Login Page
 *
 * @components
 * - LoginForm
 * - Login
 */
import React from "react";
import { Button } from "@chakra-ui/react";
import { Login } from "page-components";
import { PageBackgroundWrapper } from "wrappers";
import { dealerAuth } from "assets";
import { useMediaQuery } from "@chakra-ui/react";
import { PageChangeContext } from "contexts";

const DealerLogin = () => {
	const { handlePageChange } = React.useContext(PageChangeContext);

	const handleRequestAccount = () => {
		handlePageChange("/services/dealer-application");
	};

	const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
	return (
		<PageBackgroundWrapper
			backgroundStyle={{
				type: "image",
				value: dealerAuth,
			}}
		>
			<Login
				heading="Wayside Fence"
				bgColor={{ base: "white", xl: "whiteAlpha.300" }}
				strategy={"dealer"} // Pass strategy prop
				bottomButton={
					<Button
						variant="ghost"
						fontWeight="bold"
						onClick={handleRequestAccount}
					>
						Request an account
					</Button>
				}
				style={{
					backdropFilter: isLargerThan768 ? "blur(20px)" : "none",
					WebkitBackdropFilter: isLargerThan768 ? "blur(20px)" : "none",
					border: "1px solid rgba(255, 255, 255, 0.1)",
				}}
			/>
		</PageBackgroundWrapper>
	);
};

export default DealerLogin;
