/**
 * @module Reviews.tsx
 * @description This file contains the Reviews component.
 * This currently includes just the `CreateReviewForm` component.
 */
import React, { useState } from "react";
import { ActionsRow } from "components/features/tables/cells";
import { useRetrieval } from "hooks";
import { SearchTable } from "components/features/tables";
import { createColumnHelper } from "@tanstack/react-table";
import { Review, useGetReviews, FilterSettingsInput } from "services";
import {
	CreateReviewForm,
	UpdateReviewForm,
	DeleteReviewModal,
} from "page-components/admin/reviews";
import { AdminResponsiveLayout } from "components";
import { formatUnixTimestampStringForDateDisplay } from "utils";
import { RatingDisplay } from "components";
import { FaStar } from "react-icons/fa";

const AdminReviews: React.FC = () => {
	const {
		paginationInput,
		setPaginationInput,
		filterSettings,
		handleFiltersChange,
		sortInput,
		handleSortChange,
	} = useRetrieval();

	const handleBuildFilterSettings = (
		query: string,
		conditional: "AND" | "OR" = "OR",
	) => {
		const filterSettings: FilterSettingsInput = {
			conditional,
			filters: [
				{
					field: "author",
					operator: "regex",
					value: query,
				},
				{
					field: "content",
					operator: "regex",
					value: query,
				},
			],
		};
		return filterSettings;
	};

	const {
		loading,
		error,
		data: reviews,
		refetch,
	} = useGetReviews({
		filterSettings: handleBuildFilterSettings(
			filterSettings.query,
			filterSettings.conditional,
		),
		pagination: paginationInput,
		sort: sortInput,
	});

	const [selectedReview, setSelectedReview] = useState<Review | null>(null);

	const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

	const closeDeleteModal = () => {
		setIsDeleteModalOpen(false);
		setSelectedReview(null);
	};

	const closeUpdateModal = () => {
		setIsUpdateModalOpen(false);
		setSelectedReview(null);
	};

	const onRowDelete = (review: Review) => {
		setSelectedReview(review);
		setIsDeleteModalOpen(true);
	};

	const onRowEdit = (review: Review) => {
		setSelectedReview(review);
		setIsUpdateModalOpen(true);
	};

	const columnHelper = createColumnHelper<Review>();

	const columns = [
		columnHelper.accessor("author", {
			header: () => "Author",
			cell: (info) => info.getValue(),
		}),
		columnHelper.accessor("content", {
			header: () => "Content",
			cell: (info) => info.getValue(),
		}),
		columnHelper.accessor("date", {
			header: () => "Date",
			cell: (info) => formatUnixTimestampStringForDateDisplay(info.getValue()),
		}),
		columnHelper.accessor("rating", {
			header: () => "Rating",
			cell: (info) => (
				<RatingDisplay
					rating={info.getValue()}
					maxRating={5}
					RatingIcon={FaStar}
				/>
			),
		}),
		columnHelper.display({
			header: () => "Actions",
			id: "actions",
			cell: (info) => (
				<ActionsRow
					onEdit={() => onRowEdit(info.row.original)}
					onDelete={() => onRowDelete(info.row.original)}
				/>
			),
		}),
	];

	return (
		<AdminResponsiveLayout>
			<CreateReviewForm refetch={refetch} />
			<SearchTable
				columnsData={columns}
				tableData={reviews?.items || []}
				paginationInput={paginationInput}
				setPaginationInput={setPaginationInput}
				filterSettings={filterSettings}
				handleBuildFilters={handleFiltersChange}
				sortInput={sortInput}
				handleSortChange={handleSortChange}
				loading={loading}
				error={error}
				totalCount={reviews?.totalCount || 0}
				hasNextPage={reviews?.hasNextPage || false}
				hasPreviousPage={reviews?.hasPreviousPage || false}
			/>
			{selectedReview && (
				<UpdateReviewForm
					isOpen={isUpdateModalOpen}
					onClose={closeUpdateModal}
					refetch={refetch}
					existingReview={selectedReview}
				/>
			)}
			{selectedReview && (
				<DeleteReviewModal
					isOpen={isDeleteModalOpen}
					onClose={closeDeleteModal}
					refetch={refetch}
					review={selectedReview}
				/>
			)}
		</AdminResponsiveLayout>
	);
};

export default AdminReviews;
