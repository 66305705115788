import BaseUpdate from "./BaseUpdate";

import { CreateToastFnReturn } from "@chakra-ui/react";
import { Resource, ResourceInput } from "services/types";
import { RESOURCE_PATH } from "consts";

class ResourceUpdate extends BaseUpdate<Resource, ResourceInput> {
	//ResourceUpdate Expands upon the BaseUpdate by adding in support for uploading files
	//The constructor here will take in an additional uploadFile function in the constructor that it will use to upload the files
	protected file: File | null;
	protected uploadFile: (file: File, path: string) => Promise<string>;

	constructor(
		object: Resource,
		toast: CreateToastFnReturn,
		updateFn: (
			id: string,
			input: ResourceInput,
		) => Promise<{ data?: any; error?: any }>,
		refetch: () => Promise<void>,
		file: File | null,
		uploadFile: (file: File, path: string) => Promise<string>,
	) {
		super(object, "Resource", toast, updateFn, refetch);
		this.file = file;
		this.uploadFile = uploadFile;
	}

	public async handleUpdate(input: ResourceInput): Promise<boolean> {
		if (!this.updateFn) throw new Error("Update function not defined");
		if (!this.uploadFile) throw new Error("Upload function not defined");

		let path = this.object.path;
		if (this.file) {
			try {
				path = await this.uploadFile(this.file, RESOURCE_PATH);
			} catch (err: any) {
				this.toast({
					title: "Error",
					description: err.message,
					status: "error",
					duration: 5000,
					isClosable: true,
				});
				return false;
			}
		}

		const resourceInput: ResourceInput = {
			...input,
			path,
		};

		return await super.handleUpdate(resourceInput);
	}
}

export default ResourceUpdate;
