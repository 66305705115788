import React, { useState } from "react";

import { useToast } from "@chakra-ui/react";

import { IUseForm } from "./types";

const useForm = <T>({ initialState }: IUseForm<T>) => {
	const [formState, setFormState] = useState<T>(initialState);
	const [loading, setLoading] = useState(false);

	const toast = useToast();

	const handleInputChange = (
		e:
			| React.ChangeEvent<HTMLInputElement>
			| {
					target: {
						name: string;
						value: any;
						checked?: boolean;
						type?: string;
					};
			  },
	) => {
		const { name, value, type } = e.target;
		const checked = e.target.checked || false;
		const actualValue: any =
			type === "checkbox" || type === "switch" ? checked : value;

		setFormState((prev: T) => {
			// Function to recursively set the value in the form state
			const updateState = (state: any, path: string[], value: any): any => {
				let current = state;
				for (let i = 0; i < path.length - 1; i++) {
					const key = path[i];
					current[key] = current[key] || {};
					current = current[key];
				}
				current[path[path.length - 1]] = value;
			};

			// Create a copy of the previous state
			const newState = { ...prev };

			// Determine if the name corresponds to a nested path
			const path = name.split(".");

			// Update the state with the actual value
			updateState(newState, path, actualValue);

			return newState as T;
		});
	};

	const resetFormState = () => {
		setFormState(initialState);
	};

	return {
		formState,
		setFormState,
		loading,
		setLoading,
		handleInputChange,
		resetFormState,
		toast,
	};
};

export default useForm;
