/**
 * @module DropdownButton.tsx
 * @description Navigation button component using Chakra UI and React Router for navigation.
 *
 */
import React, { useState, useEffect, useRef } from "react";
import { Menu, MenuButton, MenuList } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";
import NavButton from "./NavButton";
import { NavItem, MapNavButtons } from "components/features/navbar";
// import { useDebounce } from "hooks";
import { colors } from "styles/theme";

type DropdownButtonProps = {
	label: string;
	depth?: number;
	to?: string;
	items: NavItem[];
	closeParentMenu?: (event?: MouseEvent) => void;
	parentMenuRef?: React.RefObject<HTMLDivElement>;
};

const DropdownButton: React.FC<DropdownButtonProps> = ({
	label,
	items,
	to,
	closeParentMenu,
	parentMenuRef,
	depth = 0,
}) => {
	const [isOpen, setIsOpen] = useState(false);

	const menuButtonRef = useRef<HTMLButtonElement>(null);
	const menuListRef = useRef<HTMLDivElement>(null);

	const closeMenu = (event?: MouseEvent) => {
		if (
			isOpen &&
			(!event || !menuListRef.current?.contains(event.target as Node))
		) {
			setIsOpen(false);
			closeParentMenu && closeParentMenu(event);
		}
	};

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (isOpen) {
				if (
					menuListRef.current &&
					!menuListRef.current.contains(event.target as Node)
				) {
					//Don't close if the menuButton is clicked
					if (
						menuButtonRef.current &&
						menuButtonRef.current.contains(event.target as Node)
					)
						return;
					setIsOpen(false);
				}
				if (
					parentMenuRef?.current &&
					!parentMenuRef.current.contains(event.target as Node)
				) {
					closeParentMenu && closeParentMenu(event);
				}
			}
		};

		if (isOpen) {
			document.addEventListener("click", handleClickOutside);
		} else {
			document.removeEventListener("click", handleClickOutside);
		}

		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, [isOpen, parentMenuRef, closeParentMenu]);

	const placement = depth === 0 ? "bottom" : "right-start";

	const ButtonIcon =
		placement === "right-start" ? ChevronRightIcon : ChevronDownIcon;

	return (
		<Menu
			placement={placement} // we need this to be styled recursively from the parent
			gutter={0}
			offset={depth === 0 ? [0, 16] : [0, 0]}
			closeOnSelect={true}
			closeOnBlur={false}
			autoSelect={false}
			size={"sm"}
			isOpen={isOpen}
			isLazy
			onClose={closeMenu}
		>
			<MenuButton
				ref={menuButtonRef}
				key={`${label}-button`}
				id={`${label}-button`}
				as={NavButton}
				rightIcon={<ButtonIcon />}
				iconSpacing={1}
				to={to}
				onClick={() => setIsOpen(!isOpen)}
				variant="ghost_white"
			>
				{label}
			</MenuButton>
			<MenuList
				id={`${label}-menu`}
				borderRadius={10}
				borderWidth={"1px"}
				borderColor={colors.graydient[200]}
				boxShadow="md"
				py="1rem"
				px="0.5rem"
				minW={"auto"}
				ref={menuListRef}
				bgColor="accent.500"
			>
				<MapNavButtons
					navItems={items}
					depth={depth + 1}
					closeParentMenu={closeMenu}
					parentMenuRef={menuListRef}
				/>
			</MenuList>
		</Menu>
	);
};

export default DropdownButton;
