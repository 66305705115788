import React from "react";
import {
	Box,
	Image,
	ResponsiveValue,
	BoxProps,
	ImageProps,
	AspectRatio,
	AspectRatioProps,
} from "@chakra-ui/react";
import { colors } from "styles";

interface ImageButtonProps extends BoxProps {
	onClick: () => void;
	src: string;
	alt: string;
	aspectRatio?: ResponsiveValue<number>;
	aspectRatioProps?: AspectRatioProps;
	imageHeight?: ResponsiveValue<string | number>;
	imageWidth?: ResponsiveValue<string | number>;
	imageProps?: ImageProps;
}

const ImageButton: React.FC<ImageButtonProps> = ({
	onClick,
	src,
	alt,
	aspectRatio = 1,
	aspectRatioProps = {},
	imageHeight = "100%",
	imageWidth = "100%",
	imageProps = {},
	...props
}) => {
	return (
		<AspectRatio ratio={aspectRatio} {...aspectRatioProps}>
			<Box
				p={{ base: 0, md: 4 }}
				borderWidth="1px"
				borderColor={colors.secondary[300]}
				borderRadius="md"
				bg="white"
				transition="all 0.2s ease-in-out"
				_hover={{
					boxShadow: "dark-lg",
					cursor: "pointer",
					transform: { base: "none", md: "translateY(-4px)" },
				}}
				h="100%"
				w="100%"
				position="relative"
				display="flex"
				alignItems="center"
				justifyContent="center"
				onClick={onClick}
				{...props}
			>
				<Image
					src={src}
					alt={alt}
					objectFit="scale-down"
					cursor={"pointer"}
					h={imageHeight}
					w={imageWidth}
					{...imageProps}
				/>
			</Box>
		</AspectRatio>
	);
};

export default ImageButton;
