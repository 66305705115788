/**
 * @module useUpdateResourceForm
 * @category Hooks/Form Hooks
 * @description A custom hook for handling the form state and submission for updating a resource.
 */
import { useUpdateResource } from "services";
import { FieldDefinition } from "components";
import { FileInput } from "components/common/inputs";

import { Resource, ResourceInput } from "services/types";

import { useFormWithFiles } from "hooks/forms";
import ResourceUpdate from "cud/update/ResourceUpdate";
import { ICUDFormHook } from "./types";

/**
 * @typedef {Object} useUpdateResourceForm
 * @property {boolean} loading - The loading state of the form
 * @property {FieldDefinition[]} fields - The form fields
 * @property {() => Promise<void>} handleSubmit - The form submission handler
 * @property {Resource} existingResource - The existing resource to be updated
 * @property {() => Promise<void>} refetch - The refetch function to be called after the resource is updated
 */
const useUpdateResourceForm = ({
	refetch,
	existingResource,
}: ICUDFormHook & { existingResource: Resource }) => {
	// The initial form state is set to the existing resource
	const initialFormState = {
		title: existingResource.title,
		type: existingResource.type,
		path: existingResource.path,
	};

	// The useFormWithFiles hook is used to handle the form state and file uploads
	const {
		formState,
		loading,
		setLoading,
		handleInputChange,
		resetFormState,
		toast,
		files: file,
		handleSetFiles: setFile,
		uploadFile,
		fileUrls,
		handleSetFileUrls: setFileUrls,
	} = useFormWithFiles<ResourceInput>({
		initialState: initialFormState,
		allowMultiple: false,
		initialFileUrls: [existingResource.path],
	});

	// The useUpdateResource hook is used to get the updateResource function
	const { updateResource } = useUpdateResource();

	// The ResourceUpdate class is instantiated with the existing resource, the toast function, the updateResource function, the refetch function, the file, and the uploadFile function
	const resourceUpdate = new ResourceUpdate(
		existingResource,
		toast,
		updateResource,
		refetch,
		file as File | null,
		uploadFile,
	);

	// The handleSubmit function is used to handle the form submission
	const handleSubmit = async () => {
		setLoading(true);
		const res = await resourceUpdate.handleUpdate(formState);
		if (res) resetFormState();
		setLoading(false);
	};

	// The form fields are defined
	const fields: FieldDefinition[] = [
		{
			type: "text",
			name: "title",
			label: "Title",
			value: formState.title,
			placeholder: "Enter a title for the resource...",
			required: true,
			onChange: handleInputChange,
			variant: "base",
			size: "lg",
			width: "100%",
		},
		{
			type: "select",
			name: "type",
			label: "Type",
			value: formState.type,
			placeholder: "Select a type for the resource...",
			required: true,
			onChange: handleInputChange,
			options: ["Video", "Image", "Document"],
			variant: "base",
			size: "lg",
			width: "100%",
		},
		{
			type: "custom",
			name: "file",
			label: "Resource",
			required: false,
			customComponent: FileInput,
			customComponentProps: {
				fileType: formState.type.toLowerCase(),
				files: file ? [file] : [],
				setFiles: setFile,
				allowMultiple: false,
				fileUrls,
				setFileUrls,
			},
			isConditional: true,
			isVisible:
				formState.type.toLowerCase() === "document" ||
				formState.type.toLowerCase() === "image" ||
				formState.type.toLowerCase() === "video",
			minWidth: "100%",
		},
	];

	// The loading state, form fields, and form submission handler are returned
	return { loading, fields, handleSubmit };
};

export default useUpdateResourceForm;
