/**
 * @module Products.tsx
 */
import React from "react";
import { Text, Heading, Container, Box } from "@chakra-ui/react";
import { SectionWrapper } from "wrappers";
import { BoxGrid } from "components";

interface ProductsSectionProps {
	productCategories: string[];
}

const ProductsSection: React.FC<ProductsSectionProps> = ({
	productCategories,
}) => {
	return (
		<>
			<Heading
				variant="sectionTitle"
				mb={6}
				alignSelf="center"
				mx={{ base: "4", md: "40" }}
			>
				Our Products
			</Heading>
			<SectionWrapper>
				<Container maxW="container.xl" py={2}>
					<BoxGrid
						columns={{ base: "repeat(2, 1fr)", md: "repeat(3, 1fr)" }}
						gap={6}
					>
						{productCategories.map((category, index) => (
							<Box key={index} p={{ base: 3, md: 5 }} textAlign="start">
								<Text variant="category" textAlign="start">
									{category}
								</Text>
							</Box>
						))}
					</BoxGrid>
				</Container>
			</SectionWrapper>
		</>
	);
};

export default ProductsSection;
