import React from "react";
import { Flex, Image } from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";

/**
 * @interface CategoryCarouselProps
 * @description Interface for the props of the CategoryCarousel component.
 * @property {string} category - The category of the carousel.
 * @property {string[]} images - An array of image paths.
 * @property {string[]} currentImages - An array of current image paths.
 * @property {number} duration - The duration of the animation.
 * @property {boolean} isPreviousClicked - A boolean indicating if the previous button was clicked.
 */
interface CategoryCarouselProps {
	category: string;
	images: string[];
	currentImages: string[];
	duration: number;
}

/**
 * The CategoryCarousel component displays a carousel of images for a specific category.
 *
 * @param {CategoryCarouselProps} props - The props for the CategoryCarousel component.
 * @returns {React.ReactElement} - A React element of the CategoryCarousel component.
 */
const CategoryCarousel: React.FC<CategoryCarouselProps> = ({
	category,
	images,
	currentImages,
	duration,
}) => {
	return (
		<Flex
			w="100%"
			direction="row"
			justifyContent="center"
			alignItems="center"
			overflow="hidden"
		>
			<Flex
				position="relative"
				flexDirection="row"
				flexWrap="nowrap"
				justifyContent="flex-start"
				alignItems="center"
				overflow="hidden"
				w="fit-content"
				id="flexcontainer"
			>
				<AnimatePresence mode="popLayout">
					{currentImages.map((path, imgIndex) => (
						<motion.div
							key={`${category}-${path}-${imgIndex}`}
							style={{ padding: "0 1rem" }}
							initial={{ x: "100%", scale: 1 }}
							animate={{
								opacity: 1,
								x: 0,
								scale: 1,
								transition: { duration, ease: "easeInOut" },
							}}
							exit={{
								display: imgIndex === 0 ? "block" : "none",
								position: "absolute",
								top: 0,
								left: 0,
								right: undefined,
								x: "-100%",
								opacity: "0",
								transition: {
									duration: imgIndex === 0 ? duration : 0,
									ease: "easeInOut",
								},
							}}
							transition={{ duration, ease: "easeInOut" }}
						>
							<Image
								src={path}
								alt={`${category}-${path}`}
								objectFit="cover"
								loading={"eager"}
								borderRadius="lg"
								borderWidth="1px"
								borderColor="gray.400"
								boxSize={{ base: "90%", sm: "70%", md: "60%", xl: "450px" }}
								boxShadow="md"
								mt={{ base: "4", md: "12" }}
								mb={{ base: "4", md: "8" }}
							/>
						</motion.div>
					))}
				</AnimatePresence>
			</Flex>
		</Flex>
	);
};

export default CategoryCarousel;
