import React from "react";
import { Stack, StackProps, useBreakpointValue } from "@chakra-ui/react";

interface StackLayoutProps extends StackProps {
	orientation?:
		| "horizontal"
		| "vertical"
		| { [key: string]: "horizontal" | "vertical" };
	height?:
		| "auto"
		| "full"
		| "screen"
		| "120vh"
		| { [key: string]: "auto" | "full" | "screen" | "120vh" };
	children: React.ReactNode;
}

const StackLayout: React.FC<StackLayoutProps> = ({
	orientation = "vertical",
	height = "auto",
	children,
	...rest
}) => {
	// Determine the stack direction based on the breakpoint
	const stackDirection =
		useBreakpointValue(
			typeof orientation === "object" ? orientation : { base: orientation },
		) === "vertical"
			? "column"
			: "row";

	// Determine the stack height based on the breakpoint
	const stackHeight = useBreakpointValue(
		typeof height === "object" ? height : { base: height },
	);

	return (
		<Stack
			direction={stackDirection}
			height={stackHeight}
			spacing={8}
			{...rest}
		>
			{children}
		</Stack>
	);
};

export default StackLayout;
