/**
 * @module ComingSoonWrapper.tsx
 * @description A wrapper component that displays a "Coming Soon" modal over the children components being wrapped.
 */
import React, { useEffect } from "react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	Text,
	useDisclosure,
} from "@chakra-ui/react";
import { PageChangeContext } from "contexts";

/**
 * @typedef ComingSoonWrapperProps
 * @property {React.ReactNode} children - The children to be wrapped
 * @property {string} comingSoonFooterText - The footer text for the modal
 * @property {string} comingSoonBtnText - The button text for the modal
 * @property {string} targetPagePath - The path to redirect to
 */
interface ComingSoonWrapperProps {
	children: React.ReactNode;
	comingSoonFooterText: string;
	comingSoonBtnText: string;
	targetPagePath: string;
}

/**
 * @function ComingSoonWrapper
 * @param {ComingSoonWrapperProps} props
 * @returns {JSX.Element}
 */
const ComingSoonWrapper: React.FC<ComingSoonWrapperProps> = ({
	children,
	comingSoonFooterText,
	comingSoonBtnText,
	targetPagePath,
}) => {
	// Get the handlePageChange and goBack functions from the PageChangeContext
	const { handlePageChange } = React.useContext(PageChangeContext);

	// Use the useDisclosure hook to manage the modal state
	const { isOpen, onOpen } = useDisclosure();

	// Open the modal when the component mounts
	useEffect(() => {
		onOpen();
	}, [onOpen]);

	// Function to close the modal
	const handleClose = () => {
		handlePageChange("/");
	};

	// Function to handle the button click
	// Redirects to the target page path passed as a prop
	const onButtonClick = () => {
		handlePageChange(targetPagePath);
	};

	return (
		<>
			{children}
			<Modal
				isOpen={isOpen}
				onClose={() => {}}
				isCentered
				size="xl"
				blockScrollOnMount={false}
				closeOnOverlayClick={false}
				closeOnEsc={false}
			>
				<ModalOverlay bg="blackAlpha.800" backdropFilter="blur(4px)" />
				<ModalContent bg="transparent" boxShadow="none">
					<ModalHeader color="white">Coming Soon</ModalHeader>
					<ModalCloseButton color="white" onClick={handleClose} />
					<ModalBody color="white" py={4}>
						This feature is under development and will be available soon. <br />{" "}
						Thank you for your patience.
					</ModalBody>
					<ModalFooter
						bg="transparent"
						borderTop="1px solid"
						borderColor="whiteAlpha.300"
						justifyContent="space-evenly"
						py={4}
						width="100%"
					>
						<Text color="white" fontSize={{ base: "sm", md: "lg" }}>
							{comingSoonFooterText}
						</Text>
						<Button variant="cta" onClick={onButtonClick}>
							{comingSoonBtnText}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};

export default ComingSoonWrapper;
