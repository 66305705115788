/**
 * @file useCreateProductCollection.ts
 */

import { useMutation } from "@apollo/client";
import { createProductCollection as createProductCollectionGQL } from "./mutations";

import { ProductCollection, ProductCollectionInput } from "../../types";

// Define the structure of the data returned by the mutation
interface CreateProductCollectionResultData {
	createProductCollection: ProductCollection; // Replace ProductCollection with the actual return type of your mutation
}

/**
 * @hook useCreateProductCollection
 *
 * @description
 * Custom hook that handles the createProductCollection mutation functionality.
 * This hook can be used to create a new product collection.
 *
 * @returns An object containing the createProductCollection execution, loading state, and error
 */
const useCreateProductCollection = () => {
	const [createProductCollectionMutation, { loading, error }] =
		useMutation<CreateProductCollectionResultData>(createProductCollectionGQL);

	/**
	 * Executes the createProductCollection mutation.
	 *
	 * @param params - Parameters for the createProductCollection mutation
	 * @returns An object containing the createProductCollection result data or an error object.
	 */
	const createProductCollection = async (
		productCollectionInput: ProductCollectionInput,
	) => {
		try {
			const { data } = await createProductCollectionMutation({
				variables: { productCollectionInput },
			});
			if (error) {
				throw new Error(error.message);
			}
			// Process the returned data as required
			return { data: data?.createProductCollection }; // Replace 'createProductCollection' with the actual field from your mutation
		} catch (error) {
			return { error };
		}
	};

	return { createProductCollection, loading, error };
};

export default useCreateProductCollection;
