/**
 * @module BaseAccordion
 * @category Common Components
 * @description A single accordion item component.
 *
 * This component represents an individual accordion item.
 * It is designed to be used as part of an accordion list, where each item can be expanded or collapsed to show or hide its content.
 *
 * @prop {ReactNode} buttonContent - The content to be displayed on the accordion button.
 * @prop {ReactNode} children - The content to be displayed inside the accordion panel when expanded.
 * @prop {AccordionItemProps} itemProps - Additional props for the accordion item.
 * @prop {AccordionButtonProps} buttonProps - Additional props for the accordion button.
 * @prop {AccordionPanelProps} panelProps - Additional props for the accordion panel.
 */
import {
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionPanelProps,
	AccordionItemProps,
	AccordionButtonProps,
	Icon,
} from "@chakra-ui/react";

import { FiChevronDown, FiChevronUp } from "react-icons/fi";

import { colors } from "styles";

import React from "react";

interface BaseAccordionProps {
	buttonContent: React.ReactNode;
	panelProps?: AccordionPanelProps;
	children: React.ReactNode;
	buttonProps?: AccordionButtonProps;
	itemProps?: AccordionItemProps;
}

const BaseAccordionItem = ({
	buttonContent,
	panelProps,
	children,
	buttonProps,
	itemProps,
}: BaseAccordionProps) => (
	<AccordionItem {...itemProps}>
		{({ isExpanded }) => (
			<>
				<AccordionButton {...buttonProps}>
					{buttonContent}
					<Icon
						fontSize="1.25rem"
						aria-label="expand"
						ml={2}
						_hover={{ color: colors.primary[500], transform: "scale(1.5)" }}
						_active={{ color: "primary.500" }}
					>
						{isExpanded ? <FiChevronUp /> : <FiChevronDown />}
					</Icon>
				</AccordionButton>
				<AccordionPanel {...panelProps}>{children}</AccordionPanel>
			</>
		)}
	</AccordionItem>
);

export default BaseAccordionItem;
