/**
 * @module Form.tsx
 * @author Steven Secreti
 * @author Bryan Shea (contributions)
 */
import React, { useState } from "react";
import {
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	Textarea,
	Select,
	Button,
	Wrap,
	WrapItem,
	Checkbox,
	Switch,
	Collapse,
} from "@chakra-ui/react";

import { FormProps } from "./index";

const Form: React.FC<FormProps> = ({
	fields,
	onSubmit,
	isLoading = false,
	submitButtons,
	submitVariant = "primary",
	...props
}) => {
	const [errors, setErrors] = useState<Record<string, string>>({});

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();

		const newErrors: Record<string, string> = {};
		let isValid = true;
		fields.forEach((field) => {
			if (field.validate) {
				if (field.isConditional && !field.isVisible) return;
				const result = field.validate(field.value);
				if (result !== true) {
					newErrors[field.name!] = result;
					isValid = false;
				}
			}
		});

		setErrors(newErrors);

		if (isValid) onSubmit();
	};

	const getComponent = (propField: any) => {
		const field = {
			...propField,
			required: propField.isConditional
				? propField.isVisible && propField.required
				: propField.required,
		};
		switch (field.type) {
			case "textarea":
				return (
					<Textarea
						id={field.name}
						name={field.name}
						value={field.value}
						placeholder={field.placeholder || ""}
						onChange={field.onChange}
						required={field.required}
						variant={field.variant}
						{...field}
					/>
				);
			case "select":
				return (
					<Select
						id={field.name}
						name={field.name}
						value={field.value}
						placeholder={field.placeholder || ""}
						onChange={field.onChange}
						required={field.required}
						variant={field.variant}
						{...field}
					>
						{field.options?.map((option: string) => (
							<option key={option} value={option}>
								{option}
							</option>
						))}
					</Select>
				);
			case "custom":
				const CustomComponent = field.customComponent;
				const fieldProps = { ...field };
				delete fieldProps.customComponent;
				delete fieldProps.customComponentProps;
				delete fieldProps.validate;
				const customComponentProps = field.customComponentProps || {};
				return <CustomComponent {...fieldProps} {...customComponentProps} />;
			case "checkbox":
				return (
					<Checkbox
						id={field.name}
						name={field.name}
						isChecked={field.value}
						onChange={field.onChange}
						variant={field.variant}
						{...field} // Additional props like color, size etc.
					>
						{field.label}
					</Checkbox>
				);
			case "switch":
				return (
					<Switch
						id={field.name}
						name={field.name}
						isChecked={field.value}
						onChange={field.onChange}
						variant={field.variant}
						size={{ base: "sm", lg: "md" }}
						{...field} // Additional props like color, size etc.
					>
						{field.label}
					</Switch>
				);

			default:
				//Remove `validate` from the field props
				const inputFieldProps = { ...field };
				delete inputFieldProps.validate;
				return <Input id={inputFieldProps.name} {...inputFieldProps} />;
		}
	};

	return (
		<Flex
			as="form"
			onSubmit={handleSubmit}
			direction="column"
			alignItems="center"
			justifyContent="space-between"
			alignSelf="center"
			width="100%"
			height="100%"
			{...props}
		>
			<Wrap
				display="stretch"
				p="1rem"
				width="100%"
				// Changed to `auto` to enhance the visual heirarchy of the form. Space below is justifiable i.e. additional form fields, space above is redundant.
				h="auto"
				justify={"space-between"}
				alignItems="center"
				alignContent={"center"}
				spacing={4}
			>
				{fields.map((field) => (
					<WrapItem
						key={field.name}
						width={field.minWidth || "auto"}
						height="auto"
						flexGrow={field.flexGrow || 1}
					>
						{field.isConditional ? (
							<Collapse
								in={field.isVisible}
								style={{
									width: "100%",
									position: "relative",
									overflow: "visible",
								}}
								animateOpacity
								unmountOnExit
							>
								<FormControl
									key={field.name}
									isRequired={field.required && field.isVisible ? true : false}
									isInvalid={!!errors[field.name]}
								>
									{field.type !== "checkbox" && field.type !== "switch" && (
										<FormLabel htmlFor={field.name}>{field.label}</FormLabel>
									)}
									{getComponent(field)}
									<FormErrorMessage aria-live="assertive">
										{errors[field.name]}
									</FormErrorMessage>
								</FormControl>
							</Collapse>
						) : (
							<FormControl
								key={field.name}
								isRequired={field.required ? true : false}
								isInvalid={!!errors[field.name]}
							>
								{field.type !== "checkbox" && field.type !== "switch" && (
									<FormLabel htmlFor={field.name}>{field.label}</FormLabel>
								)}
								{getComponent(field)}
								<FormErrorMessage aria-live="assertive">
									{errors[field.name]}
								</FormErrorMessage>
							</FormControl>
						)}
					</WrapItem>
				))}
			</Wrap>
			{submitButtons ? (
				submitButtons
			) : (
				<Button
					variant={submitVariant}
					type="submit"
					isLoading={isLoading}
					p={5}
					mt={4}
				>
					Submit
				</Button>
			)}
		</Flex>
	);
};

export default Form;
