//import { switchAnatomy } from '@chakra-ui/anatomy'
//import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'
import { colors } from "styles/theme";
import { ComponentStyleConfig } from "@chakra-ui/react";

const switchStyles: ComponentStyleConfig = {
	parts: ["container", "thumb", "track"],
	baseStyle: {},
	variants: {
		login: () => ({
			container: {
				//bg: colors.graydient[50],
			},
			thumb: {
				bg: colors.graydient[50],
			},
			track: {
				bg: colors.graydient[400],
				p: 1,
				//px: "0.5rem",
				_checked: {
					bg: colors.primary[500],
				},
			},
		}),
	},
};

export default switchStyles;
