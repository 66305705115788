import { useState } from "react";
import { BaseDelete } from "cud/delete";
import { useToast } from "@chakra-ui/react";
import useDeleteCustomWorkGalleryImage from "services/content-management/mutations/useDeleteCustomWorkGalleryImage";
import { CustomWorkGalleryImage } from "services";

const useDeleteCustomWorkGalleryForm = (
	customWorkGalleryImage: CustomWorkGalleryImage,
	refetch: () => Promise<void>,
) => {
	const [loading, setLoading] = useState(false);
	const toast = useToast();
	const { deleteCustomWorkGalleryImage } = useDeleteCustomWorkGalleryImage();

	const baseDelete = new BaseDelete<CustomWorkGalleryImage>(
		customWorkGalleryImage,
		"custom work gallery image",
		toast,
		deleteCustomWorkGalleryImage,
		refetch,
	);

	const handleDelete = async () => {
		setLoading(true);
		await baseDelete.handleDelete();
		setLoading(false);
	};

	return {
		handleDelete,
		loading,
	};
};

export default useDeleteCustomWorkGalleryForm;
