/**
 * @file useGetUploadURL.ts
 */
import { useMutation } from "@apollo/client";
import { getUploadURL as getUploadURLGQL } from "./mutations";
import { FileUploadInput } from "../../types";

// Define the structure of the data returned by the mutation
interface GetUploadURLResultData {
	getUploadURL: string; // Assuming the mutation returns a string URL
}

/**
 * @hook useGetUploadURL
 *
 * @description
 * Custom hook that handles the getUploadURL mutation functionality.
 * Use this hook to obtain a secure URL for file uploads.
 *
 * @returns An object containing the getUploadURL execution, loading state, and error
 */
const useGetUploadURL = () => {
	const [getUploadURLFunction, { loading, error }] =
		useMutation<GetUploadURLResultData>(getUploadURLGQL);

	/**
	 * Executes the getUploadURL mutation.
	 *
	 * @param params - Parameters for the mutation
	 * @returns An object containing the mutation result data or an error object.
	 */
	const getUploadURL = async (input: FileUploadInput) => {
		try {
			const { data } = await getUploadURLFunction({
				variables: { input },
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.getUploadURL };
		} catch (error) {
			return { error };
		}
	};

	return { getUploadURL, loading, error };
};

export default useGetUploadURL;
