/**
 * @module Promotions.tsx
 * @description This file contains the Promotions component.
 * This currently includes just the `CreatePromotionForm` component.
 */
import React, { useState } from "react";
import { ActionsRow } from "components/features/tables/cells";
import { useRetrieval } from "hooks";
import { SearchTable } from "components/features/tables";
import { createColumnHelper } from "@tanstack/react-table";
import { Promotion, useGetPromotions, FilterSettingsInput } from "services";
import {
	CreatePromotionForm,
	UpdatePromotionForm,
	DeletePromotionModal,
} from "page-components/admin/promotions";

import { AdminResponsiveLayout } from "components";

const AdminPromotions: React.FC = () => {
	const {
		paginationInput,
		setPaginationInput,
		filterSettings,
		handleFiltersChange,
		sortInput,
		handleSortChange,
	} = useRetrieval();

	const handleBuildFilterSettings = (
		query: string,
		conditional: "AND" | "OR" = "OR",
	) => {
		const filterSettings: FilterSettingsInput = {
			conditional,
			filters: [
				{
					field: "title",
					operator: "regex",
					value: query,
				},
				{
					field: "subtitle",
					operator: "regex",
					value: query,
				},
				{
					field: "callToAction",
					operator: "regex",
					value: query,
				},
			],
		};
		return filterSettings;
	};

	const {
		loading,
		error,
		data: promotions,
		refetch,
	} = useGetPromotions({
		filterSettings: handleBuildFilterSettings(
			filterSettings.query,
			filterSettings.conditional,
		),
		sort: sortInput,
		pagination: paginationInput,
	});

	const [selectedPromotion, setSelectedPromotion] = useState<Promotion | null>(
		null,
	);

	const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

	const closeDeleteModal = () => {
		setIsDeleteModalOpen(false);
		setSelectedPromotion(null);
	};

	const closeUpdateModal = () => {
		setIsUpdateModalOpen(false);
		setSelectedPromotion(null);
	};

	const onRowEdit = (promotion: Promotion) => {
		setSelectedPromotion(promotion);
		setIsUpdateModalOpen(true);
	};

	const onRowDelete = (promotion: Promotion) => {
		setSelectedPromotion(promotion);
		setIsDeleteModalOpen(true);
	};

	const columnHelper = createColumnHelper<Promotion>();

	const columns = [
		columnHelper.accessor("title", {
			header: () => "Title",
			cell: (info) => info.getValue(),
		}),
		columnHelper.accessor("subtitle", {
			header: () => "Subtitle",
			cell: (info) => info.getValue(),
		}),
		columnHelper.display({
			header: () => "Actions",
			id: "actions",
			cell: (info) => (
				<ActionsRow
					onEdit={() => onRowEdit(info.row.original)}
					onDelete={() => onRowDelete(info.row.original)}
				/>
			),
		}),
	];

	return (
		<AdminResponsiveLayout>
			<CreatePromotionForm refetch={refetch} />
			<SearchTable
				columnsData={columns}
				tableData={promotions?.items || []}
				paginationInput={paginationInput}
				setPaginationInput={setPaginationInput}
				filterSettings={filterSettings}
				handleBuildFilters={handleFiltersChange}
				sortInput={sortInput}
				handleSortChange={handleSortChange}
				loading={loading}
				error={error}
				totalCount={promotions?.totalCount || 0}
				hasNextPage={promotions?.hasNextPage || false}
				hasPreviousPage={promotions?.hasPreviousPage || false}
			/>
			{selectedPromotion && (
				<UpdatePromotionForm
					existingPromotion={selectedPromotion}
					refetch={refetch}
					isOpen={isUpdateModalOpen}
					onClose={closeUpdateModal}
				/>
			)}
			{selectedPromotion && (
				<DeletePromotionModal
					promotion={selectedPromotion}
					refetch={refetch}
					isOpen={isDeleteModalOpen}
					onClose={closeDeleteModal}
				/>
			)}
		</AdminResponsiveLayout>
	);
};

export default AdminPromotions;
