// dateUtils.ts

/**
 * Formats dates for display.
 * @param date A Date object.
 * @returns A string representing the formatted date.
 */
export function formatDate(date: Date): string {
	return date.toLocaleDateString(undefined, {
		day: "2-digit",
		month: "long",
		year: "numeric",
	});
}

/**
 * Formats times for display.
 * @param date A Date object.
 * @returns A string representing the formatted time.
 */
export function formatTime(date: Date): string {
	return date.toLocaleTimeString(undefined, {
		hour: "2-digit",
		minute: "2-digit",
	});
}

/**
 * Formats dates and times for display.
 * @param date A Date object.
 * @returns A string representing the formatted date and time.
 */
export function formatDateTime(date: Date): string {
	return `${formatDate(date)} ${formatTime(date)}`;
}

/**
 * Formats dates for input fields.
 * @param date A Date object.
 * @returns A string in 'YYYY-MM-DD' format.
 */
export function formatDateForInput(date: Date): string {
	return date.toISOString().split("T")[0];
}

/**
 * Formats times for input fields.
 * @param date A Date object.
 * @returns A string in 'HH:MM' format.
 */
export function formatTimeForInput(date: Date): string {
	return date.toISOString().split("T")[1].substring(0, 5);
}

export function formatUnixTimestampStringForDateDisplay(
	timestamp: string,
): string {
	const date = convertUnixTimestampToDateTime(parseInt(timestamp));
	return date.toISOString().split("T")[0];
}

/**
 * @function formatUnixTimestampStringForDateInput
 */
export function formatUnixTimestampStringForDateInput(
	timestamp: string,
): string {
	const date = convertUnixTimestampToDateTime(parseInt(timestamp));
	return formatDateForInput(date);
}

/**
 * Converts a Unix timestamp to a Date object.
 * @param timestamp A Unix timestamp.
 * @returns A Date object.
 */
export function convertUnixTimestampToDateTime(timestamp: number): Date {
	return new Date(timestamp);
}
