import React, { useState } from "react";
import { ActionsRow } from "components/features/tables/cells";
import { useRetrieval } from "hooks";
import { SearchTable } from "components/features/tables";
import { createColumnHelper } from "@tanstack/react-table";
import {
	GalleryImage,
	useGetGalleryImages,
	FilterSettingsInput,
} from "services";
import {
	CreateGalleryForm,
	DeleteGalleryModal,
} from "page-components/admin/gallery";
import { Image } from "@chakra-ui/react";
import { AdminResponsiveLayout } from "components";

const AdminGallery: React.FC = () => {
	const {
		paginationInput,
		setPaginationInput,
		filterSettings,
		sortInput,
		handleSortChange,
	} = useRetrieval();

	const handleBuildFilterSettings = (
		query: string,
		conditional: "AND" | "OR" = "OR",
	) => {
		const filterSettings: FilterSettingsInput = {
			conditional,
			filters: [
				{
					field: "title",
					operator: "regex",
					value: query,
				},
			],
		};
		return filterSettings;
	};

	const {
		loading,
		error,
		data: galleryImages,
		refetch,
	} = useGetGalleryImages({
		filterSettings: handleBuildFilterSettings(
			filterSettings.query,
			filterSettings.conditional,
		),
		pagination: paginationInput,
		sort: sortInput,
	});

	const [selectedGalleryImage, setSelectedGalleryImage] =
		useState<GalleryImage | null>(null);

	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

	const closeDeleteModal = () => {
		setIsDeleteModalOpen(false);
		setSelectedGalleryImage(null);
	};

	const onRowDelete = (galleryImage: GalleryImage) => {
		setSelectedGalleryImage(galleryImage);
		setIsDeleteModalOpen(true); // Open the modal
	};

	const columnHelper = createColumnHelper<GalleryImage>();

	const columns = [
		columnHelper.accessor("title", {
			header: () => "Image Title",
			cell: (info) => info.getValue(),
		}),
		columnHelper.display({
			header: () => "Image",
			id: "image",
			cell: (info) => (
				<Image
					src={info.row.original.image}
					alt={info.row.original.title}
					width={"auto"}
					height={{ base: "100px", md: "auto", lg: "auto", xl: "12rem" }}
					minW="100px"
				/>
			),
		}),
		columnHelper.display({
			header: () => "Actions",
			id: "actions",
			cell: (props) => (
				<ActionsRow onDelete={() => onRowDelete(props.row.original)} />
			),
		}),
	];

	return (
		<AdminResponsiveLayout>
			<SearchTable
				columnsData={columns}
				tableData={galleryImages?.items || []}
				paginationInput={paginationInput}
				setPaginationInput={setPaginationInput}
				filterSettings={filterSettings}
				handleBuildFilters={handleBuildFilterSettings}
				sortInput={sortInput}
				handleSortChange={handleSortChange}
				loading={loading}
				error={error}
				totalCount={galleryImages?.totalCount || 0}
				hasNextPage={galleryImages?.hasNextPage || false}
				hasPreviousPage={galleryImages?.hasPreviousPage || false}
			/>
			<CreateGalleryForm refetch={refetch} />
			{selectedGalleryImage && (
				<DeleteGalleryModal
					galleryImage={selectedGalleryImage}
					isOpen={isDeleteModalOpen}
					onClose={closeDeleteModal}
					refetch={refetch}
				/>
			)}
		</AdminResponsiveLayout>
	);
};

export default AdminGallery;
