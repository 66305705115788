import React, { useState, useRef, useEffect, useCallback } from "react";
import { VStack, Flex } from "@chakra-ui/react";
import { motion, useAnimation, useMotionValue } from "framer-motion";

interface TrackProps {
	setTrackIsActive: (isActive: boolean) => void;
	trackIsActive: boolean;
	setActiveItem: (updateFn: (prev: number) => number) => void;
	activeItem: number;
	constraint: number;
	multiplier: number;
	itemWidth: number;
	positions: number[];
	children: React.ReactNode;
}

const MotionFlex = motion(Flex);

const Track: React.FC<TrackProps> = ({
	setTrackIsActive,
	trackIsActive,
	setActiveItem,
	activeItem,
	constraint,
	multiplier,
	itemWidth,
	positions,
	children,
}) => {
	const [dragStartPosition, setDragStartPosition] = useState(0);
	const controls = useAnimation();
	const x = useMotionValue(0);
	const node = useRef<HTMLDivElement>(null);

	const handleDragStart = () => setDragStartPosition(positions[activeItem]);

	const handleDragEnd = (_: any, info: any) => {
		const distance = info.offset.x;
		const velocity = info.velocity.x * multiplier;
		const direction = velocity < 0 || distance < 0 ? 1 : -1;
		const extrapolatedPosition =
			dragStartPosition +
			(direction === 1
				? Math.min(velocity, distance)
				: Math.max(velocity, distance));

		const closestPosition = positions.reduce((prev, curr) =>
			Math.abs(curr - extrapolatedPosition) <
			Math.abs(prev - extrapolatedPosition)
				? curr
				: prev,
		);

		if (closestPosition >= positions[positions.length - constraint]) {
			setActiveItem((prev) => positions.indexOf(closestPosition));
			controls.start({
				x: closestPosition,
				transition: {
					velocity: info.velocity.x,
					stiffness: 400,
					type: "spring",
					damping: 60,
					mass: 3,
				},
			});
		} else {
			setActiveItem((prev) => positions.length - constraint);
			controls.start({
				x: positions[positions.length - constraint],
				transition: {
					velocity: info.velocity.x,
					stiffness: 400,
					type: "spring",
					damping: 60,
					mass: 3,
				},
			});
		}
	};

	const handleResize = useCallback(() => {
		controls.start({
			x: positions[activeItem],
			transition: { stiffness: 400, type: "spring", damping: 60, mass: 3 },
		});
	}, [activeItem, controls, positions]);

	const handleClick = useCallback(
		(event: MouseEvent) => {
			if (node.current && !node.current.contains(event.target as Node)) {
				setTrackIsActive(false);
			}
		},
		[setTrackIsActive],
	);

	const handleKeyDown = useCallback(
		(event: KeyboardEvent) => {
			if (trackIsActive) {
				if (event.key === "ArrowRight" || event.key === "ArrowUp") {
					event.preventDefault();
					setActiveItem((prev) =>
						prev < positions.length - constraint ? prev + 1 : prev,
					);
				} else if (event.key === "ArrowLeft" || event.key === "ArrowDown") {
					event.preventDefault();
					setActiveItem((prev) => (prev > 0 ? prev - 1 : prev));
				}
			}
		},
		[trackIsActive, setActiveItem, positions.length, constraint],
	);

	useEffect(() => {
		handleResize();

		document.addEventListener("keydown", handleKeyDown as any);
		document.addEventListener("mousedown", handleClick as any);
		return () => {
			document.removeEventListener("keydown", handleKeyDown as any);
			document.removeEventListener("mousedown", handleClick as any);
		};
	}, [handleClick, handleResize, handleKeyDown]);

	return (
		<>
			{itemWidth && (
				<VStack ref={node} spacing={5} alignItems="stretch">
					<MotionFlex
						dragConstraints={node}
						onDragStart={handleDragStart}
						onDragEnd={handleDragEnd}
						animate={controls}
						style={{
							x,
						}}
						drag="x"
						_active={{ cursor: "grabbing" }}
						minWidth="min-content"
						flexWrap="nowrap"
						cursor="grab"
						zIndex={2}
					>
						{children}
					</MotionFlex>
				</VStack>
			)}
		</>
	);
};

export default Track;
