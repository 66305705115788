import BaseCreate from "./BaseCreate";

import { CreateToastFnReturn } from "@chakra-ui/react";
import { PromotionInput } from "services";
import { PROMOTION_PATH } from "consts";

class PromotionCreate extends BaseCreate<PromotionInput> {
	protected files: File[] | null;
	protected uploadFile: (file: File, path: string) => Promise<string>;

	constructor(
		toast: CreateToastFnReturn,
		createFn: (input: PromotionInput) => Promise<{ data?: any; error?: any }>,
		refetch: () => Promise<void>,
		files: File[] | null,
		uploadFile: (file: File, path: string) => Promise<string>,
	) {
		super("Promotion", toast, createFn, refetch);
		this.files = files;
		this.uploadFile = uploadFile;
	}

	public async handleCreate(input: PromotionInput): Promise<boolean> {
		if (!this.createFn) throw new Error("Create function not defined");
		if (!this.uploadFile) throw new Error("Upload function not defined");
		let paths = [];
		try {
			if (this.files) {
				for (let i = 0; i < this.files.length; i++) {
					const path = await this.uploadFile(this.files[i], PROMOTION_PATH);
					paths.push(path);
				}
			}
		} catch (err: any) {
			this.toast({
				title: "Error",
				description: err.message,
				status: "error",
				duration: 5000,
				isClosable: true,
			});
			return false;
		}

		const promotionInput: PromotionInput = {
			...input,
			images: paths,
		};

		return await super.handleCreate(promotionInput);
	}
}

export default PromotionCreate;
