import React from "react";
import {
	AspectRatio,
	Image,
	ImageProps,
	ResponsiveValue,
	AspectRatioProps,
} from "@chakra-ui/react";

interface ImagePreviewProps extends ImageProps {
	src: string;
	alt: string;
	aspectRatio?: ResponsiveValue<number>;
	aspectRatioProps?: AspectRatioProps;
}

const ImagePreview: React.FC<ImagePreviewProps> = ({
	src,
	alt,
	aspectRatio = 1,
	aspectRatioProps = {},
	...props
}) => {
	return (
		<AspectRatio ratio={aspectRatio} {...aspectRatioProps}>
			<Image
				src={src}
				alt={alt}
				objectFit={"cover"}
				borderRadius="md"
				{...props}
			/>
		</AspectRatio>
	);
};

export default ImagePreview;
