import { ComponentStyleConfig } from "@chakra-ui/react";
import { colors } from "styles";

const textareaStyles: ComponentStyleConfig = {
	variants: {
		outline: {
			borderColor: "gray",
			borderWidth: "1px",
			padding: 2,
			_hover: {
				borderColor: "gray.400",
			},
			_focus: {
				borderColor: "blue.500",
				boxShadow: "0 0 0 1px blue.500",
			},
			minHeight: "120px",
		},
		base: {
			borderColor: colors.graydient[300],
			borderWidth: "1px",
			boxShadow: "base",
			color: colors.graydient[600],
			paddingX: 4,
			_hover: {
				borderColor: "gray.400",
				boxShadow: "lg",
			},
			_focus: {
				borderColor: "blue.500",
				boxShadow: "0 0 0 1px blue.500",
			},
			"::placeholder": {
				color: colors.graydient[500],
			},
		},
	},
	// defaultProps can be added if needed
};

export default textareaStyles;
