import React from "react";
import { Container } from "@chakra-ui/react";
import { EmployeeCollections } from "page-components/employee";
import { ImagePreview, IFramePreview } from "components/features/previews";
import { ImageButton } from "components";
import { Loader, ErrorMessage } from "components/common/ui-elements";
import { EmployeeResourcesContext } from "contexts";

const EmployeeDashboard: React.FC = () => {
	//use context to get resources and catalogs
	const {
		loading,
		error,
		paginatedDocuments,
		paginatedImages,
		paginatedVideos,
		paginatedCatalogs,
	} = React.useContext(EmployeeResourcesContext);

	if (loading) return <Loader />;

	if (error)
		return (
			<ErrorMessage
				title={"We're sorry, an error occurred while loading this dashboard"}
				description="Please try again later."
			/>
		);
	if (
		!paginatedVideos ||
		!paginatedVideos.items ||
		!paginatedImages ||
		!paginatedImages.items ||
		!paginatedDocuments ||
		!paginatedDocuments.items ||
		!paginatedCatalogs ||
		!paginatedCatalogs.items
	)
		return (
			<ErrorMessage
				title={"We're sorry, there are no resources available at this time"}
				description="Please try again later."
			/>
		);

	const imagePreviews = paginatedImages?.items.map((resource, index) => (
		<ImagePreview
			key={`image-preview-${index}-${resource._id}`}
			src={resource.path}
			alt={resource.title}
		/>
	));

	const videoPreviews = paginatedVideos?.items.map((resource, index) => (
		<IFramePreview
			key={`video-preview-${index}-${resource._id}`}
			src={resource.path}
			title={resource.title}
		/>
	));

	const documentPreviews = paginatedDocuments.items.map((resource, index) => (
		<IFramePreview
			key={`document-preview-${index}-${resource._id}`}
			src={resource.path}
			title={resource.title}
		/>
	));

	const catalogs = paginatedCatalogs.items;
	const catalogPreviews = catalogs.map((catalog, index) => (
		<ImageButton
			key={`catalog-preview-${index}-${catalog._id}`}
			src={catalog.image}
			alt={catalog.name}
			onClick={() => window.open(catalog.src, "_blank")}
		/>
	));

	return (
		<Container
			maxW="container.2xl"
			centerContent
			paddingY={2}
			overflowY="scroll"
		>
			<EmployeeCollections
				collections={[
					{
						title: "Catalogs",
						buttonText: "View All",
						items: catalogPreviews,
					},
					{
						title: "Images",
						buttonText: "View All",
						items: imagePreviews,
					},
					{
						title: "Videos",
						buttonText: "View All",
						items: videoPreviews,
					},
					{
						title: "Documents",
						buttonText: "View All",
						items: documentPreviews,
					},
				]}
			/>
		</Container>
	);
};

export default EmployeeDashboard;
