import React from "react";
import { Container, VStack, Flex } from "@chakra-ui/react";
import { Collection } from "page-components";
import { useGetProductCollections } from "services";
import {
	Loader,
	ErrorMessage,
	InfoMessage,
} from "components/common/ui-elements";

const Trending = () => {
	const { data: collections, loading, error } = useGetProductCollections();

	const collectionComponents = collections?.items.map((collection, index) => (
		<Collection
			collection={collection}
			textPosition={index % 2 === 0 ? "left" : "right"}
		/>
	));

	const CollectionsContent = () => {
		if (loading) {
			return <Loader />;
		}
		if (error) {
			return (
				<ErrorMessage
					title={"We were unable to load our product collections."}
					description={
						"You can try refreshing your browser or try again later. We apologize for the inconvenience."
					}
					alertProps={{ minHeight: "85vh" }}
				/>
			);
		}
		if (collectionComponents) {
			if (collectionComponents.length > 0) {
				return (
					<>
						{collectionComponents.map((collection, index) => {
							return (
								<Flex
									key={`collection-${index}`}
									position="relative"
									w={{ base: "auto", lg: "100%" }}
									h="100%"
								>
									{collection}
								</Flex>
							);
						})}
					</>
				);
			} else {
				return (
					<InfoMessage
						title={"We currently don't have any trending product collections."}
						description={"Please check back later."}
						alertProps={{ minHeight: "85vh" }}
					/>
				);
			}
		}
		return (
			<ErrorMessage
				title={"We were unable to load our trending product collections."}
				description={
					"You can try refreshing your browser or try again later. We apologize for the inconvenience."
				}
			/>
		);
	};

	return (
		<Container
			h="100%"
			maxW="100%"
			py={10}
			w="100%"
			zIndex={0}
			minH={{ base: "auto", md: "auto", lg: "auto", xl: "85vh" }}
		>
			<VStack spacing={16} w="100%" h="100%">
				<CollectionsContent />
			</VStack>
		</Container>
	);
};

export default Trending;
