import React, {
	createContext,
	useState,
	useEffect,
	ReactNode,
	useContext,
} from "react";
import { PageChangeContext } from "./PageChangeContext";
import { useAuthEmployee, Employee } from "services";
import { SidebarItem } from "components/features/sidebar/types";

import {
	CONTENT_MANAGEMENT_ACCESS,
	APPLICATION_MANAGEMENT_ACCESS,
	ADMIN_ACCESS,
} from "consts";

import {
	FaHandsHelping,
	FaQuestionCircle,
	FaClipboardList,
	FaBook,
	FaImages,
	FaWrench,
	FaShoppingBag,
	FaStar,
} from "react-icons/fa";
import {
	FiMenu,
	FiBookmark,
	FiVideo,
	FiPaperclip,
	FiImage,
} from "react-icons/fi";
import { FaUserTie, FaBriefcase } from "react-icons/fa6";
import { MdCollections, MdNewspaper } from "react-icons/md";
import { BsClipboardDataFill } from "react-icons/bs";
import { HiUsers } from "react-icons/hi";

interface EmployeeContextType {
	employee: Employee | null;
	refetch: () => void;
	loading: boolean;
	hasAccess: (accessLevels: string[]) => boolean;
	adminSidebarItems: SidebarItem[];
	employeeSidebarItems: SidebarItem[];
	pageLabel: string;
}

export const EmployeeContext = createContext<EmployeeContextType>({
	employee: null,
	refetch: () => {},
	loading: true,
	hasAccess: () => false,
	adminSidebarItems: [],
	employeeSidebarItems: [],
	pageLabel: "",
});

interface EmployeeProviderProps {
	children: ReactNode;
}

export const EmployeeProvider: React.FC<EmployeeProviderProps> = ({
	children,
}) => {
	const [employee, setEmployee] = useState<Employee | null>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const [pageLabel, setPageLabel] = useState<string>("");

	const { handlePageChange, page } = useContext(PageChangeContext);

	const {
		data: employeeData,
		refetch: employeeRefetch,
		loading: employeeLoading,
		error,
	} = useAuthEmployee();

	useEffect(() => {
		if (employeeData) {
			setEmployee(employeeData._id ? employeeData : null);
		} else {
			setEmployee(null);
		}
	}, [employeeData]);

	useEffect(() => {
		setLoading(employeeLoading);
	}, [employeeLoading]);

	useEffect(() => {
		if (error) {
			console.error("Error fetching employee data:", error);
		}
	}, [error]);

	/**
	 * Determines whether the employee has access to a specific access level.
	 *
	 * @param {string} accessLevel - The access level to check.
	 * @returns {boolean} - True if the employee has access, false otherwise.
	 */
	const hasAccess = (accessLevels: string[]) => {
		if (employee) {
			//Check if any of the access levels in accessLevels are in the employee's accessLevel
			return accessLevels.some((accessLevel) =>
				employee.accessLevel.includes(accessLevel),
			);
		} else {
			return false;
		}
	};

	const applicationItems = [
		{
			label: "Dealer Applications",
			icon: HiUsers,
			onClick: (label: string) =>
				handlePageChange(`/admin/dealer-applications`),
			route: "/admin/dealer-applications",
		},
		{
			label: "Career Applications",
			icon: FaClipboardList,
			onClick: (label: string) => handlePageChange(`/admin/career`),
			route: "/admin/career",
		},
	];

	const contentItems = [
		{
			label: "Partners",
			icon: FaHandsHelping,
			onClick: (label: string) =>
				handlePageChange(`/admin/${label.toLowerCase()}`),
			route: "/admin/partners",
		},
		{
			label: "Catalogs",
			icon: FaBook,
			onClick: (label: string) =>
				handlePageChange(`/admin/${label.toLowerCase()}`),
			route: "/admin/catalogs",
		},
		{
			label: "Trending",
			icon: MdCollections,
			onClick: (label: string) =>
				handlePageChange(`/admin/${label.toLowerCase()}`),
			route: "/admin/trending",
		},
		{
			label: "Promotions",
			icon: MdNewspaper,
			onClick: (label: string) =>
				handlePageChange(`/admin/${label.toLowerCase()}`),
			route: "/admin/promotions",
		},
		// reviews
		{
			label: "Reviews",
			icon: FaStar,
			onClick: (label: string) =>
				handlePageChange(`/admin/${label.toLowerCase()}`),
			route: "/admin/reviews",
		},
		{
			label: "FAQ",
			icon: FaQuestionCircle,
			onClick: (label: string) =>
				handlePageChange(`/admin/${label.toLowerCase()}`),
			route: "/admin/faq",
		},
		{
			label: "Gallery",
			icon: FaImages,
			onClick: (label: string) =>
				handlePageChange(`/admin/${label.toLowerCase()}`),
			route: "/admin/gallery",
		},
		{
			label: "Custom Work Gallery",
			icon: FaWrench,
			onClick: (label: string) => handlePageChange(`/admin/custom`),
			route: "/admin/custom",
		},
		{
			label: "Job Openings/Positions",
			icon: FaBriefcase,
			onClick: (label: string) => handlePageChange(`/admin/jobs`),
			route: "/admin/jobs",
		},
		{
			label: "Products (Ecwid)",
			icon: FaShoppingBag,
			onClick: (label: string) =>
				window.open("https://my.ecwid.com/store/69679755", "_blank"),
		},
	];

	const adminItems: SidebarItem[] = [
		{
			label: "Employees",
			icon: FaUserTie,
			onClick: (label: string) =>
				handlePageChange(`/admin/${label.toLowerCase()}`),
			route: "/admin/employees",
		},
		{
			label: "Resources",
			icon: BsClipboardDataFill,
			onClick: (label: string) =>
				handlePageChange(`/admin/${label.toLowerCase()}`),
			route: "/admin/resources",
		},
	];

	const adminSidebarItems: SidebarItem[] = [];

	//Include application items if user has access to application management
	if (hasAccess(ADMIN_ACCESS)) {
		adminSidebarItems.push(...adminItems);
	}
	if (hasAccess(APPLICATION_MANAGEMENT_ACCESS)) {
		adminSidebarItems.push(...applicationItems);
	}
	if (hasAccess(CONTENT_MANAGEMENT_ACCESS)) {
		adminSidebarItems.push(...contentItems);
	}

	const employeeSidebarItems = [
		{
			label: "Dashboard",
			icon: FiMenu,
			onClick: (label: string) =>
				handlePageChange(`/employee/${label.toLowerCase()}`),
			route: "/employee/dashboard",
		},
		{
			label: "Images",
			icon: FiImage,
			onClick: (label: string) =>
				handlePageChange(`/employee/resources/${label.toLowerCase()}`),
			route: "/employee/resources/images",
		},
		{
			label: "Catalogs",
			icon: FiBookmark,
			onClick: (label: string) =>
				handlePageChange(`/employee/resources/${label.toLowerCase()}`),
			route: "/employee/resources/catalogs",
		},
		{
			label: "Videos",
			icon: FiVideo,
			onClick: (label: string) =>
				handlePageChange(`/employee/resources/${label.toLowerCase()}`),
			route: "/employee/resources/videos",
		},
		{
			label: "Documents",
			icon: FiPaperclip,
			onClick: (label: string) =>
				handlePageChange(`/employee/resources/${label.toLowerCase()}`),
			route: "/employee/resources/documents",
		},
	];

	useEffect(() => {
		const findPageLabel = () => {
			const pageWithSlash = page.startsWith("/") ? page : `/${page}`;

			const findLabel = (items: SidebarItem[]): string | undefined => {
				for (const item of items) {
					if (item.route === pageWithSlash) {
						return item.label;
					}
					if (item.items) {
						const label = findLabel(item.items);
						if (label) {
							return label;
						}
					}
				}
				return "";
			};

			const adminLabel = findLabel(adminSidebarItems);
			const employeeLabel = findLabel(employeeSidebarItems);

			setPageLabel(adminLabel || employeeLabel || "");
		};

		findPageLabel();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	return (
		<EmployeeContext.Provider
			value={{
				employee,
				refetch: employeeRefetch,
				loading,
				hasAccess,
				adminSidebarItems,
				employeeSidebarItems,
				pageLabel,
			}}
		>
			{children}
		</EmployeeContext.Provider>
	);
};
