/**
 * @module MapNavButtons.tsx
 */
import React from "react";

import {
	Flex,
	useBreakpointValue,
	ResponsiveValue,
	Text,
} from "@chakra-ui/react";

import { NavButton, DropdownButton } from "components/common/buttons";
import { NavItem } from "components/features/navbar";

interface IMapNavButtonsProps {
	navItems: NavItem[];
	depth?: number;
	closeParentMenu?: () => void;
	parentMenuRef?: React.RefObject<HTMLDivElement>;
}

const MapNavButtons: React.FC<IMapNavButtonsProps> = ({
	navItems,
	closeParentMenu,
	parentMenuRef,
	depth = 0,
}) => {
	const isMobile = useBreakpointValue(
		{ base: true, xl: false },
		{ ssr: false },
	);

	let direction = "row";
	if (isMobile || depth !== 0) direction = "column";

	return (
		<Flex
			direction={
				direction as ResponsiveValue<
					"row" | "column" | "row-reverse" | "column-reverse"
				>
			}
			gap={depth === 0 ? 0 : 2}
			alignItems={isMobile ? "flex-start" : "center"}
			justifyContent={isMobile ? "flex-start" : "space-between"}
			flexGrow={1}
		>
			{navItems.map((item) =>
				item.items ? (
					isMobile ? (
						<React.Fragment key={`${item.label}-dropdown`}>
							<Text variant="navButton">{item.label}</Text>
							<Flex
								w="100%"
								alignItems="flex-start"
								justifyContent="flex-start"
								direction="column"
								pl={(depth + 1) * 6}
							>
								<MapNavButtons
									key={`${item.label}-dropdown`}
									navItems={item.items}
									depth={depth + 1}
								/>
							</Flex>
						</React.Fragment>
					) : (
						<DropdownButton
							key={`${item.label}-dropdown`}
							label={item.label}
							items={item.items}
							depth={depth}
							closeParentMenu={closeParentMenu}
							parentMenuRef={parentMenuRef}
						/>
					)
				) : (
					<NavButton
						key={`${item.label}-dropdown`}
						to={item.to!}
						clickCallback={closeParentMenu}
					>
						{item.label}
					</NavButton>
				),
			)}
		</Flex>
	);
};

export default MapNavButtons;
