/**
 * @module CustomInquiryForm
 */
import React from "react";
import { Heading, Flex, Text } from "@chakra-ui/react";
import { MultiStepForm } from "components";
import { useCustomInquiryForm } from "hooks/form-hooks";

import { FormLayout } from "components";

/**
 * @function CustomInquiryForm
 * @returns {JSX.Element} - Rendered CustomInquiryForm component
 * @description Renders the CustomInquiryForm page
 */
const CustomInquiryForm: React.FC = () => {
	const { loading, onSubmit, steps } = useCustomInquiryForm();

	const HeadingComponent = (
		<>
			<Flex
				direction="column"
				justifyContent="center"
				alignItems="flex-start"
				p={2}
				w="100%"
			>
				<Text fontSize="lg" fontWeight="bold" color="graydient.600">
					Custom Inquiry
				</Text>
				<Text
					fontSize={{ base: "auto", md: "md" }}
					fontWeight="medium"
					color="graydient.600"
				>
					Tell us about your custom fencing needs.
				</Text>
			</Flex>
			<Heading
				as="h1"
				fontSize="4xl"
				fontWeight="bold"
				color="primary.500"
				textShadow="1px 1px 2px rgba(0,0,0,0.25)"
			>
				Wayside Fence Co.
			</Heading>
		</>
	);

	const FormComponent = (
		<MultiStepForm steps={steps} onSubmit={onSubmit} isLoading={loading} />
	);

	return (
		<FormLayout
			headingComponent={HeadingComponent}
			formComponent={FormComponent}
		/>
	);
};

export default CustomInquiryForm;
