import React from "react";
import { Box, Stack, Link, Heading } from "@chakra-ui/react";

interface LinkColumnProps {
	title: string;
	links: { label: string; href: string }[];
}

const LinkColumn: React.FC<LinkColumnProps> = ({ title, links }) => {
	return (
		<Box
			flex="1"
			px={{ base: 4, sm: 8, md: 4 }}
			py={{ base: 4, md: 8 }}
			textAlign={{ base: "center", md: "left" }}
			w="100%"
			h="auto"
		>
			<Heading
				variant="linkTitle"
				mb={{ base: 2, md: 4 }}
				fontSize={{ base: "xl", sm: "2xl", md: "3xl" }}
				fontWeight="bold"
				textAlign="center"
			>
				{title}
			</Heading>
			<Stack direction="column" spacing={{ base: 2, md: 3 }}>
				{links.map((link, index) => (
					<Link
						key={index}
						href={link.href}
						variant="link"
						fontSize={{ base: "sm", sm: "md", md: "lg" }}
						textAlign="center"
						_hover={{
							textDecoration: "underline",
							color: "primary.500",
						}}
					>
						{link.label}
					</Link>
				))}
			</Stack>
		</Box>
	);
};

export default LinkColumn;
