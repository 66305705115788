/**
 * @module DeleteConfirmationWrapper
 * @description A wrapper component for the `Alert Dialog` Chakra UI component.
 */
import React, { useRef } from "react";
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	Button,
} from "@chakra-ui/react";

/**
 * @typedef DeleteConfirmationWrapperProps
 * @property {boolean} isOpen - The state of the modal
 * @property {function} onClose - The function to close the modal
 * @property {string} header - The header of the modal
 * @property {string} content - The content of the modal
 * @property {string} body - The body of the modal
 * @property {function} onDeleteConfirm - The function to confirm the delete action
 */
interface DeleteConfirmationWrapperProps {
	isOpen: boolean;
	onClose: () => void;
	header: string;
	content: string;
	onDeleteConfirm: () => void;
	loading?: boolean;
	children?: React.ReactNode;
}

/**
 * @param {DeleteConfirmationWrapperProps} props
 * @returns {JSX.Element}
 * @see AlertDialog - Chakra UI component
 */
const DeleteConfirmationWrapper: React.FC<DeleteConfirmationWrapperProps> = ({
	isOpen,
	onClose,
	header,
	content,
	onDeleteConfirm,
	loading = false,
	children,
}) => {
	// Create a reference for the cancel button
	const cancelRef = useRef<HTMLButtonElement>(null);

	// Function to handle the delete confirmation
	const handleConfirmDelete = () => {
		onDeleteConfirm();
	};

	return (
		<AlertDialog
			isOpen={isOpen}
			leastDestructiveRef={cancelRef}
			onClose={onClose}
			isCentered
		>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize="lg" fontWeight="bold">
						{header} <span style={{ color: "red" }}> {content} </span>
					</AlertDialogHeader>

					<AlertDialogBody
						color="gray.600"
						fontSize={{ base: "sm", sm: "md" }}
						fontWeight="normal"
					>
						{children}
					</AlertDialogBody>
					<AlertDialogFooter>
						<Button ref={cancelRef} onClick={onClose} isDisabled={loading}>
							Cancel
						</Button>
						<Button
							colorScheme="red"
							onClick={handleConfirmDelete}
							ml={3}
							isLoading={loading}
							loadingText={"Deleting"}
						>
							Delete
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};

export default DeleteConfirmationWrapper;
