/**
 * @module SidebarShell.tsx
 * @description This component is used to dictate the layout of the sidebar components in the application.
 */
import React from "react";
import { Flex, Box, Heading } from "@chakra-ui/react";

/**
 * @typedef SidebarShellProps
 * @property {React.ReactNode} Header - The header of the sidebar.
 * @property {string} Title - The title of the sidebar.
 * @property {React.ReactNode} Content - The content of the sidebar.
 * @property {React.ReactNode} Footer - The footer of the sidebar.
 * @description The props for the SidebarShell component.
 */
interface SidebarShellProps {
	sidebarBgColor?: string;
	Header: React.ReactNode;
	Content: React.ReactNode;
	Footer: React.ReactNode;
}

/**
 * @param {SidebarShellProps} props - The props for the SidebarShell component.
 * @returns {React.ReactElement} The SidebarShell component
 */
const SidebarShell: React.FC<SidebarShellProps> = ({
	sidebarBgColor = "",
	Header,
	Content,
	Footer,
}: SidebarShellProps): React.ReactElement => {
	return (
		<Flex
			height="100vh"
			direction="column"
			width="100%"
			boxShadow="dark-lg"
			bg={sidebarBgColor}
		>
			<Heading
				display="flex"
				alignItems="center"
				justifyContent="center"
				py={1}
				size={{ base: "2xl", md: "3xl" }}
			>
				{Header}
			</Heading>
			<Box display="flex" flex="1" overflowY="auto" overflowX="hidden" p="2">
				{Content}
			</Box>
			<Box
				display="flex"
				p="1"
				alignItems="center"
				justifyContent="center"
				pb={2}
			>
				{Footer}
			</Box>
		</Flex>
	);
};

export default SidebarShell;
