/**
 * @module EmployeeCollection
 * @desc This module provides a collection component for employees.
 * - Each content section is a resource category collection.
 * - uses `CollectionRow` to display the collection of employees.
 */
import React, { useContext } from "react";
import { Box, Flex, SimpleGrid } from "@chakra-ui/react";
import { CollectionRow, CollectionHeader } from "components";
import { PageChangeContext } from "contexts";
/**
 * @module Collection
 * @desc props for Collection component
 * @prop {string} title - the title of the collection
 * @prop {string} buttonText - the text for the button
 * @prop {Employee[]} employees - the collection of employees
 */
interface Collection {
	title: string;
	buttonText: string;
	items: JSX.Element[];
}

/**
 * @module EmployeeCollectionProps
 * @desc props for EmployeeCollection component
 * @prop {Collection[]} collections - the collection of employees
 * - Each collection is a resource category collection.
 * - Each collection has a title, button text, and a collection of employees.
 * - Each employee has an id, name, and image url.
 * - Each collection uses `CollectionHeader` and `CollectionRow` to display the collection of employees.
 */
interface EmployeeCollectionProps {
	collections: Collection[];
}

const EmployeeCollections: React.FC<EmployeeCollectionProps> = ({
	collections,
}) => {
	const { handlePageChange } = useContext(PageChangeContext);
	return (
		<Flex
			direction="column"
			align="stretch"
			justify="space-evenly"
			paddingY={4}
			width="100%"
		>
			{/*  Displays a collection of resources for employees */}
			{collections.map((collection) => (
				<Box key={collection.title} paddingY={4}>
					<CollectionHeader
						collectionName={collection.title}
						buttonText={collection.buttonText}
						onClick={() =>
							handlePageChange(
								`/employee/resources/${collection.title.toLowerCase()}`,
							)
						}
					/>
					<SimpleGrid
						spacing={4}
						alignItems="center"
						minChildWidth="100%"
						width="100%"
					>
						<CollectionRow items={collection.items} />
					</SimpleGrid>
				</Box>
			))}
		</Flex>
	);
};

export default EmployeeCollections;
