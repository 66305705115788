/**
 * @module UpdateEmployeeForm
 * @description A form for updating a resource in the database
 */
import React from "react";
import { Form } from "components";
import { ModalWrapper } from "wrappers";
import { useUpdateEmployeeForm } from "hooks/form-hooks";
import { Employee } from "services/types";

interface UpdateEmployeeFormProps {
	isOpen: boolean;
	onClose: () => void;
	refetch: () => Promise<any>;
	existingEmployee: Employee;
}

const UpdateEmployeeForm: React.FC<UpdateEmployeeFormProps> = ({
	isOpen,
	onClose,
	refetch,
	existingEmployee,
}) => {
	const { fields, handleSubmit, loading } = useUpdateEmployeeForm({
		refetch,
		existingEmployee,
	});

	const onSubmit = async () => {
		await handleSubmit();
		onClose();
	};

	return (
		<ModalWrapper
			isOpen={isOpen}
			onClose={onClose}
			showShadow={true}
			heading="Update Employee"
			hideCloseButton={false}
		>
			<Form fields={fields} onSubmit={onSubmit} isLoading={loading} />
		</ModalWrapper>
	);
};

export default UpdateEmployeeForm;
