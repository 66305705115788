/**
 * @module DashboardHeader
 * @desc A header component for the dashboard
 * @namespace components/common/headings
 * @implementations
 * - In `DealerDashboard` component (pages/dealer/DealerDashboard.tsx)
 * - In `AdminDashboard` component (pages/admin/AdminDashboard.tsx)
 * - In `EmployeeDashboard` component (pages/employee/EmployeeDashboard.tsx)
 */
import { Flex, Heading } from "@chakra-ui/react";

interface DashboardHeaderProps {
	dashboardHeading?: string;
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({
	dashboardHeading = "Dashboard",
}) => {
	return (
		<Flex
			w="100%"
			h="100%"
			justifyContent="space-between"
			alignItems="flex-start"
			px="2.5rem"
			pt={"30px"}
			pb={"15px"}
		>
			<Heading size="lg" ml={6} fontWeight={"medium"} color={"#535353"}>
				{dashboardHeading}
			</Heading>
		</Flex>
	);
};

export default DashboardHeader;
