/**
 * @module ContactInfoModal.tsx
 */
import React from "react";
import { Stack } from "@chakra-ui/react";
import { ModalWrapper } from "wrappers";
import { ModalButton } from "components/common/buttons";
import { InformationStack } from "components/features/informational";

/**
 * @typedef ContactInfoModalProps
 * @property {string} phone The phone number to display
 * @property {string} email The email to display
 * @property {string} [contactButtonText] The text of the contact button
 */
interface ContactInfoModalProps {
	phone: string;
	email: string;
	contactButtonText?: string;
	isOpen: boolean;
	onOpen?: () => void;
	onClose: () => void;
	showButton?: boolean;
}

/**
 * A stack of buttons that display contact information.
 * @param {ContactInfoModalProps} props The props of the component.
 * @returns {React.ReactElement} A stack of buttons that display contact information.
 */
const ContactInfoModal: React.FC<ContactInfoModalProps> = ({
	phone,
	email,
	contactButtonText = "Contact Us",
	isOpen,
	onOpen = () => {},
	onClose,
	showButton = true,
}) => {
	return (
		<>
			{showButton && (
				<Stack
					direction={{ base: "column", md: "row" }}
					spacing={{ base: "2", md: "6" }}
					my={{ base: "2", md: "6" }}
					justifyContent="center"
					padding="4"
				>
					<ModalButton buttonText={contactButtonText} onClick={onOpen} />
				</Stack>
			)}
			<ModalWrapper
				isOpen={isOpen}
				onClose={onClose}
				size={{ base: "sm", md: "md" }}
				hideCloseButton={false}
			>
				<Stack
					direction="column"
					alignItems="center"
					justifyContent="center"
					w="100%"
					maxW="2xl"
					minW="auto"
					p={{ base: "4", md: "6" }}
				>
					<Stack direction="row" alignItems="center">
						<InformationStack
							withIcon={true}
							title={"CONTACT INFORMATION"}
							information={[`Phone: ${phone}`, `Email: ${email}`]}
						/>
					</Stack>
				</Stack>
			</ModalWrapper>
		</>
	);
};

export default ContactInfoModal;
