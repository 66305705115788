import BaseCreate from "./BaseCreate";

import { CreateToastFnReturn } from "@chakra-ui/react";
import { CatalogInput } from "services";
import { CATALOG_PATH } from "consts";

class CatalogCreate extends BaseCreate<CatalogInput> {
	protected imageFile: File | null;
	protected srcFile: File | null;
	protected uploadFile: (file: File, path: string) => Promise<string>;

	constructor(
		toast: CreateToastFnReturn,
		createFn: (input: CatalogInput) => Promise<{ data?: any; error?: any }>,
		refetch: () => Promise<void>,
		imageFile: File | null,
		srcFile: File | null,
		uploadFile: (file: File, path: string) => Promise<string>,
	) {
		super("Catalog", toast, createFn, refetch);
		this.imageFile = imageFile;
		this.srcFile = srcFile;
		this.uploadFile = uploadFile;
	}

	public async handleCreate(input: CatalogInput): Promise<boolean> {
		if (!this.createFn) throw new Error("Create function not defined");
		if (!this.uploadFile) throw new Error("Upload function not defined");

		let imagePath = "";

		try {
			if (!this.imageFile) throw new Error("Image file is required");
			imagePath = await this.uploadFile(this.imageFile, CATALOG_PATH);
		} catch (err: any) {
			this.toast({
				title: "Error",
				description: err.message,
				status: "error",
				duration: 5000,
				isClosable: true,
			});
			return false;
		}

		let srcPath = "";
		try {
			if (!this.srcFile) throw new Error("Source file is required");
			srcPath = await this.uploadFile(this.srcFile, CATALOG_PATH);
		} catch (err: any) {
			this.toast({
				title: "Error",
				description: err.message,
				status: "error",
				duration: 5000,
				isClosable: true,
			});
			return false;
		}

		const catalogInput: CatalogInput = {
			...input,
			image: imagePath,
			src: srcPath,
		};

		return await super.handleCreate(catalogInput);
	}
}

export default CatalogCreate;
