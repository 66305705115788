import React, { useState, useContext } from "react";
import {
	InputGroup,
	Input,
	InputRightElement,
	IconButton,
} from "@chakra-ui/react";

import { FaSearch } from "react-icons/fa";

import { StoreContext, PageChangeContext } from "contexts";

interface StoreSearchProps {
	onClose?: () => void;
}

/**
 * @function StoreSearch
 * @returns React component for the store search bar.
 */
const StoreSearch: React.FC<StoreSearchProps> = ({ onClose }) => {
	const { handlePageChange } = useContext(PageChangeContext);
	const { openPage } = useContext(StoreContext);
	const [searchTerm, setSearchTerm] = useState("");

	const handleSearchTermChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setSearchTerm(event.target.value);
	};

	const handleSearch = () => {
		handlePageChange("store");
		setTimeout(() => {
			openPage("search", { keyword: searchTerm });
		}, 100);
		setSearchTerm("");
		onClose && onClose();
	};

	return (
		<InputGroup size="md">
			<Input
				type="text"
				placeholder="Search for products..."
				value={searchTerm}
				onChange={handleSearchTermChange}
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						handleSearch();
					}
				}}
			/>
			<InputRightElement width="2rem">
				<IconButton
					aria-label="Search"
					icon={<FaSearch />}
					onClick={handleSearch}
				/>
			</InputRightElement>
		</InputGroup>
	);
};

export default StoreSearch;
