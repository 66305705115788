/**
 * @module HorizontalCTAModal
 * @desc Horizontal CTA Modal component
 */
import React from "react";
import { ModalWrapper } from "wrappers";
import { Image, Text, VStack } from "@chakra-ui/react";

/**
 * @typedef ImageProps
 * @property {string} url - Image URL
 * @property {string} alt - Image alt text
 */
interface ImageProps {
	url: string;
	alt: string;
}

/**
 * @typedef ModalContentProps
 * @property {string} title - Modal title
 * @property {string[]} descriptions - Modal descriptions
 * @property {ImageProps[]} images - Modal images
 */
interface ModalContentProps {
	title: string;
	descriptions: string[];
	images: ImageProps[];
}

/**
 * @interface HorizontalCTAModalProps
 * @property {boolean} isOpen - Modal open state
 * @property {() => void} onClose - Modal close handler
 * @property {ModalContentProps} modalContent - Modal content
 */
interface HorizontalCTAModalProps {
	isOpen: boolean;
	onClose: () => void;
	modalContent: ModalContentProps;
}

/**
 * Horizontal CTA Modal component
 * @param {HorizontalCTAModalProps} props - Component props
 * @returns {React.ReactElement} - Rendered component
 * @desc Modal displays content from the HorizontalCta component along with additional images and descriptions per the modal content prop
 */
const HorizontalCTAModal: React.FC<HorizontalCTAModalProps> = ({
	isOpen,
	onClose,
	modalContent,
}) => {
	const modalBodyContent = (
		<VStack
			spacing={4}
			align="start"
			overflowY="scroll"
			maxH="80vh"
			pb={6}
			px={2}
		>
			{modalContent.descriptions.map((paragraph, index) => (
				<Text key={index} px={1}>
					{paragraph}
				</Text>
			))}
			{modalContent.images.map((image, index) => (
				<Image
					key={index}
					src={image.url}
					alt={image.alt}
					mb={4}
					borderRadius="md"
					maxWidth="100%"
				/>
			))}
		</VStack>
	);

	return (
		<ModalWrapper
			isOpen={isOpen}
			onClose={onClose}
			heading={modalContent.title}
			showShadow={true}
			size="4xl"
		>
			{modalBodyContent}
		</ModalWrapper>
	);
};

export default HorizontalCTAModal;
