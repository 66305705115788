import React from "react";
import { Heading } from "@chakra-ui/react";

export type ComponentHeadingProps = {
	title: string;
	fontSize?: string | object;
	variant?: string;
	color?: string;
	// Any other props like textAlign, etc.
};

const ComponentHeading: React.FC<ComponentHeadingProps> = ({
	title,
	fontSize,
	variant,
	color,
	// Other props
}) => {
	return (
		<Heading
			as="h1"
			fontSize={fontSize || "2xl"}
			variant={variant}
			color={color}
			// Apply other styles or props
		>
			{title}
		</Heading>
	);
};

export default ComponentHeading;
