import { Heading, Image } from "@chakra-ui/react";
import ResponsiveImageGrid from "components/common/grids/ImageGrid";
import MainContentsWrapper from "wrappers/MainContentsWrapper";
import { useGetWebsiteCatalogs } from "services";
import { Loader, ErrorMessage } from "components/common/ui-elements";
// import { ModalWrapper } from "wrappers";

import { colors } from "styles";

const Catalogs = () => {
	const { data: catalogs, loading, error } = useGetWebsiteCatalogs();

	const catalogData = catalogs?.items.map((catalog) => {
		return {
			name: catalog.name,
			image: catalog.image,
			pdfURL: catalog.src,
		};
	});

	const handleBoxClick = (index: number) => {
		const pdfURL = catalogs?.items[index].src;
		window.open(pdfURL, "_blank");
	};

	const CatalogsContent = () => {
		if (loading) return <Loader />;

		if (error) {
			return (
				<ErrorMessage
					title={"We were unable to load our catalogs."}
					description={
						"You can try refreshing your browser or try again later. We apologize for the inconvenience."
					}
					alertProps={{ minHeight: "85vh" }}
				/>
			);
		}

		if (catalogData) {
			return (
				<ResponsiveImageGrid
					columnCount={{ base: 2, md: 3, lg: 3, xl: 4 }}
					spacing={{ base: 4, md: 10, xl: 6 }}
					height={{ base: "auto", md: "auto", lg: "auto", xl: "auto" }}
					width={{ base: "auto", md: "auto", lg: "300px", xl: "300px" }}
					onBoxClick={handleBoxClick}
					boxStyles={{
						borderWidth: "2px",
						borderColor: colors.primary[500],
						borderRadius: "lg",
						boxShadow: "lg",
						alignItems: "center",
						justifyContent: "center",
						_hover: {
							boxShadow: "dark-lg",
							borderColor: colors.primary[600],
							cursor: "pointer",
							transform: "translateY(-2px)",
						},
					}}
				>
					{catalogData.map((catalog, index) => (
						<Image
							key={index}
							src={catalog.image}
							alt={catalog.name}
							maxH="100%"
							maxW="100%"
							objectFit="cover"
							objectPosition="size-down"
							cursor={"pointer"}
						/>
					))}
				</ResponsiveImageGrid>
			);
		}
		return (
			<ErrorMessage
				title={"We were unable to load our catalogs."}
				description={
					"You can try refreshing your browser or try again later. We apologize for the inconvenience."
				}
			/>
		);
	};

	return (
		<MainContentsWrapper justifyContent="start" minHeight="50vh" pt="20">
			<Heading variant="primary" size="3xl" textAlign={"center"} py={6}>
				Learn More, By Browsing Brochures!
			</Heading>
			<CatalogsContent />
		</MainContentsWrapper>
	);
};

export default Catalogs;
