/**
 * @module AccordionList
 * @category Common Components
 * @description A component that renders a list of accordion items.
 *
 * `AccordionList` is a container component that takes an array of items and renders them as a list of accordions.
 * Each item in the list is expected to be structured with properties suitable for rendering `BaseAccordion` components.
 *
 * @prop {Array} items - An array of items to be rendered as accordions via `BaseAccordion` components.
 * @prop {AccordionProps} AccordionProps - Additional props to be passed to the Chakra UI Accordion component.
 */
import React from "react";
import { Accordion, AccordionProps } from "@chakra-ui/react";
import BaseAccordionItem from "./BaseAccordionItem";

interface AccordionListProps extends AccordionProps {
	items: any[];
}

const AccordionList: React.FC<AccordionListProps> = ({ items, ...props }) => (
	<Accordion defaultIndex={0} {...props}>
		{items.map((item, id) => (
			<BaseAccordionItem
				key={id}
				buttonContent={item.buttonContent}
				panelProps={item.panelProps}
				children={item.children}
				buttonProps={item.buttonProps}
				itemProps={item.itemProps}
			/>
		))}
	</Accordion>
);

export default AccordionList;
