import { useUpdateProductCollection } from "services";
import { FieldDefinition } from "components";
import { FileInput } from "components/common/inputs";

import { ProductCollection, ProductCollectionInput } from "services/types";
import { useFormWithFiles } from "hooks/forms";
import ProductCollectionUpdate from "cud/update/ProductCollectionUpdate";
import { ICUDFormHook } from "./types";
import { ProductMultiSelect } from "page-components";

const useUpdateProductCollectionForm = ({
	refetch,
	existingProductCollection,
}: ICUDFormHook & { existingProductCollection: ProductCollection }) => {
	const initialFormState: ProductCollectionInput = {
		name: existingProductCollection.name,
		description: existingProductCollection.description,
		image: existingProductCollection.image,
		ecwidIDs: existingProductCollection.ecwidIDs,
	};

	const {
		formState,
		loading,
		setLoading,
		handleInputChange,
		resetFormState,
		toast,
		files: file,
		handleSetFiles: setFile,
		uploadFile,
		fileUrls,
		handleSetFileUrls,
	} = useFormWithFiles<ProductCollectionInput>({
		initialState: initialFormState,
		allowMultiple: false,
		initialFileUrls: [existingProductCollection.image],
	});

	const { updateProductCollection } = useUpdateProductCollection();

	const productCollectionUpdate = new ProductCollectionUpdate(
		existingProductCollection,
		toast,
		updateProductCollection,
		refetch,
		file as File | null,
		uploadFile,
	);

	const handleSubmit = async () => {
		setLoading(true);
		const success = await productCollectionUpdate.handleUpdate(formState);
		if (success) resetFormState();
		setLoading(false);
	};

	const fields: FieldDefinition[] = [
		{
			label: "Name",
			name: "name",
			type: "text",
			placeholder: "Enter the name...",
			onChange: handleInputChange,
			required: true,
			value: formState.name,
			variant: "base",
			size: "lg",
		},
		{
			label: "Description",
			name: "description",
			type: "textarea",
			placeholder: "Enter the description...",
			onChange: handleInputChange,
			value: formState.description,
			variant: "base",
			size: "lg",
			width: "100%",
			required: true,
			flexGrow: 3,
		},
		{
			label: "Image",
			name: "image",
			type: "custom",
			customComponent: FileInput,
			customComponentProps: {
				fileType: "image",
				files: file ? [file] : [],
				setFiles: setFile,
				allowMultiple: false,
				fileUrls,
				setFileUrls: handleSetFileUrls,
			},
			required: true,
			minWidth: "100%",
		},
		{
			label: "Products",
			name: "ecwidIDs",
			type: "custom",
			customComponent: ProductMultiSelect,
			customComponentProps: {
				selectedIds: formState.ecwidIDs,
				onSelectionChange: (products: number[]) =>
					handleInputChange({ target: { name: "ecwidIDs", value: products } }),
			},
			required: true,
		},
	];

	return {
		fields,
		handleSubmit,
		loading,
	};
};

export default useUpdateProductCollectionForm;
