import { CreateToastFnReturn } from "@chakra-ui/react";
import { UNKNOWN_ERROR_TOAST } from "consts";

/**
 * ## Summary
	The `BaseUpdate` class is a generic class that provides a common structure for handling updates of objects. It takes in an object, object type, toast function, update function, and refetch function as parameters. It has a method called `handleUpdate` that performs the update operation and handles success and error cases by displaying toasts.

	## Example Usage
	```javascript
	const toast = useToast();
	const updateFn = async (id: string, input: U) => {
	// perform update operation
	};
	const refetch = async () => {
	// perform refetch operation
	};

	const baseUpdate = new BaseUpdate(object, objectType, toast, updateFn, refetch);
	await baseUpdate.handleUpdate(input);
	```

	## Code Analysis
	### Main functionalities
	- Provides a common structure for handling updates of objects
	- Handles success and error cases by displaying toasts
	___
	### Methods
	- `constructor(object: T, objectType: string, toast: CreateToastFnReturn, updateFn: (id: string, input: U) => Promise<{ data?: any; error?: any }>, refetch: () => Promise<void>)`: Initializes the `BaseUpdate` instance with the provided object, object type, toast function, update function, and refetch function.
	- `handleUpdate(input: U): Promise<boolean>`: Performs the update operation by calling the update function with the object's ID and the provided input. Handles success and error cases by displaying appropriate toasts and returns a boolean indicating the success of the update operation.
	___
	### Fields
	- `object: T`: The object to be updated.
	- `objectType: string`: The type of the object.
	- `toast: CreateToastFnReturn`: The toast function used to display success and error messages.
	- `updateFn: (id: string, input: U) => Promise<{ data?: any; error?: any }>`: The function responsible for performing the update operation.
	- `refetch: () => Promise<void>`: The function used to refetch data after a successful update.
	___

 */
class BaseUpdate<T extends { _id: string }, U> {
	public object: T;
	public objectType: string;
	public toast: CreateToastFnReturn;
	public updateFn: (
		id: string,
		input: U,
	) => Promise<{ data?: any; error?: any }>;
	public refetch: () => Promise<void>;

	constructor(
		object: T,
		objectType: string,
		toast: CreateToastFnReturn, // Define more specific type if available
		updateFn: (id: string, input: U) => Promise<{ data?: any; error?: any }>,
		refetch: () => Promise<void>,
	) {
		this.object = object;
		this.objectType = objectType;
		this.toast = toast;
		this.updateFn = updateFn;
		this.refetch = refetch;
	}

	public async handleUpdate(input: U): Promise<boolean> {
		if (!this.updateFn) throw new Error("Update function not defined");
		const { data, error } = await this.updateFn(this.object._id, input);
		if (error) {
			this.toast({
				title: "Error",
				description: error.message,
				status: "error",
				duration: 5000,
				isClosable: true,
			});
			return false;
		} else if (data) {
			this.toast({
				title: "Success",
				description: `${this.objectType} Updated Successfully`,
				status: "success",
				duration: 5000,
				isClosable: true,
			});
			if (this.refetch) await this.refetch();
			return true;
		} else {
			this.toast(UNKNOWN_ERROR_TOAST);
			return false;
		}
	}
}

export default BaseUpdate;
