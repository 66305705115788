import React from "react";
import { Text, Heading } from "@chakra-ui/react";
import Associations from "page-components/Resources/Associations";
import Brands from "page-components/Resources/Brands";

const OurPartners = () => {
	return (
		<>
			<Heading
				display={"flex"}
				alignItems={"center"}
				justifyContent={"center"}
				variant="primary"
				size={{ base: "3xl", md: "4xl" }}
				pt={{ base: 10, md: 20 }}
				mx={4}
				textAlign="center"
				letterSpacing={".0005em"}
			>
				Our Partnerships.
			</Heading>
			<Text
				pt={10}
				textAlign={"center"}
				px={{ base: 4 }}
				//p={10}
				fontSize={"lg"}
				//ustifyContent={"center"}
				//color={colors.graydient[400]}
			>
				The success of a company isn’t only measured by the quality of their
				products and services, but also by the depth and strength of their
				Industry Partnerships.
			</Text>
			<Text
				fontSize={"lg"}
				px={{ base: 4 }}
				textAlign={"center"}
				//ustifyContent={"center"}
				//color={colors.graydient[400]}
			>
				Wayside Fence is very involved with the following highly respected
				organizations - and we invite you to visit their websites:
			</Text>
			<Associations />
			<Brands />
		</>
	);
};

export default OurPartners;
