import React, { ReactNode } from "react";
import SidebarContentsLayout from "./SidebarContentsLayout";
import HeaderContentsLayout from "./HeaderContentsLayout";

interface DashboardLayoutProps {
	sidebar: ReactNode;
	header?: ReactNode;
	children: ReactNode;
}

/**
 * @component DashboardLayout
 *
 * @description `DashboardLayout` is a functional component that provides a structured layout for a dashboard interface.
 * It uses `SidebarContentsLayout` for the sidebar and optionally uses `HeaderContentsLayout` for the header.
 *
 * Preconditions:
 * - The `sidebar` and `children` props are required and must be valid React nodes.
 * - The `header` prop is optional. If provided, it must be a valid React node.
 * - `SidebarContentsLayout` and `HeaderContentsLayout` components must be correctly imported and functional.
 *
 * Postconditions:
 * - Renders a sidebar consistently using `SidebarContentsLayout`.
 * - Conditionally renders a header using `HeaderContentsLayout` if the `header` prop is provided.
 * - Displays the main content (`children`) either directly within the sidebar layout or within the header layout, based on the presence of the `header` prop.
 *
 * @param {DashboardLayoutProps} props - The component props.
 * @param {ReactNode} props.sidebar - The content for the sidebar section of the layout.
 * @param {ReactNode} [props.header] - Optional content for the header section of the layout.
 * @param {ReactNode} props.children - The main content of the layout.
 *
 * @returns {React.ReactElement} A React element representing a dashboard layout with optional header and sidebar components.
 */
const DashboardLayout: React.FC<DashboardLayoutProps> = ({
	sidebar,
	header,
	children,
}) => {
	return (
		<SidebarContentsLayout sidebar={sidebar}>
			{header ? (
				<HeaderContentsLayout header={header}>{children}</HeaderContentsLayout>
			) : (
				children
			)}
		</SidebarContentsLayout>
	);
};

export default DashboardLayout;
