import React from "react";
import DeleteConfirmationWrapper from "wrappers/DeleteConfirmationWrapper";
import { GalleryImage } from "services/types";
import { useDeleteGalleryForm } from "hooks/form-hooks";

interface DeleteGalleryModalProps {
	galleryImage: GalleryImage;
	isOpen: boolean;
	onClose: () => void;
	refetch: () => Promise<any>;
}

const DeleteGalleryModal: React.FC<DeleteGalleryModalProps> = ({
	galleryImage,
	isOpen,
	onClose,
	refetch,
}) => {
	const { loading, handleDelete } = useDeleteGalleryForm(galleryImage, refetch);

	const onConfirmDelete = async () => {
		await handleDelete();
		onClose();
	};

	return (
		<DeleteConfirmationWrapper
			isOpen={isOpen}
			onClose={onClose}
			header={`Confirm Deletion: `}
			onDeleteConfirm={onConfirmDelete}
			content={galleryImage?.title}
			loading={loading}
		>
			{`Are you sure you want to delete this gallery image? This action cannot be undone.`}
		</DeleteConfirmationWrapper>
	);
};

export default DeleteGalleryModal;
