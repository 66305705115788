/**
 * @module textStyles
 */
import { colors } from "styles";
import { getTextShadowString } from "utils";
import { ComponentStyleConfig } from "@chakra-ui/react";

/**
 * Style configuration for text components.
 * Includes base styles and variants for different use cases.
 * Supports responsive styles.
 */
const textStyles: ComponentStyleConfig = {
	baseStyle: {
		fontSize: { base: "md", md: "2xl" },
		fontWeight: { sm: "light", md: "medium" },
		color: ["text.primary", null, "text.paragraph"],
		textShadow: getTextShadowString(colors.shadow, 1),
	},
	variants: {
		caption: {
			fontSize: { base: "sm", md: "xl" },
			fontWeight: "light",
			color: colors.text,
			textShadow: getTextShadowString(colors.shadow, 1),
		},
		label: {
			fontSize: { base: "sm", md: "lg" },
			fontWeight: "medium",
			color: colors.text,
			textShadow: getTextShadowString(colors.shadow, 1),
		},
		sectionTitle: {
			fontSize: {
				base: "xl",
				md: "2xl",
			},
			fontWeight: "medium",
			color: colors.text.primary,
		},
		sectionParagraph: {
			fontSize: {
				base: "md",
				md: "lg",
			},
			fontWeight: {
				base: "light",
				md: "medium",
			},
			color: colors.text.primary,
		},
		info: {
			fontSize: { sm: "xs", md: "sm" },
			fontWeight: { sm: "light", md: "semibold" },
			color: colors.text.tertiary,
		},
		statement: {
			fontSize: { sm: "md", md: "xl" },
			fontWeight: "normal",
			color: colors.graydient[700],
		},
		category: {
			fontSize: { sm: "md", md: "xl" },
			fontWeight: "medium",
			color: colors.graydient[700],
		},
		navButton: {
			fontSize: "2xl",
			fontWeight: "bold",
			color: colors.primary[500],
			px: "1rem",
		},
	},
};

export default textStyles;
