import React from "react";
import { Heading } from "@chakra-ui/react";
import { FAQ, CTA, ContactInfo } from "page-components/Resources";
import { Flamingo } from "assets";
import CustomDivider from "../../components/common/divider/CustomDivider";

const HelpCenter = () => {
	return (
		<>
			<Heading
				display={"flex"}
				alignItems={"center"}
				justifyContent={"center"}
				variant="primary"
				size={{ base: "3xl", md: "4xl" }}
				pt={{ base: 10, md: 20 }}
				mx={4}
				textAlign="center"
			>
				Hello, How Can We Help?
			</Heading>
			<FAQ />
			<CustomDivider />
			<CTA
				image={Flamingo}
				title="Still Need a Hand?"
				width={{ xl: "1200px", lg: "auto", base: "auto" }}
				height={{ xl: "320px", lg: "225px", base: "auto" }}
			/>
			<ContactInfo />
		</>
	);
};

export default HelpCenter;
