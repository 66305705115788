import { useQuery } from "@apollo/client";
import { getCustomWorkGalleryImages as GET_CUSTOM_WORK_GALLERY_IMAGES } from "./queries";
import {
	RetrievalInput,
	CustomWorkGalleryImage,
	PaginatedResult,
} from "../../types";

interface GetCustomWorkGalleryImagesData {
	getCustomWorkGalleryImages: PaginatedResult<CustomWorkGalleryImage>;
}

const useGetCustomWorkGalleryImages = (retrievalInput?: RetrievalInput) => {
	const { loading, error, data, refetch, networkStatus } =
		useQuery<GetCustomWorkGalleryImagesData>(GET_CUSTOM_WORK_GALLERY_IMAGES, {
			variables: { retrievalInput },
		});
	const processedData = data?.getCustomWorkGalleryImages;
	return { loading, error, data: processedData, refetch, networkStatus };
};
export default useGetCustomWorkGalleryImages;
