import { useMutation } from "@apollo/client";
import { updateEmployee as UPDATE_EMPLOYEE } from "./mutations";
import { EmployeeInput } from "../../types";

interface UpdateEmployeeData {
	updateEmployee: boolean;
}

const useUpdateEmployee = () => {
	const [updateEmployeeMutation, { loading, error }] =
		useMutation<UpdateEmployeeData>(UPDATE_EMPLOYEE);
	const updateEmployee = async (
		employeeId: string,
		employeeInput: EmployeeInput,
	) => {
		try {
			const { data } = await updateEmployeeMutation({
				variables: {
					employeeId,
					employeeInput,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.updateEmployee };
		} catch (error: any) {
			return { error };
		}
	};
	return { updateEmployee, loading, error };
};
export default useUpdateEmployee;
