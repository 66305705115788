/**
 * @hook useFeedbackForm
 * @desc Feedback form custom hook.
 */
import { useForm } from "hooks/forms";
import { useSubmitFeedbackForm, FeedbackFormInput } from "services";
import { EmailInput } from "components/common/inputs";
import { FieldDefinition } from "components";
import { validateEmail } from "utils/validators";

const useFeedbackForm = () => {
	const initialFormState = {
		name: "",
		email: "",
		howDidYouHearAboutUs: "",
		howDidYouHearAboutUsOther: "",
		overallExperience: "",
		improvementSuggestions: "",
		productSatisfaction: "",
		additionalProductsDesired: "",
		siteNavigation: "",
		siteNavigationSuggestions: "",
		mobileExperience: "",
		mobileIssues: "",
		easeOfCheckout: "",
		improvementsToCheckout: "",
		additionalComments: "",
	};

	const {
		formState,
		loading,
		setLoading,
		handleInputChange,
		resetFormState,
		toast,
	} = useForm<FeedbackFormInput>({
		initialState: initialFormState,
	});

	const { submitFeedbackForm } = useSubmitFeedbackForm();

	const handleSubmit = async () => {
		setLoading(true);

		const { data, error } = await submitFeedbackForm(formState);
		if (data) {
			toast({
				title: "Feedback Submitted",
				description: "Thank you for your feedback! We appreciate your input.",
				status: "success",
				duration: 5000,
				isClosable: true,
			});
			resetFormState();
		} else if (error) {
			toast({
				title: "Error",
				description: error.message,
				status: "error",
				duration: 5000,
				isClosable: true,
			});
		} else {
			toast({
				title: "Error",
				description:
					"An unexpected error occurred while submitting your feedback. Please try again later.",
				status: "error",
				duration: 5000,
				isClosable: true,
			});
		}

		setLoading(false);
	};

	const fields: FieldDefinition[][] = [
		[
			{
				type: "text",
				name: "name",
				label: "Name",
				value: formState.name,
				placeholder: "Please enter your name...",
				required: true,
				onChange: handleInputChange,
				variant: "base",
				size: "lg",
				width: "100%",
				minWidth: "45%",
			},
			{
				type: "custom",
				name: "email",
				label: "Email",
				value: formState.email,
				placeholder: "Please enter your email...",
				required: true,
				onChange: handleInputChange,
				customComponent: EmailInput,
				variant: "base",
				size: "lg",
				width: "100%",
				validate: validateEmail,
				minWidth: "45%",
			},
			{
				type: "select",
				name: "howDidYouHearAboutUs",
				label: "How did you hear about us?",
				value: formState.howDidYouHearAboutUs,
				placeholder: "Please select an option...",
				required: true,
				onChange: handleInputChange,
				options: [
					"Social Media",
					"Search Engine",
					"Word of Mouth",
					"Advertising",
					"Email Marketing",
					"Website Referral",
					"Events",
					"Direct Mail",
					"Other",
				],
				variant: "base",
				size: "lg",
				width: "100%",
				minWidth: "45%",
			},
			{
				type: "text",
				name: "howDidYouHearAboutUsOther",
				label: "Other (please specify)",
				value: formState.howDidYouHearAboutUsOther,
				placeholder: "Please specify...",
				required: false,
				onChange: handleInputChange,
				variant: "base",
				size: "lg",
				width: "100%",
				minWidth: "45%",
				isConditional: true,
				isVisible: formState.howDidYouHearAboutUs === "Other",
			},
		],
		[
			{
				type: "select",
				name: "overallExperience",
				label: "How would you rate your overall experience with our website?",
				value: formState.overallExperience,
				placeholder: "Please select an option...",
				required: true,
				onChange: handleInputChange,
				options: ["Excellent", "Good", "Average", "Poor", "Very Poor"],
				variant: "base",
				size: "lg",
				width: "100%",
				minWidth: "45%",
			},
			{
				type: "textarea",
				name: "improvementSuggestions",
				label:
					"Please provide any specific feedback or suggestions for improvement.",
				value: formState.improvementSuggestions,
				placeholder: "Please enter your suggestions...",
				required: false,
				onChange: handleInputChange,
				variant: "base",
				size: "lg",
				width: "100%",
				minWidth: "45%",
				minHeight: "48px",
			},
			{
				type: "select",
				name: "productSatisfaction",
				label:
					"How satisfied are you with the range of products available on our website?",
				value: formState.productSatisfaction,
				placeholder: "Please select an option...",
				required: false,
				onChange: handleInputChange,
				options: [
					"Very Satisfied",
					"Satisfied",
					"Neutral",
					"Dissatisfied",
					"Very Dissatisfied",
				],
				variant: "base",
				size: "lg",
				width: "100%",
				minWidth: "45%",
			},
			{
				type: "textarea",
				name: "additionalProductsDesired",
				label:
					"Are there any additional products or categories you would like to see?",
				value: formState.additionalProductsDesired,
				placeholder: "Please enter your suggestions...",
				required: false,
				onChange: handleInputChange,
				variant: "base",
				size: "lg",
				width: "100%",
				minWidth: "45%",
				minHeight: "48px",
			},
			{
				type: "select",
				name: "siteNavigation",
				label:
					"How easy is it to find the information or products you are looking for on our website?",
				value: formState.siteNavigation,
				placeholder: "Please select an option...",
				required: false,
				onChange: handleInputChange,
				options: ["Very Easy", "Good", "Average", "Poor", "Very Poor"],
				variant: "base",
				size: "lg",
				width: "100%",
				minWidth: "45%",
			},
			{
				type: "textarea",
				name: "siteNavigationSuggestions",
				label:
					"Do you have any suggestions for making navigation more intuitive?",
				value: formState.siteNavigationSuggestions,
				placeholder: "Please enter your suggestions...",
				required: false,
				onChange: handleInputChange,
				variant: "base",
				size: "lg",
				width: "100%",
				minWidth: "45%",
				minHeight: "48px",
			},
			{
				type: "select",
				name: "mobileExperience",
				label: "How well does our website perform on mobile devices?",
				value: formState.mobileExperience,
				placeholder: "Please select an option...",
				required: false,
				onChange: handleInputChange,
				options: ["Excellent", "Good", "Average", "Poor", "Very Poor"],
				variant: "base",
				size: "lg",
				width: "100%",
				minWidth: "45%",
			},
			{
				type: "textarea",
				name: "mobileIssues",
				label: "If you experienced any mobile issues, please describe them.",
				value: formState.mobileIssues,
				placeholder: "Please enter your feedback...",
				required: false,
				onChange: handleInputChange,
				variant: "base",
				size: "lg",
				width: "100%",
				minWidth: "45%",
				minHeight: "48px",
			},
			{
				type: "select",
				name: "easeOfCheckout",
				label:
					"How would you rate the ease of the checkout process on our website?",
				value: formState.easeOfCheckout,
				placeholder: "Please select an option...",
				required: false,
				onChange: handleInputChange,
				options: [
					"Very Easy",
					"Easy",
					"Neutral",
					"Difficult",
					"Very Difficult",
				],
				variant: "base",
				size: "lg",
				width: "100%",
				minWidth: "45%",
			},
			{
				type: "textarea",
				name: "improvementsToCheckout",
				label:
					"What improvements could be made to enhance your checkout experience?",
				value: formState.improvementsToCheckout,
				placeholder: "Please enter your suggestions...",
				required: false,
				onChange: handleInputChange,
				variant: "base",
				size: "lg",
				width: "100%",
				minWidth: "45%",
				minHeight: "48px",
			},
			{
				type: "textarea",
				name: "additionalComments",
				label: "Any additional comments or feedback?",
				value: formState.additionalComments,
				placeholder: "Please enter your feedback...",
				required: false,
				onChange: handleInputChange,
				variant: "base",
				size: "lg",
				width: "100%",
				minWidth: "100%",
			},
		],
	];

	return { loading, fields, handleSubmit };
};

export default useFeedbackForm;
