import { useMutation } from "@apollo/client";
import { applyForDealerAccount as APPLY_FOR_DEALER_ACCOUNT } from "./mutations";
import { DealerApplicationInput } from "../../types";

interface ApplyForDealerAccountData {
	applyForDealerAccount: boolean;
}

const useApplyForDealerAccount = () => {
	const [applyForDealerAccountMutation, { loading, error }] =
		useMutation<ApplyForDealerAccountData>(APPLY_FOR_DEALER_ACCOUNT);
	const applyForDealerAccount = async (input: DealerApplicationInput) => {
		try {
			const { data } = await applyForDealerAccountMutation({
				variables: {
					input,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.applyForDealerAccount };
		} catch (error: any) {
			return { error };
		}
	};
	return { applyForDealerAccount, loading, error };
};
export default useApplyForDealerAccount;
