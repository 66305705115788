import { useMutation } from "@apollo/client";
import { deleteCatalog as DELETE_CATALOG } from "./mutations";
interface DeleteCatalogData {
	deleteCatalog: boolean;
}

const useDeleteCatalog = () => {
	const [deleteCatalogMutation, { loading, error }] =
		useMutation<DeleteCatalogData>(DELETE_CATALOG);
	const deleteCatalog = async (catalogID: string) => {
		try {
			const { data } = await deleteCatalogMutation({
				variables: {
					catalogID,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.deleteCatalog };
		} catch (error: any) {
			return { error };
		}
	};
	return { deleteCatalog, loading, error };
};
export default useDeleteCatalog;
