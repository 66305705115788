import { useQuery } from "@apollo/client";
import { getEmployees as GET_EMPLOYEES } from "./queries";
import { RetrievalInput, Employee, PaginatedResult } from "../../types";

interface GetEmployeesData {
	getEmployees: PaginatedResult<Employee>;
}

const useGetEmployees = (retrievalInput: RetrievalInput) => {
	const { loading, error, data, refetch, networkStatus } =
		useQuery<GetEmployeesData>(GET_EMPLOYEES, {
			variables: { retrievalInput },
		});
	const processedData = data?.getEmployees;
	return { loading, error, data: processedData, refetch, networkStatus };
};
export default useGetEmployees;
