import { useQuery } from "@apollo/client";
import { getWebsiteCatalogs as GET_WEBSITE_CATALOGS } from "./queries";
import { RetrievalInput, Catalog, PaginatedResult } from "../../types";

interface GetWebsiteCatalogsParams {
	retrievalInput?: RetrievalInput;
}

interface GetWebsiteCatalogsData {
	getWebsiteCatalogs: PaginatedResult<Catalog>;
}

const useGetWebsiteCatalogs = (input?: GetWebsiteCatalogsParams) => {
	const { loading, error, data, refetch, networkStatus } =
		useQuery<GetWebsiteCatalogsData>(GET_WEBSITE_CATALOGS, {
			variables: input ? input : {},
		});
	const processedData = data?.getWebsiteCatalogs;
	return { loading, error, data: processedData, refetch, networkStatus };
};

export default useGetWebsiteCatalogs;
