import { useCreateProductCollection, ProductCollectionInput } from "services";
import { FileInput } from "components/common/inputs";
import { FieldDefinition } from "components";
import { ICUDFormHook } from "./types";
import { useFormWithFiles } from "hooks/forms";
import { ProductCollectionCreate } from "cud";
import { ProductMultiSelect } from "page-components";
const useCreateProductCollectionForm = ({ refetch }: ICUDFormHook) => {
	const initialFormState: ProductCollectionInput = {
		name: "",
		description: "",
		image: "",
		ecwidIDs: [],
	};

	const {
		formState,
		loading,
		setLoading,
		handleInputChange,
		resetFormState,
		toast,
		files: file,
		handleSetFiles: setFile,
		uploadFile,
	} = useFormWithFiles<ProductCollectionInput>({
		initialState: initialFormState,
		allowMultiple: false,
	});

	const { createProductCollection } = useCreateProductCollection();

	const productCollectionCreate = new ProductCollectionCreate(
		toast,
		createProductCollection,
		refetch,
		file as File | null,
		uploadFile,
	);

	const handleSubmit = async () => {
		setLoading(true);
		const success = await productCollectionCreate.handleCreate(formState);
		if (success) resetFormState();
		setLoading(false);
	};

	const fields: FieldDefinition[] = [
		{
			label: "Name",
			name: "name",
			type: "text",
			placeholder: "Enter the name...",
			onChange: handleInputChange,
			required: true,
			value: formState.name,
			variant: "base",
			size: "lg",
		},
		{
			label: "Description",
			name: "description",
			type: "textarea",
			placeholder: "Enter the description...",
			onChange: handleInputChange,
			value: formState.description,
			size: "lg",
			width: "100%",
			required: true,
			flexGrow: 3,
			variant: "base",
		},
		{
			label: "Image",
			name: "image",
			type: "custom",
			customComponent: FileInput,
			customComponentProps: {
				fileType: "image",
				files: file ? [file] : [],
				setFiles: setFile,
				allowMultiple: false,
			},
			required: true,
			minWidth: "100%",
		},
		{
			label: "Products",
			name: "ecwidIDs",
			type: "custom",
			customComponent: ProductMultiSelect,
			customComponentProps: {
				selectedIds: formState.ecwidIDs,
				onSelectionChange: (products: number[]) =>
					handleInputChange({ target: { name: "ecwidIDs", value: products } }),
			},
			required: true,
		},
	];

	return { fields, handleSubmit, loading };
};

export default useCreateProductCollectionForm;
