import React, { ReactNode } from "react";
import { Tooltip, PlacementWithLogical } from "@chakra-ui/react";

interface TooltipWrapperProps {
	children: ReactNode;
	label: string;
	placement: string;
	bg?: string;
	color?: string;
	fontSize?: string;
	fontWeight?: string;
	openDelay?: number;
}

const TooltipWrapper: React.FC<TooltipWrapperProps> = ({
	children,
	label,
	placement,
	bg,
	color,
	fontSize,
	fontWeight,
	openDelay,
}) => {
	return (
		<Tooltip
			label={label}
			placement={placement as PlacementWithLogical}
			bg={bg}
			color={color}
			fontSize={fontSize}
			fontWeight={fontWeight}
			openDelay={openDelay}
			hasArrow
		>
			{children}
		</Tooltip>
	);
};

export default TooltipWrapper;
