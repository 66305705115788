import React from "react";

import { Routes, Route } from "react-router-dom";

import { NotFound } from "pages";

import { TrackRouteChange } from "analytics";

interface RoutesWrapperProps {
	children: React.ReactNode;
	fallbackComponent?: React.ReactNode;
}

const RoutesWrapper: React.FC<RoutesWrapperProps> = ({
	children,
	fallbackComponent,
}: RoutesWrapperProps) => {
	return (
		<>
			<TrackRouteChange />
			<Routes>
				{children}
				<Route path="/*" element={fallbackComponent || <NotFound />} />
			</Routes>
		</>
	);
};

export default RoutesWrapper;
