import React from "react";
import { Heading, Flex, Text } from "@chakra-ui/react";
import { MultiStepForm } from "components";
import { useQuoteForm } from "hooks";

import { FormLayout } from "components";

const QuoteForm: React.FC = () => {
	const { loading, onSubmit, steps } = useQuoteForm();

	const HeadingComponent = (
		<>
			<Flex
				direction="column"
				justifyContent="center"
				alignItems="flex-start"
				p={2}
				w="100%"
			>
				<Text fontSize="lg" fontWeight="bold" color="graydient.600">
					Request a Quote
				</Text>
				<Text fontSize="md" fontWeight="medium" color="graydient.600">
					Become a certified Wayside Dealer.
				</Text>
			</Flex>
			<Heading
				as="h1"
				fontSize="4xl"
				fontWeight="bold"
				color="primary.500"
				textShadow="1px 1px 2px rgba(0,0,0,0.25)"
			>
				Wayside Fence Co.
			</Heading>
		</>
	);

	const FormComponent = (
		<MultiStepForm steps={steps} onSubmit={onSubmit} isLoading={loading} />
	);

	return (
		<FormLayout
			headingComponent={HeadingComponent}
			formComponent={FormComponent}
		/>
	);
};

export default QuoteForm;
