/**
 * @module DeleteResourceModal
 * @description A modal for deleting a resource from the database
 * @see DeleteConfirmationWrapper
 * @see useDeleteResource
 */
import React from "react";
import DeleteConfirmationWrapper from "wrappers/DeleteConfirmationWrapper"; // Adjust the import path as needed
import { Resource } from "services/types"; // Adjust the import path as needed
import { useDeleteResourceForm } from "hooks/form-hooks"; // Adjust the import path as needed

/**
 * @typedef DeleteResourceModalProps
 * @property {Resource} resource - The resource to be deleted
 * @property {boolean} isOpen - The state of the modal
 * @property {function} onClose - The function to close the modal
 * @property {function} refetch - The function to refetch the resources
 * @see DeleteConfirmationWrapper - For the `Alert Dialog` wrapper component
 */
interface DeleteResourceModalProps {
	resource: Resource;
	isOpen: boolean;
	onClose: () => void;
	refetch: () => Promise<any>;
}

/**
 * @param {DeleteResourceModalProps} props
 * @returns {JSX.Element}
 * @see DeleteConfirmationWrapper - For the `Alert Dialog` wrapper component
 * @see useDeleteResource - For the `useDeleteResource` hook
 */
const DeleteResourceModal: React.FC<DeleteResourceModalProps> = ({
	resource,
	isOpen,
	onClose,
	refetch,
}) => {
	// Destructure the openDialog and handleDelete functions from the useDeleteResourceForm hook
	const { loading, handleDelete } = useDeleteResourceForm(resource, refetch);

	// Function to confirm the delete action and close the modal
	const onConfirmDelete = async () => {
		await handleDelete();
		onClose();
	};

	return (
		<DeleteConfirmationWrapper
			isOpen={isOpen}
			onClose={onClose}
			header={`Confirm Deletion: `}
			onDeleteConfirm={onConfirmDelete}
			content={resource?.title}
			loading={loading}
		>
			{`Are you sure you want to delete this Resource? This action cannot be undone.`}
		</DeleteConfirmationWrapper>
	);
};

export default DeleteResourceModal;
