// components/IconText.tsx

import { HStack, Icon, Text, IconProps, TextProps } from "@chakra-ui/react";

import { colors } from "styles";

interface IconTextProps {
	icon?: React.ElementType;
	text: string;
	iconProps?: IconProps;
	textProps?: TextProps;
}

const IconText = ({
	icon,
	text,
	iconProps = {
		boxSize: 7,
		mr: 2,
		color: colors.primary[500],
	},
	textProps = {
		variant: "info",
		fontSize: { base: "md", lg: "lg" },
	},
}: IconTextProps) => (
	<HStack>
		{icon && <Icon as={icon} {...iconProps} />}
		<Text variant="sectionParagraph" {...textProps}>
			{text}
		</Text>
	</HStack>
);

export default IconText;
