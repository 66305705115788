import { useMutation } from "@apollo/client";
import { assignResourcesToEmployee as ASSIGN_RESOURCES_TO_EMPLOYEE } from "./mutations";

interface AssignResourcesToEmployeeData {
	assignResourcesToEmployee: boolean;
}

const useAssignResourcesToEmployee = () => {
	const [assignResourcesToEmployeeMutation, { loading, error }] =
		useMutation<AssignResourcesToEmployeeData>(ASSIGN_RESOURCES_TO_EMPLOYEE);
	const assignResourcesToEmployee = async (
		employeeId: string,
		resourceIds: string[],
	) => {
		try {
			const { data } = await assignResourcesToEmployeeMutation({
				variables: {
					employeeId,
					resourceIds,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.assignResourcesToEmployee };
		} catch (error: any) {
			return { error };
		}
	};
	return { assignResourcesToEmployee, loading, error };
};

export default useAssignResourcesToEmployee;
