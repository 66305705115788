/**
 * Utility validator functions for custom Form component Field validations.
 * These functions return either true or a string containing an error message.
 * The error message is displayed as the form error message when a field is invalid.
 */
import validator from "validator";

/**
 * Validates an email address.
 * @param email - The email address to validate.
 * @returns Either true if the email is valid, or a string containing an error message if the email is invalid.
 */
export function validateEmail(email: string): true | string {
	const isValidEmail = validator.isEmail(email);
	if (!isValidEmail) return "Invalid email address";
	return true;
}

/**
 * Validates a phone number.
 * @param phone - The phone number to validate.
 * @param locale - The locale to use for phone number validation. Defaults to "en-US".
 * @returns Either true if the phone number is valid, or a string containing an error message if the phone number is invalid.
 */
export function validatePhoneNumber(
	phone: string,
	locale = "en-US",
): true | string {
	const isValidPhoneNumber = validator.isMobilePhone(phone, locale);
	if (!isValidPhoneNumber) return "Invalid phone number";
	return true;
}

/**
 * Validates a zip code based on the specified locale.
 * @param zip The zip code to validate.
 * @param locale The locale to use for validation. Defaults to "US".
 * @returns Returns `true` if the zip code is valid, otherwise returns a string indicating the error message.
 */
export function validateZipCode(zip: string, locale = "US"): true | string {
	const isValidZipCode = validator.isPostalCode(zip, locale);
	if (!isValidZipCode) return "Invalid zip code";
	return true;
}

export function validatePassword(password: string): true | string {
	let isValid = true;
	if (password.length < 8) isValid = false;
	if (!/[A-Z]/.test(password)) isValid = false;
	if (!/[0-9]/.test(password)) isValid = false;
	if (!isValid)
		return "Password must contain at least one number, uppercase letter, and be at least 8 characters long";
	return true;
}
