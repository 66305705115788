/**
 * @module CollectionHeader
 * @desc This module provides a collection header component.
 */
import React from "react";
import { Text, Button, HStack } from "@chakra-ui/react";

/**
 * @module CollectionHeaderProps
 * @desc props for CollectionHeader component
 * @prop {string} collectionName - the name of the collection
 * @prop {string} buttonText - the text for the button
 */
interface CollectionHeaderProps {
	collectionName: string;
	buttonText: string;
	onClick?: () => void;
}

const CollectionHeader: React.FC<CollectionHeaderProps> = ({
	collectionName,
	buttonText,
	onClick,
}) => {
	return (
		<HStack
			justify="space-between"
			align="stretch"
			bg="white"
			p={4}
			borderRadius="md"
		>
			<Text fontSize="2xl">{collectionName}</Text>
			<Button variant="ghost" size="sm" onClick={onClick}>
				{buttonText}
			</Button>
		</HStack>
	);
};

export default CollectionHeader;
