import React from "react";
import { Box, Image, Heading, Center } from "@chakra-ui/react";

import { EcwidProduct } from "services";
import { getViableEcwidProductImage } from "utils";

interface EcwidProductItemProps {
	product: EcwidProduct;
}

const EcwidProductItem: React.FC<EcwidProductItemProps> = ({ product }) => {
	return (
		<Box
			p="5"
			w="100%"
			borderWidth="1px"
			borderRadius="lg"
			bg="white"
			textAlign={"center"}
		>
			<Center flexDirection="column" alignItems="center">
				<Heading size="md" my="2">
					{product.name}
				</Heading>
				<Image
					src={getViableEcwidProductImage(product.media?.images) || undefined}
					alt={`Image of ${product.name}`}
				/>
			</Center>
		</Box>
	);
};

export default EcwidProductItem;
