import React from "react";
import { useFeedbackForm } from "hooks/form-hooks";
import { MultiStepForm } from "components";

const FeedbackForm: React.FC = () => {
	const { loading, fields, handleSubmit } = useFeedbackForm();

	return (
		<MultiStepForm onSubmit={handleSubmit} steps={fields} isLoading={loading} />
	);
};

export default FeedbackForm;
