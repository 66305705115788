/**
 * @module HorizontalCta
 * @desc Horizontal CTA component
 */
import React from "react";
import { VStack, Heading, Text, Button, Flex, Image } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

/**
 * @interface HorizontalCtaProps
 * @property {string} title - CTA title
 * @property {string} description - CTA description
 * @property {string} buttonText - CTA button text
 * @property {string} imageUrl - CTA image URL
 * @property {string} imageAlt - CTA image alt text
 * @property {"left" | "right"} textPosition - CTA text position
 * @property {() => void} onOpenModal - CTA open modal handler
 * @property {string} [buttonLink] - URL to direct users when the button is clicked
 */
interface HorizontalCtaProps {
	title: string;
	description: string;
	buttonText: string;
	imageUrl: string;
	imageAlt: string;
	textPosition: "left" | "right";
	onOpenModal?: () => void;
	buttonLink?: string;
}

/**
 * Horizontal CTA component
 * @param {HorizontalCtaProps} props - Component props
 * @returns {React.ReactElement} - Rendered component
 */
const HorizontalCta: React.FC<HorizontalCtaProps> = ({
	title,
	description,
	buttonText,
	imageUrl,
	imageAlt,
	textPosition,
	onOpenModal,
	buttonLink,
}) => {
	const isTextLeft = textPosition === "left";
	const navigate = useNavigate();

	const handleButtonClick = () => {
		if (buttonLink) {
			navigate(buttonLink);
		} else if (onOpenModal) {
			onOpenModal();
		}
	};

	return (
		<Flex
			direction={isTextLeft ? "row" : "row-reverse"}
			align="center"
			justify="space-between"
			gap="4"
			mt={1}
			mb={2}
			w="100%"
		>
			<VStack align={isTextLeft ? "start" : "end"} flex={1}>
				<Heading>{title}</Heading>
				<Text
					fontSize="md"
					fontWeight="medium"
					textAlign={isTextLeft ? "left" : "right"}
					noOfLines={{ base: 3, md: 2 }}
					w="90%"
					py={1}
				>
					{description}
				</Text>
				<Button onClick={handleButtonClick} variant="primary_bg">
					{buttonText}
				</Button>
			</VStack>
			<Image
				src={imageUrl}
				alt={imageAlt}
				boxSize="280px"
				w="full"
				objectFit="cover"
				objectPosition="center"
				flex={1}
				boxShadow="dark-lg"
				borderRadius="md"
				transition="all 0.15s ease-in-out"
			/>
		</Flex>
	);
};

export default HorizontalCta;
