import React from "react";
import {
	SimpleGrid,
	Box,
	BoxProps,
	useTheme,
	ResponsiveValue,
	SimpleGridProps,
} from "@chakra-ui/react";
interface ImageGridProps {
	height?: BoxProps["height"];
	width?: BoxProps["width"];
	columnCount?: ResponsiveValue<number>;
	spacing?: ResponsiveValue<number | string>;
	boxStyles?: BoxProps;
	children: React.ReactNode;
	onBoxClick?: (index: number) => void;
	minChildWidth?: SimpleGridProps["minChildWidth"];
}

const ImageGrid: React.FC<ImageGridProps> = ({
	height = "200px",
	width = "200px",
	columnCount = { base: 2, sm: 3, md: 4 },
	spacing = { base: 8, md: 14 },
	boxStyles,
	onBoxClick,
	children,
	minChildWidth,
}) => {
	const theme = useTheme();
	const { colors } = theme;
	return (
		<SimpleGrid
			columns={columnCount}
			spacing={spacing}
			mb={{ base: "2", md: "4" }}
			alignItems="center"
			minChildWidth={minChildWidth}
			p={{ base: 4 }}
		>
			{React.Children.map(children, (child, index) => (
				<Box
					key={index}
					p={{ base: 0, md: 4 }}
					borderWidth="1px"
					borderColor={colors.secondary[300]}
					//boxShadow="md"
					//rounded="md"
					bg="white"
					transition="all 0.2s ease-in-out"
					_hover={{
						boxShadow: "dark-lg",
						cursor: "pointer",
						transform: { base: "none", md: "translateY(-4px)" },
					}}
					position="relative"
					h={height}
					w={width}
					display="flex"
					alignItems="center"
					justifyContent="center"
					overflow="hidden"
					{...boxStyles} // Spread additional box styles
					onClick={() => onBoxClick && onBoxClick(index)}
				>
					{child}
				</Box>
			))}
		</SimpleGrid>
	);
};
export default ImageGrid;
