import { IconButton } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { Tooltip } from "@chakra-ui/react";

type DeleteIconButtonProps = {
	onClick: () => void;
};

const DeleteIconButton: React.FC<DeleteIconButtonProps> = ({ onClick }) => {
	return (
		<Tooltip
			hasArrow
			label="Delete"
			variant="delete"
			placement="right"
			openDelay={500}
		>
			<IconButton
				aria-label="Delete"
				variant="action"
				icon={<DeleteIcon />}
				onClick={onClick}
				_hover={{ bg: "red.500", transform: "translateY(-2px)" }}
			/>
		</Tooltip>
	);
};

export default DeleteIconButton;
