import { createBrowserRouter, RouterProvider } from "react-router-dom";

import App from "App";

import ErrorFallback from "pages/error/ErrorFallback";
import { Loader } from "components";

const browserRouter = createBrowserRouter([
	{
		path: "/*",
		element: <App />,
		errorElement: <ErrorFallback />,
	},
]);

const Router = () => {
	return <RouterProvider router={browserRouter} fallbackElement={<Loader />} />;
};

export default Router;
