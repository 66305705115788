/**
 * @module BackButton.tsx
 * @description Back button component for navigating back to previous page
 */
import React, { useContext } from "react";
import { IconButton } from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { PageChangeContext } from "contexts";
import { PositionProps } from "@chakra-ui/styled-system";
//import { handlePageChange } from "utils

interface BackButtonProps {
	position?: PositionProps["position"];
	variant?: string;
	top?: string;
	left?: string;
	m?: string;
	colorScheme?: string;
	size?: string;
	boxShadow?: string;
	borderWidth?: string;
	borderColor?: string;
}

const BackButton: React.FC<BackButtonProps> = ({
	position = "absolute",
	variant = "ghost",
	top = "4",
	left = "4",
	m = "0",
	colorScheme = "white",
	size = "md",
	boxShadow = "base",
	borderWidth = "1px",
	borderColor = "gray.200",
}) => {
	const { handlePageChange } = useContext(PageChangeContext); // Destructure goBack from the context

	const handleBack = () => {
		handlePageChange("/home");
	};

	return (
		<IconButton
			aria-label="Back"
			icon={<ArrowBackIcon />}
			onClick={handleBack}
			position={position}
			variant={variant}
			top={top}
			left={left}
			m={m}
			colorScheme={colorScheme}
			size={size}
			boxShadow={boxShadow}
			borderWidth={borderWidth}
			borderColor={borderColor}
			zIndex={1}
		/>
	);
};

export default BackButton;
