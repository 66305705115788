/**
 * @file Home.tsx
 * @description The Home page.
 * @module HomePage
 */
import React from "react";
import { Flex } from "@chakra-ui/react";
import { LargeHero } from "components/features/heros";
import HomeHeroImg from "../../assets/hero/Home_Hero.webp";
import CustomInspiration from "page-components/Home/CustomInspiration";
import FenceSolutionSection from "page-components/Home/FenceSolutions";
import ReviewCarousel from "page-components/Home/ReviewCarousel";
import Locations from "page-components/Home/Locations";
import Store from "page-components/Home/Store";

import WaysideCT from "../../assets/Home/WaysideCT.webp";
import WaysideNY from "../../assets/Home/WaysideNY.webp";
import Logo from "assets/logo/Logo.webp";

import { LOCATIONS, PHONE_NUMBER, EMAIL } from "consts";
/**
 * A functional component representing the Home page.
 * @returns {React.ReactElement} The Home page.
 */
const Home: React.FC = () => {
	return (
		<>
			<LargeHero
				title="Beyond Fence, Beyond Boundaries"
				backgroundImage={HomeHeroImg}
				imagePosition={{ base: "center 56%", md: "center 48%" }}
				imageSize={{ base: "fill-contain", md: "cover" }}
				contactPhone={PHONE_NUMBER}
				contactEmail={EMAIL}
				heroImage={Logo}
			/>
			<Store />
			<CustomInspiration title="Custom Inspiration" />
			<Flex
				direction="row"
				justifyContent="center"
				alignItems="center"
				flexWrap="wrap"
				boxSize="100%"
				gap={{
					base: "4",
					md: "14",
				}}
			>
				<Locations
					title="Bay Shore, NY Location"
					state="NY"
					address={LOCATIONS[0]["address"]}
					imageSrc={WaysideNY}
					imageWidth={{ base: "300px", md: "700px" }}
					imageHeight={{ base: "300px", md: "490px" }}
				/>
				<Locations
					title="Newington, CT Location"
					state="CT"
					address={LOCATIONS[1]["address"]}
					imageSrc={WaysideCT}
					imageWidth={{ base: "300px", md: "700px" }}
					imageHeight={{ base: "300px", md: "490px" }}
				/>
				<Flex pb={12} pt={6}>
					<ReviewCarousel />
				</Flex>
				<FenceSolutionSection />
			</Flex>
		</>
	);
};

export default Home;
