import { gql } from "@apollo/client";

const getPromotion = gql`
	query getPromotion {
		getPromotion {
			_id
			title
			subtitle
			products {
				ecwidID
				content
			}
			images
			callToAction
		}
	}
`;

const getPromotions = gql`
	query getPromotions($retrievalInput: RetrievalInput) {
		getPromotions(retrievalInput: $retrievalInput) {
			items {
				_id
				title
				subtitle
				products {
					ecwidID
					content
				}
				images
				callToAction
			}
			totalCount
			hasNextPage
			hasPreviousPage
		}
	}
`;

const getReviews = gql`
	query getReviews($retrievalInput: RetrievalInput) {
		getReviews(retrievalInput: $retrievalInput) {
			items {
				_id
				author
				content
				date
				rating
			}
			totalCount
			hasNextPage
			hasPreviousPage
		}
	}
`;

const getFAQs = gql`
	query getFAQs($retrievalInput: RetrievalInput) {
		getFAQs(retrievalInput: $retrievalInput) {
			items {
				_id
				category
				question
				answer
			}
			totalCount
			hasNextPage
			hasPreviousPage
		}
	}
`;

const getPartners = gql`
	query getPartners($retrievalInput: RetrievalInput) {
		getPartners(retrievalInput: $retrievalInput) {
			items {
				_id
				name
				type
				details {
					image
					url
					subcategories
				}
			}
			totalCount
			hasNextPage
			hasPreviousPage
		}
	}
`;

const getGalleryImages = gql`
	query getGalleryImages($retrievalInput: RetrievalInput) {
		getGalleryImages(retrievalInput: $retrievalInput) {
			items {
				_id
				title
				image
			}
			totalCount
			hasNextPage
			hasPreviousPage
		}
	}
`;

const getCustomWorkGalleryImages = gql`
	query getCustomWorkGalleryImages($retrievalInput: RetrievalInput) {
		getCustomWorkGalleryImages(retrievalInput: $retrievalInput) {
			items {
				_id
				category
				image
			}
			totalCount
			hasNextPage
			hasPreviousPage
		}
	}
`;

const getWebsiteCatalogs = gql`
	query getWebsiteCatalogs($retrievalInput: RetrievalInput) {
		getWebsiteCatalogs(retrievalInput: $retrievalInput) {
			items {
				_id
				name
				src
				image
				isDealer
			}
			totalCount
			hasNextPage
			hasPreviousPage
		}
	}
`;

export {
	getPromotion,
	getPromotions,
	getReviews,
	getFAQs,
	getPartners,
	getGalleryImages,
	getCustomWorkGalleryImages,
	getWebsiteCatalogs,
};
