import { useMutation } from "@apollo/client";
import { createPosition as CREATE_POSITION } from "./mutations";
import { PositionInput } from "../../types";

interface CreatePositionData {
	createPosition: boolean;
}

const useCreatePosition = () => {
	const [createPositionMutation, { loading, error }] =
		useMutation<CreatePositionData>(CREATE_POSITION);
	const createPosition = async (positionInput: PositionInput) => {
		try {
			const { data } = await createPositionMutation({
				variables: {
					positionInput,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.createPosition };
		} catch (error: any) {
			return { error };
		}
	};
	return { createPosition, loading, error };
};
export default useCreatePosition;
