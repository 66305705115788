/**
 * @module LoginForm.tsx
 * @description Login Form
 */
import React from "react";
import { Button, Spinner } from "@chakra-ui/react";
import { Form } from "./index";
import { useLoginForm } from "hooks";

interface LoginFormProps {
	strategy: "admin" | "employee" | "dealer";
}

const LoginForm: React.FC<LoginFormProps> = ({ strategy }) => {
	const { loginFields, handleLogin, loading } = useLoginForm({ strategy });

	return (
		<Form
			fields={loginFields}
			onSubmit={handleLogin}
			submitButtons={
				<Button
					type="submit"
					variant="primary"
					width="full"
					mt="2"
					//pb="2"
					isLoading={loading}
					disabled={loading}
				>
					{loading ? <Spinner size="sm" mr="2" /> : null} Login
				</Button>
			}
		/>
	);
};

export default LoginForm;
