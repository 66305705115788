import React, { useState } from "react";
import { Flex, Text, Button, Stack, Icon, Input } from "@chakra-ui/react";

import { colors } from "styles";

import { GrFormNext, GrFormPrevious } from "react-icons/gr";

interface PaginationProps {
	pageSize: number;
	pageCount: number;
	pageIndex: number;
	totalCount: number;
	previousPage: () => void;
	canPreviousPage: boolean;
	gotoPage: (page: number) => void;
	nextPage: () => void;
	canNextPage: boolean;
	setPageSize: (size: number) => void;
	createPages: (pageCount: number) => number[];
}

const Pagination: React.FC<PaginationProps> = ({
	pageSize,
	pageCount,
	pageIndex,
	totalCount,
	previousPage,
	canPreviousPage,
	gotoPage,
	nextPage,
	canNextPage,
	setPageSize,
	createPages,
}) => {
	const [goToPageValue, setGoToPageValue] = useState("");

	let displayPageLowerBound = pageIndex - 2;
	let displayPageUpperBound = pageIndex + 3;
	if (displayPageLowerBound < 0) {
		displayPageUpperBound += Math.abs(displayPageLowerBound);
		displayPageLowerBound = 0;
	}
	if (displayPageUpperBound > pageCount) {
		displayPageLowerBound -= displayPageUpperBound - pageCount;
		displayPageUpperBound = pageCount + 1;
	}
	if (pageCount < 5) {
		displayPageLowerBound = 0;
		displayPageUpperBound = pageCount + 1;
	}

	const handleGoToPage = () => {
		const pageNumber = parseInt(goToPageValue);
		if (pageNumber >= 1 && pageNumber <= pageCount) {
			gotoPage(pageNumber - 1);
		}
	};

	return (
		<Flex
			direction={{
				base: "column",
				lg: "row",
			}}
			justify="space-between"
			align="center"
			px="22px"
			w="100%"
			gap={{
				base: "12px",
				lg: "0px",
			}}
			pb={4}
		>
			<Flex
				direction={{
					base: "column",
					sm: "row",
				}}
				align="center"
				mb={{ sm: "24px", md: "0px" }}
			>
				<Text
					fontSize="sm"
					color="gray.600"
					fontWeight="500"
					textAlign="center"
				>
					Showing page {pageIndex + 1} of {pageCount}
				</Text>
				<Input
					type="number"
					value={goToPageValue}
					onChange={(e) => setGoToPageValue(e.target.value)}
					w="120px"
					h="30px"
					borderRadius="md"
					border={
						parseInt(goToPageValue) > pageCount || parseInt(goToPageValue) <= 0
							? "1px solid red"
							: "1px solid lightgray"
					}
					mr="10px"
					placeholder="Go to Page"
					mx="8px"
					title="Go to page"
					aria-label="Go to page"
				/>
				<Button
					variant="no-hover"
					onClick={handleGoToPage}
					transition="all .5s ease"
					w="60px"
					h="30px"
					borderRadius="md"
					bg={colors.primary[500]}
					color="white"
					_hover={{
						bg: "gray.200",
						opacity: "0.7",
						borderColor: "gray.500",
					}}
					isDisabled={
						goToPageValue === "" ||
						goToPageValue === "0" ||
						parseInt(goToPageValue) > pageCount
					}
				>
					Go
				</Button>
			</Flex>
			<Stack
				direction="row"
				alignSelf={{
					base: "center",
					lg: "flex-end",
				}}
				spacing="4px"
			>
				<Button
					variant="no-hover"
					onClick={() => previousPage()}
					transition="all .5s ease"
					w="40px"
					h="40px"
					borderRadius="50%"
					bg={colors.primary[500]}
					border="1px solid lightgray"
					isDisabled={!canPreviousPage}
					_hover={{
						bg: "gray.200",
						opacity: "0.7",
						borderColor: "gray.500",
					}}
					aria-label="Previous page"
					title="Previous page"
				>
					<Icon as={GrFormPrevious} w="16px" h="16px" color="gray.400" />
				</Button>
				{createPages(pageCount)
					.slice(displayPageLowerBound, displayPageUpperBound)
					.map((pageNumber, index) => {
						return (
							<Button
								variant={pageNumber === pageIndex + 1 ? "base" : "outline"}
								transition="all .5s ease"
								onClick={() => gotoPage(pageNumber - 1)}
								w="40px"
								h="40px"
								borderRadius="160px"
								key={index}
								fontSize="sm"
							>
								{pageNumber}
							</Button>
						);
					})}
				<Button
					variant="no-hover"
					onClick={() => nextPage()}
					transition="all .5s ease"
					w="40px"
					h="40px"
					borderRadius="160px"
					bg={colors.primary[500]}
					isDisabled={!canNextPage}
					aria-label="Next page"
					title="Next page"
				>
					<Icon as={GrFormNext} w="16px" h="16px" color="gray.400" />
				</Button>
			</Stack>
		</Flex>
	);
};

export default Pagination;
