import { gql } from "@apollo/client";

const resolvePositionApplication = gql`
	mutation resolvePositionApplication(
		$positionApplicationId: ID!
		$status: String!
	) {
		resolvePositionApplication(
			positionApplicationId: $positionApplicationId
			status: $status
		)
	}
`;

const applyForPosition = gql`
	mutation applyForPosition($input: PositionApplicationInput!) {
		applyForPosition(input: $input)
	}
`;

const deletePositionApplication = gql`
	mutation deletePositionApplication($positionApplicationId: ID!) {
		deletePositionApplication(positionApplicationId: $positionApplicationId)
	}
`;

const resolveDealerApplication = gql`
	mutation resolveDealerApplication(
		$dealerApplicationId: ID!
		$status: String!
	) {
		resolveDealerApplication(
			dealerApplicationId: $dealerApplicationId
			status: $status
		)
	}
`;

const applyForDealerAccount = gql`
	mutation applyForDealerAccount($input: DealerApplicationInput!) {
		applyForDealerAccount(input: $input)
	}
`;

const deleteDealerApplication = gql`
	mutation deleteDealerApplication($dealerApplicationId: ID!) {
		deleteDealerApplication(dealerApplicationId: $dealerApplicationId)
	}
`;

export {
	resolvePositionApplication,
	applyForPosition,
	deletePositionApplication,
	resolveDealerApplication,
	applyForDealerAccount,
	deleteDealerApplication,
};
