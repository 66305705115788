import React from "react";
import { ModalWrapper } from "wrappers";
import { Employee } from "services";
import { useAssignResourcesForm } from "hooks/form-hooks";
import { Form } from "components";

interface AssignResourcesModalProps {
	isOpen: boolean;
	onClose: () => void;
	employee: Employee; //Assuming you pass employee ID for resource assignment
	refetch: () => Promise<any>;
}

const AssignResourcesModal: React.FC<AssignResourcesModalProps> = ({
	isOpen,
	onClose,
	employee,
	refetch,
}) => {
	const { fields, handleSubmit, loading } = useAssignResourcesForm(employee);

	const handleAssignResources = async () => {
		const result = await handleSubmit();
		if (result) {
			onClose();
			await refetch();
		}
	};

	return (
		<ModalWrapper
			isOpen={isOpen}
			onClose={onClose}
			heading="Assign Resources"
			size="3xl"
		>
			<Form
				fields={fields}
				onSubmit={handleAssignResources}
				isLoading={loading}
			/>
		</ModalWrapper>
	);
};

export default AssignResourcesModal;
