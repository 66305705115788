import React, { useContext } from "react";
import { Sidebar } from "components/features";
import { PageChangeContext } from "contexts";
import { FiMenu, FiUser } from "react-icons/fi";
import { GrCatalogOption } from "react-icons/gr";
import { colors } from "styles";

const DealerSidebar: React.FC = () => {
	const { handlePageChange } = useContext(PageChangeContext);

	const dealerItems = [
		{
			label: "Dashboard",
			icon: FiMenu,
			onClick: (label: string) =>
				handlePageChange(`/dealer/${label.toLowerCase()}`),
		},
		{
			label: "Account",
			icon: FiUser,
			onClick: (label: string) =>
				handlePageChange(`/dealer/${label.toLowerCase()}`),
		},
		{
			label: "Catalogs",
			icon: GrCatalogOption,
		},
	];

	return (
		<Sidebar
			sidebarHeading={"Dealer Portal"}
			items={dealerItems}
			bgColor={colors.primary[500]}
		/>
	);
};

export default DealerSidebar;
