import React, { useState } from "react";
import { Heading, Flex, Text, Progress } from "@chakra-ui/react";
import { MultiStepForm } from "components";
import { useApplicationForm } from "hooks"; // Adjust the import path as needed

import { FormLayout } from "components";

const ApplicationForm: React.FC = () => {
	const { loading, onSubmit, steps } = useApplicationForm();

	const [progressPercent, setProgressPercent] = useState(0);

	const HeadingComponent = (
		<>
			<Flex
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				w="100%"
				pb={4}
			>
				<Flex
					direction="column"
					justifyContent="center"
					alignItems="flex-start"
					px={2}
					w="100%"
					h="100%"
				>
					<Text
						fontSize={{ base: "sm", md: "lg" }}
						fontWeight="bold"
						color="graydient.600"
					>
						Dealer Application
					</Text>
					<Text
						fontSize={{ base: "xs", md: "md" }}
						fontWeight="medium"
						color="graydient.600"
					>
						Become a certified Wayside Dealer.
					</Text>
				</Flex>
				<Progress
					hasStripe
					isAnimated
					value={progressPercent}
					colorScheme="primary"
					bg="graydient.100"
					borderRadius={5}
					size="sm"
					w="100%"
					alignSelf="flex-end"
				/>
			</Flex>
			<Heading
				as="h1"
				fontSize={{
					base: "3xl",
					md: "4xl",
				}}
				fontWeight="bold"
				color="primary.500"
				textShadow="1px 1px 2px rgba(0,0,0,0.25)"
				mb={{
					base: "0",
					md: "1rem",
				}}
				mt={{
					base: "0",
					md: "1rem",
				}}
			>
				Wayside Fence Co.
			</Heading>
		</>
	);

	const FormComponent = (
		<MultiStepForm
			steps={steps}
			onSubmit={onSubmit}
			isLoading={loading}
			setProgressPercent={setProgressPercent}
		/>
	);

	return (
		<FormLayout
			headingComponent={HeadingComponent}
			formComponent={FormComponent}
		/>
	);
};

export default ApplicationForm;
