/**
 * @module Login.tsx
 * @description Reusable Login component
 */
import React from "react";
import { LoginForm } from "components/common/forms";
import { Box, Container, Stack, Text, Heading } from "@chakra-ui/react";
import { colors } from "styles/theme";
import { ResponsiveValue } from "@chakra-ui/react";

interface LoginProps {
	heading?: string;
	subHeading?: string;
	subHeading2?: string;
	bottomButton?: React.ReactNode;
	bgColor?: ResponsiveValue<string>;
	style?: React.CSSProperties;
	strategy: "admin" | "employee" | "dealer"; // Added strategy prop
}

const Login: React.FC<LoginProps> = ({
	heading,
	subHeading,
	subHeading2,
	bottomButton,
	strategy, // Destructure strategy prop
	style,
	bgColor,
}) => {
	return (
		<Box py={{ base: "12", md: "24" }}>
			<Container
				maxW="md"
				w={{ base: "300px", md: "md" }}
				py={{ base: "4", sm: "8" }}
				px={{ base: "4", sm: "10" }}
				bg={bgColor}
				boxShadow="dark-lg"
				borderRadius={{ base: "lg", md: "xl" }}
				style={style}
			>
				<Stack spacing="6">
					<Stack spacing="6" align="center">
						<Stack spacing="0" textAlign="center">
							<Heading
								display={"flex"}
								alignItems={"center"}
								justifyContent={"center"}
								size={{ base: "2xl", md: "3xl" }}
								style={{
									WebkitTextStroke:
										strategy === "admin"
											? "0px white"
											: ".5px " + colors.primary[500],
								}}
								color={{
									base: colors.primary[500],
									xl: colors.primary[500],
								}}
								textShadow="0px 1px 2px rgba(0,0,0,0.5)"
								pt={{ base: 10, md: 5 }}
								mx={4}
								textAlign="center"
								fontFamily="montserrat"
							>
								{heading}
							</Heading>
							<Heading
								display={"flex"}
								alignItems={"center"}
								justifyContent={"center"}
								size={{ base: "md", md: "lg" }}
								fontWeight={"bold"}
								textShadow="0px 1px 1px rgba(0,0,0,0.6)"
								color={{
									base: colors.primary[500],
									xl: colors.primary[400],
								}}
								mx={4}
								textAlign="center"
								fontFamily="montserrat"
							>
								{strategy === "admin"
									? "Admin Portal"
									: strategy === "employee"
									? "Employee Portal"
									: "Dealer Portal"}
							</Heading>
							<Text
								color="fg.muted"
								fontSize="xl"
								fontWeight="bold"
								noOfLines={3}
							>
								{subHeading}
							</Text>
							<Text color="fg.muted" fontSize="md" noOfLines={3}>
								{subHeading2}
							</Text>
						</Stack>
					</Stack>
					<Stack spacing="8">
						<Stack spacing="6">
							<LoginForm
								strategy={strategy} // Passing strategy prop to LoginForm
							/>
						</Stack>
					</Stack>
					{bottomButton}
				</Stack>
			</Container>
		</Box>
	);
};

export default Login;
