/**
 * @file useGetResources.ts
 */

import { useQuery } from "@apollo/client";
import { getResources as getResourcesQuery } from "./queries"; // Replace with the actual path

import { RetrievalInput, Resource, PaginatedResult } from "../../types";

// Define a type for the hook's parameters
interface GetResourcesParams {
	retrievalInput?: RetrievalInput; // Assuming RetrievalInput is already defined in your type definitions
}

interface GetResourcesData {
	getResources: PaginatedResult<Resource>;
}

/**
 * @hook useGetResources
 *
 * @description
 * Custom hook that handles the getResources query functionality.
 * This hook can be used to retrieve resources based on given parameters.
 *
 * @param params - Parameters for the getResources query
 * @returns An object containing loading state, error, data, refetch function, and network status
 */
const useGetResources = (input?: GetResourcesParams) => {
	const { loading, error, data, refetch, networkStatus } =
		useQuery<GetResourcesData>(getResourcesQuery, {
			variables: input ? input : {},
		});

	// Optionally process the data
	const processedData = data?.getResources; // Adjust according to the actual field from your query

	return { loading, error, data: processedData, refetch, networkStatus };
};

export default useGetResources;
