import React from "react";
import { Flex } from "@chakra-ui/react";

interface HeaderLayoutProps {
	children: React.ReactNode;
}

const HeaderLayout: React.FC<HeaderLayoutProps> = ({ children }) => {
	return (
		<Flex
			direction="row"
			align="center"
			justify="space-between"
			w="100%"
			bgColor="accent.500"
			boxShadow="lg"
			borderBottomWidth="2px"
			borderBottomColor="accent.700"
			px={{ base: 4, md: 2 }}
			py={2}
			h={{ base: "auto", lg: "09vh" }}
			zIndex="docked"
		>
			{children}
		</Flex>
	);
};

export default HeaderLayout;
