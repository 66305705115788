import { ComponentStyleConfig } from "@chakra-ui/react";
import { colors } from "styles";

const tooltipStyles: ComponentStyleConfig = {
	variants: {
		action: {
			color: colors.primary[500],
			bgColor: "white",
			borderColor: colors.primary[500],
			border: "1px solid",
			fontSize: "sm",
			fontWeight: "normal",
			borderRadius: "md",
			boxShadow: "md",
		},
		delete: {
			color: "red.500",
			bgColor: "white",
			borderColor: "red.500",
			border: "1px solid",
			fontSize: "sm",
			fontWeight: "normal",
			borderRadius: "md",
			boxShadow: "md",
		},
	},
};

export default tooltipStyles;
