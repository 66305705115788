import React from "react";
import { Providers } from "./contexts";

import { ScrollRestoration } from "react-router-dom";

import MainRouter from "routes/MainRouter";

const App: React.FC = () => {
	return (
		<Providers>
			<ScrollRestoration />
			<MainRouter />
		</Providers>
	);
};

export default App;
