import { useMutation } from "@apollo/client";
import { deletePosition as DELETE_POSITION } from "./mutations";

interface DeletePositionData {
	deletePosition: boolean;
}

const useDeletePosition = () => {
	const [deletePositionMutation, { loading, error }] =
		useMutation<DeletePositionData>(DELETE_POSITION);
	const deletePosition = async (positionId: string) => {
		try {
			const { data } = await deletePositionMutation({
				variables: {
					positionId,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.deletePosition };
		} catch (error: any) {
			return { error };
		}
	};
	return { deletePosition, loading, error };
};
export default useDeletePosition;
