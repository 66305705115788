const colors = {
	primary: {
		50: "#f9f7ed",
		100: "#f2eccf",
		200: "#e6d6a2",
		300: "#d7bb6d",
		400: "#caa245",
		500: "#B78E43",
		600: "#a0702e",
		700: "#815327",
		800: "#6c4527",
		900: "#5d3b26",
	},
	secondary: {
		50: "#FFFFFF",
		100: "#F5F5F5",
		200: "#EBEBEB",
		300: "#E0E0E0",
		400: "#D6D6D6",
		500: "#CCCCCC",
		600: "#C2C2C2",
		700: "#B8B8B8",
		800: "#ADADAD",
		900: "#A3A3A3",
	},
	accent: {
		50: "#7D8387",
		100: "#707679",
		200: "#63686B",
		300: "#555A5C",
		400: "#484C4E",
		500: "#3B3E40",
		600: "#37393B",
		700: "#323537",
		800: "#2E3032",
		900: "#2A2C2D",
	},
	graydient: {
		50: "#F7FAFC",
		100: "#EDF2F7",
		200: "#E2E8F0",
		300: "#CBD5E0",
		400: "#A0AEC0",
		500: "#718096",
		600: "#4A5568",
		700: "#2D3748",
		800: "#1A202C",
		900: "#171923",
	},
	white: "#FFFFFF",
	black: "#000000",
	gray: "#999999",
	slate: "#474747",
	shadow: "rgba(0, 0, 0, 0.25)",
	text: {
		primary: "#0D4532", // primary text color (Wayside Green)
		paragraph: "#1A202C", // secondary text color (Gray 800)
		tertiary: "#4A5568", // tertiary text color (Gray 600)
		caption: "#474747",
	},
};

export default colors;
