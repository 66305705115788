/**
 * @module UpdatePromotionForm.tsx
 * @description This file contains the form for updating a Promotion.
 */
import React from "react";
import { Form } from "components";
import { ModalWrapper } from "wrappers";
import { useUpdatePromotionForm } from "hooks/form-hooks";
import { Promotion } from "services/types";

interface UpdatePromotionFormProps {
	isOpen: boolean;
	onClose: () => void;
	refetch: () => Promise<any>;
	existingPromotion: Promotion;
}

const UpdatePromotionForm: React.FC<UpdatePromotionFormProps> = ({
	isOpen,
	onClose,
	refetch,
	existingPromotion,
}) => {
	const { fields, handleSubmit, loading } = useUpdatePromotionForm({
		refetch,
		existingPromotion,
	});

	const onSubmit = async () => {
		await handleSubmit();
		onClose();
	};

	return (
		<ModalWrapper
			isOpen={isOpen}
			onClose={onClose}
			showShadow={true}
			heading="Update Promotion"
			hideCloseButton={false}
			size={"full"}
			modalContentProps={{
				maxHeight: "100vh",
				overflowY: "auto",
				pb: "8",
			}}
		>
			<Form
				fields={fields}
				onSubmit={onSubmit}
				isLoading={loading}
				maxHeight="85vh"
			/>
		</ModalWrapper>
	);
};

export default UpdatePromotionForm;
