import BaseUpdate from "./BaseUpdate";

import { CreateToastFnReturn } from "@chakra-ui/react";
import { ProductCollection, ProductCollectionInput } from "services/types";
import { PRODUCT_COLLECTION_PATH } from "consts";

class ProductCollectionUpdate extends BaseUpdate<
	ProductCollection,
	ProductCollectionInput
> {
	//ProductCollectionUpdate Expands upon the BaseUpdate by adding in support for uploading files
	//The constructor here will take in an additional uploadFile function in the constructor that it will use to upload the files
	protected file: File | null;
	protected uploadFile: (file: File, path: string) => Promise<string>;

	constructor(
		object: ProductCollection,
		toast: CreateToastFnReturn,
		updateFn: (
			id: string,
			input: ProductCollectionInput,
		) => Promise<{ data?: any; error?: any }>,
		refetch: () => Promise<void>,
		file: File | null,
		uploadFile: (file: File, path: string) => Promise<string>,
	) {
		super(object, "Product Collection", toast, updateFn, refetch);
		this.file = file;
		this.uploadFile = uploadFile;
	}

	public async handleUpdate(input: ProductCollectionInput): Promise<boolean> {
		if (!this.updateFn) throw new Error("Update function not defined");
		if (!this.uploadFile) throw new Error("Upload function not defined");

		let path = "";

		if (this.file) {
			try {
				path = await this.uploadFile(this.file, PRODUCT_COLLECTION_PATH);
			} catch (err: any) {
				this.toast({
					title: "Error",
					description: err.message,
					status: "error",
					duration: 5000,
					isClosable: true,
				});
				return false;
			}
		}

		const productCollectionInput: ProductCollectionInput = {
			...input,
		};

		if (path) productCollectionInput.image = path;

		return await super.handleUpdate(productCollectionInput);
	}
}

export default ProductCollectionUpdate;
