import React, { useState } from "react";
import {
	Button,
	Heading,
	Text,
	VStack,
	HStack,
	useBreakpointValue,
	Flex,
	Icon,
} from "@chakra-ui/react";
import {
	ChevronLeftIcon,
	ChevronRightIcon,
	ChevronUpIcon,
	ChevronDownIcon,
} from "@chakra-ui/icons";
import { IoIosPin } from "react-icons/io";
import { MotionDiv } from "components";
import { colors } from "styles";
import LocationInfo, { LocationProps } from "./LocationInfo";
import { ComponentHeading } from "components";
import { useSearchParams } from "react-router-dom";

const LocationCarousel: React.FC<{ locations: LocationProps[] }> = ({
	locations,
}) => {
	//get 'location' search param
	const [searchParams] = useSearchParams();
	const location = searchParams.get("location");
	const [currentLocation, setCurrentLocation] = useState(
		location && location === "CT" ? 1 : 0,
	);
	const isMobile = useBreakpointValue({ base: true, lg: false });

	const nextLocation = () => {
		setCurrentLocation((prev) => (prev + 1) % locations.length);
	};

	const prevLocation = () => {
		setCurrentLocation(
			(prev) => (prev - 1 + locations.length) % locations.length,
		);
	};

	const buttonSize = useBreakpointValue({ base: "10", lg: "6" });

	return (
		<VStack
			align="stretch"
			w="full"
			position="relative"
			height={{
				base: "100%",
				lg: "full",
			}}
			py={-5}
		>
			{isMobile ? (
				// Mobile layout
				<>
					<HStack justify="space-between" w="full" px={4}>
						<Button
							onClick={prevLocation}
							variant="ghost"
							color={colors.primary[500]}
							aria-label="Previous location"
						>
							<ChevronLeftIcon boxSize="6" />
						</Button>
						<Flex
							align="center"
							justify="center"
							flexDirection="column"
							w="full"
						>
							<Flex align="center" justify="center" flexDirection="row" gap={2}>
								<Text
									fontSize="lg"
									textAlign="center"
									fontWeight="bold"
									color={colors.primary[500]}
								>
									{locations[currentLocation].name}
								</Text>
							</Flex>
							<Flex>
								<Icon as={IoIosPin} boxSize={6} color={colors.primary[500]} />
								<Text
									fontSize="lg"
									textAlign="center"
									fontWeight="bold"
									color={colors.primary[500]}
								>
									{locations[currentLocation].address}
								</Text>
							</Flex>
						</Flex>
						<Button
							onClick={nextLocation}
							variant="ghost"
							color={colors.primary[500]}
							aria-label="Next location"
						>
							<ChevronRightIcon boxSize="6" />
						</Button>
					</HStack>
				</>
			) : (
				// Original desktop layout
				<>
					<Flex
						w="auto"
						mb={{ base: "2", lg: "4" }}
						ml={{
							sm: "0",
							lg: "6",
						}}
						position="sticky"
					>
						<ComponentHeading
							variant="ComponentTitle"
							fontSize="4xl"
							title="Our locations"
						/>
					</Flex>
					<HStack spacing={2}>
						<VStack spacing={-1}>
							<Button
								onClick={prevLocation}
								variant="ghost"
								color={colors.primary[500]}
								_hover={{
									color: colors.primary[300],
									transform: "translateY(-3px)",
								}}
							>
								<ChevronUpIcon w={buttonSize} h={buttonSize} />
							</Button>
							<Button
								onClick={nextLocation}
								variant="ghost"
								color={colors.primary[500]}
								_hover={{
									color: colors.primary[300],
									transform: "translateY(3px)",
								}}
								px={{ base: 4, xl: 10 }}
							>
								<ChevronDownIcon w={buttonSize} h={buttonSize} />
							</Button>
						</VStack>
						<Flex align="center" justify="space-between" w="100%" px={4}>
							<Flex align="center" justify="start" flexDirection="column">
								<Heading
									variant="subtitle"
									ml={{ base: 0, xl: 2 }}
									textAlign={{ base: "center", xl: "start" }}
								>
									{locations[currentLocation].name}
								</Heading>
							</Flex>
							<Flex align="center" justify="end" flexDirection="column">
								<Text variant="paragraph">
									{locations[currentLocation].description}
								</Text>
							</Flex>
						</Flex>
					</HStack>
				</>
			)}
			<MotionDiv key={currentLocation}>
				<LocationInfo {...locations[currentLocation]} />
			</MotionDiv>
		</VStack>
	);
};

export default LocationCarousel;
