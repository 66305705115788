import React, { createContext, useState, useEffect, ReactNode } from "react";
import { HeaderFooterWrapper } from "wrappers";

import { Flex } from "@chakra-ui/react";

declare global {
	interface Window {
		xProductBrowser: (...args: string[]) => void;
		ecwid_script_defer: boolean;
		ecwid_dynamic_widgets: boolean;
		Ecwid:
			| {
					init: () => void;
					destroy: () => void;
					openPage: (page: string, options?: { [key: string]: any }) => void;
					OnPageLoaded: {
						add: (callback: (page?: any) => void) => void;
					};
					Cart: {
						addProduct: (productId: number) => void;
					};
			  }
			| any;
		_xnext_initialization_scripts: any;
		ecwid_onBodyDone: any;
	}
}

interface StoreContextType {
	loading: boolean;
	setupEcwid: () => void;
	openPage: (page: string, options?: { [key: string]: any }) => void;
	renderStore: () => void;
	hideStore: () => void;
	addProductToCart: (productId: number) => void;
}

export const StoreContext = createContext<StoreContextType>({
	loading: true,
	setupEcwid: () => {},
	openPage: () => {},
	renderStore: () => {},
	hideStore: () => {},
	addProductToCart: () => {},
});

interface StoreProviderProps {
	children: ReactNode;
}

export const StoreProvider: React.FC<StoreProviderProps> = ({ children }) => {
	const [loading, setLoading] = useState(true);
	const [showStore, setShowStore] = useState(false);

	useEffect(() => {
		if (!window.Ecwid) {
			// Replace requestIdleCallback with setTimeout for better compatibility
			const timeoutId = setTimeout(() => {
				window.localStorage.setItem("show_ec_logs", "false");
				window.ecwid_script_defer = true;
				window.ecwid_dynamic_widgets = true;

				const script = document.createElement("script");
				script.id = "ecwid-script";
				script.charset = "utf-8";
				script.type = "text/javascript";
				script.src =
					process.env.REACT_APP_ECWID_SCRIPT_URL || "default_script_url";
				script.async = true;
				script.defer = true;
				script.onload = () => {
					setupEcwid();
				};
				document.body.appendChild(script);
			}, 2000); // Delay script loading slightly to simulate 'idle' time

			return () => {
				clearTimeout(timeoutId);
				if (window.Ecwid?.destroy) {
					window.Ecwid.destroy();
				}
			};
		}
	}, []);

	const setupEcwid = () => {
		if (window.Ecwid) {
			window._xnext_initialization_scripts = [
				{
					widgetType: "ProductBrowser",
					id: process.env.REACT_APP_STORE_ID,
					arg: ["id=productBrowser"],
				},
			];
			window.Ecwid.init();

			window.Ecwid.OnPageLoaded.add((page: any) => {
				setLoading(false);
			});
		}
	};

	const openPage = (page: string, options?: { [key: string]: any }) => {
		if (window.Ecwid && typeof window.Ecwid.openPage === "function") {
			if (options) {
				window.Ecwid.openPage(page, options);
				return;
			} else {
				window.Ecwid.openPage(page);
				return;
			}
		}
	};

	const addProductToCart = (productId: number) => {
		if (
			window.Ecwid &&
			window.Ecwid.Cart &&
			typeof window.Ecwid.Cart.addProduct === "function"
		) {
			window.Ecwid.Cart.addProduct(productId);
		}
	};

	const renderStore = () => {
		setShowStore(true);
	};

	const hideStore = () => {
		setShowStore(false);
	};

	return (
		<StoreContext.Provider
			value={{
				loading,
				setupEcwid,
				openPage,
				renderStore,
				hideStore,
				addProductToCart,
			}}
		>
			<HeaderFooterWrapper>
				{children}
				<Flex
					w="100%"
					id={process.env.REACT_APP_STORE_ID || "my-store-69679755"}
					style={{
						padding: "25px",
						display: showStore ? "flex" : "none",
						minHeight: "90vh",
					}}
				/>
			</HeaderFooterWrapper>
		</StoreContext.Provider>
	);
};
