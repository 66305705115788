import { useQuery } from "@apollo/client";
import { getPartners as GET_PARTNERS } from "./queries";
import { RetrievalInput, Partner, PaginatedResult } from "../../types";

interface GetPartnersData {
	getPartners: PaginatedResult<Partner>;
}

const useGetPartners = (retrievalInput?: RetrievalInput) => {
	const { loading, error, data, refetch, networkStatus } =
		useQuery<GetPartnersData>(GET_PARTNERS, { variables: { retrievalInput } });
	const processedData = data?.getPartners;
	console.log("partners: ", processedData);
	return { loading, error, data: processedData, refetch, networkStatus };
};
export default useGetPartners;
