/**
 * @module useUpdateReviewForm.tsx
 * @description This file contains the hook for updating a review.
 */
import { useUpdateReview } from "services";
import { FieldDefinition } from "components";
import { Review, ReviewInput } from "services/types";
import { useForm } from "hooks/forms";
import BaseUpdate from "cud/update/BaseUpdate";
import { ICUDFormHook } from "./types";
import { formatUnixTimestampStringForDateInput } from "utils";
import { RatingInput } from "components/common/inputs";
import { FaStar } from "react-icons/fa";

/**
 * This hook is used to update a review.
 * @param {ICUDFormHook & { existingReview: Review }} param0 The refetch function and the existing review to update.
 * @returns {Object} An object containing the state variables and functions for updating a review.
 */

const useUpdateReviewForm = ({
	refetch,
	existingReview,
}: ICUDFormHook & { existingReview: Review }) => {
	// Define initial form state
	const initialFormState: ReviewInput = {
		author: existingReview.author,
		content: existingReview.content,
		date: formatUnixTimestampStringForDateInput(existingReview.date),
		rating: existingReview.rating,
	};

	const {
		formState,
		loading,
		setLoading,
		handleInputChange,
		resetFormState,
		toast,
	} = useForm<ReviewInput>({
		initialState: initialFormState,
	});

	const { updateReview } = useUpdateReview();

	const reviewUpdate = new BaseUpdate(
		existingReview,
		"Review",
		toast,
		updateReview,
		refetch,
	);

	const handleSubmit = async () => {
		setLoading(true);
		const input = {
			...formState,
			rating: parseInt(formState.rating.toString()),
		};
		const res = await reviewUpdate.handleUpdate(input);
		if (res) resetFormState();
		setLoading(false);
	};

	const fields: FieldDefinition[] = [
		{
			label: "Author",
			name: "author",
			type: "text",
			placeholder: "Enter the author...",
			onChange: handleInputChange,
			value: formState.author,
			required: true,
			variant: "base",
			size: "lg",
			width: "100%",
		},
		{
			label: "Content",
			name: "content",
			type: "text",
			placeholder: "Enter the content...",
			onChange: handleInputChange,
			value: formState.content,
			required: true,
			variant: "base",
			size: "lg",
			width: "100%",
		},
		// date
		{
			label: "Date",
			name: "date",
			type: "date",
			placeholder: "Enter the date...",
			onChange: handleInputChange,
			value: formState.date,
			required: true,
			variant: "base",
			size: "lg",
			width: "100%",
		},
		{
			label: "Rating",
			name: "rating",
			type: "custom",
			customComponent: RatingInput,
			customComponentProps: {
				rating: formState.rating,
				onChange: handleInputChange,
				RatingIcon: FaStar,
				activeColor: "yellow.400",
				inactiveColor: "gray.300",
			},
			required: true,
		},
	];

	return { fields, handleSubmit, loading };
};

export default useUpdateReviewForm;
