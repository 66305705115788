/**
 * @module useDeleteReview.ts
 * @description This file contains the logic for deleting a review in the database.
 */
import { useMutation } from "@apollo/client";
import { deleteReview as DELETE_REVIEW } from "./mutations";

interface DeleteReviewData {
	deleteReview: boolean;
}

/**
 * This is the hook that is used to delete a review in the database.
 * @returns {Object} The deleteReview function, loading state, and error state.
 */
const useDeleteReview = () => {
	const [deleteReviewMutation, { loading, error }] =
		useMutation<DeleteReviewData>(DELETE_REVIEW);

	/**
	 * This function is used to delete a review in the database.
	 * @param {string} reviewID The ID of the review to delete.
	 * @returns {Object} The data for the deleted review or an error.
	 */
	const deleteReview = async (reviewID: string) => {
		try {
			const { data } = await deleteReviewMutation({
				variables: {
					reviewID,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.deleteReview };
		} catch (error: any) {
			return { error };
		}
	};

	return { deleteReview, loading, error };
};
export default useDeleteReview;
