import React from "react";
import {
	Box,
	Button,
	Menu,
	MenuButton,
	MenuList,
	MenuItemOption,
	MenuOptionGroup,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

import { colors } from "styles";

// Define the type for your options
type Option = {
	value: string;
	label: string;
};

interface MultiSelectProps {
	options: Option[];
	name: string;
	onChange: (e: any) => void;
	value: string[];
}

const MultiSelect: React.FC<MultiSelectProps> = ({
	options,
	name,
	onChange,
	value = [],
}) => {
	const handleSelectChange = (values: string[]) => {
		onChange({ target: { name, value: values } } as any);
	};

	const getSelectedLabels = () => {
		return options
			.filter((option) => value.includes(option.value))
			.map((option) => option.label)
			.join(", ");
	};

	// Display selected options or "Select Options" if none
	const selectedLabels = getSelectedLabels();
	const buttonLabel = selectedLabels ? selectedLabels : "Select Options";

	return (
		<Box>
			<Menu closeOnSelect={false}>
				<MenuButton
					as={Button}
					rightIcon={<ChevronDownIcon />}
					variant="outline"
					color="black"
					borderColor={colors.graydient[300]}
					_hover={{ bg: colors.graydient[100] }}
					borderWidth="1px"
					borderRadius="5px"
				>
					{buttonLabel}
				</MenuButton>
				<MenuList
					minWidth="240px"
					borderWidth="1px"
					borderRadius="5px"
					borderColor={colors.graydient[200]}
					boxShadow="md"
				>
					<MenuOptionGroup
						value={value}
						type="checkbox"
						onChange={handleSelectChange as any}
					>
						{options.map((option) => (
							<MenuItemOption
								key={option.value}
								value={option.value}
								_hover={{ bg: colors.graydient[100] }}
							>
								{option.label}
							</MenuItemOption>
						))}
					</MenuOptionGroup>
				</MenuList>
			</Menu>
		</Box>
	);
};

export default MultiSelect;
