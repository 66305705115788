import React, { useContext } from "react";
import { Sidebar } from "components/features";
import { EmployeeContext } from "contexts";
import { colors } from "styles";
import { EmployeeProfileCard } from "page-components/profile-cards";

const EmployeeSidebar: React.FC = () => {
	const { employeeSidebarItems } = useContext(EmployeeContext);

	return (
		<Sidebar
			sidebarHeading={"Employee Portal"}
			items={employeeSidebarItems}
			bgColor={colors.primary[500]}
			ProfileCard={EmployeeProfileCard}
		/>
	);
};

export default EmployeeSidebar;
