import React, { useState } from "react";
import { ActionsRow } from "components/features/tables/cells";
import { useRetrieval } from "hooks";
import { SearchTable } from "components/features/tables";
import { createColumnHelper } from "@tanstack/react-table";
import { FAQ, useGetFAQs, FilterSettingsInput } from "services";
import {
	CreateFAQForm,
	UpdateFAQForm,
	DeleteFAQModal,
} from "page-components/admin/faq";
import { AdminResponsiveLayout } from "components";

const AdminFAQ: React.FC = () => {
	const {
		paginationInput,
		setPaginationInput,
		filterSettings,
		handleFiltersChange,
		sortInput,
		handleSortChange,
	} = useRetrieval();

	const handleBuildFilterSettings = (
		query: string,
		conditional: "AND" | "OR" = "OR",
	) => {
		const filterSettings: FilterSettingsInput = {
			conditional,
			filters: [
				{
					field: "category",
					operator: "regex",
					value: query,
				},
				{
					field: "question",
					operator: "regex",
					value: query,
				},
				{
					field: "answer",
					operator: "regex",
					value: query,
				},
			],
		};
		return filterSettings;
	};

	const {
		loading,
		error,
		data: FAQs,
		refetch,
	} = useGetFAQs({
		filterSettings: handleBuildFilterSettings(
			filterSettings.query,
			filterSettings.conditional,
		),
		pagination: paginationInput,
		sort: sortInput,
	});

	const [selectedFAQ, setSelectedFAQ] = React.useState<FAQ | null>(null);

	const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

	const closeDeleteModal = () => {
		setIsDeleteModalOpen(false);
		setSelectedFAQ(null); // Reset the resource to delete
	};

	// Function to close the update modal
	const closeUpdateModal = () => {
		setUpdateModalOpen(false);
		setSelectedFAQ(null);
	};

	// Function to handle the delete action for a resource
	const onRowDelete = (faq: FAQ) => {
		setSelectedFAQ(faq);
		setIsDeleteModalOpen(true); // Open the modal
	};

	// Function to handle the edit action for a resource with useUpdateResourceForm
	const onRowEdit = (resource: FAQ) => {
		setSelectedFAQ(resource);
		setUpdateModalOpen(true);
	};

	const columnHelper = createColumnHelper<FAQ>();

	const columns = [
		columnHelper.accessor("category", {
			header: () => "Category",
			cell: (info) => info.getValue(),
		}),
		columnHelper.accessor("question", {
			header: () => "Question",
			cell: (info) => info.getValue(),
		}),
		columnHelper.accessor("answer", {
			header: () => "Answer",
			cell: (info) => info.getValue(),
		}),
		columnHelper.display({
			header: () => "Actions",
			id: "actions",
			cell: (props) => (
				<ActionsRow
					onEdit={() => onRowEdit(props.row.original)}
					onDelete={() => onRowDelete(props.row.original)}
				/>
			),
		}),
	];

	return (
		<AdminResponsiveLayout>
			<SearchTable
				columnsData={columns}
				tableData={FAQs?.items || []}
				paginationInput={paginationInput}
				setPaginationInput={setPaginationInput}
				filterSettings={filterSettings}
				handleBuildFilters={handleFiltersChange}
				sortInput={sortInput}
				handleSortChange={handleSortChange}
				loading={loading}
				error={error}
				totalCount={FAQs?.totalCount || 0}
				hasNextPage={FAQs?.hasNextPage || false}
				hasPreviousPage={FAQs?.hasPreviousPage || false}
			/>
			<CreateFAQForm refetch={refetch} />
			{selectedFAQ && (
				<UpdateFAQForm
					isOpen={isUpdateModalOpen}
					onClose={closeUpdateModal}
					refetch={refetch}
					existingFAQ={selectedFAQ}
				/>
			)}
			{selectedFAQ && (
				<DeleteFAQModal
					isOpen={isDeleteModalOpen}
					onClose={closeDeleteModal}
					refetch={refetch}
					faq={selectedFAQ}
				/>
			)}
		</AdminResponsiveLayout>
	);
};

export default AdminFAQ;
