import { useState } from "react";

import useUploadFile from "hooks/useUploadFile";

import useForm from "./useForm";

import { IUseFormWithFiles } from "./types";

const useFormWithFiles = <T>({
	initialState,
	allowMultiple,
	initialFileUrls,
}: IUseFormWithFiles<T>) => {
	const [files, setFiles] = useState<File[] | File | null>(null);
	const [fileUrls, setFileUrls] = useState<string[]>(initialFileUrls || []);

	const { uploadFile } = useUploadFile();

	const {
		formState,
		setFormState,
		loading,
		setLoading,
		handleInputChange,
		resetFormState: superResetFormState,
		toast,
	} = useForm({ initialState });

	const handleSetFiles = (files: File[]) => {
		if (allowMultiple) {
			setFiles(files);
		} else {
			if (files.length > 0) {
				setFiles(files[0]);
			} else {
				setFiles(null);
			}
		}
	};

	const handleSetFileUrls = (urls: string[]) => {
		setFileUrls(urls);
	};

	const resetFormState = () => {
		superResetFormState();
		setFiles(null);
	};

	return {
		formState,
		loading,
		setLoading,
		handleInputChange,
		setFormState,
		resetFormState,
		toast,
		files,
		handleSetFiles,
		uploadFile,
		fileUrls,
		handleSetFileUrls,
	};
};

export default useFormWithFiles;
