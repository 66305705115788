import { useMutation } from "@apollo/client";
import { createResource as CREATE_RESOURCE } from "./mutations";
import { ResourceInput } from "../../types";

interface CreateResourceData {
	createResource: boolean;
}

/**
 * A custom hook for creating a resource using Apollo Client mutation.
 *
 * @param resourceInput - The input object containing the resource details (title, type, path).
 * @returns An object containing the createResource function, loading state, and error state.
 * @throws {Error} If there is an error during the mutation.
 * @returns {Object} - The createResource function, loading state, and error state.
 */
const useCreateResource = () => {
	const [createResourceMutation, { loading, error }] =
		useMutation<CreateResourceData>(CREATE_RESOURCE);
	const createResource = async (resourceInput: ResourceInput) => {
		try {
			const { data } = await createResourceMutation({
				variables: {
					resourceInput,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.createResource };
		} catch (error: any) {
			return { error };
		}
	};
	return { createResource, loading, error };
};

export default useCreateResource;
