import { useUpdatePosition } from "services";
import { FieldDefinition } from "components";

import { Position, PositionInput } from "services/types";
import { useForm } from "hooks/forms";
import BaseUpdate from "cud/update/BaseUpdate";
import { ICUDFormHook } from "./types";

const useUpdatePositionForm = ({
	refetch,
	existingPosition,
}: ICUDFormHook & { existingPosition: Position }) => {
	const initialFormState = {
		positionName: existingPosition.positionName,
		description: existingPosition.description,
		details: existingPosition.details,
	};

	const {
		formState,
		loading,
		setLoading,
		handleInputChange,
		resetFormState,
		toast,
	} = useForm<PositionInput>({
		initialState: initialFormState,
	});

	const { updatePosition } = useUpdatePosition();

	const positionUpdate = new BaseUpdate(
		existingPosition,
		"Position",
		toast,
		updatePosition,
		refetch,
	);

	const handleSubmit = async () => {
		setLoading(true);
		const res = await positionUpdate.handleUpdate(formState);
		if (res) resetFormState();
		setLoading(false);
	};

	const fields: FieldDefinition[] = [
		{
			label: "Position Name",
			name: "positionName",
			type: "text",
			placeholder: "Enter the position name...",
			onChange: handleInputChange,
			value: formState.positionName,
			required: true,
			variant: "base",
			size: "lg",
			width: "100%",
		},
		{
			label: "Description",
			name: "description",
			type: "text",
			placeholder: "Enter the description...",
			onChange: handleInputChange,
			value: formState.description,
			required: true,
			variant: "base",
			size: "lg",
			width: "100%",
		},
		{
			label: "Job Title",
			name: "details.jobTitle",
			type: "text",
			placeholder: "Enter the job title...",
			onChange: handleInputChange,
			value: formState.details.jobTitle,
			required: true,
			variant: "base",
			size: "lg",
			width: "100%",
		},
		{
			label: "Department",
			name: "details.department",
			type: "text",
			placeholder: "Enter the department...",
			onChange: handleInputChange,
			value: formState.details.department,
			required: true,
			variant: "base",
			size: "lg",
			width: "100%",
		},
		{
			label: "Location",
			name: "details.location",
			type: "select",
			options: ["New York", "Connecticut", "Remote", "Hybrid"],
			placeholder: "Select a location...",
			onChange: handleInputChange,
			value: formState.details.location,
			required: true,
			variant: "base",
			size: "lg",
			width: "100%",
		},
		{
			label: "Commitment Level",
			name: "details.commitmentLevel",
			type: "select",
			options: ["Full-time", "Part-time", "Contract", "Internship"],
			placeholder: "Enter the commitment level...",
			onChange: handleInputChange,
			value: formState.details.commitmentLevel,
			required: true,
			variant: "base",
			size: "lg",
			width: "100%",
		},
	];
	return { fields, handleSubmit, loading };
};

export default useUpdatePositionForm;
