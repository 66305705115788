import { Box, VStack, Container } from "@chakra-ui/react";
import SectionTitle from "../../components/features/seo/SectionTitle";
import IntroText from "../../components/features/seo/IntroText";
import ContentSection from "../../components/features/seo/ContentSection";
import CustomDivider from "../../components/common/divider/CustomDivider";

import { PHONE_NUMBER } from "consts";

const FenceSolutionSection = () => {
	return (
		<Box padding="4" maxWidth="8xl" margin="auto" mb={"12"}>
			<Container maxW="container.2xl">
				<VStack spacing={8} align="stretch">
					<SectionTitle>Fence Solutions for Your Property</SectionTitle>
					<IntroText>
						At Wayside Fence Company, we're dedicated to providing exceptional
						fence solutions. Our commitment to quality craftsmanship and
						customer satisfaction ensures that each project is completed to the
						highest standards.
					</IntroText>
					<CustomDivider width="100%" marginY="2" />
					<ContentSection title="Professional Fence Construction">
						As your premier fence builder, we approach every project with
						meticulous care and attention to detail. With wood, PVC, and welding
						shops, our dedicated teams utilize state-of-the-art tools and
						techniques for precise and timely fence construction. We prioritize
						your vision, dedicating ample time and resources to deliver superior
						fencing solutions that meet your needs.
					</ContentSection>
					<ContentSection title="Explore Your Fencing Options">
						Discover a wide range of fencing options tailored to your unique
						requirements at our locations. From pool and pet fences to privacy
						and vinyl options, we provide comprehensive solutions to enhance the
						security and aesthetics of your property. Our knowledgeable team is
						available to guide you through the selection process, offering
						expert insights and recommendations.
					</ContentSection>
					<ContentSection title="High-Quality Fence Products">
						Our fence products are designed to withstand the test of time.
						Whether you choose vinyl, wood, or aluminum fencing, you can trust
						in the durability and reliability of our materials. We also offer a
						variety of accessories and hardware to complement your fence,
						including gates, posts, and caps.
					</ContentSection>
					<ContentSection title="Contact Us Today">
						Ready to enhance your property with a quality fence solution?
						Contact Wayside Fence Company today at {PHONE_NUMBER}. Our dedicated
						team is ready to bring your vision to life with unmatched service
						and craftsmanship.
					</ContentSection>
				</VStack>
				{/* <CustomDivider width="100%"/> */}
			</Container>
		</Box>
	);
};

export default FenceSolutionSection;
