/**
 * @module useApplicationForm
 * @desc Application form custom hook.
 */
import { useState, useContext, useMemo } from "react";
import { PageChangeContext } from "contexts";
import { useToast } from "@chakra-ui/react";
import { FieldDefinition } from "components";
import { useApplyForDealerAccount, ApplicationDetails } from "services";
import {
	StateInput,
	ZipCodeInput,
	EmailInput,
	PhoneNumberInput,
} from "components/common/inputs";
import {
	validateEmail,
	validatePhoneNumber,
	validateZipCode,
} from "utils/validators";

const useApplicationForm = () => {
	const initialFormDataState = {
		name: "",
		company: "",
		address: {
			line1: "",
			line2: "",
			city: "",
			state: "",
			zipcode: "",
		},
		office: "",
		cell: "",
		fax: "",
		email: "",
		lineOfBusiness: "",
	};
	const [formData, setFormData] =
		useState<ApplicationDetails>(initialFormDataState);
	const toast = useToast();
	const { handlePageChange } = useContext(PageChangeContext);

	const { applyForDealerAccount } = useApplyForDealerAccount();

	const [loading, setLoading] = useState(false);

	const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
	};

	const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setFormData((prevFormData) => ({
			...prevFormData,
			address: {
				...prevFormData.address,
				[name]: value,
			},
		}));
	};

	const onSubmit = async () => {
		setLoading(true);
		const input = {
			applicationDetails: formData,
		};

		const { data, error } = await applyForDealerAccount(input);

		if (data) {
			toast({
				title: "Application Submitted",
				description: "Your application has been submitted successfully.",
				status: "success",
				duration: 5000,
				isClosable: true,
			});
		} else if (error) {
			toast({
				title: "Error",
				description: error.message,
				status: "error",
				duration: 5000,
				isClosable: true,
			});
		} else {
			toast({
				title: "Error",
				description: "An error occurred while submitting your application.",
				status: "error",
				duration: 5000,
				isClosable: true,
			});
		}
		setLoading(false);
		handleClose();
	};

	const handleClose = () => {
		//Reset form data
		setFormData(initialFormDataState);
		//Reset page to home page
		handlePageChange("/");
	};

	const steps: FieldDefinition[][] = useMemo(
		() => [
			// Step 1
			[
				{
					type: "text",
					name: "name",
					label: "Name",
					value: formData.name,
					placeholder: "Enter your name",
					required: true,
					variant: "base",
					size: "lg",
					width: "100%",
					onChange: handleFieldChange,
				},
				{
					type: "text",
					name: "company",
					label: "Company",
					value: formData.company,
					placeholder: "Company",
					required: true,
					size: "lg",
					width: "100%",
					variant: "base",
					onChange: handleFieldChange,
				},
			],
			// Step 2 - Address
			[
				{
					type: "text",
					name: "line1",
					label: "Address Line 1",
					value: formData.address?.line1,
					placeholder: "Address Line 1",
					required: true,
					variant: "base",
					size: "lg",
					width: "100%",
					onChange: handleAddressChange,
				},
				{
					type: "text",
					name: "line2",
					label: "Address Line 2",
					value: formData.address?.line2,
					placeholder: "Address Line 2",
					required: false,
					variant: "base",
					size: "lg",
					width: "100%",
					onChange: handleAddressChange,
				},
				{
					type: "text",
					name: "city",
					label: "City",
					value: formData.address?.city,
					placeholder: "City",
					required: true,
					variant: "base",
					size: "lg",
					width: "100%",
					onChange: handleAddressChange,
				},
				{
					type: "custom",
					name: "state",
					label: "State",
					value: formData.address?.state,
					placeholder: "State",
					required: true,
					customComponent: StateInput,
					variant: "base",
					size: "lg",
					width: "100%",
					onChange: handleAddressChange,
				},
				{
					type: "custom",
					name: "zipcode",
					label: "Zipcode",
					value: formData.address?.zipcode,
					placeholder: "Zipcode",
					required: true,
					variant: "base",
					customComponent: ZipCodeInput,
					size: "lg",
					width: "100%",
					onChange: handleAddressChange,
					validate: validateZipCode,
				},
			],
			// Step 3
			[
				{
					type: "custom",
					name: "office",
					label: "Office #",
					value: formData.office,
					placeholder: "(098)765-4321", //(098)765-4321
					required: true,
					customComponent: PhoneNumberInput,
					variant: "base",
					size: "lg",
					width: "100%",
					onChange: handleFieldChange,
					validate: validatePhoneNumber,
				},
				{
					type: "custom",
					name: "cell",
					label: "Cell #",
					value: formData.cell,
					placeholder: "(123)456-7890",
					customComponent: PhoneNumberInput,
					required: true,
					variant: "base",
					size: "lg",
					width: "100%",
					onChange: handleFieldChange,
					validate: validatePhoneNumber,
				},
				{
					type: "text",
					name: "fax",
					label: "Fax #",
					value: formData.fax,
					placeholder: "(123)456-7890",
					required: false,
					variant: "base",
					size: "lg",
					width: "100%",
					onChange: handleFieldChange,
				},
				{
					type: "custom",
					name: "email",
					label: "Email Address",
					value: formData.email,
					placeholder: "myemail@address.com",
					customComponent: EmailInput,
					required: true,
					variant: "base",
					size: "lg",
					width: "100%",
					onChange: handleFieldChange,
					validate: validateEmail,
				},
				{
					type: "text",
					name: "lineOfBusiness",
					label: "Line of Business",
					value: formData.lineOfBusiness,
					placeholder: "Contractor, Supplier, etc.",
					required: true,
					variant: "base",
					size: "lg",
					width: "100%",
					onChange: handleFieldChange,
				},
			],
		],
		[formData],
	);

	return { loading, onSubmit, steps };
};

export default useApplicationForm;
