import React, { useContext } from "react";
import { Sidebar } from "components/features";

import { EmployeeContext } from "contexts";
import { colors } from "styles";
import { AdminProfileCard } from "page-components/profile-cards";

const AdminSidebar: React.FC = () => {
	const { adminSidebarItems } = useContext(EmployeeContext);

	return (
		<Sidebar
			sidebarHeading={"Admin Portal"}
			items={adminSidebarItems}
			bgColor={colors.primary[500]}
			ProfileCard={AdminProfileCard}
		/>
	);
};

export default AdminSidebar;
