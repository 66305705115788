/**
 * @module FAQ
 * @category Resources
 */
import { useState } from "react";
import {
	Box,
	Button,
	Container,
	SimpleGrid,
	Skeleton,
	Center,
	Text,
} from "@chakra-ui/react";

/**
 * @reusedComponents
 * - AccordionList
 * - BaseAccordion
 */
import { AccordionList } from "components/common/accordions";
import { useGetFAQs, FAQ as FAQObject } from "services";
import ErrorMessage from "components/common/ui-elements/ErrorMessage";

import { FAQ_CATEGORIES } from "consts";

// Define a type for each FAQ item
interface FAQItem {
	question: string | undefined;
	answer: string | undefined;
}

// Define a type for each category
interface Category {
	name: string;
	faqs: FAQItem[];
	icon: JSX.Element;
}

/**
 *
 * @returns {React.ReactElement} A page with frequently asked questions.
 * @description This page contains a list of frequently asked questions.
 */
const FAQ = () => {
	// Define a state for the active category
	const [activeCategory, setActiveCategory] = useState<string>("General");

	const { loading, error, data: faqs } = useGetFAQs();

	// Define a list of categories
	// Id, name, icon, and faqs per category
	const categories: Category[] = FAQ_CATEGORIES.map((category, index) => ({
		name: category.name,
		icon: <category.icon size={50} />,
		faqs:
			faqs?.items.filter((faq: FAQObject) => faq.category === category.name) ||
			[],
	}));

	// Define a function to handle category clicks
	const handleCategoryClick = (categoryName: string) => {
		setActiveCategory(categoryName);
	};

	// Define a structured list of FAQs for rendering via AccordionList
	const structuredFAQs = categories.map((category, index) => ({
		id: index,
		name: category.name,
		icon: category.icon,
		faqs: category.faqs.map((faq) => ({
			buttonContent: (
				<Box flex="1" textAlign="left" fontWeight="bold" fontSize="lg" pb={1}>
					{faq.question}
				</Box>
			),
			children: <p>{faq.answer}</p>,
			itemProps: {
				mb: 4,
				borderWidth: "1px",
				borderColor: "graydient.200",
				borderRadius: "md",
				boxShadow: "md",
			},
			buttonProps: {
				borderBottomWidth: "1px",
				borderColor: "graydient.200",
				borderTopRadius: "md",
				textColor: "primary.500",
				_expanded: {
					bg: "primary.500",
					textColor: "white",
					boxShadow: "base",
				},
			},
			panelProps: {
				px: 6,
				py: 4,
				fontSize: "lg",
				fontWeight: "medium",
			},
		})),
	}));

	return (
		<Container maxW="container.xl" mt={8}>
			<SimpleGrid
				columns={{ base: 2, md: 4 }}
				spacing={{ base: 16, md: 8 }}
				py={8}
			>
				{categories.map((category) => (
					<Button
						key={`${category.name}-category-button`}
						onClick={() => handleCategoryClick(category.name)}
						variant={activeCategory === category.name ? "black" : "ghost"}
						leftIcon={category.icon}
						flexDirection={"column"}
						fontSize="lg"
						fontWeight="medium"
						px={8}
						py={4}
						borderRadius="md"
					>
						<Text
							fontSize="lg"
							fontWeight={activeCategory === category.name ? "600" : "medium"}
							color="inherit"
						>
							{category.name}
						</Text>
					</Button>
				))}
			</SimpleGrid>
			{error ? (
				<ErrorMessage
					title="We were unable to load the FAQs."
					description="You can try refreshing your browser or try again later. We apologize for the inconvenience."
				/>
			) : (
				structuredFAQs
					.filter((category) => category.name === activeCategory)
					.map((category) => (
						<Skeleton
							key={`${category.name}-FAQs-list`}
							isLoaded={!loading}
							startColor="primary.300"
							endColor="white"
							speed={1}
							mt={4}
						>
							<AccordionList items={category.faqs} p={4} allowToggle={true} />
							{category.faqs.length === 0 && (
								<Center>
									<Text fontSize="lg" fontWeight="medium" color="gray.500">
										There are no FAQs available in this category. If you have a
										question related to {category.name.toLowerCase()}, please
										contact us.
									</Text>
								</Center>
							)}
						</Skeleton>
					))
			)}
		</Container>
	);
};

export default FAQ;
