/**
 * @module RatingInput
 * @description This module provides a component for inputting a rating.
 */
import { useState } from "react";
import { HStack, IconButton } from "@chakra-ui/react";
import { motion } from "framer-motion";

/**
 * @interface RatingInputProps
 * @description The props for the RatingInput component.
 * @prop {number} rating The rating value.
 * @prop {(rating: number) => void} onChange The function to call when the rating changes.
 * @prop {number} [maxRating=5] The maximum rating value.
 * @prop {React.ReactElement} RatingIcon The icon component to use for each rating item.
 * @prop {string} [activeColor="yellow.400"] The color of the icon when active.
 * @prop {string} [inactiveColor="gray.300"] The color of the icon when inactive.
 */
interface RatingInputProps {
	rating: number;
	onChange: (event: { target: { name: string; value: number } }) => void;
	maxRating?: number;
	RatingIcon: React.ElementType;
	activeColor?: string;
	inactiveColor?: string;
}

const RatingInput: React.FC<RatingInputProps> = ({
	rating,
	onChange,
	maxRating = 5,
	RatingIcon,
	activeColor = "yellow.400",
	inactiveColor = "gray.300",
}) => {
	const [hoverRating, setHoverRating] = useState(0);

	const handleRatingChange = (value: number) => {
		if (value >= 1 && value <= maxRating) {
			onChange({ target: { name: "rating", value } });
		}
	};

	const handleMouseEnter = (value: number) => {
		setHoverRating(value);
	};

	const handleMouseLeave = () => {
		setHoverRating(0);
	};

	return (
		<HStack spacing={1}>
			{Array.from({ length: maxRating }, (_, i) => i + 1).map((value) => (
				<motion.div
					key={value}
					whileHover={{ scale: 1.2 }}
					whileTap={{ scale: 0.8 }}
				>
					<IconButton
						as={RatingIcon}
						onClick={() => handleRatingChange(value)}
						onMouseEnter={() => handleMouseEnter(value)}
						onMouseLeave={handleMouseLeave}
						color={
							value <= (hoverRating || rating) ? activeColor : inactiveColor
						}
						variant="ghost"
						aria-label={`${value} star`}
					/>
				</motion.div>
			))}
		</HStack>
	);
};

export default RatingInput;
