import { useContext } from "react";
import { EmployeeContext } from "contexts";
import { EmployeeSidebar } from "page-components";
import { DashboardHeader } from "components";
import { DashboardLayout } from "components/layouts";
import { RoutesWrapper } from "wrappers";
import { Route } from "react-router-dom";
import {
	EmployeeDashboard,
	ResourcePreview,
	CatalogsPreview,
} from "pages/employee";
import { withProtectedRoute } from "hocs";
import { EmployeeResourcesProvider } from "contexts";

/**
 * AuthenticatedEmployeeRouter component.
 *
 * This component is responsible for rendering the authenticated employee routes.
 * It uses the DashboardLayout component to provide a consistent layout for the employee pages.
 * The EmployeeSidebar component is used as the sidebar for the employee pages.
 * The DashboardHeader component is used as the header for the employee pages.
 * The ProtectedEmployeeDashboard component is used as the protected route for the employee pages.
 *
 * @returns The rendered AuthenticatedEmployeeRouter component.
 */
const AuthenticatedEmployeeRouter: React.FC = () => {
	const EmployeeRoutes = () => {
		const { pageLabel } = useContext(EmployeeContext);

		return (
			<EmployeeResourcesProvider>
				<DashboardLayout
					sidebar={<EmployeeSidebar />}
					header={<DashboardHeader dashboardHeading={pageLabel} />}
				>
					<RoutesWrapper>
						<Route path="/dashboard/*" element={<EmployeeDashboard />} />
						<Route
							path="/resources/images/"
							element={<ResourcePreview type="Image" />}
						/>
						<Route
							path="/resources/videos/"
							element={<ResourcePreview type="Video" />}
						/>
						<Route path="/resources/catalogs/" element={<CatalogsPreview />} />
						<Route
							path="/resources/documents/"
							element={<ResourcePreview type="Document" />}
						/>
					</RoutesWrapper>
				</DashboardLayout>
			</EmployeeResourcesProvider>
		);
	};

	const ProtectedEmployeeRoutes = withProtectedRoute(
		EmployeeRoutes,
		"employee",
	);

	return <ProtectedEmployeeRoutes />;
};

export default AuthenticatedEmployeeRouter;
