import BaseUpdate from "./BaseUpdate";

import { CreateToastFnReturn } from "@chakra-ui/react";
import { Promotion, PromotionInput } from "services/types";
import { PROMOTION_PATH } from "consts";

class PromotionUpdate extends BaseUpdate<Promotion, PromotionInput> {
	//PromotionUpdate Expands upon the BaseUpdate by adding in support for uploading files
	//The constructor here will take in an additional uploadFile function in the constructor that it will use to upload the files
	protected files: File[] | null;
	protected uploadFile: (file: File, path: string) => Promise<string>;

	constructor(
		object: Promotion,
		toast: CreateToastFnReturn,
		updateFn: (
			id: string,
			input: PromotionInput,
		) => Promise<{ data?: any; error?: any }>,
		refetch: () => Promise<void>,
		files: File[] | null,
		uploadFile: (file: File, path: string) => Promise<string>,
	) {
		super(object, "Promotion", toast, updateFn, refetch);
		this.files = files;
		this.uploadFile = uploadFile;
	}

	public async handleUpdate(input: PromotionInput): Promise<boolean> {
		if (!this.updateFn) throw new Error("Update function not defined");
		if (!this.uploadFile) throw new Error("Upload function not defined");

		let images = input.images;
		let newImages = [];

		if (this.files) {
			try {
				for (let i = 0; i < this.files.length; i++) {
					const path = await this.uploadFile(this.files[i], PROMOTION_PATH);
					newImages.push(path);
				}
			} catch (err: any) {
				this.toast({
					title: "Error",
					description: err.message,
					status: "error",
					duration: 5000,
					isClosable: true,
				});
				return false;
			}
		}

		const updatedImages = [...images, ...newImages];

		const PromotionInput: PromotionInput = {
			...input,
			images: updatedImages,
		};

		return await super.handleUpdate(PromotionInput);
	}
}

export default PromotionUpdate;
