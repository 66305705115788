export function percentage(x: number, y: number): number {
	if (y === 0) {
		throw new Error("The denominator cannot be zero.");
	}
	return 100 / (y / x);
}

type CallbackFunction = (...args: any[]) => void;

export function debounce(
	limit: number,
	callback: CallbackFunction,
): CallbackFunction {
	let timeoutId: NodeJS.Timeout | null = null;

	return (...args: any[]) => {
		if (timeoutId) {
			clearTimeout(timeoutId);
		}
		timeoutId = setTimeout(() => {
			callback(...args);
		}, limit);
	};
}

export function capsFirst(str: string): string {
	if (str.length === 0) {
		return str;
	}
	return str.charAt(0).toUpperCase() + str.slice(1);
}
