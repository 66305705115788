import { Select, Stack, Text } from "@chakra-ui/react";

import { colors } from "styles";

interface PageSizeSelectorProps {
	pageSize: number;
	setPageSize: (size: number) => void;
}

const PageSizeSelector: React.FC<PageSizeSelectorProps> = ({
	pageSize,
	setPageSize,
}) => {
	return (
		<Stack
			direction={{ sm: "column", md: "row" }}
			spacing={{ base: "10px", sm: "4px", md: "12px" }}
			align="center"
			me="12px"
			my="24px"
			minW={{ base: "200px", md: "200px" }}
		>
			<Select
				value={pageSize}
				onChange={(e) => setPageSize(Number(e.target.value))}
				color={colors.primary[600]}
				bg={colors.graydient[100]}
				boxShadow="base"
				border="0.5px solid"
				borderColor="white"
				borderRadius="15px"
				size="sm"
				maxW="75px"
				cursor="pointer"
				title="entries-per-page-select"
				aria-label="Entries per page"
			>
				<option>1</option>
				<option>5</option>
				<option>10</option>
				<option>15</option>
				<option>20</option>
				<option>25</option>
			</Select>
			<Text
				fontSize="sm"
				color="gray.400"
				fontWeight="500"
				id="entries-per-page-label"
			>
				entries per page
			</Text>
		</Stack>
	);
};

export default PageSizeSelector;
