import { useCreatePosition } from "services";
import { PositionInput } from "services/types";
import { useToast } from "@chakra-ui/react";
import { FieldDefinition } from "components";
import { ICUDFormHook } from "./types";
import { useForm } from "hooks/forms";
import { BaseCreate } from "cud";

const useCreatePositionForm = ({ refetch }: ICUDFormHook) => {
	const initialFormState: PositionInput = {
		positionName: "",
		description: "",
		details: {
			jobTitle: "",
			department: "",
			location: "",
			commitmentLevel: "",
		},
	};

	const { formState, loading, setLoading, handleInputChange, resetFormState } =
		useForm<PositionInput>({
			initialState: initialFormState,
		});
	const toast = useToast();

	const { createPosition } = useCreatePosition();

	const positionCreate = new BaseCreate<PositionInput>(
		"Position",
		toast,
		createPosition,
		refetch,
	);

	const handleSubmit = async () => {
		setLoading(true);
		const success = await positionCreate.handleCreate(formState);
		if (success) resetFormState();
		setLoading(false);
	};

	const fields: FieldDefinition[] = [
		{
			label: "Position Name",
			name: "positionName",
			type: "text",
			placeholder: "Enter the position name...",
			onChange: handleInputChange,
			value: formState.positionName,
			required: true,
			variant: "base",
			size: "lg",
			width: "100%",
		},
		{
			label: "Description",
			name: "description",
			type: "textarea",
			placeholder: "Enter the description...",
			onChange: handleInputChange,
			value: formState.description,
			required: true,
			variant: "base",
			size: "lg",
			width: "100%",
		},
		{
			label: "Job Title",
			name: "details.jobTitle",
			type: "text",
			placeholder: "Enter the job title...",
			onChange: handleInputChange,
			value: formState.details.jobTitle,
			required: true,
			variant: "base",
			size: "lg",
			width: "100%",
		},
		{
			label: "Department",
			name: "details.department",
			type: "text",
			placeholder: "Enter the department...",
			onChange: handleInputChange,
			value: formState.details.department,
			required: true,
			variant: "base",
			size: "lg",
			width: "100%",
		},
		{
			label: "Location",
			name: "details.location",
			type: "select",
			options: ["New York", "Connecticut", "Remote", "Hybrid"],
			placeholder: "Select a location...",
			onChange: handleInputChange,
			value: formState.details.location,
			required: true,
			variant: "base",
			size: "lg",
			width: "100%",
		},
		{
			label: "Commitment Level",
			name: "details.commitmentLevel",
			type: "select",
			options: ["Full-time", "Part-time", "Contract", "Internship"],
			placeholder: "Enter the commitment level...",
			onChange: handleInputChange,
			value: formState.details.commitmentLevel,
			required: true,
			variant: "base",
			size: "lg",
			width: "100%",
		},
	];
	return { fields, handleSubmit, loading };
};

export default useCreatePositionForm;
