import React from "react";
import { Flex } from "@chakra-ui/react";
import JobPanel from "../../page-components/About/Careers/JobPanel"; // Adjust the import path as necessary
import Hero from "components/features/heros/ComponentHero";
import { CareersHero } from "assets";
import { useGetPositions } from "services";
import {
	Loader,
	InfoMessage,
	ErrorMessage,
} from "components/common/ui-elements";

const Career: React.FC = () => {
	const { data: positions, loading, error } = useGetPositions();

	const CareerContent = () => {
		if (loading) return <Loader />;

		if (error) {
			return (
				<ErrorMessage
					title={"We were unable to load our job positions."}
					description={
						"You can try refreshing your browser or try again later. We apologize for the inconvenience."
					}
					alertProps={{ minHeight: "35vh" }}
				/>
			);
		}

		if (positions && positions.items.length === 0) {
			return (
				<InfoMessage
					title={"We currently have no open positions."}
					description={
						"Please check back later for new opportunities. Thank you for your interest."
					}
					alertProps={{ minHeight: "35vh", status: "info" }}
				/>
			);
		}

		if (positions) {
			return <JobPanel jobListings={positions.items} />;
		}

		return (
			<InfoMessage
				title={"We were unable to load our current positions."}
				description={
					"You can try refreshing your browser or try again later. We apologize for the inconvenience."
				}
			/>
		);
	};

	return (
		<>
			<Hero
				titleText="Career Oppurtunity"
				backgroundImage={CareersHero}
				backgroundColor="rgba(0, 0, 0, 0.5)"
				boxProps={{
					height: { base: "250px", md: "350px" }, // Responsive height
					bgPos: "center",
					bgSize: "cover",
					bgPosition: "center",
					// bg: 'black',
					// opacity: '0.80',
				}}
				textProps={{
					fontSize: { base: "3xl", md: "5xl" }, // Responsive font size
					textAlign: "center",
					textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
				}}
			/>
			<Flex
				p={4}
				mt={1}
				mb={28}
				overflow="hidden"
				justifyContent="center"
				alignContent="center"
			>
				<CareerContent />
			</Flex>
		</>
	);
};

export default Career;
