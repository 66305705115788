import React from "react";
import { Button, Flex } from "@chakra-ui/react";
import { Form } from "components";
import { useModal } from "hooks";
import { useCreateCustomWorkGalleryForm } from "hooks/form-hooks";
import { ModalWrapper } from "wrappers";
import { FaPlus } from "react-icons/fa6";

interface CreateCustomWorkGalleryFormProps {
	refetch: () => Promise<any>;
}

const CreateCustomWorkGalleryForm: React.FC<
	CreateCustomWorkGalleryFormProps
> = ({ refetch }) => {
	const { isOpen, open, close } = useModal();

	const { fields, handleSubmit, loading } = useCreateCustomWorkGalleryForm({
		refetch,
	});

	const onSubmit = async () => {
		await handleSubmit();
		close();
	};

	return (
		<>
			<Flex>
				<Button
					size="lg"
					aria-label="create-custom-work-gallery"
					onClick={open}
					variant={"withIcon"}
					leftIcon={<FaPlus />}
					borderRadius="15px"
					mx={6}
					my={4}
				>
					Add Image to Custom Work Gallery
				</Button>
			</Flex>
			<ModalWrapper
				isOpen={isOpen}
				onClose={close}
				showShadow={true}
				heading="Add Image to Custom Work Gallery"
				hideCloseButton={false}
			>
				<Form fields={fields} onSubmit={onSubmit} isLoading={loading} />
			</ModalWrapper>
		</>
	);
};

export default CreateCustomWorkGalleryForm;
