import React from "react";
import { Form } from "components";
import { ModalWrapper } from "wrappers";
import { useUpdateFAQForm } from "hooks/form-hooks";
import { FAQ } from "services/types";

interface UpdateFAQFormProps {
	isOpen: boolean;
	onClose: () => void;
	refetch: () => Promise<any>;
	existingFAQ: FAQ;
}

const UpdateFAQForm: React.FC<UpdateFAQFormProps> = ({
	isOpen,
	onClose,
	refetch,
	existingFAQ,
}) => {
	const { fields, handleSubmit, loading } = useUpdateFAQForm({
		refetch,
		existingFAQ,
	});

	const onSubmit = async () => {
		await handleSubmit();
		onClose();
	};

	return (
		<ModalWrapper
			isOpen={isOpen}
			onClose={onClose}
			showShadow={true}
			heading="Update FAQ"
			hideCloseButton={false}
		>
			<Form fields={fields} onSubmit={onSubmit} isLoading={loading} />
		</ModalWrapper>
	);
};

export default UpdateFAQForm;
