import React from "react";
import { Forms, Terms } from "page-components";
import { CustomDivider } from "components";

const OurPolicies = () => {
	return (
		<>
			<Forms />
			<CustomDivider />
			<Terms />
			<CustomDivider />
		</>
	);
};

export default OurPolicies;
