import { useMutation } from "@apollo/client";
import { createCatalog as CREATE_CATALOG } from "./mutations";
import { CatalogInput } from "../../types";

interface CreateCatalogData {
	createCatalog: boolean;
}

const useCreateCatalog = () => {
	const [createCatalogMutation, { loading, error }] =
		useMutation<CreateCatalogData>(CREATE_CATALOG);
	const createCatalog = async (catalogInput: CatalogInput) => {
		try {
			const { data } = await createCatalogMutation({
				variables: {
					catalogInput,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.createCatalog };
		} catch (error: any) {
			return { error };
		}
	};
	return { createCatalog, loading, error };
};
export default useCreateCatalog;
