import { useMutation } from "@apollo/client";
import { submitRequestQuoteForm as SUBMIT_REQUEST_QUOTE_FORM } from "./mutations";
import { RequestQuoteFormInput } from "../../types";

interface SubmitRequestQuoteFormData {
	submitRequestQuoteForm: boolean;
}

const useSubmitRequestQuoteForm = () => {
	const [submitRequestQuoteFormMutation, { loading, error }] =
		useMutation<SubmitRequestQuoteFormData>(SUBMIT_REQUEST_QUOTE_FORM);
	const submitRequestQuoteForm = async (
		requestQuoteFormInput: RequestQuoteFormInput,
	) => {
		try {
			const { data } = await submitRequestQuoteFormMutation({
				variables: {
					input: requestQuoteFormInput,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.submitRequestQuoteForm };
		} catch (error: any) {
			return { error };
		}
	};
	return { submitRequestQuoteForm, loading, error };
};

export default useSubmitRequestQuoteForm;
