import React from "react";
import DeleteConfirmationWrapper from "wrappers/DeleteConfirmationWrapper";
import { Catalog } from "services/types";
import { useDeleteCatalogForm } from "hooks/form-hooks";

interface DeleteCatalogModalProps {
	catalog: Catalog;
	isOpen: boolean;
	onClose: () => void;
	refetch: () => Promise<any>;
}

const DeleteCatalogModal: React.FC<DeleteCatalogModalProps> = ({
	catalog,
	isOpen,
	onClose,
	refetch,
}) => {
	const { loading, handleDelete } = useDeleteCatalogForm(catalog, refetch);

	const onConfirmDelete = async () => {
		await handleDelete();
		onClose();
	};

	return (
		<DeleteConfirmationWrapper
			isOpen={isOpen}
			onClose={onClose}
			header={`Confirm Deletion: `}
			onDeleteConfirm={onConfirmDelete}
			content={catalog?.name}
			loading={loading}
		>
			{`Are you sure you want to delete this catalog? This action cannot be undone.`}
		</DeleteConfirmationWrapper>
	);
};

export default DeleteCatalogModal;
