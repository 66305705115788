// SocialIcons.js
import React from "react";
import { HStack, IconButton } from "@chakra-ui/react";

interface SocialIcon {
	element: JSX.Element; // This will be the actual icon element
	url: string;
}

interface SocialIconsProps {
	icons: SocialIcon[];
}

const SocialIcons: React.FC<SocialIconsProps> = ({ icons }) => {
	return (
		<HStack spacing={8}>
			{icons.map((icon, index) => (
				<IconButton
					as={"a"}
					href={icon.url}
					target="_blank"
					rel="noopener noreferrer"
					key={index}
					icon={icon.element}
					aria-label="Social Media Link"
					borderRadius="full"
					borderColor="gray.300"
					boxShadow="md"
					bg="accent.500"
					color="primary.400"
					variant="ghost"
					size={{
						base: "sm",
						md: "md",
					}}
					mb={{
						base: 1,
						md: 1,
					}}
					_hover={{ transform: "translateY(-2px)", color: "primary.300" }}
					_active={{ transform: "scale(1.0)", color: "primary.500" }}
				/>
			))}
		</HStack>
	);
};

export default SocialIcons;
