/**
 * @module MainContentsWrapper.tsx
 */
import React from "react";
import { Box, ContainerProps, BoxProps, Flex } from "@chakra-ui/react";

interface MainContentsWrapperProps extends BoxProps {
	children: React.ReactNode;
	containerProps?: ContainerProps;
	minHeight?: string | number;
	boxPadding?: string | number;
}

/**
 * MainContentsWrapper provides a consistent layout and spacing for the main contents of a page.
 * @param {MainContentsWrapperProps} props - The props to spread onto the underlying Box component.
 * @returns {React.ReactElement} A React element representing the main contents wrapper.
 */
const MainContentsWrapper: React.FC<MainContentsWrapperProps> = ({
	children,
	containerProps,
	minHeight = "85vh",
	boxPadding = "4",
	...boxProps
}) => {
	return (
		<Flex
			maxWidth={"full"}
			padding={containerProps?.padding || "0"}
			margin={containerProps?.margin || "0"}
			{...containerProps}
		>
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="flex-start"
				alignItems="center"
				width="100%"
				minHeight={minHeight}
				padding={boxPadding}
				bg="gray.50"
				{...boxProps}
			>
				{children}
			</Box>
		</Flex>
	);
};

export default MainContentsWrapper;
