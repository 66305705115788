/**
 * @file useUpdateProductCollection.ts
 */
import { useMutation } from "@apollo/client";
import { updateProductCollection as updateProductCollectionGQL } from "./mutations";

import { ProductCollection, ProductCollectionInput } from "../../types";

// Define the structure of the data returned by the mutation
interface UpdateProductCollectionResultData {
	updateProductCollection: ProductCollection; // Replace ProductCollection with the actual return type of your mutation
}

/**
 * @hook useUpdateProductCollection
 *
 * @description
 * Custom hook that handles the updateProductCollection mutation functionality.
 * This hook can be used to update a product collection.
 *
 * @returns An object containing the updateProductCollection execution, loading state, and error
 */
const useUpdateProductCollection = () => {
	const [updateCollectionMutation, { loading, error }] =
		useMutation<UpdateProductCollectionResultData>(updateProductCollectionGQL);

	/**
	 * Executes the updateProductCollection mutation.
	 *
	 * @param params - Parameters for the updateProductCollection mutation
	 * @returns An object containing the updateProductCollection result data or an error object.
	 */
	const updateProductCollection = async (
		productCollectionID: string,
		productCollectionInput: ProductCollectionInput,
	) => {
		try {
			const { data } = await updateCollectionMutation({
				variables: { productCollectionID, productCollectionInput },
			});
			if (error) {
				throw new Error(error.message);
			}
			// Process the returned data as required
			return { data: data?.updateProductCollection }; // Adjust according to the actual field from your mutation
		} catch (error) {
			return { error };
		}
	};

	return { updateProductCollection, loading, error };
};

export default useUpdateProductCollection;
