// src/theme/componentStyles.ts
import {
	buttonStyles,
	headingStyles,
	textStyles,
	tooltipStyles,
} from "./simple";
import {
	selectStyles,
	textareaStyles,
	inputStyles,
	switchStyles,
} from "./complex";

const componentStyles = {
	Button: buttonStyles,
	Heading: headingStyles,
	Text: textStyles,
	Textarea: textareaStyles,
	Select: selectStyles,
	Input: inputStyles,
	Switch: switchStyles,
	Tooltip: tooltipStyles,
};

export { componentStyles };
