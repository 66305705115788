import log from "loglevel";

log.setDefaultLevel(log.levels.INFO);

class Logger {
	static debug(message: string, ...args: any[]) {
		const caller = new Error().stack?.split("\n")[2].trim();
		log.debug(`[${caller}] ${message}`, ...args);
	}

	static info(message: string, ...args: any[]) {
		const caller = new Error().stack?.split("\n")[2].trim();
		log.info(`[${caller}] ${message}`, ...args);
	}

	static warn(message: string, ...args: any[]) {
		const caller = new Error().stack?.split("\n")[2].trim();
		log.warn(`[${caller}] ${message}`, ...args);
	}

	static error(message: string, ...args: any[]) {
		const caller = new Error().stack?.split("\n")[2].trim();
		log.error(`[${caller}] ${message}`, ...args);
	}
}

export default Logger;
