import { useMutation } from "@apollo/client";
import { submitCustomInquiryForm as SUBMIT_CUSTOM_INQUIRY_FORM } from "./mutations";
import { CustomInquiryFormInput } from "../../types";

interface SubmitCustomInquiryFormData {
	submitCustomInquiryForm: boolean;
}

const useSubmitCustomInquiryForm = () => {
	const [submitCustomInquiryFormMutation, { loading, error }] =
		useMutation<SubmitCustomInquiryFormData>(SUBMIT_CUSTOM_INQUIRY_FORM);
	const submitCustomInquiryForm = async (
		customInquiryFormInput: CustomInquiryFormInput,
	) => {
		try {
			const { data } = await submitCustomInquiryFormMutation({
				variables: {
					input: customInquiryFormInput,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.submitCustomInquiryForm };
		} catch (error: any) {
			return { error };
		}
	};
	return { submitCustomInquiryForm, loading, error };
};

export default useSubmitCustomInquiryForm;
