import React from "react";
import {
	Box,
	VStack,
	Heading,
	Text,
	Button,
	Image,
	Flex,
} from "@chakra-ui/react";
import CollectionModal from "./CollectionModal";
import { useModal } from "hooks";
import { ProductCollection } from "services";

interface CollectionProps {
	collection: ProductCollection;
	textPosition: "left" | "right";
}

const Collection: React.FC<CollectionProps> = ({
	collection,
	textPosition,
}) => {
	const { isOpen, open, close } = useModal();

	const isTextLeft = textPosition === "left";

	return (
		<Flex
			direction={isTextLeft ? "row" : "row-reverse"}
			align="center"
			justify="space-between"
			w="full"
			h="100%"
			gap={10}
		>
			<VStack
				align={isTextLeft ? "start" : "end"}
				textAlign={isTextLeft ? "left" : "right"}
				spacing={{ base: 6, sm: 4 }}
				flex={1}
				w="100%"
				h="100%"
			>
				<Heading as="h2" size="xl">
					{collection.name}
				</Heading>
				<Text fontSize="md">{collection.description}</Text>
				<Button variant={"baseStyle"} onClick={open} m={{ base: 0, xl: 6 }}>
					View Collection
				</Button>
			</VStack>
			<Box
				flex={1}
				height={{ base: "525px", lg: "425px" }} // Adjust height as necessary
				w="100%"
			>
				<Image
					src={collection.image}
					alt={`${collection.name} collection image`}
					objectFit="cover"
					w="full"
					h="full"
					borderRadius="md"
				/>
			</Box>
			{isOpen && (
				<CollectionModal
					isOpen={isOpen}
					onClose={close}
					collection={collection}
				/>
			)}
		</Flex>
	);
};

export default Collection;
