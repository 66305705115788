import { useMutation } from "@apollo/client";
import { deleteFAQ as DELETE_F_A_Q } from "./mutations";
interface DeleteFAQData {
	deleteFAQ: boolean;
}

const useDeleteFAQ = () => {
	const [deleteFAQMutation, { loading, error }] =
		useMutation<DeleteFAQData>(DELETE_F_A_Q);
	const deleteFAQ = async (faqID: string) => {
		try {
			const { data } = await deleteFAQMutation({
				variables: {
					faqID,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.deleteFAQ };
		} catch (error: any) {
			return { error };
		}
	};
	return { deleteFAQ, loading, error };
};
export default useDeleteFAQ;
