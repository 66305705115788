// SectionWrapper.tsx
import React from "react";
import { Box, BoxProps } from "@chakra-ui/react";

/**
 * SectionWrapper provides a consistent layout and spacing for sections of a page.
 * It uses Chakra UI's Box component as a base & applies default max width, margins,
 * padding, and other styling that can be overridden through props.
 *
 * It centralizes common styling patterns for different page sections to ensure
 * uniformity across the application. This component should wrap any distinct
 * section of content that requires the standardized spacing and layout.
 *
 * @param {BoxProps} props - The props to spread onto the underlying Box component.
 * These can include styling properties, layout properties, or any valid property
 * that the Box component accepts.
 * @returns {React.ReactElement} A React element representing the section wrapper.
 *
 * @example
 * // To use the SectionWrapper, wrap it around the content of a page section
 *  & override the default styles by passing Chakra UI style props:
 *
 * <SectionWrapper bg="gray.100" p={10}>
 *   <Text>This section has a gray background and more padding.</Text>
 * </SectionWrapper>
 */
const SectionWrapper: React.FC<BoxProps> = ({ children, ...props }) => {
	return (
		<Box
			maxWidth={{
				base: "auto",
				md: "container.xl",
			}}
			mx={{
				base: "auto",
				md: "auto",
			}}
			px={{
				base: "auto",
				md: "4",
			}}
			py={{
				base: "auto",
				md: "8",
			}}
			{...props}
		>
			{children}
		</Box>
	);
};

export default SectionWrapper;
