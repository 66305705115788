import { useMutation } from "@apollo/client";
import { createCustomWorkGalleryImage as CREATE_CUSTOM_WORK_GALLERY_IMAGE } from "./mutations";
import { CustomWorkGalleryImageInput } from "../../types";

interface CreateCustomWorkGalleryImageData {
	createCustomWorkGalleryImage: boolean;
}

const useCreateCustomWorkGalleryImage = () => {
	const [createCustomWorkGalleryImageMutation, { loading, error }] =
		useMutation<CreateCustomWorkGalleryImageData>(
			CREATE_CUSTOM_WORK_GALLERY_IMAGE,
		);
	const createCustomWorkGalleryImage = async (
		customWorkGalleryImageInput: CustomWorkGalleryImageInput,
	) => {
		try {
			const { data } = await createCustomWorkGalleryImageMutation({
				variables: {
					customWorkGalleryImageInput,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.createCustomWorkGalleryImage };
		} catch (error: any) {
			return { error };
		}
	};
	return { createCustomWorkGalleryImage, loading, error };
};
export default useCreateCustomWorkGalleryImage;
