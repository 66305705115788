import { useAssignResourcesToEmployee } from "services";
import { FieldDefinition } from "components";
import { useForm } from "hooks/forms";
import { Employee } from "services";
import { ResourceMultiSelect } from "page-components/admin/employees/resources";

/**
 * @returns {JSX.Element} - A form for assigning resources to an employee
 * @name useAssignResourcesForm
 */
const useAssignResourcesForm = (employee: Employee) => {
	const initialState = {
		resourceIds: employee.assignedContent,
	};
	const {
		formState,
		loading,
		setLoading,
		handleInputChange,
		resetFormState,
		toast,
	} = useForm({ initialState });
	const { assignResourcesToEmployee } = useAssignResourcesToEmployee();

	const handleSubmit = async () => {
		setLoading(true);
		const { data, error } = await assignResourcesToEmployee(
			employee._id,
			formState.resourceIds,
		);
		if (data) {
			toast({
				title: "Resources Assigned",
				description: "The resources have been assigned to the employee",
				status: "success",
				duration: 3000,
				isClosable: true,
			});
			setLoading(false);
			resetFormState();
			return true;
		} else if (error) {
			toast({
				title: "Error",
				description: error.message,
				status: "error",
				duration: 3000,
				isClosable: true,
			});
			setLoading(false);
			return false;
		} else {
			toast({
				title: "Error",
				description:
					"An unexpected error occurred while assigning resources to this employee. Please try again later.",
				status: "error",
				duration: 3000,
				isClosable: true,
			});
			setLoading(false);
			return false;
		}
	};

	const fields: FieldDefinition[] = [
		{
			label: "Assigned Resources",
			name: "resources",
			type: "custom",
			placeholder: "Select resources...",
			customComponent: ResourceMultiSelect,
			customComponentProps: {
				selectedIds: formState.resourceIds,
				onSelectionChange: (resourceIds: string[]) =>
					handleInputChange({
						target: { name: "resourceIds", value: resourceIds },
					}),
				name: "resourceIds",
			},
		},
	];

	return { fields, handleSubmit, loading };
};

export default useAssignResourcesForm;
