/**
 * @module JobPosting.tsx
 */
import { Button, Text } from "@chakra-ui/react";
import { MdAccessTime, MdLocationOn } from "react-icons/md";
import { IconText } from "components";
import { StackLayout } from "components";
import { useModal } from "hooks";

import { Position } from "services";

import PositionApplicationModal from "./PositionApplicationModal";

export interface JobPostingProps {
	position: Position;
}

const JobPosting = ({ position }: JobPostingProps) => {
	const { open, close, isOpen } = useModal();

	const { description, details } = position;
	const { location, commitmentLevel } = details;

	return (
		<StackLayout>
			<Text variant="sectionParagraph">{description}</Text>
			<StackLayout>
				<IconText icon={MdLocationOn} text={location} />
				<IconText icon={MdAccessTime} text={commitmentLevel} />
			</StackLayout>
			<Button variant="primary" alignSelf="start" onClick={open}>
				Apply Now
			</Button>
			<PositionApplicationModal
				isOpen={isOpen}
				onClose={close}
				position={position}
			/>
		</StackLayout>
	);
};

export default JobPosting;
