import { gql } from "@apollo/client";

const createEmployee = gql`
	mutation createEmployee($employeeInput: EmployeeInput!) {
		createEmployee(employeeInput: $employeeInput)
	}
`;

const updateEmployee = gql`
	mutation updateEmployee($employeeId: ID!, $employeeInput: EmployeeInput!) {
		updateEmployee(employeeId: $employeeId, employeeInput: $employeeInput)
	}
`;

const assignResourcesToEmployee = gql`
	mutation assignResourcesToEmployee($employeeId: ID!, $resourceIds: [ID!]!) {
		assignResourcesToEmployee(
			employeeId: $employeeId
			resourceIds: $resourceIds
		)
	}
`;

const deleteEmployee = gql`
	mutation deleteEmployee($employeeId: ID!) {
		deleteEmployee(employeeId: $employeeId)
	}
`;

const createDealer = gql`
	mutation createDealer($dealerInput: DealerInput!) {
		createDealer(dealerInput: $dealerInput)
	}
`;

const updateDealer = gql`
	mutation updateDealer($dealerId: ID!, $dealerInput: DealerInput!) {
		updateDealer(dealerId: $dealerId, dealerInput: $dealerInput)
	}
`;

const deleteDealer = gql`
	mutation deleteDealer($dealerId: ID!) {
		deleteDealer(dealerId: $dealerId)
	}
`;

const createPosition = gql`
	mutation createPosition($positionInput: PositionInput!) {
		createPosition(positionInput: $positionInput)
	}
`;

const updatePosition = gql`
	mutation updatePosition($positionId: ID!, $positionInput: PositionInput!) {
		updatePosition(positionId: $positionId, positionInput: $positionInput)
	}
`;

const deletePosition = gql`
	mutation deletePosition($positionId: ID!) {
		deletePosition(positionId: $positionId)
	}
`;

export {
	createEmployee,
	updateEmployee,
	assignResourcesToEmployee,
	deleteEmployee,
	createDealer,
	updateDealer,
	deleteDealer,
	createPosition,
	updatePosition,
	deletePosition,
};
