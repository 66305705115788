import React, { useContext } from "react";
import { PageChangeContext } from "contexts";
import ResponsiveImageGrid from "components/common/grids/ImageGrid";
import { Heading, Image, Flex, useToast } from "@chakra-ui/react";
import { colors } from "styles";
import { Partner, useGetPartners } from "services";
import { Loader, ErrorMessage } from "components/common/ui-elements";

const Brands = () => {
	const { loading, error, data: brands } = useGetPartners();

	const { handlePageChange } = useContext(PageChangeContext);
	const toast = useToast();

	const handleBoxClick = (category: string, index: number) => {
		const subcategoryBrand = brands?.items.find(
			(brand) => brand.details.subcategories?.includes(category),
		);

		if (!subcategoryBrand) {
			toast({
				title: `Error: Unable to find brand for ${category}.`,
				description: "Please try again later.",
				status: "warning",
				duration: 5000,
				isClosable: true,
			});
			return;
		}

		handlePageChange(`products/${subcategoryBrand.name}`);
	};

	if (loading) return <Loader />;

	if (error) {
		return (
			<ErrorMessage
				title={"We were unable to load our partners."}
				description={
					"You can try refreshing your browser or try again later. We apologize for the inconvenience."
				}
				alertProps={{ minHeight: "85vh" }}
			/>
		);
	}

	if (!brands) return <></>;

	type BrandsBySubcategory = Record<string, Partner[]>;
	const brandsBySubcategory = brands.items.reduce(
		(acc: BrandsBySubcategory, brand) => {
			const subcategories = brand.details.subcategories;
			if (!subcategories) return acc;
			for (const subcategory of subcategories) {
				(acc[subcategory] = acc[subcategory] || []).push(brand);
			}
			return acc;
		},
		{},
	);

	return (
		<>
			<Heading
				display={"flex"}
				alignItems={"center"}
				justifyContent={"center"}
				variant="primary"
				size={{ base: "3xl", md: "4xl" }}
				pt={{ base: 10, md: 20 }}
				mx={4}
				textAlign="center"
				letterSpacing={".0005em"}
				//pt={10}
			>
				Brand Partners:
			</Heading>
			{Object.entries(brandsBySubcategory).map(
				([subcategory, brandsInSubcategory]) => (
					<React.Fragment key={`${subcategory}-brands`}>
						<Heading
							display={"flex"}
							alignItems={"flex-start"}
							justifyContent={"flex-start"}
							fontSize={{ base: "3xl", md: "3xl" }}
							variant={"subtitle"}
							fontWeight={"normal"}
							pt={{ base: 10, md: 20 }}
							pb={{ base: 5, md: 10 }}
							mx={28}
							textAlign="center"
						>
							{subcategory}:
						</Heading>
						<ResponsiveImageGrid
							minChildWidth={"325px"}
							spacing={{ base: 6, md: 10, xl: 16 }}
							onBoxClick={(index) => handleBoxClick(subcategory, index)}
							width={{ base: "170px", md: "195px" }}
							height={{ base: "auto", md: "200px" }}
							boxStyles={{
								borderWidth: "1px",
								borderColor: colors.graydient[400],
								justifySelf: "start",
								borderRadius: "lg",
								boxShadow: "lg",
								mx: 28,
							}}
						>
							{brandsInSubcategory.map((brand) => (
								<Flex
									key={`${brand.name}-brand`}
									flexDirection={"column"}
									justifyContent="start"
									alignItems="start"
								>
									<a
										href={brand.details.url}
										onClick={(e) => e.preventDefault()}
									>
										<Image
											src={brand.details.image}
											alt={brand.name}
											objectFit="cover"
											cursor={"pointer"}
											_hover={{ opacity: 0.8 }}
											transition="all 0.3s ease-in-out"
										/>
									</a>
								</Flex>
							))}
						</ResponsiveImageGrid>
					</React.Fragment>
				),
			)}
			<Flex pb={10} />
		</>
	);
};

export default Brands;
