import React from "react";
import { RoutesWrapper } from "wrappers";
import { Route } from "react-router-dom";

import { UserRouter, DealerRouter, EmployeeRouter, AdminRouter } from "routes";

const MainRouter: React.FC = () => {
	return (
		<RoutesWrapper>
			<Route path="/*" element={<UserRouter />} />
			<Route path="/dealer/*" element={<DealerRouter />} />
			<Route path="/employee/*" element={<EmployeeRouter />} />
			<Route path="/admin/*" element={<AdminRouter />} />
		</RoutesWrapper>
	);
};

export default MainRouter;
