import { useMutation } from "@apollo/client";
import { deleteEmployee as DELETE_EMPLOYEE } from "./mutations";
interface DeleteEmployeeData {
	deleteEmployee: boolean;
}

const useDeleteEmployee = () => {
	const [deleteEmployeeMutation, { loading, error }] =
		useMutation<DeleteEmployeeData>(DELETE_EMPLOYEE);
	const deleteEmployee = async (employeeId: string) => {
		try {
			const { data } = await deleteEmployeeMutation({
				variables: {
					employeeId,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.deleteEmployee };
		} catch (error: any) {
			return { error };
		}
	};
	return { deleteEmployee, loading, error };
};
export default useDeleteEmployee;
