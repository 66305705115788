import { CreateToastFnReturn } from "@chakra-ui/react";
import { UNKNOWN_ERROR_TOAST } from "consts";

/**
 * ## Summary
	The `BaseCreate` class is a TypeScript class that provides a generic implementation for handling the creation of objects. It includes methods for creating objects, displaying success or error toasts, and triggering a refetch.

	## Example Usage
	```javascript
	const baseCreate = new BaseCreate(
	"Object",
	createToast,
	createFunction,
	refetchFunction
	);

	const input = { ... };
	const success = await baseCreate.handleCreate(input);
	```

	## Code Analysis
	### Main functionalities
	- Handles the creation of objects
	- Displays success or error toasts
	- Triggers a refetch after successful creation
	___
	### Methods
	- `constructor(objectType: string, toast: CreateToastFnReturn, createFn: (input: T) => Promise<{ data?: any; error?: any }>, refetch: () => Promise<void>)`: Initializes the `BaseCreate` instance with the provided parameters.
	- `handleCreate(input: T): Promise<boolean>`: Handles the creation of an object by calling the `createFn` function with the provided input. If the creation is successful, it displays a success toast and triggers a refetch. If there is an error, it displays an error toast. Returns a boolean indicating the success of the creation.
	___
	### Fields
	- `objectType: string`: The type of object being created.
	- `toast: CreateToastFnReturn`: The function for displaying toasts.
	- `createFn: (input: T) => Promise<{ data?: any; error?: any }>`: The function for creating objects.
	- `refetch: () => Promise<void>`: The function for triggering a refetch.
	___
 */
class BaseCreate<T> {
	public objectType: string;
	public toast: CreateToastFnReturn;
	public createFn: (input: T) => Promise<{ data?: any; error?: any }>;
	public refetch: () => Promise<void>;

	constructor(
		objectType: string,
		toast: CreateToastFnReturn, // Define more specific type if available
		createFn: (input: T) => Promise<{ data?: any; error?: any }>,
		refetch: () => Promise<void>,
	) {
		this.objectType = objectType;
		this.toast = toast;
		this.createFn = createFn;
		this.refetch = refetch;
	}

	public async handleCreate(input: T): Promise<boolean> {
		if (!this.createFn) throw new Error("Create function not defined");
		const { data, error } = await this.createFn(input);
		if (error) {
			this.toast({
				title: "Error",
				description: error.message,
				status: "error",
				duration: 5000,
				isClosable: true,
			});
			return false;
		} else if (data) {
			this.toast({
				title: "Success",
				description: `${this.objectType} created successfully`,
				status: "success",
				duration: 5000,
				isClosable: true,
			});
			if (this.refetch) await this.refetch();
			return true;
		} else {
			this.toast(UNKNOWN_ERROR_TOAST);
			return false;
		}
	}
}

export default BaseCreate;
