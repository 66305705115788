import React from "react";
import { Box, Flex } from "@chakra-ui/react";
// import { UserProfileCard } from "components/common";
// import { SidebarHeader } from "components/common/headings";
// import { SidebarContent } from "components/common/content";
// import { SidebarItem } from "../features/sidebar/types";
// import { colors } from "styles";

type SidebarContentsLayoutProps = {
	sidebar: React.ReactNode;
	children: React.ReactNode;
};

/**
 * @component SidebarContentsLayout
 *
 * @description `SidebarContentsLayout` is a responsive layout component for displaying a sidebar alongside main content.
 * It renders a sidebar as a drawer on mobile screens and as a static box on larger screens.
 *
 * Preconditions:
 * - The `sidebar` and `children` props must be valid `ReactNode` elements.
 * - Chakra UI's theme provider should be present in the React tree for proper styling.
 *
 * Postconditions:
 * - Renders a responsive sidebar alongside the main content.
 * - Manages the open/close state of the sidebar on mobile screens.
 *
 * @param {SidebarContentsLayoutProps} props The component props:
 * - `sidebar`: `React.ReactNode` - The content to be displayed in the sidebar.
 * - `children`: `React.ReactNode` - The main content of the layout.
 *
 * @returns {React.ReactElement} A `Flex` container with conditional rendering for the sidebar and main content areas.
 */
const SidebarContentsLayout: React.FC<SidebarContentsLayoutProps> = ({
	sidebar: Sidebar,
	children,
}) => {
	return (
		<Flex
			w="100%"
			h="100vh"
			direction="row"
			justifyContent={"flex-start"}
			transition="all 0.25s ease-in-out"
		>
			<Box display="flex" flexShrink={1}>
				{Sidebar}
			</Box>
			<Box
				w="100%"
				h="100%"
				display={"flex"}
				flex={1}
				maxHeight="100vh"
				overflowY="auto"
			>
				{children}
			</Box>
		</Flex>
	);
};

export default SidebarContentsLayout;
