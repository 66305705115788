import { CreateToastFnReturn } from "@chakra-ui/react";
import { UNKNOWN_ERROR_TOAST } from "consts";

/**
 * ## Summary
	The `BaseDelete` class is a base class for deleting objects of a specific type. It provides a method `handleDelete()` that handles the deletion process by calling a delete function, displaying success or error toasts, and optionally refetching data.

	## Example Usage
	```javascript
	const toast = useToast();
	const deleteFn = async (id: string) => {
	// delete logic
	};
	const refetch = async () => {
	// refetch logic
	};

	const object = { _id: "123", name: "Example Object" };
	const objectType = "Example";

	const baseDelete = new BaseDelete(object, objectType, toast, deleteFn, refetch);
	await baseDelete.handleDelete();
	```

	## Code Analysis
	### Main functionalities
	- The `BaseDelete` class provides a reusable implementation for deleting objects of a specific type.
	- It handles the deletion process by calling a delete function, displaying success or error toasts, and optionally refetching data.
	___
	### Methods
	- `constructor(object: T, objectType: string, toast: CreateToastFnReturn, deleteFn: (id: string) => Promise<{ data?: any; error?: any }>, refetch: () => Promise<void>)`: Initializes the `BaseDelete` instance with the provided object, object type, toast function, delete function, and refetch function.
	- `handleDelete(): Promise<boolean>`: Handles the deletion process by calling the delete function, displaying success or error toasts, and optionally refetching data. Returns a promise that resolves to a boolean indicating whether the deletion was successful.
	___
	### Fields
	- `object: T`: The object being deleted.
	- `objectType: string`: The type of object being deleted.
	- `toast: CreateToastFnReturn`: The toast function used to display success or error messages.
	- `deleteFn: (id: string) => Promise<{ data?: any; error?: any }>`: The delete function that performs the actual deletion.
	- `refetch: () => Promise<void>`: The function used to refetch data after successful deletion.
	___
 */
class BaseDelete<T extends { _id: string }> {
	public object: T;
	public objectType: string;
	public toast: CreateToastFnReturn;
	public deleteFn: (id: string) => Promise<{ data?: any; error?: any }>;
	public refetch: () => Promise<void>;

	constructor(
		object: T,
		objectType: string,
		toast: CreateToastFnReturn, // Define more specific type if available
		deleteFn: (id: string) => Promise<{ data?: any; error?: any }>,
		refetch: () => Promise<void>,
	) {
		this.object = object;
		this.objectType = objectType;
		this.toast = toast;
		this.deleteFn = deleteFn;
		this.refetch = refetch;
	}

	public async handleDelete(): Promise<boolean> {
		if (!this.deleteFn) throw new Error("Delete function not defined");
		const { data, error } = await this.deleteFn(this.object._id);
		if (error) {
			this.toast({
				title: "Error",
				description: error.message,
				status: "error",
				duration: 5000,
				isClosable: true,
			});
			return false;
		} else if (data) {
			this.toast({
				title: "Success",
				description: `${this.objectType} deleted successfully`,
				status: "success",
				duration: 5000,
				isClosable: true,
			});
			if (this.refetch) await this.refetch();
			return true;
		} else {
			this.toast(UNKNOWN_ERROR_TOAST);
			return false;
		}
	}
}

export default BaseDelete;
