import React from "react";
import { RoutesWrapper } from "wrappers";
import { Route } from "react-router-dom";

import {
	HelpCenter,
	Catalogs,
	OurPartners,
	OurPolicies,
	Trending,
	Gallery,
} from "pages";

const ResourcesRouter: React.FC = () => {
	return (
		<RoutesWrapper>
			<Route path="/help-center" element={<HelpCenter />} />
			<Route path="/trending" element={<Trending />} />
			<Route path="/gallery" element={<Gallery />} />
			<Route path="/catalogs" element={<Catalogs />} />
			<Route path="/our-partners" element={<OurPartners />} />
			<Route path="/our-policies" element={<OurPolicies />} />
		</RoutesWrapper>
	);
};

export default ResourcesRouter;
