/**
 * @module ReviewCard
 * @description A reviews card component for displaying reviews.
 */
import React from "react";
import RatingDisplay from "components/common/ui-elements/RatingDisplay";
import { Box, Flex, Text, VStack, Icon } from "@chakra-ui/react";
import { formatUnixTimestampStringForDateDisplay } from "utils";
import { FaStar } from "react-icons/fa";

/**
 * @interface ReviewCardProps
 * @property {string} author The author of the review.
 * @property {string} content The content of the review.
 * @property {string} date The date of the review.
 * @property {number} rating The rating of the review.
 * @property {React.ElementType} icon The icon to display.
 * @property {number} maxLength The maximum length of the content before truncation.
 */
interface ReviewCardProps {
	author: string;
	content: string;
	date: string;
	rating: number;
	icon: React.ElementType;
}

const ReviewCard: React.FC<ReviewCardProps> = ({
	rating,
	content,
	author,
	date,
	icon,
}) => {
	const formattedDate = formatUnixTimestampStringForDateDisplay(date);

	return (
		<Box
			bg="white"
			borderRadius="lg"
			borderWidth="1px"
			borderColor="blackAlpha.300"
			boxShadow="lg"
			p={{ base: 4, md: 6 }}
			my={6}
			w="100%"
			maxW={{ base: "300px", md: "390px" }}
			minW={"auto"}
			height={{ base: "240px", md: "220px" }}
			position="relative"
			overflow="hidden"
		>
			<VStack align="start" spacing={4} height="100%" w="100%">
				<Flex w="100%" justify="space-between" align="center">
					<RatingDisplay rating={rating} RatingIcon={FaStar} />
					<Icon as={icon} boxSize={6} />
				</Flex>
				<Text
					fontSize="md"
					fontWeight="medium"
					lineHeight="inherit"
					color="black"
					whiteSpace="normal"
					wordBreak="break-word"
					overflowWrap="break-word"
					maxW="100%"
					noOfLines={4}
				>
					"{content}"
				</Text>
				<Flex
					w="80%"
					justify="space-between"
					align="center"
					pt={{ base: 6, md: 3 }}
					position="absolute"
					bottom={{ base: 2, md: 4 }}
					left={6}
					right={6}
				>
					<Text fontWeight="normal" fontSize="sm" color="black">
						{author}
					</Text>
					<Text fontSize="sm" color="blackAlpha.600">
						{formattedDate}
					</Text>
				</Flex>
			</VStack>
		</Box>
	);
};

export default ReviewCard;
