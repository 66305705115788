import { Box, Heading, Text } from "@chakra-ui/react";

interface ContentSectionProps {
	title: string;
	titleSize?: "lg" | "md" | "sm";
	children: React.ReactNode;
}

const ContentSection = ({
	title,
	titleSize = "lg",
	children,
}: ContentSectionProps) => (
	<Box
		alignContent={{ base: "center", md: "flex-start" }}
		textAlign={{ base: "center", md: "left" }}
		px={{ base: "4", md: "0" }}
	>
		<Heading as="h3" size={titleSize} mb={"2"}>
			{title}
		</Heading>
		<Text fontSize="xl" lineHeight="tall" color="#4A5568" py={"2"} mb={"1"}>
			{children}
		</Text>
	</Box>
);

export default ContentSection;
