import { useMutation } from "@apollo/client";
import { createGalleryImage as CREATE_GALLERY_IMAGE } from "./mutations";
import { GalleryImageInput } from "../../types";

interface CreateGalleryImageData {
	createGalleryImage: boolean;
}

const useCreateGalleryImage = () => {
	const [createGalleryImageMutation, { loading, error }] =
		useMutation<CreateGalleryImageData>(CREATE_GALLERY_IMAGE);
	const createGalleryImage = async (galleryImageInput: GalleryImageInput) => {
		try {
			const { data } = await createGalleryImageMutation({
				variables: {
					galleryImageInput,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.createGalleryImage };
		} catch (error: any) {
			return { error };
		}
	};
	return { createGalleryImage, loading, error };
};
export default useCreateGalleryImage;
