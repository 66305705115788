import React from "react";
import { Box, Flex, SimpleGrid } from "@chakra-ui/react";
import { Loader, ErrorMessage } from "components/common/ui-elements";
import { useGetCatalogs } from "services";
import { ImageButton } from "components";

const CatalogsPreview: React.FC = () => {
	const {
		loading,
		error,
		data: paginatedCatalogs,
	} = useGetCatalogs({
		retrievalInput: {
			filterSettings: {
				conditional: "AND",
				filters: [
					{
						field: "isDealer",
						operator: "eq",
						value: "true",
					},
				],
			},
		},
	});

	if (loading) return <Loader />;

	if (error)
		return (
			<ErrorMessage
				title={"We're sorry, an error occurred while loading this dashboard"}
				description="Please try again later."
			/>
		);

	if (!paginatedCatalogs || !paginatedCatalogs.items)
		return (
			<ErrorMessage
				title={"We're sorry, there are no catalogs available at this time"}
				description="Please try again later."
			/>
		);

	let previews = [];

	previews = paginatedCatalogs?.items.map((catalog, index) => (
		<ImageButton
			key={`catalog-preview-${index}-${catalog._id}`}
			src={catalog.image}
			alt={catalog.name}
			onClick={() => window.open(catalog.src, "_blank")}
		/>
	));

	return (
		<Box>
			<Flex justifyContent="center" mb={4}>
				<SimpleGrid
					columns={[1, 2, 3, 4]}
					spacing={6}
					justifyContent="center"
					alignItems="center"
					width="100%"
					paddingX={4}
				>
					{previews}
				</SimpleGrid>
			</Flex>
		</Box>
	);
};

export default CatalogsPreview;
