import React from "react";
import { useCreatePartner } from "services";
import { PartnerInput } from "services/types";
import { FileInput, MultiSelect } from "components/common/inputs";
import { FieldDefinition } from "components";
import { ICUDFormHook } from "./types";
import { useFormWithFiles } from "hooks/forms";
import { PartnerCreate } from "cud";
import { PARTNER_SUBCATEGORIES } from "consts";

const useCreatePartnerForm = ({ refetch }: ICUDFormHook) => {
	const initialFormState: PartnerInput = {
		name: "",
		type: "",
		details: { image: "", url: "", subcategories: [] },
	};

	const {
		formState,
		loading,
		setLoading,
		handleInputChange,
		setFormState,
		resetFormState,
		toast,
		files: file,
		handleSetFiles: setFile,
		uploadFile,
	} = useFormWithFiles<PartnerInput>({
		initialState: initialFormState,
		allowMultiple: false,
	});

	const { createPartner } = useCreatePartner();

	const partnerCreate = new PartnerCreate(
		toast,
		createPartner,
		refetch,
		file as File | null,
		uploadFile,
	);

	const handleSubmit = async () => {
		setLoading(true);
		const success = await partnerCreate.handleCreate(formState);
		if (success) resetFormState();
		setLoading(false);
	};

	const handleTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const isBrand = event.target.value.toLowerCase() === "brand";
		if (isBrand) {
			handleInputChange(event);
		} else {
			setFormState({
				...formState,
				type: event.target.value,
				details: { ...formState.details, subcategories: [] },
			});
		}
	};

	const fields: FieldDefinition[] = [
		{
			label: "Name",
			name: "name",
			type: "text",
			placeholder: "Enter the name...",
			onChange: handleInputChange,
			value: formState.name,
			variant: "base",
			size: "lg",
			width: "100%",
		},
		{
			label: "Type",
			name: "type",
			type: "select",
			onChange: handleTypeChange,
			placeholder: "Select the type of Partner...",
			options: ["Association", "Brand"],
			required: true,
			variant: "base",
			size: "lg",
			width: "100%",
			minWidth: "100%",
			value: formState.type,
		},
		{
			label: "Subcategories",
			name: "details.subcategories",
			type: "custom",
			customComponent: MultiSelect,
			customComponentProps: {
				options: PARTNER_SUBCATEGORIES,
				onChange: handleInputChange,
				value: formState.details.subcategories,
				zIndex: 2,
			},
			required: true,
			isConditional: true,
			isVisible: formState.type.toLowerCase() === "brand",
		},
		{
			label: "Image",
			name: "details.image",
			type: "custom",
			customComponent: FileInput,
			customComponentProps: {
				fileType: "image",
				files: file ? [file] : [],
				setFiles: setFile,
				allowMultiple: false,
			},
			minWidth: "100%",
		},
		{
			label: "URL",
			name: "details.url",
			type: "text",
			placeholder: "Enter the URL...",
			value: formState.details.url,
			onChange: handleInputChange,
			variant: "base",
			size: "lg",
			width: "100%",
			required: true,
		},
	];
	return { fields, handleSubmit, loading };
};

export default useCreatePartnerForm;
