import useCreateResource from "services/content-management/mutations/useCreateResource";
import { FieldDefinition } from "components";
import { FileInput } from "components/common/inputs";

import { ResourceInput } from "services/types";

import { useFormWithFiles } from "hooks/forms";
import { ResourceCreate } from "cud";

import { ICUDFormHook } from "./types";

const useCreateResourceForm = ({ refetch }: ICUDFormHook) => {
	const initialFormState = {
		title: "",
		type: "",
		path: "",
	};

	const {
		formState,
		loading,
		setLoading,
		handleInputChange,
		resetFormState,
		toast,
		files: file,
		handleSetFiles: setFile,
		uploadFile,
	} = useFormWithFiles<ResourceInput>({
		initialState: initialFormState,
		allowMultiple: false,
	});

	const { createResource } = useCreateResource();

	const resourceCreate = new ResourceCreate(
		toast,
		createResource,
		refetch,
		file as File | null,
		uploadFile,
	);

	const handleSubmit = async () => {
		setLoading(true);
		const res = await resourceCreate.handleCreate(formState);
		if (res) resetFormState();
		setLoading(false);
	};

	const fields: FieldDefinition[] = [
		{
			type: "text",
			name: "title",
			label: "Title",
			value: formState.title,
			placeholder: "Enter a title for the resource...",
			required: true,
			onChange: handleInputChange,
			variant: "base",
			size: "lg",
			width: "100%",
		},
		{
			type: "select",
			name: "type",
			label: "Type",
			value: formState.type,
			placeholder: "Select a type for the resource...",
			required: true,
			onChange: handleInputChange,
			options: ["Video", "Image", "Document"],
			variant: "base",
			size: "lg",
			width: "100%",
		},
		{
			type: "custom",
			name: "file",
			label: "Resource",
			required: true,
			customComponent: FileInput,
			customComponentProps: {
				fileType: formState.type.toLowerCase(),
				files: file ? [file] : [],
				setFiles: setFile,
				allowMultiple: false,
			},
			isConditional: true,
			isVisible:
				formState.type.toLowerCase() === "document" ||
				formState.type.toLowerCase() === "image" ||
				formState.type.toLowerCase() === "video",
			minWidth: "100%",
		},
	];

	return { loading, fields, handleSubmit };
};

export default useCreateResourceForm;
