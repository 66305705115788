import { useMutation } from "@apollo/client";
import { deleteCustomWorkGalleryImage as DELETE_CUSTOM_WORK_GALLERY_IMAGE } from "./mutations";
interface DeleteCustomWorkGalleryImageData {
	deleteCustomWorkGalleryImage: boolean;
}

const useDeleteCustomWorkGalleryImage = () => {
	const [deleteCustomWorkGalleryImageMutation, { loading, error }] =
		useMutation<DeleteCustomWorkGalleryImageData>(
			DELETE_CUSTOM_WORK_GALLERY_IMAGE,
		);
	const deleteCustomWorkGalleryImage = async (
		customWorkGalleryImageID: string,
	) => {
		try {
			const { data } = await deleteCustomWorkGalleryImageMutation({
				variables: {
					customWorkGalleryImageID,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.deleteCustomWorkGalleryImage };
		} catch (error: any) {
			return { error };
		}
	};
	return { deleteCustomWorkGalleryImage, loading, error };
};
export default useDeleteCustomWorkGalleryImage;
