import { useUpdateFAQ } from "services";
import { FieldDefinition } from "components";

import { FAQ, FAQInput } from "services/types";
import { useForm } from "hooks/forms";
import BaseUpdate from "cud/update/BaseUpdate";
import { ICUDFormHook } from "./types";
import { FAQ_CATEGORIES } from "consts";

const useUpdateFAQForm = ({
	refetch,
	existingFAQ,
}: ICUDFormHook & { existingFAQ: FAQ }) => {
	const initialFormState = {
		category: existingFAQ.category,
		question: existingFAQ.question,
		answer: existingFAQ.answer,
	};

	const {
		formState,
		loading,
		setLoading,
		handleInputChange,
		resetFormState,
		toast,
	} = useForm<FAQInput>({
		initialState: initialFormState,
	});

	const { updateFAQ } = useUpdateFAQ();

	const faqUpdate = new BaseUpdate(
		existingFAQ,
		"FAQ",
		toast,
		updateFAQ,
		refetch,
	);

	const handleSubmit = async () => {
		setLoading(true);
		const res = await faqUpdate.handleUpdate(formState);
		if (res) resetFormState();
		setLoading(false);
	};

	const fields: FieldDefinition[] = [
		{
			name: "category",
			label: "Category",
			type: "select",
			onChange: handleInputChange,
			placeholder: "Select a Category...",
			options: FAQ_CATEGORIES.map((category) => category.name),
			required: true,
			variant: "base",
			size: "lg",
			width: "100%",
			value: formState.category,
		},
		{
			name: "question",
			label: "Question",
			type: "text",
			value: formState.question,
			required: true,
			placeholder: "Enter the question...",
			onChange: handleInputChange,
		},
		{
			name: "answer",
			label: "Answer",
			type: "textarea",
			value: formState.answer,
			required: true,
			placeholder: "Enter the answer to the question...",
			onChange: handleInputChange,
		},
	];

	return {
		fields,
		handleSubmit,
		loading,
	};
};

export default useUpdateFAQForm;
