import { Box } from "@chakra-ui/react";
import { ApplicationForm } from "page-components";
import { MainContentsLayout } from "wrappers";

const DealerApplication = () => {
	return (
		<MainContentsLayout>
			<Box
				p="4"
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="flex-start"
				h="100%"
				w="100%"
				maxH="100%"
			>
				<ApplicationForm />
			</Box>
		</MainContentsLayout>
	);
};

export default DealerApplication;
