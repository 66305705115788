import { useState } from "react";
import { useCreateCatalog } from "services";
import { CatalogInput } from "services/types";
import { FileInput } from "components/common/inputs";

import { FieldDefinition } from "components";
import { ICUDFormHook } from "hooks/form-hooks/types";
import { useForm } from "hooks/forms";
import { CatalogCreate } from "cud";
import { useUploadFile } from "hooks";

const useCreateCatalogForm = ({ refetch }: ICUDFormHook) => {
	const initialFormState = {
		name: "",
		src: "",
		image: "",
		isDealer: false,
	};

	const {
		formState,
		loading,
		setLoading,
		handleInputChange,
		resetFormState,
		toast,
	} = useForm<CatalogInput>({
		initialState: initialFormState,
	});

	const [imageFile, setImageFile] = useState<File | null>(null);
	const [srcFile, setSrcFile] = useState<File | null>(null);

	const handleUpdateImageFile = (files: File[]) => {
		if (files.length > 0) {
			setImageFile(files[0]);
		} else {
			setImageFile(null);
		}
	};

	const handleUpdateSrcFile = (files: File[]) => {
		if (files.length > 0) {
			setSrcFile(files[0]);
		} else {
			setSrcFile(null);
		}
	};

	const { uploadFile } = useUploadFile();

	const { createCatalog } = useCreateCatalog();

	const catalogCreate = new CatalogCreate(
		toast,
		createCatalog,
		refetch,
		imageFile as File | null,
		srcFile as File | null,
		uploadFile,
	);

	const handleSubmit = async () => {
		setLoading(true);
		const res = await catalogCreate.handleCreate(formState);
		if (res) resetFormState();
		setLoading(false);
	};

	const fields: FieldDefinition[] = [
		{
			type: "text",
			name: "name",
			label: "Name",
			value: formState.name,
			placeholder: "Enter a name for the catalog...",
			required: true,
			onChange: handleInputChange,
			variant: "base",
			size: "lg",
		},
		{
			type: "custom",
			name: "image",
			label: "Image",
			customComponent: FileInput,
			customComponentProps: {
				fileType: "image",
				files: imageFile ? [imageFile] : [],
				setFiles: handleUpdateImageFile,
				allowMultiple: false,
			},
		},
		{
			type: "custom",
			name: "src",
			label: "Catalog Source",
			customComponent: FileInput,
			required: true,
			customComponentProps: {
				fileType: "document",
				files: srcFile ? [srcFile] : [],
				setFiles: handleUpdateSrcFile,
				allowMultiple: false,
			},
		},
		{
			type: "switch",
			name: "isDealer",
			label: "Is Dealer?",
			value: formState.isDealer,
			onChange: handleInputChange,
			variant: "login",
			size: "sm",
		},
	];

	return {
		loading,
		handleSubmit,
		fields,
	};
};

export default useCreateCatalogForm;
