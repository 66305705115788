import { useMutation } from "@apollo/client";
import { deleteDealerApplication as DELETE_DEALER_APPLICATION } from "./mutations";

interface DeleteDealerApplicationData {
	deleteDealerApplication: boolean;
}

const useDeleteDealerApplication = () => {
	const [deleteDealerApplicationMutation, { loading, error }] =
		useMutation<DeleteDealerApplicationData>(DELETE_DEALER_APPLICATION);
	const deleteDealerApplication = async (dealerApplicationId: string) => {
		try {
			const { data } = await deleteDealerApplicationMutation({
				variables: {
					dealerApplicationId,
				},
			});
			if (error) {
				throw new Error(error.message);
			}
			return { data: data?.deleteDealerApplication };
		} catch (error: any) {
			return { error };
		}
	};
	return { deleteDealerApplication, loading, error };
};

export default useDeleteDealerApplication;
