import {
	Box,
	HStack,
	Text,
	Avatar,
	IconButton,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
} from "@chakra-ui/react";
import { FiMoreVertical } from "react-icons/fi";
import { colors } from "styles";

interface UserProfileCardProps {
	profileCardBackground?: string;
	avatarSize?: string;
	avatarSrc?: string;
	userName: string;
	userNameColor?: string;
	userEmail: string;
	userEmailColor?: string;
	menuItems?: {
		label: string;
		onClick: () => void;
		icon?: React.FC;
	}[];
}

const UserProfileCard: React.FC<UserProfileCardProps> = ({
	userName,
	userEmail,
	avatarSrc,
	profileCardBackground = "whiteAlpha.300",
	avatarSize = "20px",
	userNameColor = "white",
	userEmailColor = colors.accent[500],
	menuItems,
}) => {
	return (
		<Box
			bg={profileCardBackground}
			borderRadius="xl"
			p={1}
			h="auto"
			w="auto"
			maxW="100%"
		>
			<HStack spacing="3" justify="space-between" maxW="100%">
				<HStack spacing="3" p={4} w="75%" maxW="75%">
					<Avatar boxSize={avatarSize} src={avatarSrc} mb={1} />
					<Box maxW="100%">
						<Text
							color={userNameColor}
							fontWeight="medium"
							fontSize="lg"
							overflow="hidden"
							whiteSpace="nowrap"
							textOverflow="ellipsis"
						>
							{userName}
						</Text>
						<Text
							fontSize="md"
							color={userEmailColor}
							overflow="hidden"
							whiteSpace="nowrap"
							textOverflow="ellipsis"
						>
							{userEmail}
						</Text>
					</Box>
				</HStack>
				<Menu>
					<MenuButton
						as={IconButton}
						variant="text"
						icon={<FiMoreVertical />}
						aria-label="Open Menu"
					/>
					<MenuList>
						{menuItems?.map((item, index) => {
							const Icon = item.icon;
							const additionalProps = Icon ? { icon: <Icon /> } : {};
							return (
								<MenuItem
									key={index}
									onClick={item.onClick}
									{...additionalProps}
								>
									{item.label}
								</MenuItem>
							);
						})}
					</MenuList>
				</Menu>
			</HStack>
		</Box>
	);
};

export default UserProfileCard;
