/**
 * @module CustomDivider
 * @description A custom divider component that can be used to separate content.
 * @param {string} width - The width of the divider.
 * @returns {JSX.Element}
 */
import React from "react";
import { Flex, Divider } from "@chakra-ui/react";
import { FaDiamond } from "react-icons/fa6";
import { colors } from "styles";

// Define interface for component props
interface CustomDividerProps {
	width?: string | number;
	marginY?: string | number;
}

/**
 * CustomDivider creates a horizontal line with icons at both ends,
 * styled according to the Figma design.
 *
 * @param {CustomDividerProps} props The props for the component.
 * @returns {React.ReactElement} A styled divider element with icons.
 */
const CustomDivider: React.FC<CustomDividerProps> = ({
	width = "80%",
	marginY = "10",
}) => {
	const IconComponent = FaDiamond;

	return (
		<Flex align="center" justify="center">
			<IconComponent color={colors.primary[700]} />
			<Divider
				my={marginY}
				borderColor={colors.primary[700]}
				borderWidth="1px"
				width={width}
				variant="dashed"
			/>
			<IconComponent color={colors.primary[700]} />
		</Flex>
	);
};

export default CustomDivider;
