import { ComponentStyleConfig } from "@chakra-ui/react";
import { colors } from "styles";

const inputStyles: ComponentStyleConfig = {
	// parts: ["field"],
	baseStyle: {
		field: {
			focusBorderColor: "blue.500",
			errorBorderColor: "red.500",
		},
	},
	variants: {
		outline: () => ({
			field: {
				borderColor: colors.graydient[300],
				_hover: {
					borderColor: "gray.400",
				},
				_focus: {
					borderColor: "blue.500",
					boxShadow: "0 0 0 1px blue.500",
				},
			},
		}),
		filled: () => ({
			field: {
				background: colors.graydient[100],
				borderColor: "transparent",
				_hover: {
					background: colors.graydient[200],
				},
				_focus: {
					borderColor: "blue.500",
					boxShadow: "0 0 0 1px blue.500",
				},
			},
		}),
		base: () => ({
			field: {
				borderColor: colors.graydient[300],
				borderWidth: "1px",
				boxShadow: "base",
				color: colors.graydient[600],
				_hover: {
					borderColor: "gray.400",
					boxShadow: "lg",
				},
				_focus: {
					borderColor: "blue.500",
					boxShadow: "0 0 0 1px blue.500",
				},
				"::placeholder": {
					color: colors.graydient[500],
				},
			},
		}),
		// Add more variants as needed
	},
	// defaultProps can be added if needed
};

export default inputStyles;
