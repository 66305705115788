/**
 * @module useConveyorBeltCarousel
 * @description A custom hook for creating a conveyor belt carousel.
 */
import { useEffect, useState } from "react";
import { useBreakpointValue } from "@chakra-ui/react";

/**
 * @interface CarouselConfig
 * @property {number} scrollDurationFactor The duration factor for scrolling animation.
 */
interface CarouselConfig {
	scrollDurationFactor: number;
}

const useConveyorBeltCarousel = (items: any[], config: CarouselConfig) => {
	const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

	// Dynamically adjust item width based on viewport size
	const numItems =
		useBreakpointValue(
			{
				base: 1,
				lg: 2,
				xl: 3,
			},
			{ ssr: false },
		) || 1;
	const itemWidthPercentage = 100 / numItems;

	// Update viewport width on window resize
	useEffect(() => {
		const updateViewportWidth = () => {
			setViewportWidth(window.innerWidth);
		};

		window.addEventListener("resize", updateViewportWidth);
		return () => window.removeEventListener("resize", updateViewportWidth);
	}, []);

	// Calculate total width based on the number of items and their calculated width
	const totalWidth =
		items.length * (viewportWidth * (itemWidthPercentage / 100));

	const scrollAnimation =
		items.length >= 3
			? {
					animate: {
						x: [0, -totalWidth],
						transition: {
							x: {
								repeat: Infinity,
								repeatType: "loop",
								duration: items.length * config.scrollDurationFactor,
								ease: "linear",
							},
						},
					},
			  }
			: {};

	return { totalWidth, scrollAnimation };
};

export default useConveyorBeltCarousel;
