/**
 * @module Commercial.tsx
 * @desc Commercial page component
 */
import React, { useState } from "react";
import { Flex, VStack } from "@chakra-ui/react";
import Hero from "components/features/heros/ComponentHero";
import { HorizontalCta } from "components";
import { HorizontalCtaModal } from "components/features";
import { useModal } from "hooks";
import {
	Commercial_CTA,
	Commercial_Hero2,
	Commercial_Security,
	Commercial_Sports,
	Commercial_Gate,
} from "assets";

const commercialCTAs = [
	{
		title: "Commercial Services",
		description:
			"When it comes to commercial fencing needs, Wayside Fence Company stands out with decades of experience and expertise. Whether you're securing a business property, enhancing privacy, or delineating boundaries, Wayside Fence Company offers tailored solutions to meet your specific requirements. Their commitment to quality craftsmanship and reliable service ensures that businesses receive fencing solutions that are durable, secure, and professionally installed.",
		buttonText: "Learn More",
		imageUrl: Commercial_CTA,
		imageAlt: "Commercial Services",
		textPosition: "left",
		modalContent: {
			title: "Commercial Services",
			descriptions: [
				"Whether you're securing a business property, enhancing privacy, or delineating boundaries, Wayside Fence Company offers tailored solutions to meet your specific requirements.",
				"Their commitment to quality craftsmanship and reliable service ensures that businesses receive fencing solutions that are durable, secure, and professionally installed.",
			],
			images: [],
		},
	},
	{
		title: "High Security",
		description:
			"Essential for settings demanding the utmost security and protection, commercial fencing from Wayside Fence Company is best suited for businesses and local government buildings.",
		buttonText: "Learn More",
		imageUrl: Commercial_Security,
		imageAlt: "High Security",
		textPosition: "right",
		modalContent: {
			title: "High Security",
			descriptions: [
				"Their extensive range of options ensures they can meet stringent security requirements while maintaining aesthetic appeal and functionality.",
				"Whether it's perimeter fencing, access control systems, or specialized barriers, Wayside Fence Company provides solutions that prioritize both security and durability, crucial for safeguarding high-value assets and sensitive locations.",
			],
			images: [],
		},
	},
	{
		title: "Commercial Gates",
		description:
			"For businesses and municipalities alike, having a durable and dependable gate is essential for ensuring security and controlling access effectively.",
		buttonText: "Learn More",
		imageUrl: Commercial_Gate,
		imageAlt: "Commercial Gates",
		textPosition: "left",
		modalContent: {
			title: "Commercial Gates",
			descriptions: [
				"Whether it's a commercial facility, government building, or industrial site, a robust gate serves as the first line of defense against unauthorized entry. Wayside Fence Company understands the importance of reliable gate systems and offers a variety of options tailored to specific security needs.",
				"From automated gates with access control features to heavy-duty manual gates, their solutions are designed to withstand continuous use while providing peace of mind that your property is protected.",
			],
			images: [],
		},
	},
	{
		title: "Parks, Recreation, and Sports Facilities",
		description:
			"At Wayside Fence Company, we specialize in a wide range of fencing solutions tailored to various needs. Whether it's installing fences for tennis courts, baseball fields, pool enclosures, or securing parking lot entry points, we have the expertise and experience to handle it all.",
		buttonText: "Learn More",
		imageUrl: Commercial_Sports,
		imageAlt: "Parks, Recreation, and Sports Facilities",
		textPosition: "right",
		modalContent: {
			title: "Parks, Recreation, and Sports Facilities",
			descriptions: [
				"Our goal is to provide durable, high-quality fencing that not only enhances safety and security but also complements the aesthetic and functional requirements of each specific application.",
				"From design to installation, Wayside Fence Company ensures your project is completed to the highest standards, delivering solutions that meet your exact specifications and exceed expectations.",
			],
			images: [],
		},
	},
];

/**
 * Commercial page component
 * @returns {React.ReactElement} - Rendered component
 */
const Commercial = () => {
	// modal state
	const [modalContent, setModalContent] = useState({});
	const { isOpen, open, close } = useModal();

	// open modal handler
	const handleOpenModal = (content: React.SetStateAction<{}>) => {
		setModalContent(content);
		open();
	};

	return (
		<Flex direction="column" gap={5}>
			<Hero
				titleText="Commercial"
				backgroundImage={Commercial_Hero2}
				backgroundColor="rgba(0, 0, 0, 0.36)"
				boxProps={{
					height: { base: "250px", md: "350px" },
					bgPos: "center",
					bgSize: "cover",
					bgPosition: "center",
				}}
				imageProps={{ objectFit: "cover", objectPosition: "center 40%" }}
				textProps={{
					fontSize: { base: "3xl", md: "5xl" },
					textAlign: "center",
					textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
				}}
			/>
			<VStack
				spacing={16}
				px="8"
				py="2"
				w="100%"
				h="100%"
				mb={{ base: "2", md: "10" }}
			>
				{commercialCTAs.map((cta, index) => (
					<HorizontalCta
						key={index}
						title={cta.title}
						description={cta.description}
						buttonText={cta.buttonText}
						imageUrl={cta.imageUrl}
						imageAlt={cta.imageAlt}
						textPosition={cta.textPosition as "left" | "right"}
						onOpenModal={() => handleOpenModal(cta.modalContent)}
					/>
				))}
			</VStack>
			{isOpen && (
				<HorizontalCtaModal
					isOpen={isOpen}
					onClose={close}
					modalContent={{
						title: "",
						descriptions: [],
						images: [],
						...modalContent,
					}}
				/>
			)}
		</Flex>
	);
};

export default Commercial;
