import { useState } from "react";

import { PaginationInput, FilterFields, SortInput } from "services/types";

const useRetrieval = () => {
	const [paginationInput, setPaginationInput] = useState<PaginationInput>({
		limit: 5,
		offset: 0,
	});

	const [filterSettings, setFilterSettings] = useState<FilterFields>({
		query: "",
		conditional: "OR",
	});

	const [sortInput, setSortInput] = useState<SortInput>({
		field: "_id",
		order: "asc",
	});

	const handleFiltersChange = (
		query: string,
		conditional: "AND" | "OR" = "OR",
	) => {
		setFilterSettings({ query, conditional });
	};

	const handleSortChange = (field: string, order: "asc" | "desc") => {
		setSortInput({ field, order });
	};

	return {
		filterSettings,
		handleFiltersChange,
		paginationInput,
		setPaginationInput,
		sortInput,
		handleSortChange,
	};
};

export default useRetrieval;
