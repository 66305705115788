import { gql } from "@apollo/client";

const authEmployee = gql`
	query authEmployee {
		authEmployee {
			_id
			firstName
			lastName
			email
			phone
			role
			accessLevel
			assignedContent
		}
	}
`;

const getAssignedContent = gql`
	query getAssignedContent($retrievalInput: RetrievalInput) {
		getAssignedContent(retrievalInput: $retrievalInput) {
			items {
				_id
				title
				type
				path
			}
			totalCount
			hasNextPage
			hasPreviousPage
		}
	}
`;

export { authEmployee, getAssignedContent };
