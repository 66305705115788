import GoogleAnalytics from "react-ga4";

const measurementId = "G-D20YSMD3TZ";

export const initializeAnalytics = (): boolean => {
	try {
		GoogleAnalytics.initialize(measurementId);
		return true;
	} catch (error) {
		console.error("Failed to initialize Google Analytics:", error);
		return false;
	}
};

export const trackPageView = (path: string) => {
	try {
		GoogleAnalytics.send({
			hitType: "pageview",
			page: path,
		});
	} catch (error) {
		console.error("Failed to track page view:", error);
	}
};

export const trackEvent = (
	category: string,
	action: string,
	label?: string,
	value?: number,
) => {
	try {
		GoogleAnalytics.event({
			category: category,
			action: action,
			label: label,
			value: value,
		});
	} catch (error) {
		console.error("Failed to track event:", error);
	}
};
