import { useCreateGalleryImage } from "services";
import { FieldDefinition } from "components";
import { FileInput } from "components/common/inputs";

import { GalleryImageInput } from "services/types";

import { useFormWithFiles } from "hooks/forms";
import { GalleryCreate } from "cud";

import { ICUDFormHook } from "./types";

const useCreateGalleryForm = ({ refetch }: ICUDFormHook) => {
	const initialFormState = {
		title: "",
		image: "",
	};

	const {
		formState,
		loading,
		setLoading,
		handleInputChange,
		resetFormState,
		toast,
		files: file,
		handleSetFiles: setFile,
		uploadFile,
	} = useFormWithFiles<GalleryImageInput>({
		initialState: initialFormState,
		allowMultiple: false,
	});

	const { createGalleryImage } = useCreateGalleryImage();

	const galleryCreate = new GalleryCreate(
		toast,
		createGalleryImage,
		refetch,
		file as File | null,
		uploadFile,
	);

	const handleSubmit = async () => {
		setLoading(true);
		const res = await galleryCreate.handleCreate(formState);
		if (res) resetFormState();
		setLoading(false);
	};

	const fields: FieldDefinition[] = [
		{
			type: "text",
			name: "title",
			label: "Title",
			value: formState.title,
			placeholder: "Enter a title for the gallery image...",
			onChange: handleInputChange,
			variant: "base",
			size: "lg",
		},
		{
			type: "custom",
			name: "image",
			label: "Image",
			required: true,
			customComponent: FileInput,
			customComponentProps: {
				fileType: "image",
				files: file ? [file] : [],
				setFiles: setFile,
				allowMultiple: false,
			},
		},
	];

	return {
		loading,
		handleSubmit,
		fields,
	};
};

export default useCreateGalleryForm;
