import React from "react";
import { Box, BoxProps } from "@chakra-ui/react";

/**
 * `BoxGridProps` interface extends the properties from `BoxProps` of Chakra UI.
 * It includes additional properties specific to the `BoxGrid` component.
 *
 * @property {Object} columns - Defines the number of columns for different breakpoints.
 * @property {Object} spacing - Defines the spacing for different breakpoints.
 * @property {Object} mt - Margin top values for different breakpoints.
 * @property {number} gap - Gap between grid items.
 * @property {React.ReactNode} children - React children nodes to be rendered inside the grid.
 */
interface BoxGridProps extends BoxProps {
	columns?: { base: string; md?: string; lg?: string; [key: string]: any };
	spacing?: { base: number; md?: number; lg?: number; [key: string]: any };
	mt?: { base: number; md?: number; lg?: number; [key: string]: any };
	gap?: number;
	children: React.ReactNode;
}

/**
 * `BoxGrid` is a React functional component utilizing Chakra UI's Box component
 * to create a grid layout. It allows for responsive column adjustments, spacing,
 * margin, and gap configuration.
 *
 * @param {BoxGridProps} props - The properties passed to the component.
 * @returns {React.ReactElement} - A styled Box component as a grid container with children.
 */
const BoxGrid: React.FC<BoxGridProps> = ({
	columns,
	gap = 6,
	spacing,
	mt,
	children,
}) => {
	return (
		<Box
			display="grid"
			gridTemplateColumns={columns}
			gap={gap}
			mt={mt}
			{...spacing}
			px={{ base: 6, md: 12, lg: 24 }}
		>
			{children}
		</Box>
	);
};

BoxGrid.defaultProps = {
	columns: {
		base: "repeat(1, 1fr)",
		md: "repeat(3, 1fr)",
	},
};

export default BoxGrid;
