import React from "react";
import { ModalWrapper } from "wrappers";
import { usePositionApplicationForm } from "hooks/form-hooks";
import { Form } from "components";
import { Position } from "services";

interface PositionApplicationModalProps {
	position: Position;
	isOpen: boolean;
	onClose: () => void;
}

const PositionApplicationModal: React.FC<PositionApplicationModalProps> = ({
	position,
	isOpen,
	onClose,
}) => {
	const { loading, fields, handleSubmit } = usePositionApplicationForm(
		position._id,
	);

	const onSubmit = async () => {
		await handleSubmit();
		onClose();
	};

	return (
		<ModalWrapper
			heading={`Apply for ${position.positionName}`}
			isOpen={isOpen}
			onClose={onClose}
			size="2xl"
		>
			<Form onSubmit={onSubmit} fields={fields} isLoading={loading} />
		</ModalWrapper>
	);
};

export default PositionApplicationModal;
