/**
 * @module Partners.tsx
 */
import React, { useContext } from "react";
import { PageChangeContext } from "contexts";
import { Heading, Button, Text, Image } from "@chakra-ui/react";
import { SectionWrapper } from "wrappers";
import ResponsiveImageGrid from "components/common/grids/ImageGrid";
import { Link } from "react-router-dom";
import { useGetPartners } from "services";
import { Loader, ErrorMessage } from "components";

interface PartnershipsSectionProps {
	description: string;
}

const PartnershipsSection: React.FC<PartnershipsSectionProps> = ({
	description,
}) => {
	const { handlePageChange } = useContext(PageChangeContext);

	const { loading, error, data: partners } = useGetPartners();

	if (loading) return <Loader />;

	if (error)
		return (
			<ErrorMessage
				title={"Were unable to load our partners."}
				description={
					"You can try refreshing your browser or try again later. We apologize for the inconvenience."
				}
				alertProps={{ minHeight: "85vh" }}
			/>
		);

	if (!partners) return <></>;

	const handlePartnerClick = (index: number) => {
		handlePageChange(`products/${partners.items[index].name}`);
	};

	return (
		<>
			<Heading
				variant="sectionTitle"
				mb={6}
				alignSelf="center"
				mx={{ base: "4", md: "40" }}
			>
				Our Partners
			</Heading>
			<Text
				variant="statement"
				alignSelf="center"
				textAlign={"center"}
				as="p"
				mb={{ base: "0", md: "8" }}
				px={{ base: "4", md: "48" }}
				pb={{ base: "8" }}
				w="65%"
			>
				{description}
			</Text>
			<SectionWrapper>
				<ResponsiveImageGrid
					columnCount={{ base: 2, sm: 3, md: 4 }}
					spacing={{ base: 6, md: 10 }}
					height="200px"
					width={{ base: "150px", md: "200px" }}
					boxStyles={{
						borderWidth: "1px",
						borderRadius: "lg",
						boxShadow: "lg",
					}}
					onBoxClick={handlePartnerClick}
				>
					{partners.items?.map((partner, index) => (
						<Image
							key={index}
							src={partner.details?.image || ""}
							alt={partner.name || ""}
							maxH="100%"
							maxW="100%"
							objectFit="cover"
						/>
					))}
				</ResponsiveImageGrid>
			</SectionWrapper>
			<Button
				as={Link}
				mt={8}
				variant="primary"
				size={{ base: "md", md: "lg" }}
				width={{ base: "full", md: "auto" }}
				alignSelf="center"
				to="/resources/our-partners"
			>
				View All Partners
			</Button>
		</>
	);
};

export default PartnershipsSection;
