import {
	Button,
	List,
	ListItem,
	ListIcon,
	Box,
	Heading,
	Flex,
	useMediaQuery,
} from "@chakra-ui/react";
import { colors } from "styles";
import { HiOutlineCheckCircle } from "react-icons/hi";
import { TOS } from "assets";
import {
	Drawer,
	DrawerBody,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	useDisclosure,
} from "@chakra-ui/react";

const terms = [
	"All sales are C.O.D. cash or certified check for new accounts on initial orders.",
	"Company checks will only be accepted only after your completed check acceptance form is approved. Company checks are guaranteed through UTA Check Service. We do not accept 3rd Party (your customer’s) credit cards for payment.",
	"Once a credit line is established, our standard terms are Net 30 days. Any purchase over the credit limit will be C.O.D.",
	"We also accept Visa, MasterCard, American Express and Discover for your convenience. We do not accept 3rd Party (your customer’s) credit cards for payment.",
	"Full payment is required on all custom orders.",
	"There is a standard fee of $50.00 for returned checks.",
	"Orders on credit hold will not be scheduled until your account is current.",
	"Finance charges of 1-1/2% per month will be assessed on past due balances.",
	"No returns on the following items: · Aluminum · PVC · Custom work · Special order items · Vinyl chain link systems · Slats · Wood · All hardware · Other non-stock items",
	"Orders must be reviewed and signed upon delivery. Report issues upon delivery or within 48 hours of receipt. Claims are to be noted on presented paperwork and submitted with photos for credit approval.",
	"We also have a 25% restocking fee for any acceptable and marketable products that are returned.",
];

const Terms = () => {
	const [isLargerThan768] = useMediaQuery("(min-width: 1025px)");
	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<>
			<Heading
				display={"flex"}
				alignItems={"center"}
				justifyContent={"center"}
				size={{ base: "3xl", md: "4xl" }}
				variant={"primary"}
				pt={{ base: 10, md: 20 }}
				mx={4}
				textAlign="center"
				letterSpacing={".0005em"}
			>
				Terms Of Sale
			</Heading>
			<Flex
				justifyContent={"center"}
				alignItems={"center"}
				flexDirection={"column"}
				//alignSelf={"center"}
				p={10}
				overflow={"hidden"}
				gap={6}
			>
				{isLargerThan768 ? (
					<Box
						display={{ base: "none", md: "flex" }}
						alignItems={"center"}
						flexDirection={"column"}
						backgroundColor="white"
						p={{ base: 12, md: 10 }}
						w={{ base: "auto", md: "60%" }}
						gap={4}
						borderRadius={10}
						//boxShadow="inset 1px 1px 2px #CBD5E0, inset -1px -1px 2px #CBD5E0"
					>
						<List spacing={4} fontSize={"24px"}>
							{terms.map((term, index) => (
								<ListItem key={index}>
									<ListIcon
										as={HiOutlineCheckCircle}
										color={colors.primary[500]}
										boxSize={"40px"}
									/>
									{term}
								</ListItem>
							))}
						</List>
					</Box>
				) : (
					<>
						<Button
							w="80%"
							h={{ base: "90px", md: "45px" }}
							fontWeight={"semibold"}
							variant="outline"
							onClick={onOpen}
						>
							View Details
						</Button>
						<Drawer
							isOpen={isOpen}
							placement="top"
							onClose={onClose}
							allowPinchZoom
							preserveScrollBarGap
						>
							<DrawerOverlay>
								<DrawerContent>
									<DrawerCloseButton />
									<DrawerHeader>Terms of Sale</DrawerHeader>
									<DrawerBody>
										<List spacing={4} fontSize={"16px"}>
											{terms.map((term, index) => (
												<ListItem key={index}>
													<ListIcon
														as={HiOutlineCheckCircle}
														color={colors.primary[500]}
														boxSize={"24px"}
													/>
													{term}
												</ListItem>
											))}
										</List>
									</DrawerBody>
								</DrawerContent>
							</DrawerOverlay>
						</Drawer>
					</>
				)}

				<Button
					w="80%"
					h={{ base: "90px", md: "45px" }}
					fontWeight={"semibold"}
					variant="outline"
					onClick={() => window.open(TOS, "_blank", "noopener noreferrer")}
				>
					View Terms Of Sale PDF
				</Button>
			</Flex>
		</>
	);
};

export default Terms;
