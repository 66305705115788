import React, { ReactNode } from "react";
import { Wrap, WrapItem } from "@chakra-ui/react";

interface ButtonStackProps {
	children: ReactNode;
	width?: string;
}

const ButtonStack: React.FC<ButtonStackProps> = ({ children, width }) => {
	return (
		<Wrap width={width} spacing="1rem" justify="center">
			{React.Children.map(children, (child) => (
				<WrapItem>{child}</WrapItem>
			))}
		</Wrap>
	);
};

export default ButtonStack;
