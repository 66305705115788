import React from "react";
import { Select, SelectProps } from "@chakra-ui/react";

const states = [
	"AL",
	"AK",
	"AZ",
	"AR",
	"CA",
	"CO",
	"CT",
	"DE",
	"FL",
	"GA",
	"HI",
	"ID",
	"IL",
	"IN",
	"IA",
	"KS",
	"KY",
	"LA",
	"ME",
	"MD",
	"MA",
	"MI",
	"MN",
	"MS",
	"MO",
	"MT",
	"NE",
	"NV",
	"NH",
	"NJ",
	"NM",
	"NY",
	"NC",
	"ND",
	"OH",
	"OK",
	"OR",
	"PA",
	"RI",
	"SC",
	"SD",
	"TN",
	"TX",
	"UT",
	"VT",
	"VA",
	"WA",
	"WV",
	"WI",
	"WY",
];

// Define an interface for the component's props
interface StateInputProps {
	name: string;
	value: string;
	onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
	placeholder?: string;
	props?: SelectProps;
}

const StateInput: React.FC<StateInputProps> = ({
	name,
	value,
	onChange,
	placeholder = "Select a state",
	...props
}) => {
	const memoizedOnChange = React.useCallback(onChange, [onChange]);

	return (
		<Select
			name={name}
			value={value}
			onChange={memoizedOnChange}
			placeholder={placeholder}
			{...props}
		>
			{states.map((state) => (
				<option key={state} value={state}>
					{state}
				</option>
			))}
		</Select>
	);
};

export default StateInput;
