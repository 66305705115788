/**
 * @module NavButton.tsx
 * @description Navigation button component using Chakra UI and React Router for navigation.
 */
import { Button, ButtonProps, forwardRef } from "@chakra-ui/react";
import {
	Link as RouterLink,
	LinkProps as RouterLinkProps,
} from "react-router-dom";
import { useContext } from "react";
import { PageChangeContext } from "../../../contexts/PageChangeContext";

/**
 * @typedef NavButtonProps
 * @property {ButtonProps} [ButtonProps] - Props inherited from Chakra UI Button.
 * @property {RouterLinkProps} [RouterLinkProps] - Props inherited from React Router Link.
 * @description Props for the NavButton component, combining Chakra UI ButtonProps and React Router LinkProps.
 */
type NavButtonProps = ButtonProps &
	RouterLinkProps & {
		to?: string;
		clickCallback?: () => void;
	};

const NavButton: React.FC<NavButtonProps> = forwardRef<NavButtonProps, "div">(
	({ to, clickCallback, children, ...props }, ref) => {
		const { handlePageChange } = useContext(PageChangeContext);

		const handleClick = (newPage: string) => {
			handlePageChange(newPage);
			if (clickCallback) clickCallback();
		};

		return (
			<Button
				as={RouterLink}
				ref={ref}
				variant={props.variant || "ghost_white"}
				fontWeight="bold"
				fontSize="1.1rem"
				boxSize="auto"
				onClick={() => handleClick(to as string)}
				to={to}
				{...props}
				_hover={{ color: "primary.200", transform: "translateY(-2px)" }}
			>
				{children}
			</Button>
		);
	},
);

export default NavButton;
