import React, { useContext } from "react";
import { PageChangeContext } from "contexts";
import ResponsiveImageGrid from "components/common/grids/ImageGrid";
import { Heading, Image } from "@chakra-ui/react";
import { colors } from "styles";
import { useGetPartners } from "services";
import { Loader, ErrorMessage } from "components/common/ui-elements";

const Associations = () => {
	const { handlePageChange } = useContext(PageChangeContext);

	const { loading, error, data: partners } = useGetPartners();

	if (loading) return <Loader />;

	if (error) {
		return (
			<ErrorMessage
				title={"We were unable to load our partners."}
				description={
					"You can try refreshing your browser or try again later. We apologize for the inconvenience."
				}
				alertProps={{ minHeight: "85vh" }}
			/>
		);
	}

	if (!partners) return <></>;

	const handleBoxClick = (index: number) => {
		const currPartner = partners.items[index];
		const url = currPartner.details?.url;
		if (url) {
			window.open(url, "_blank");
		} else {
			handlePageChange(`products/${currPartner.name}`);
		}
	};

	return (
		<>
			<Heading
				display={"flex"}
				alignItems={"center"}
				justifyContent={"center"}
				variant="primary"
				size={{ base: "3xl", md: "4xl" }}
				pt={{ base: 10, md: 20 }}
				mx={4}
				textAlign="center"
				pb={10}
				letterSpacing={".0005em"}
			>
				Associations:
			</Heading>

			<ResponsiveImageGrid
				spacing={{ base: 6, md: 10, xl: 16 }}
				onBoxClick={(index) => handleBoxClick(index)}
				minChildWidth={"325px"}
				width={{ base: "170px", md: "195px" }}
				height={{ base: "auto", md: "200px" }}
				boxStyles={{
					borderWidth: "1px",
					borderColor: colors.graydient[400],
					justifySelf: "center",
					borderRadius: "lg",
					boxShadow: "lg",
				}}
			>
				{partners.items
					.filter((partner) => partner.type === "Association")
					.map((partner, index) => (
						<a
							href={partner.details?.url || "#"}
							key={`association-partner-${index}`}
							onClick={(e) => e.preventDefault()}
						>
							<Image
								src={partner.details?.image || ""}
								alt={partner.name}
								objectFit="cover"
								cursor={"pointer"}
								_hover={{ opacity: 0.8 }}
								transition="all 0.3s ease-in-out"
							/>
						</a>
					))}
			</ResponsiveImageGrid>
		</>
	);
};

export default Associations;
